import { useTable } from "react-table";
import { useMemo } from "react";
import { formatCurrency } from "../../../helpers";

const useAllPaymentEOD = ({ paymentEOD, refetch }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "dates",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          return value && !isNaN(date.getTime())
            ? date.toLocaleDateString("en-US")
            : "N/A";
        },
      },
      {
        Header: "Payer",
        accessor: "payer",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "AMOUNT",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          value ? formatCurrency({ value: value, currencyCode: "NGN" }) : "N/A",
      },
      {
        Header: "Type",
        accessor: "paid_through",
        Cell: ({ cell: { value } }) =>
          value ? (value === "C" ? "Card" : "Transfer") : "N/A",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "8px 8px",
              height: "24px",
              width: "90px",
              maxWidth: "90px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value === "S" ? "Successful" : "Fail"}
          </span>
        ),
      },
    ],
    [refetch],
  );

  const handleRenderStatusIndicator = (value) => {
    if (value === "S") {
      return { color: "#19AE57", backgroundColor: "#E5FFF0" };
    } else {
      return { color: "#E02020", backgroundColor: "#FFEEE9" };
    }
  };

  const instance = useTable({
    columns,
    data: paymentEOD?.eod_payment_list_data || [],
  });
  return {
    instance,
  };
};

export default useAllPaymentEOD;
