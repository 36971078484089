import { getPlatform } from "../helpers";

const appBaseUrl = getPlatform();

export const pathConstants = {
  // AUTH PATH
  SIGNUP: "/signup",
  SIGNUP_INVITE: "/signup/team-invite",
  LOGIN: "/login",
  LOGIN_VERIFICATION: "/login/otp-verification",
  FORGOT_PASSOWRD: "/forgot-password",
  EMAIL_VERIFICATION: "/email-verification",
  RESET_PASSWORD: "/reset-password",

  // Public pages
  SUBSCRIPTION_PLAN: "/subscription-plans",
  MENU: ({ id, path = "" }) => {
    return `/storefront/${id}${path}`;
  },
  MENU_ORDER_STATUS: "/order-status/:id",
  PLATFORM_NAVIGATOR: "/platform-navigator",

  // app path
  DASHBOARD_MAIN: appBaseUrl + "/dashboard",
  DASHBOARD_OVERVIEW: appBaseUrl + "/payments/overview/general",
  BANK_ACCOUNTINGS: appBaseUrl + "/accountings/bank/account",
  CASH_ACCOUNTINGS: appBaseUrl + "/accountings/cash/account",
  ACCOUNTINGS_TRANSACTION: appBaseUrl + "/accountings/bank/transation",
  DASHBOARD_CASHFLOW_ANALYTICS:
    appBaseUrl + "/payments/overview/cashflow-analytics",
  DASHBOARD_CUSTOMER_ANALYTICS:
    appBaseUrl + "/payments/overview/customer-analytics",

  PAYMENT_TRANSFER: appBaseUrl + "/payments/transfers",
  PAYMENT_TRANSFER_DETAILS: ({ transferId }) => {
    return appBaseUrl + `/payments/transfers/${transferId}`;
  },
  PAYMENT_TRANSFER_APPROVED: appBaseUrl + "/payments/transfers/approved",
  PAYMENT_TRANSFER_PENDING: appBaseUrl + "/payments/transfers/pending",
  PAYMENT_TRANSFER_REJECTED: appBaseUrl + "/payments/transfers/rejected",
  PAYMENT_TRANSFER_SAVEDBENEFICIARY:
    appBaseUrl + "/payments/transfers/beneficiaries",

  PAYMENT_TRANSACTIONS_ALL: appBaseUrl + "/payments/transactions",
  PAYMENT_TRANSACTIONS_DETAILS: ({ id }) => {
    return appBaseUrl + `/payments/transactions/${id}`;
  },

  PAYMENT_SUBACCOUNTS: appBaseUrl + "/payments/sub-accounts",
  PAYMENT_SUBACCOUNTS_CREATE: appBaseUrl + "/payments/sub-accounts/create",

  PAYMENT_POS_ALL_TERMINALS: appBaseUrl + "/payments/pos/all",
  PAYMENT_POS_TERMINAL_DETAILS: ({ terminalId }) => {
    return appBaseUrl + `/payments/pos/${terminalId}`;
  },
  PAYMENT_POS_TERMINAL_CREDENTIALS: ({ terminalId }) => {
    return appBaseUrl + `/payments/pos/${terminalId}/credentials`;
  },
  PAYMENT_POS_REQUEST_TERMINAL: appBaseUrl + "/payments/pos/request",
  PAYMENT_POS_ADD_TERMINALS: appBaseUrl + "/payments/pos/add",
  PAYMENT_ALL_TAGS: appBaseUrl + "/payments/tags",

  INVENTORY: appBaseUrl + "/inventory",
  INVENTORY_ANALYTICS: appBaseUrl + "/inventory/analytics",
  INVENTORY_PRODUCTS: appBaseUrl + "/inventory/products",
  INVENTORY_PRODUCTS_ADD_SINGLE: appBaseUrl + "/inventory/products/add/single",
  INVENTORY_PRODUCTS_ADD_MULTIPLE:
    appBaseUrl + "/inventory/products/add/multiple",
  INVENTORY_PRODUCTS_ADD_COMPOSITE:
    appBaseUrl + "/inventory/products/add/composite",
  INVENTORY_PRODUCT_EDIT: ({ id }) => {
    return appBaseUrl + `/inventory/products/${id}/edit`;
  },
  INVENTORY_STORE_EDIT: ({ id }) => {
    return appBaseUrl + `/inventory/stores/${id}/edit`;
  },
  INVENTORY_SERVICE_EDIT: ({ id }) => {
    return appBaseUrl + `/inventory/services/${id}/edit`;
  },
  INVENTORY_PRODUCTS_EDIT_COMPOSITE: ({ id }) => {
    return appBaseUrl + `/composite-products/${id}/edit`;
  },
  INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY:
    appBaseUrl + "/inventory/stock-transfers",
  INVENTORY_PRODUCT_BULK_TRANSFER:
    appBaseUrl + "/inventory/stock-transfers/create",
  INVENTORY_STOCK_TRANSFER_DETAILS: ({ id }) => {
    return appBaseUrl + `/inventory/stock-transfers/${id}`;
  },
  INVENTORY_STOCK_TRANSFER_EDIT: ({ id }) => {
    return appBaseUrl + `/inventory/stock-transfers/${id}/edit`;
  },
  INVENTORY_PRODUCTS_BULK_RESTOCK:
    appBaseUrl + "/inventory/products/bulk-restock",
  INVENTORY_PRODUCT_STOCKHISTORY:
    appBaseUrl + "/inventory/products/:id/stock-history",
  INVENTORY_CATEGORIES: appBaseUrl + "/inventory/categories",
  INVENTORY_SALES_TRANSACTIONS: appBaseUrl + "/inventory/sales-transactions",
  INVENTORY_STOCK_HISTORY: appBaseUrl + "/inventory/stock-history",
  INVENTORY_SUPPLIERS: appBaseUrl + "/inventory/suppliers",
  INVENTORY_SUPPLIERS_DETAILS: appBaseUrl + "/inventory/suppliers/:id",
  INVENTORY_SUPPLIERS_DETAILS_EDIT:
    appBaseUrl + "/inventory/suppliers/:id/edit",
  INVENTORY_RECONCILIATIONS: appBaseUrl + "/inventory/reconciliations",
  INVENTORY_RECONCILIATIONS_EDIT:
    appBaseUrl + "/inventory/reconciliations/:id/edit",
  INVENTORY_RECONCILIATIONS_REVIEW:
    appBaseUrl + "/inventory/reconciliations/:id/review",
  INVENTORY_PRINTER: appBaseUrl + "/inventory/printer",
  INVENTORY_TABLES: appBaseUrl + "/inventory/tables",
  INVENTORY_TABLES_DETAILS: appBaseUrl + "/inventory/tables/:id",
  INVENTORY_STORES: appBaseUrl + "/inventory/stores",
  INVENTORY_STORES_CREATE: appBaseUrl + "/inventory/stores/create",
  INVENTORY_STORE_DETIALS: appBaseUrl + "/inventory/stores/:id",
  INVENTORY_CREDITLOG: appBaseUrl + "/inventory/debtors",
  INVENTORY_CREDITLOG_DETAILS: appBaseUrl + "/inventory/debtors/:customerId",
  INVENTORY_MENU: appBaseUrl + "/inventory/storefront",
  INVENTORY_PRODUCTS_BATCH: appBaseUrl + "/inventory/products-batch",
  INVENTORY_PRODUCTS_BATCH_EDIT: appBaseUrl + "/inventory/products-batch/edit",
  INVENTORY_MENU_DETAILS: ({ menuId }) => {
    return appBaseUrl + `/inventory/storefront/${menuId}/edit`;
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION: ({ menuId }) => {
    return appBaseUrl + `/inventory/storefront/${menuId}/edit/customization`;
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION_CHECKOUT: ({ menuId }) => {
    return (
      appBaseUrl + `/inventory/storefront/${menuId}/edit/customization/checkout`
    );
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION_PAYMENT: ({ menuId }) => {
    return (
      appBaseUrl + `/inventory/storefront/${menuId}/edit/customization/payment`
    );
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION_TEMPLATES: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/${menuId}/edit/customization/templates`
    );
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION_BRANDING: ({ menuId }) => {
    return (
      appBaseUrl + `/inventory/storefront/${menuId}/edit/customization/branding`
    );
  },
  INVENTORY_MENU_DETAILS_CUSTOMIZATION_SORT_CATEGORIES: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/${menuId}/edit/customization/sort-categories`
    );
  },
  INVENTORY_MENU_CREATE: appBaseUrl + "/inventory/storefront/create",
  INVENTORY_MENU_CREATE_EDIT: ({ menuId }) => {
    return appBaseUrl + `/inventory/storefront/${menuId}/create`;
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION: ({ menuId }) => {
    return appBaseUrl + `/inventory/storefront/create/${menuId}/customization`;
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION_CHECKOUT: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/create/${menuId}/customization/checkout`
    );
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION_PAYMENT: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/create/${menuId}/customization/payment`
    );
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION_TEMPLATES: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/create/${menuId}/customization/templates`
    );
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION_BRANDING: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/create/${menuId}/customization/branding`
    );
  },
  INVENTORY_MENU_CREATE_CUSTOMIZATION_SORT_CATEGORIES: ({ menuId }) => {
    return (
      appBaseUrl +
      `/inventory/storefront/create/${menuId}/customization/sort-categories`
    );
  },
  INVENTORY_MENU_CREATE_SHIPPING: ({ menuId }) => {
    return appBaseUrl + `/inventory/storefront/create/${menuId}/shipping`;
  },
  INVENTORY_MENU_PAYMENT: appBaseUrl + "/inventory/storefront/payment-method",
  INVENTORY_SHIPPINGS: appBaseUrl + "/inventory/storefront/shippings",
  INVENTORY_RIDERS: appBaseUrl + "/inventory/storefront/riders",
  INVENTORY_MENU_PAYMENT_DETAILS: ({ paymentId }) => {
    return appBaseUrl + `/inventory/storefront/payment-method/${paymentId}`;
  },
  INVENTORY_STOREFRONT_ORDER_STATUS: ({ id, menuId }) => {
    return `/storefront/${menuId}/${id}/status`;
  },
  INVENTORY_STOREFRONT_RIDER_ORDERS: ({ id }) => {
    return `/storefront/rider-orders/${id}/`;
  },
  INVENTORY_STOREFRONT_ORDER_DETAILS: ({ id }) => {
    return `/storefront/order-details/${id}`;
  },
  INVENTORY_INVOICE_DETAILS: ({ id }) => {
    return appBaseUrl + `/inventory/invoices/${id}`;
  },
  CREDIT_LOG_DETAILS: ({ id }) => {
    return appBaseUrl + `/inventory/debtors/${id}`;
  },
  INVENTORY_PRODUCT_ITEM_TRACKING: ({ id }) => {
    return appBaseUrl + `/inventory/products/${id}/track-item`;
  },
  INVENTORY_STORE_WALLET_LIST: appBaseUrl + "/inventory/store-wallets",
  INVENTORY_STORE_WALLET_DETAILS: ({ id }) => {
    return appBaseUrl + `/inventory/store-wallets/${id}`;
  },
  INVENTORY_STORE_WALLET_TRANSACTION_DETAILS: ({ id }) => {
    return appBaseUrl + `/inventory/store-wallets/transaction/${id}`;
  },
  STORES: appBaseUrl + "/stores",
  SELL_MODE: "/sell-mode/:store_id",
  SECTIONS: appBaseUrl + "/sections",
  SECTION_MODE: ({ section_id }) => {
    return `/section-mode/${section_id}`;
  },
  SECTION_CUSTOMER_MODE: ({ store_id }) => {
    return `/sections-customesr/${store_id}`;
  },
  MANAGE_SECTIONS: appBaseUrl + "/manage-sections",

  EXPENSES_ANALYTICS: appBaseUrl + "/expenses/analytics",
  EXPENSES_DETAILS: appBaseUrl + "/expenses/:id",
  EXPENSES_LIST: appBaseUrl + "/expenses",
  EXPENSES_CATEGORIES: appBaseUrl + "/expenses/categories",

  ACCOUNTING_OVERVIEW: appBaseUrl + "/accounting",
  ACCOUNTING_PROFITANDLOSS: appBaseUrl + "/accounting/profit-and-loss",
  ACCOUNTING_DETAILS: ({ id }) => {
    return appBaseUrl + `/accounting/${id}`;
  },

  LOYALTY_MANAGE: appBaseUrl + "/loyalty/manage",
  LOYALTY_MANAGE_CAMPAIGN: appBaseUrl + "/loyalty/manage/campaigns",
  LOYALTY_CREATE_CAMPAIGN: appBaseUrl + "/loyalty/manage/campaigns/create",
  LOYALTY_EDIT_CAMPAIGN: ({ campaignId }) => {
    return appBaseUrl + `/loyalty/manage/campaigns/edit/${campaignId}`;
  },
  LOYALTY_MANAGE_GIFTCARDS: appBaseUrl + "/loyalty/manage/giftcards",
  LOYALTY_CREATE_GIFTCARD: appBaseUrl + "/loyalty/manage/giftcards/create",
  LOYALTY_EDIT_GIFTCARD: ({ giftcardId }) => {
    return appBaseUrl + `/loyalty/manage/giftcards/edit/${giftcardId}`;
  },
  LOYALTY_CUSTOMERS: appBaseUrl + "/loyalty/customers/all",
  LOYALTY_CUSTOMERS_DETAILS: ({ id }) => {
    return appBaseUrl + `/loyalty/customers/${id}`;
  },
  LOYALTY_CUSTOMERS_HISTORY: appBaseUrl + "/loyalty/customers/history",
  LOYALTY_NOTIFICATION: appBaseUrl + "/loyalty/account-status",

  DOCUMENTATION: appBaseUrl + "/documentation",

  CUSTOMERS: appBaseUrl + "/customers",
  CUSTOMER_DETAILS: ({ customerId }) => {
    return appBaseUrl + `/customers/${customerId}`;
  },
  KYB_VERIFICATION: appBaseUrl + "/kyb-verification",

  SETTINGS: appBaseUrl + "/settings",
  SETTINGS_USERS: appBaseUrl + "/settings/users",
  SETTINGS_SECURITY_PIN_QUESTION: appBaseUrl + "/settings/security/pin",
  SETTINGS_USERS_MANAGEROLES: appBaseUrl + "/settings/users/manage-roles",
  SETTINGS_USERS_MANAGEROLES_DETAILS: ({ id }) => {
    return appBaseUrl + `/settings/users/manage-roles/${id}`;
  },
  SETTINGS_USERS_MANAGEROLES_DEFAULT:
    appBaseUrl + "/settings/users/manage-roles/default",
  SETTINGS_USERS_MANAGEROLES_CUSTOM:
    appBaseUrl + "/settings/users/manage-roles/custom",
  SETTINGS_SETTLEMENTS: appBaseUrl + "/settings/settlements",
  SETTINGS_APIKEYS: appBaseUrl + "/settings/api-keys",
  SETTINGS_PROFILE: appBaseUrl + "/settings/profile",
  SETTINGS_BUSINESS_PROFILE: appBaseUrl + "/settings/business-profile",
  SETTINGS_SUBSCRIPTIONS: appBaseUrl + "/settings/subscriptions",
  SETTINGS_SUBSCRIPTIONS_PLANS: appBaseUrl + "/settings/subscriptions/plans",

  APP_SUBSCRIPTION_PLAN: appBaseUrl + "/subscription-plans",

  CLIENT_LOGIN: "/client/login/:token",
  CLIENT_EXISTING_CONNECT: "/client/login/existing-account/:token",

  REQUEST_CAPITAL: appBaseUrl + "/request_capital",

  //market place
  APPS_MAIN: appBaseUrl + "/markets",
  APPS_DETAILS: ({ id }) => {
    return appBaseUrl + `/markets/${id}/app`;
  },
};
