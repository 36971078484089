export const AppleIcon = () => {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4104 15.8533C21.3971 13.3953 22.5054 11.54 24.7486 10.1737C23.4935 8.37206 21.5974 7.38081 19.0937 7.18657C16.7236 6.99904 14.1332 8.57299 13.1852 8.57299C12.1837 8.57299 9.88703 7.25355 8.08441 7.25355C4.35899 7.31383 0.399902 10.234 0.399902 16.1748C0.399902 17.9296 0.720368 19.7424 1.3613 21.6133C2.21588 24.0713 5.30036 30.0992 8.51837 29.9988C10.2008 29.9586 11.3892 28.7999 13.5791 28.7999C15.7021 28.7999 16.8037 29.9988 18.6798 29.9988C21.9245 29.9519 24.7153 24.4732 25.5298 22.0085C21.1768 19.9523 21.4104 15.9806 21.4104 15.8533ZM17.6316 4.85579C19.4543 2.68576 19.2874 0.709951 19.2339 0C17.6249 0.093767 15.7622 1.09841 14.7007 2.33748C13.5323 3.66361 12.8447 5.30454 12.9915 7.15309C14.7341 7.28704 16.3231 6.38955 17.6316 4.85579Z"
        fill="black"
      />
    </svg>
  );
};
