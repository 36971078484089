import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../../hooks/context";
import { useDebitStoreWalletMutation } from "../../../../hooks/api/mutations/useInventory";
import { cleanObject } from "../../../../helpers";
import { useQueryClient } from "react-query";
import { useGetCustomerStoreWallets } from "../../../../hooks/api/queries";

const useStoreWalletDebit = ({ customerDetails, setIsDebitWallet }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [debitDetails, setDebitDetails] = useState({
    description: "",
    amount: "",
    store_id: "",
    wallet_id: "",
  });
  const [isOtpWidget, setIsOtpWidget] = useState(null);
  const currencyCode = storeList?.find(
    (item) => item?.id === debitDetails?.store_id,
  )?.currency_code;
  const debitStoreWallet = useDebitStoreWalletMutation();

  const { data: storeWallets, isLoading: isLoadingStoreWallets } =
    useGetCustomerStoreWallets({
      customer_id: customerDetails?.customerId,
    });

  const handleDebitWallet = () => {
    debitStoreWallet.mutate(
      cleanObject({
        ...debitDetails,
      }),
      {
        onSuccess: (res) => {
          const isOtpRequired = res?.data?.data?.otp_required;
          triggerToast(
            isOtpRequired
              ? "OTP has been sent to customer's phone number and email"
              : `A sum of ${currencyCode} ${debitDetails?.amount} has been debited`,
            "success",
          );
          if (isOtpRequired) return setIsOtpWidget(res?.data?.data);

          queryClient.invalidateQueries(["getStoreWallets"]);
          queryClient.invalidateQueries(["getStoreWalletDetails"]);
          queryClient.invalidateQueries(["getStoreWalletTransaction"]);
          setIsDebitWallet(null);
        },
      },
    );
  };

  return {
    debitDetails,
    storeList,
    isDebitingWallet: debitStoreWallet?.isLoading,
    isOtpWidget,
    currencyCode,
    setIsOtpWidget,
    setDebitDetails,
    handleDebitWallet,
    isLoadingStoreWallets,
    storeWallets,
  };
};

export default useStoreWalletDebit;
