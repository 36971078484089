import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { bulkRestock } from "../../../../services/inventory";
import {
  cleanObject,
  deepCopyFunction,
  getRandomIntInclusive,
} from "../../../../helpers";
import { uploadImage } from "../../../../services/media";
import { useGetSuppliers } from "../../../../hooks/api/queries/useInventory";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../../hooks/api/queries";
import { useGetInfo } from "../../../../hooks/useGetInfo";

export const useBulkRestock = () => {
  const [search, setSearch] = useSearchParams();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [currentStore, setCurrentStore] = useState(
    search.get("store_id") || null,
  );
  const [restockData, setRestockData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [otherRestockInfo, setOtherRestockInfo] = useState({
    supplier_id: "",
    invoice_number: "",
    order_number: "",
    invoice_date: "",
    discount: "",
    vat: "",
    amount_paid: "",
    payment_method: "",
    notes: "",
    invoice_url: "",
    invoice_due_date: "",
    ledger_account_id: "",
  });
  const [storeError, setStoreError] = useState("");
  const [isLoadingDocUpload, setIsLoadingDocUpload] = useState(false);
  const [modalViews, setModalViews] = useState({
    isWarning: false,
    isCreateSupplier: false,
  });
  const { getStoreInfo, getPricingInfo } = useGetInfo();
  const [detectedPricing, setDetectedPricing] = useState([]);

  const resetModalViews = (key, value = true) => {
    setModalViews({
      isCreateSupplier: false,
      isWarning: false,
      [key]: value,
    });
  };

  const handleAppendRestockData = (item) => {
    setRestockData((prevData) => [...prevData, item]);
  };

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter();

  const handleInvoiceUpload = (document) => {
    setIsLoadingDocUpload(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const formData = new FormData();
    formData.append("file", document);
    uploadImage({ formData })
      .then((res) => {
        setOtherRestockInfo((prevState) => ({
          ...prevState,
          invoice_url: res?.data?.data?.url,
        }));
        setIsLoadingDocUpload(false);
        triggerToast("Document successfully uploaded", "success");
      })
      .finally(() => setRequestLoaderProgress(100));
  };

  const {
    data: suppliers,
    isLoading: isFetchingSupplier,
    refetch: handleFetchSuppliers,
  } = useGetSuppliers("?per_page=1000");

  const calculateSubtotal = () => {
    const subTotal = restockData.reduce((prev, curr) => {
      if (curr?.quantity === null) {
        curr.quantity = 0;
      }
      return prev + curr?.quantity * curr?.cost_price;
    }, 0);

    setSubtotal(subTotal);
  };

  const filterByStore = (value) => {
    search.set("store_id", value);
    setSearch(search, { replace: true });
  };

  const { mutate: restockItems, isLoading: isRestocking } = useMutation(
    (data) => bulkRestock(data),
  );
  const restockProducts = () => {
    if (restockData?.length === 0)
      return triggerToast("You must restock at least one item", "warning");

    let newDetectedPricing = [];
    // clean up invoice data
    const invoiceData = { ...otherRestockInfo };
    for (const key in invoiceData)
      if (invoiceData[key] === "") delete invoiceData[key];

    // clean up restock data
    const newRestockData = deepCopyFunction(restockData);
    const rearrangedData = newRestockData?.map((restockDataItem) => {
      const pricings = [
        cleanObject({
          price: restockDataItem?.pricings[0]?.price,
          discount_rate: restockDataItem?.pricings[0]?.discount_rate,
          pricing_type_id: restockDataItem?.pricings[0]?.pricing_type?.id,
        }),
      ];

      if (
        Number(restockDataItem?.cost_price) >
        Number(restockDataItem?.pricings[0]?.price)
      )
        newDetectedPricing.push({
          store_name: restockDataItem?.store_name,
          store_currency: getStoreInfo(restockDataItem?.store_id)
            ?.currency_code,
          cost_price: restockDataItem?.cost_price,
          selling_price: restockDataItem?.pricings[0]?.price,
          pricing_name: getPricingInfo(
            restockDataItem?.pricings[0]?.pricing_type?.id || 1,
          )?.label,
        });

      return cleanObject({ ...restockDataItem, pricings }, [
        "storeList",
        "errors",
      ]);
    });

    // return a warning modal
    if (newDetectedPricing?.length > 0 && !modalViews?.isWarning) {
      setDetectedPricing(newDetectedPricing);
      return resetModalViews("isWarning", true);
    }

    resetModalViews("isWarning", false);
    restockItems(
      {
        restock_data: rearrangedData,
        invoice_data: invoiceData,
      },
      {
        onSuccess: () => {
          setRestockData([]);
          setOtherRestockInfo({
            supplier_id: "",
            invoice_number: "",
            order_number: "",
            invoice_date: "",
            discount: "",
            vat: "",
            amount_paid: "",
            payment_method: "",
            notes: "",
            invoice_url: "",
            invoice_due_date: "",
          });
          triggerToast("Bulk Restocked Successfully", "success");
        },
      },
    );
  };

  useEffect(() => {
    calculateSubtotal();
  }, [restockData]);

  useEffect(() => {
    handleFetchBankAccountsList();
  }, [currentStore]);

  return {
    isLoadingDocUpload,
    isRestocking,
    restockData,
    suppliers: suppliers || [],
    isLoading: isFetchingSupplier,
    otherRestockInfo,
    subtotal,
    totalPrice:
      subtotal -
      (otherRestockInfo?.discount === ""
        ? 0
        : Number(otherRestockInfo?.discount)) +
      (otherRestockInfo?.vat === ""
        ? 0
        : (Number(otherRestockInfo?.vat) * subtotal) / 100),
    storeList,
    currentStore,
    storeError,
    modalViews,
    detectedPricing,
    resetModalViews,
    setCurrentStore,
    setStoreError,
    setOtherRestockInfo,
    restockProducts,
    setRestockData,
    handleAppendRestockData,
    handleFetchSuppliers,
    handleInvoiceUpload,
    filterByStore,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
    bankAccount,
  };
};
