import primaryComponents from "../../../primaryComponents";
import "./CardPayment.scss";
import CardPaymentLogic from "./CardPaymentLogic";

const CardPayment = ({
  closePaymentSession,
  selectBank,
  orderDetails,
  // supportRavenPayment,
}) => {
  const { loadingExternal, supportRavenPayment, payViaExternal } =
    CardPaymentLogic({
      closePaymentSession,
      selectBank,
      orderDetails,
    });

  return (
    <>
      <div className="card-payment">
        <p
          className="text-center dark--text mx-auto small-text-size"
          style={{ maxWidth: "75%" }}
        >
          Kindly accept card payment for this order.
        </p>
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover mt-2"
          isLoading={loadingExternal}
          loadingText="Confirming..."
          isDisabled={supportRavenPayment || loadingExternal}
          onClick={payViaExternal}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default CardPayment;
