import { useState } from "react";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import AddCustomerSection from "../../StoreCheckout/AddCustomerSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useCreateStoreWalletMutation } from "../../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";
import { useContext } from "react";
import { ToastContext } from "../../../../hooks/context";
import { cleanObject } from "../../../../helpers";

const StoreWalletCreationForm = ({ customerDetails, closeModal }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [walletDetails, setWalletDetails] = useState({
    store_id: "",
    customer: customerDetails,
  });

  const createStoreWalletMutation = useCreateStoreWalletMutation();
  const handleCreateStoreWallet = () => {
    createStoreWalletMutation.mutate(
      cleanObject({
        store_id: walletDetails?.store_id,
        customer_id: walletDetails?.customer?.customerId,
      }),
      {
        onSuccess: () => {
          triggerToast("Wallet creation successful", "success");
          queryClient.invalidateQueries(["getStoreWallets"]);
          queryClient.invalidateQueries(["getCustomerStoreWallets"]);
          queryClient.invalidateQueries(["getCustomerDetails"]);
          closeModal();
        },
      },
    );
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = Validation({ walletDetails });

  useEffect(() => {
    if (walletDetails?.customer) setError("customer", "");
    setValue("customer", walletDetails?.customer?.customerId);
  }, [walletDetails?.customer]);

  return (
    <div className="p-3">
      <h4 className="text-center mb-3">Create wallet for customer</h4>

      <form
        className="d-flex flex-column"
        style={{ gap: "10px" }}
        onSubmit={handleSubmit(handleCreateStoreWallet)}
      >
        <div>
          <AddCustomerSection
            setCustomerDetails={(info) => {
              setWalletDetails((prev) => ({ ...prev, customer: info }));
            }}
            customerDetails={walletDetails?.customer}
            showLoyalty={false}
            allowApiAddCustomer={true}
          />

          <p
            className={`smallest-text-size error-message text-danger ${errors.customer?.message ? "show" : "invisible"}`}
          >
            <FontAwesomeIcon
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
              style={{ fontSize: "13px" }}
            />
            <span>{errors.customer?.message}</span>
          </p>
        </div>

        <primaryComponents.SelectFieldV2
          control={control}
          name="store"
          label={"Originating store"}
          options={storeList}
          idKey="id"
          nameKey="name"
          isRequired={true}
          onChange={(value) => {
            setWalletDetails((prev) => ({ ...prev, store_id: value }));
          }}
          value={"Select store"}
          classNames="store-select"
          errorMessage={errors.store}
        />

        <primaryComponents.Button
          type="submit"
          classNames={"btn btn--primary cover mt-3"}
          isDisabled={createStoreWalletMutation?.isLoading}
          isLoading={createStoreWalletMutation?.isLoading}
          loadingText={"Creating wallet..."}
        >
          Create wallet
        </primaryComponents.Button>
      </form>
    </div>
  );
};
export default StoreWalletCreationForm;
