export const VerificationIcon = () => {
  return (
    <svg
      width="28"
      height="34"
      viewBox="0 0 28 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9275 0.911761L25.7133 4.53056C26.9206 4.93371 27.737 6.04277 27.7435 7.28569L27.8122 18.0768C27.8335 21.3482 26.6409 24.5208 24.4569 27.0066C23.4524 28.1476 22.1649 29.1276 20.5208 30.004L14.723 33.1033C14.5414 33.1989 14.3418 33.2483 14.1406 33.2499C13.9394 33.2515 13.7381 33.2037 13.5582 33.1097L7.7064 30.0821C6.04428 29.22 4.74533 28.2543 3.73105 27.1293C1.51106 24.669 0.277556 21.5107 0.256289 18.2345L0.187539 7.45141C0.181035 6.2069 0.982651 5.08987 2.18344 4.67079L12.9284 0.922916C13.5664 0.696641 14.278 0.691861 14.9275 0.911761ZM20.0853 12.4803C19.6027 12.0166 18.8256 12.0198 18.3496 12.4898L12.8757 17.8854L10.6344 15.731C10.1518 15.2673 9.37638 15.2721 8.89868 15.7421C8.42262 16.2122 8.42753 16.9675 8.91014 17.4312L12.0233 20.427C12.2655 20.6596 12.5796 20.7744 12.8937 20.7712C13.2078 20.7696 13.5202 20.6517 13.7591 20.4158L20.0951 14.1694C20.5712 13.6993 20.5663 12.944 20.0853 12.4803Z"
        fill="#19AE57"
      />
    </svg>
  );
};
