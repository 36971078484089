import { useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { useGetPrinters } from "../../../../hooks/api/queries/usePrint";
import primaryComponents from "../../../primaryComponents";
import styles from "./styles.module.scss";
import {
  AppleIcon,
  ArrowCircle,
  DownloadIcon,
  MicrosoftIcon,
  PrinterIcon,
} from "../../../../assets/svgIcons";
import { LoveIcon } from "../../../../assets/svgIcons/LoveIcon";
import { TestView } from "../../../printViews/TestView";

const PrinterConfigForm = () => {
  const [allowPrint, setAllowPrint] = useState(false);
  const [defaultPaper] = useState(
    localStorage.getItem("default-LB-printer-paper")
      ? JSON.parse(localStorage.getItem("default-LB-printer-paper"))
      : null,
  );
  const [defaultPrinter] = useState(
    localStorage.getItem("default-LB-printer")
      ? JSON.parse(localStorage.getItem("default-LB-printer"))
      : null,
  );
  const { data: printers } = useGetPrinters({ isEnabled: true });
  const paperOptions = [
    { name: "50mm", id: "384" },
    { name: "80mm", id: "576" },
  ];

  const updatePrinterInfo = (id) => {
    const selectedPrinter = printers?.find((printer) => printer?.id === id);
    if (selectedPrinter)
      localStorage.setItem(
        "default-LB-printer",
        JSON.stringify(selectedPrinter),
      );
  };

  const updatePaperSize = (id) => {
    const paper = paperOptions?.find((paper) => paper?.id === id);
    if (paper)
      localStorage.setItem("default-LB-printer-paper", JSON.stringify(paper));
  };

  const openDownloadLink = (link) => {
    window.open(link);
  };

  return (
    <>
      <div
        className={`${globalStyles.cardWithBackground} ${styles.printerConfig} white p-4`}
        style={{ gap: "15px" }}
      >
        <div
          className={`${styles.printerConfig__header} ${globalStyles.pageHeaders}`}
        >
          <div style={{ maxWidth: "600px" }}>
            <h4>Configuration Steps</h4>
            <p>
              Follow these easy steps below to configure your printer on this
              device for better experience without browser pop-ups
            </p>
          </div>
          <PrinterIcon className="ml-auto" />
        </div>

        <div className="d-flex flex-column" style={{ gap: "50px" }}>
          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 1</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="mb-0">
                Connect your printer to this device with a USB
              </h6>
            </div>
          </div>

          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 2</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="mb-0">
                Download our driver for your Operating System
              </h6>

              <div className="d-flex flex-wrap" style={{ gap: "30px" }}>
                <div
                  className={`${globalStyles.cardWithBorder} ${styles.osCard}`}
                >
                  <div className="text-center">
                    <MicrosoftIcon />
                    <p>
                      Driver for{" "}
                      <span className="font-weight-bold dark--text">
                        Windows
                      </span>{" "}
                      Operating System{" "}
                    </p>
                  </div>
                  <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    style={{ height: "40px" }}
                    onClick={() => {
                      openDownloadLink(
                        "https://enlumi-public-assets.s3.eu-west-2.amazonaws.com/lumi-print-driver-windows.zip",
                      );
                    }}
                  >
                    <DownloadIcon className="mr-2" />
                    Download
                  </primaryComponents.Button>

                  <div
                    className={`${globalStyles.cardWithBackground} mt-3 card-plain`}
                    style={{ gap: "20px" }}
                  >
                    <p className="error--text text-center small-text-size">
                      Windows OS Driver Installation steps:
                    </p>
                    <div className={styles.section}>
                      <span className={`${styles.section__number} white`}>
                        <span className="success--text">Step 1</span> of 2
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          Open the downloaded file
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <span
                        className={`${styles.section__number} ${styles.small} white`}
                      >
                        <span className="success--text">Step 1</span> of 2
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          Double click on this driver: <br />
                          <span className="dark--text font-weight-bold">
                            “Lumi-print-driver-windows”
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${globalStyles.cardWithBorder} ${styles.osCard}`}
                >
                  <div className="text-center">
                    <AppleIcon />
                    <p>
                      Driver for{" "}
                      <span className="font-weight-bold dark--text">Mac</span>{" "}
                      Operating System{" "}
                    </p>
                  </div>
                  <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    style={{ height: "40px" }}
                    onClick={() => {
                      openDownloadLink(
                        "https://enlumi-public-assets.s3.eu-west-2.amazonaws.com/lumi-print-driver-mac.zip",
                      );
                    }}
                  >
                    <DownloadIcon className="mr-2" />
                    Download
                  </primaryComponents.Button>

                  <div
                    className={`${globalStyles.cardWithBackground} mt-3 card-plain`}
                    style={{ gap: "20px" }}
                  >
                    <p className="error--text text-center small-text-size">
                      Mac OS Driver Installation steps:
                    </p>
                    <div className={styles.section}>
                      <span className={`${styles.section__number} white`}>
                        <span className="success--text">Step 1</span> of 2
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          Open the downloaded file
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <span
                        className={`${styles.section__number} ${styles.small} white`}
                      >
                        <span className="success--text">Step 1</span> of 2
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          Double click on this driver: <br />
                          <span className="dark--text font-weight-bold">
                            “Lumi-print-driver-mac
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 3</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="mb-0">Select your printer from the list below</h6>
              <div style={{ width: "100%", maxWidth: "500px" }}>
                <primaryComponents.SelectField
                  options={printers || []}
                  idKey={"id"}
                  nameKey={"name"}
                  placeholder={"Select a printer"}
                  value={defaultPrinter?.name || "Select a printer"}
                  onChange={(value) => {
                    updatePrinterInfo(value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 4</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="mb-0">Select your paper size below</h6>
              <div style={{ width: "100%", maxWidth: "500px" }}>
                <primaryComponents.SelectField
                  options={paperOptions}
                  idKey={"id"}
                  nameKey={"name"}
                  placeholder={"Select a paper size"}
                  value={defaultPaper?.name || "Select a paper size"}
                  onChange={(value) => {
                    updatePaperSize(value);
                  }}
                  floatError={true}
                />
              </div>
            </div>
          </div>

          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 5</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="mb-0">Download an extra driver</h6>
              <div className="d-flex flex-wrap" style={{ gap: "30px" }}>
                <div
                  className={`${globalStyles.cardWithBorder} ${styles.osCard}`}
                >
                  <div className="text-center">
                    <MicrosoftIcon />
                    <p>
                      Driver for{" "}
                      <span className="font-weight-bold dark--text">
                        Windows
                      </span>{" "}
                      Operating System{" "}
                    </p>
                  </div>
                  <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    style={{ height: "40px" }}
                    onClick={() => {
                      openDownloadLink("https://zadig.akeo.ie");
                    }}
                  >
                    <DownloadIcon className="mr-2" />
                    Download
                  </primaryComponents.Button>

                  <div
                    className={`${globalStyles.cardWithBackground} mt-3 card-plain`}
                    style={{ gap: "20px" }}
                  >
                    <p className="error--text text-center small-text-size">
                      Windows OS Driver Installation steps:
                    </p>
                    <div className={styles.section}>
                      <span className={`${styles.section__number} white`}>
                        <span className="success--text">Step 1</span> of 3
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          Open the downloaded file
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <span
                        className={`${styles.section__number} ${styles.small} white`}
                      >
                        <span className="success--text">Step 1</span> of 3
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          On the installation screen, click the path:
                          <br />
                          <span className="dark--text font-weight-bold">
                            “Options{" > "}List devices”
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={styles.section}>
                      <span
                        className={`${styles.section__number} ${styles.small} white`}
                      >
                        <span className="success--text">Step 2</span> of 3
                      </span>
                      <hr
                        className={`${styles.section__line}  ${styles.small}`}
                      />
                      <div className={styles.section__content}>
                        <p className="small-text-size">
                          On the installation screen: Select your printer from
                          the first drop-down, then click on:
                          <br />
                          <span className="dark--text font-weight-bold">
                            “Reinstall Driver”
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${globalStyles.cardWithBorder} ${styles.osCard}`}
                >
                  <div className="text-center">
                    <AppleIcon />
                    <p>
                      Driver for{" "}
                      <span className="font-weight-bold dark--text">Mac</span>{" "}
                      Operating System{" "}
                    </p>
                  </div>

                  <div
                    className={`${globalStyles.cardWithBackground} mt-auto card-plain justify-content-center align-items-center`}
                    style={{ gap: "20px", minHeight: "200px" }}
                  >
                    <p className="d-flex align-items-center error--text text-center small-text-size">
                      Please move to:{" "}
                      <span className="mx-2 font-weight-bold success--text">
                        Step 6
                      </span>
                      <ArrowCircle
                        stroke="black"
                        style={{ transform: "rotate(90deg)" }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.section} ${styles.largeView}`}>
            <span className={styles.section__number}>
              <span className="success--text">Step 6</span> of 6
            </span>
            <hr className={styles.section__line} />
            <div className={styles.section__content}>
              <h6 className="d-flex align-items-center mb-0">
                Congratulations on getting this far! We appreciate your patience{" "}
                <LoveIcon />
              </h6>
              <div
                className="d-flex flex-wrap justify-content-between"
                style={{ gap: "15px" }}
              >
                <p style={{ maxWidth: "550px" }}>
                  Click the button on the far right to test if your
                  configuration is successful for both Windows & Mac operating
                  systems
                </p>
                <primaryComponents.Button
                  classNames={"btn btn--primary"}
                  style={{ height: "40px", width: "170px" }}
                  onClick={() => setAllowPrint(true)}
                >
                  <ArrowCircle className="mr-2" />
                  Test Print
                </primaryComponents.Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {allowPrint ? (
        <TestView
          allowPrint={allowPrint}
          closePrint={() => setAllowPrint(false)}
        />
      ) : null}
    </>
  );
};
export default PrinterConfigForm;
