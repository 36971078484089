import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import {
  useFundsDepositMutation,
  useFundsTransferMutation,
  useFundsWithdrawMutation,
} from "../../../hooks/api/mutations/useAccounting";
import {
  useGetBankList,
  useGetLedgerAccountList,
} from "../../../hooks/api/queries";

const useAddDebitToBankAccount = ({
  closeWidget,
  type,
  handleFetchBanks,
  handleFetchBankTransaction,
}) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer?.businessDetails,
  );
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [originatingAccountDetails, setOriginatingAccountDetails] = useState();
  const [destinationAccountDetails, setDestinationAccountDetails] = useState();
  const [storeDetails, setStoreDetails] = useState();
  const createFundsDepositMutation = useFundsDepositMutation();
  const createFundsTransferMutation = useFundsTransferMutation();
  const createFundsWithdrawMutation = useFundsWithdrawMutation();

  const [fundsMovementDetails, setfundsMovementDetails] = useState({
    amount: "",
    description: "",
    store_id: "",
    destination_account_id: "",
    originating_account_id: "",
    bank_account_id: "",
  });

  const handleFundsMovement = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...fundsMovementDetails };

    const mutation =
      type === "deposit"
        ? createFundsDepositMutation
        : type === "transfer"
          ? createFundsTransferMutation
          : createFundsWithdrawMutation;

    mutation.mutate(cleanObject(data), {
      onSuccess: () => {
        handleFetchBankTransaction();
        handleFetchBanks();
        triggerToast(
          `${type === "deposit" ? "Deposit" : type === "transfer" ? "Transfer" : "Withdraw"} added successfully`,
          "success",
        );
        closeWidget();
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };
  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetBankList();

  const { isLoading: isLoadingLedgerAccountsList, data: ledgerAccountsList } =
    useGetLedgerAccountList();

  return {
    fundsMovementDetails,
    bankAccount,
    currency_code,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
    setfundsMovementDetails,
    storeList,
    handleFundsMovement,
    createFundsDepositMutation,
    createFundsTransferMutation,
    createFundsWithdrawMutation,
    originatingAccountDetails,
    destinationAccountDetails,
    setOriginatingAccountDetails,
    setDestinationAccountDetails,
    isLoadingLedgerAccountsList,
    ledgerAccountsList,
    setStoreDetails,
    storeDetails,
  };
};

export default useAddDebitToBankAccount;
