import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import { BankEmptyIcon } from "../../../assets/svgIcons";
import useAllPaymentEOD from "./useAllPaymentEOD";
import { formatCurrency } from "../../../helpers";
import ArrowDownloadIcon from "../../../assets/svgIcons/ArrowDownloadIcon";
import PrintIcon from "../../../assets/svgIcons/PrintIcon";

const AllPaymentEOD = ({
  paymentEOD,
  refetch,
  isFetchingTnx,
  closeModal,
  printLoadingRef,
  downloadLoadingRef,
  downloadPaymentEOD,
  isLoading,
}) => {
  const { instance } = useAllPaymentEOD({
    paymentEOD,
    refetch,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div className="mt-1">
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
        <div className="mt-3">
          <p className="default-text-size">
            {paymentEOD?.business_name} End of Day card payment transactions.
          </p>
        </div>
      </div>
      <div style={{ flex: "1", overflowY: "auto", paddingBottom: "100px" }}>
        <div className="mt-5">
          <secondaryComponents.LoaderHelper isLoading={isFetchingTnx}>
            <secondaryComponents.TableHelper
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            >
              <div className="text-center">
                <BankEmptyIcon width="95" height="95" />
                <h6>You have no end of day payment record</h6>
                <p>When you do, they would appear here.</p>
              </div>
            </secondaryComponents.TableHelper>
          </secondaryComponents.LoaderHelper>
        </div>
      </div>
      <div
        style={{
          gap: "10px",
          position: "sticky",
          bottom: "0",
          background: "#fff",
          padding: "10px 0",
        }}
      >
        <div className="text-center mt-5">
          <h5>Summary</h5>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">No. of Transactions:</p>
          <p>{paymentEOD?.eod_payment_summary_data[0]?.total_count}</p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">No. of Successful Transactions:</p>
          <p>{paymentEOD?.eod_payment_summary_data[0]?.successful_count}</p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">No. of Failed Transactions:</p>
          <p>{paymentEOD?.eod_payment_summary_data[0]?.failed_count}</p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">Card:</p>
          <p>
            {formatCurrency({
              value: paymentEOD?.eod_payment_summary_data[0]?.card_amount,
              currencyCode: "NGN",
            })}
          </p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">Transfer:</p>
          <p>
            {formatCurrency({
              value: paymentEOD?.eod_payment_summary_data[0]?.transfer_amount,
              currencyCode: "NGN",
            })}
          </p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">Successful Transactions Amount:</p>
          <p>
            {" "}
            {formatCurrency({
              value: paymentEOD?.eod_payment_summary_data[0]?.successful_amount,
              currencyCode: "NGN",
            })}
          </p>
        </div>
        <div className="mt-3 d-flex justify-content-between">
          <p className="small-text-size">Failed Transactions Amount:</p>
          <p>
            {" "}
            {formatCurrency({
              value: paymentEOD?.eod_payment_summary_data[0]?.failed_amount,
              currencyCode: "NGN",
            })}
          </p>
        </div>
        <div
          className="mt-5 d-flex"
          style={{
            gap: "10px",
          }}
        >
          <primaryComponents.Button
            classNames={"btn btn--outline cover"}
            onClick={() =>
              downloadPaymentEOD({
                delivery_method: "download",
              })
            }
            loadingText="Proccessing..."
            isLoading={downloadLoadingRef.current}
          >
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <ArrowDownloadIcon />
              <span className="small-text-size"> Download PDF. </span>
            </div>
          </primaryComponents.Button>
          <secondaryComponents.CountryItemPermission>
            <primaryComponents.Button
              classNames={"btn btn--primary cover"}
              onClick={() =>
                downloadPaymentEOD({
                  delivery_method: "print",
                })
              }
              loadingText="Proccessing..."
              isLoading={printLoadingRef.current}
            >
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <PrintIcon />
                <span className="small-text-size"> Print </span>
              </div>
            </primaryComponents.Button>
          </secondaryComponents.CountryItemPermission>
        </div>
      </div>
    </div>
  );
};

export default AllPaymentEOD;
