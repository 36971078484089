import TableCard from "../../primaryComponents/TableCard";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import styles from "./TableView.module.scss";
import Icon from "../../../assets/icons";
import useTableView from "./useTableView";
import { pathConstants } from "../../../routes/pathContants";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import Pagination from "../Pagination/Pagination";

const TableView = ({
  setTableCardId,
  resetView,
  openBillsWidget = () => {},
}) => {
  const {
    isLoadingOrderTable,
    orderTables,
    isLoadingRefresh,
    metaDetails,
    gotoPage,
    handleRefresh,
    refetchOrderTables,
    handleSearchTables,
  } = useTableView();

  return (
    <div className={styles.table_container}>
      <div className={`${globalStyles.tableHeader} justify-content-between`}>
        <primaryComponents.SearchField
          placeholder="Search for a table"
          trigger={(value) => handleSearchTables(value)}
          triggerOnInput={true}
          classNames={globalStyles.searchField}
        />
        <div className="d-flex ml-auto flex-wrap" style={{ gap: "10px" }}>
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--success"}
            onClick={() => {
              resetView("isTable", false);
              openBillsWidget(true);
            }}
          >
            View Bills
          </primaryComponents.Button>
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--primary"}
            onClick={handleRefresh}
            loadingText={"Refreshing..."}
            isLoading={isLoadingRefresh}
            isDisabled={isLoadingRefresh}
          >
            <img className="mr-2" src={Icon.refresh} alt="" />
            Refresh
          </primaryComponents.Button>
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--outline"}
            onClick={() => resetView("isTable", false)}
          >
            <img className="mr-2" src={Icon.closeIcon} alt="" />
            Close
          </primaryComponents.Button>
        </div>
      </div>

      <secondaryComponents.LoaderHelper
        isLoading={isLoadingOrderTable}
        classNames="mt-3"
      >
        {orderTables?.length > 0 ? (
          <div className={styles.card_container}>
            {orderTables?.map((table, index) => (
              <TableCard
                key={index}
                table={table}
                refetchOrderTables={refetchOrderTables}
                setTableCardId={setTableCardId}
                resetView={resetView}
              />
            ))}
          </div>
        ) : (
          <div className={styles.no_table_container}>
            <img className="mr-2" src={Icon.noTableIcon} alt="" />
            <div className={styles.no_table_text}>
              <h4>You have no table</h4>
              <p>Use the button below to add a table.</p>
              <p>When you do, they would appear here.</p>
            </div>
            <primaryComponents.Button
              type="button"
              classNames={"btn btn--primary"}
              onClick={() =>
                window.open(pathConstants.INVENTORY_TABLES, "_blank")
              }
            >
              + Add new table
            </primaryComponents.Button>
          </div>
        )}
      </secondaryComponents.LoaderHelper>

      {orderTables?.length && metaDetails?.next_page > 0 ? (
        <Pagination
          totalPageNumber={metaDetails?.last_page}
          handlePageClick={(value) => gotoPage(value.selected + 1)}
          currentPage={metaDetails?.current_page}
        />
      ) : null}
    </div>
  );
};

export default TableView;
