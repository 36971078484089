import "./modal.scss";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";
import { useEffect } from "react";

const Modal = ({
  children,
  width,
  isActive = false,
  closeModal,
  closeViaOverlay = true,
  top,
  left,
  position = "center",
  returnAsSent = false,
}) => {
  // Lock background scroll when modal is active
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup function to re-enable scroll on modal close
    return () => {
      document.body.style.overflow = "";
    };
  }, [isActive]);

  // Render modal only if it's active
  if (!isActive) return null;

  if (returnAsSent) return children;

  return ReactDOM.createPortal(
    <div className="modal">
      {/* back layer */}
      <div
        onClick={() => {
          if (closeViaOverlay) closeModal();
        }}
        className="modal__backdrop"
      ></div>
      {/* modal section */}
      <motion.div
        initial={{ opacity: 0, marginTop: "-50px" }}
        animate={{
          opacity: 1,
          marginTop: "0px",
          transition: { duration: 0.3 },
        }}
        exit={{ opacity: 0 }}
        className={`${
          position === "center" ? "modal__container" : "modal__more"
        }`}
        style={{ width: width, top: top, left: left }}
      >
        {children}
      </motion.div>
    </div>,
    document.getElementById("modal-root"),
  );
};

export default Modal;
