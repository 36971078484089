import styles from "./GenerateWaybillForm.module.scss";
import primaryComponents from "../../../primaryComponents";
import { useGenerateWaybill } from "./useGenerateWaybill";
import { useValidateForm } from "./validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { truncateString } from "../../../../helpers";
import { useEffect } from "react";
// import AddCustomerSection from "../AddCustomerSection";

const GenerateWaybillForm = ({ orderId, billId, closeView }) => {
  const {
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
    currentStep,
    waybillItems,
    isGeneratingWaybill,
    hasSelectedAnItem,
    isValidatingWaybillItems,
    customerAddress,
    // customerDetails,
    // setCustomerDetails,
    setCustomerAddress,
    setWaybillItems,
    setCurrentStep,
    setDriverName,
    setDriverPhoneNumber,
    setDriverPhoneCode,
    setLogisticsCompany,
    setVehicleNumber,
    handleGenerateWaybill,
    handleValidateWaybillItems,
    handleUpdateGenerateWaybill,
    isUpdatingWaybill,
    driverPhoneCode,
    waybills,
    setWaybills,
  } = useGenerateWaybill({ orderId, billId, closeView });
  const {
    register,
    // handleSubmit,
    formState: { errors },
    control,
    trigger,
    handleSubmit,
    setValue,
  } = useValidateForm({
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
  });

  useEffect(() => {
    setValue("driverPhoneNumber", driverPhoneNumber);
  }, [driverPhoneNumber, setValue]);

  return (
    <div className={styles.generateWaybillForm}>
      <div>
        {currentStep === "waybill_info" ? (
          <FontAwesomeIcon
            onClick={() => setCurrentStep("waybill_items")}
            className="d-block"
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        ) : null}
      </div>
      {currentStep === "waybill_items" ? (
        <>
          <div className="mt-3">
            <h4>Waybill Items</h4>
            <p>Select and confirm the items for your waybill</p>
          </div>

          <div className={styles.generateWaybillForm__orderItems}>
            {waybills?.length > 0 && billId ? (
              <div className="d-flex justify-content-between">
                <div>
                  <p className="smallest-text-size">Items</p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "25px" }}
                >
                  <div>
                    <p className="smallest-text-size">Qty</p>
                  </div>

                  <div>
                    <p className="smallest-text-size">Initial Qty</p>
                  </div>
                </div>
              </div>
            ) : null}
            {waybillItems?.map((order, index) => (
              <div
                key={order?.id}
                className={styles["generateWaybillForm__orderItems-item"]}
              >
                <label
                  className="d-flex align-items-center gap-5"
                  htmlFor={order?.id}
                >
                  <primaryComponents.Checkbox
                    name={order?.cart_item_name}
                    id={order?.id}
                    isChecked={order?.isDisabled === false}
                    onChange={(value) => {
                      const order_items = [...waybillItems];
                      order_items[index].isDisabled = !value;
                      setWaybillItems(order_items);
                    }}
                  />
                  <span className="ml-2">
                    {truncateString(waybillItems[index]?.cart_item_name, 45)}
                  </span>
                </label>
                <div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "15px" }}
                  >
                    <div>
                      {waybills?.length > 0 && billId ? (
                        <primaryComponents.InputFieldV2
                          control={control}
                          type="number"
                          defaultValue={
                            waybills[0]?.waybill_items[index]?.quantity
                          }
                          value={waybills[0]?.waybill_items[index]?.quantity}
                          isDisabled={waybillItems[index]?.isDisabled}
                          onChange={(event) => {
                            const value = event.target.value;

                            //Update waybillItems state
                            const updatedWaybillItems = [...waybillItems];
                            updatedWaybillItems[index].quantity = value;
                            setWaybillItems(updatedWaybillItems);

                            //Update waybills state
                            const updatedWaybills = [...waybills];
                            if (updatedWaybills[0]?.waybill_items[index]) {
                              updatedWaybills[0].waybill_items[index].quantity =
                                value;
                              setWaybills(updatedWaybills);
                            }
                          }}
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        <primaryComponents.InputFieldV2
                          control={control}
                          type="number"
                          defaultValue={
                            waybillItems[index]?.waybill_remaining_quantity
                          }
                          value={waybillItems[index]?.quantity}
                          isDisabled={waybillItems[index]?.isDisabled}
                          onChange={(event) => {
                            const value = event.target.value;
                            const order_items = [...waybillItems];
                            order_items[index].quantity = value;
                            setWaybillItems(order_items);
                          }}
                          style={{ width: "50px", height: "50px" }}
                        />
                      )}
                    </div>
                    <div>
                      {waybills?.length > 0 && billId ? (
                        <primaryComponents.InputFieldV2
                          control={control}
                          type="number"
                          defaultValue={
                            waybills[0]?.waybill_items[index]?.item?.quantity
                          }
                          value={
                            waybills[0]?.waybill_items[index]?.item?.quantity
                          }
                          isDisabled={true}
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            <h4>Logistics Details</h4>
            <p>Enter required details for customer&apos;s delivery</p>
          </div>
          {/* Customer information */}
          <div className="mt-5">
            {/* <div className="mb-3">
              <AddCustomerSection
                setCustomerDetails={setCustomerDetails}
                customerDetails={customerDetails}
              />
            </div> */}

            <Controller
              name="customerAddress"
              control={control}
              defaultValue={customerAddress}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  placeholder="Enter Delivery address"
                  isRequired={true}
                  name="customerAddress"
                  errorMessage={errors.customerAddress}
                  value={customerAddress}
                  onKeyUp={() =>
                    errors.customerAddress !== undefined &&
                    trigger("customerAddress")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerAddress(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>

          {/* Logistics information */}
          <div style={{ marginTop: "40px" }}>
            <h5 className="dark--text font-semibold mb-3">
              Add Driver Information
            </h5>
            <Controller
              name="driverName"
              control={control}
              defaultValue={driverName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Name"}
                  placeholder="Driver's name"
                  isRequired={true}
                  name="driverName"
                  errorMessage={errors.driverName}
                  value={driverName}
                  onKeyUp={() =>
                    errors.driverName !== undefined && trigger("driverName")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setDriverName(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="vehicleNumber"
              control={control}
              defaultValue={vehicleNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Vehicle Number"}
                  placeholder="Vehicle/bike number"
                  isRequired={true}
                  name="vehicleNumber"
                  errorMessage={errors.vehicleNumber}
                  value={vehicleNumber}
                  onKeyUp={() =>
                    errors.vehicleNumber !== undefined &&
                    trigger("vehicleNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setVehicleNumber(value);
                  }}
                  register={register}
                />
              )}
            />

            <primaryComponents.PhoneNumberInputFieldV2
              control={control}
              // label={"Driver's Phone Number"}
              placeholder={"Driver's Phone Number"}
              isRequired={true}
              name="driverPhoneNumber"
              classNames="mb-1"
              value={driverPhoneNumber}
              errorMessage={errors.driverPhoneNumber}
              onKeyUp={() =>
                errors.driverPhoneNumber !== undefined &&
                trigger("driverPhoneNumber")
              }
              onChange={({ target: { value } }) => {
                setDriverPhoneNumber(value);
              }}
              register={register}
              phoneCodeEditable={true}
              onChangePhoneCode={(val) => setDriverPhoneCode(val)}
              countryCode={driverPhoneCode}
            />

            <Controller
              name="logisticsCompany"
              control={control}
              defaultValue={logisticsCompany}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Logisitcs Company"}
                  placeholder="Delivery company"
                  isRequired={false}
                  name="logisticsCompany"
                  errorMessage={errors.logisticsCompany}
                  value={logisticsCompany}
                  onKeyUp={() =>
                    errors.logisticsCompany !== undefined &&
                    trigger("logisticsCompany")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setLogisticsCompany(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>
        </>
      )}

      <div className={styles.generateWaybillForm__bottom}>
        {currentStep === "waybill_items" ? (
          <primaryComponents.Button
            onClick={() => handleValidateWaybillItems()}
            classNames={"btn btn--primary cover"}
            isDisabled={!hasSelectedAnItem || isValidatingWaybillItems}
            isLoading={isValidatingWaybillItems}
            loadingText={"Validating selected items..."}
          >
            Next
          </primaryComponents.Button>
        ) : (
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            isDisabled={isGeneratingWaybill || isUpdatingWaybill}
            isLoading={isGeneratingWaybill || isUpdatingWaybill}
            loadingText="Generating..."
            onClick={
              billId
                ? handleUpdateGenerateWaybill
                : handleSubmit(handleGenerateWaybill)
            }
          >
            {billId ? "Update" : "Submit"}
          </primaryComponents.Button>
        )}
      </div>
    </div>
  );
};

export default GenerateWaybillForm;
