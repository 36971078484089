import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusiness,
  updateBusinessInfo as updateInfo,
} from "../../../services/settings";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { setBusinessDetails } from "../../../store/modules/profile";

const ProfileBusinessInfoFormLogic = ({ businessLogo }) => {
  const business = useSelector(
    (state) => state?.profileDetailsReducer?.businessDetails,
  );
  const [formData, setFormData] = useState({
    name: business?.business_name || "",
    size: business?.size || "",
    business_email: business?.business_email || "",
    support_email: business?.support_email || "",
    business_phone_number:
      business?.business_phone_number !== null
        ? business.business_phone_number
        : "",
    support_phone_number:
      business?.support_phone_number !== null
        ? business?.support_phone_number
        : "",
    address: business?.address || "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();

  const updateBusinessInfo = ({ isLogo = false }) => {
    if (!isEdited && !isLogo) return;

    setIsSubmitting(true);
    setRequestLoaderProgress(20);

    const data = isLogo
      ? { logo: businessLogo }
      : {
          logo: businessLogo,
          ...formData,
        };
    updateInfo(data)
      .then(() => {
        triggerToast("Business information update successful", "success");
        setIsEdited(false);
        getBusiness().then((response) => {
          dispatch(setBusinessDetails(response.data.data));
        });
      })
      .catch(() => {})
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsSubmitting(false);
      });
  };

  return {
    isSubmitting,
    isEdited,
    formData,
    setIsEdited,
    updateBusinessInfo,
    setFormData,
  };
};
export default ProfileBusinessInfoFormLogic;
