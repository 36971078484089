import styles from "./SubscriptionPay.module.scss";
import globaStyles from "../../../assets/styles/base/globalPage.module.scss";
import { formatCurrency, getDateAfter } from "../../../helpers";
import useSubscriptionPay from "./useSubscriptionPay";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import Validation from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubscriptionPay = ({
  upgradeDetails,
  subscriptionPeriod,
  setSubscriptionPeriod,
  period,
  setIsPayModal,
}) => {
  const pricingInfo = upgradeDetails?.currentSubcription?.pricings?.find(
    (item) => item?.validity === subscriptionPeriod,
  );
  const {
    has_wallet,
    isCreatingFreePlan,
    activeSubscription,
    businessList,
    walletDetails,
    isCreatingSession,
    paymentMethod,
    setPaymentMethod,
    subscibeTolumi,
    setWalletDetails,
    startFreePlan,
  } = useSubscriptionPay({
    upgradeDetails,
    pricingInfo,
    subscriptionPeriod,
    setIsPayModal,
  });

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
  } = Validation({
    walletDetails,
    isWalletPayment: paymentMethod === "lumi_wallet",
  });

  return (
    <div className={`${styles.subscriptionPay}`}>
      <FontAwesomeIcon
        icon="times"
        fontSize="22px"
        className="ml-auto"
        onClick={() => setIsPayModal(false)}
      />
      <h4 className="text-center mb-0">Upgrade Plan</h4>

      <div className={styles.subscriptionPay__top}>
        {activeSubscription && upgradeDetails?.type !== "Free" ? (
          <>
            <div className={`${globaStyles.cardWithBackground}`}>
              <p>CURRENT PLAN:</p>
              <div className="d-flex align-items-center">
                <p
                  className={`${globaStyles.plan} ${
                    activeSubscription?.pricing?.tier?.name === "Essential"
                      ? globaStyles.essentialPlan
                      : activeSubscription?.pricing?.tier?.name === "Growth"
                        ? globaStyles.growthPlan
                        : activeSubscription?.pricing?.tier?.name === "Scale"
                          ? globaStyles.scalePlan
                          : ""
                  }`}
                >
                  {activeSubscription?.pricing?.tier?.name || "None"}
                </p>
                <p className="dark--text font-weight-semibold ml-2">
                  {formatCurrency({
                    value: activeSubscription?.pricing?.price,
                    currencyCode: activeSubscription?.currency,
                    decimal: 0,
                  })}
                </p>
              </div>
            </div>
            <hr className="mb-3" />
          </>
        ) : null}
        <div>
          {upgradeDetails?.type !== "Free" ? (
            <secondaryComponents.SubscriptionPeriod
              subscriptionPeriod={subscriptionPeriod}
              setSubscriptionPeriod={setSubscriptionPeriod}
            />
          ) : null}

          <div className={`${globaStyles.cardWithBackground} mt-3`}>
            <p className="dark--text font-weight-semibold">NEW PLAN:</p>
            <div className="d-flex align-items-center">
              <p
                className={`${globaStyles.plan} ${
                  upgradeDetails?.type === "Essential"
                    ? globaStyles.essentialPlan
                    : upgradeDetails?.type === "Growth"
                      ? globaStyles.growthPlan
                      : upgradeDetails?.type === "Scale"
                        ? globaStyles.scalePlan
                        : ""
                }`}
              >
                {upgradeDetails?.type}
              </p>
              <p className="dark--text font-weight-semibold ml-2">
                {formatCurrency({
                  value: pricingInfo?.price,
                  currencyCode: pricingInfo?.currency,
                  decimal: 0,
                })}{" "}
                /{period?.[subscriptionPeriod]}
              </p>
            </div>
          </div>

          <p className="small-text-size dark--text mt-1">
            Your new plan starts now and you&apos;ll pay{" "}
            {formatCurrency({
              value:
                pricingInfo?.price - (activeSubscription?.sub_balance ?? 0),
              currencyCode: pricingInfo?.currency,
              decimal: 0,
            })}{" "}
            for a {period?.[subscriptionPeriod]}. Starting from{" "}
            {getDateAfter(subscriptionPeriod === "ONE_MONTH" ? 30 : 365)}{" "}
            you&apos;ll pay{" "}
            {formatCurrency({
              value: pricingInfo?.price,
              currencyCode: pricingInfo?.currency,
              decimal: 0,
            })}{" "}
            /{period?.[subscriptionPeriod]}
          </p>
        </div>
      </div>

      <div className={styles.totalSection}>
        <div className={styles.totalSection__item}>
          <p>Subtotal:</p>
          <h6>
            {formatCurrency({
              value:
                pricingInfo?.price - (activeSubscription?.sub_balance ?? 0),
              currencyCode: pricingInfo?.currency,
              decimal: 0,
            })}
          </h6>
        </div>
        <div className={styles.totalSection__item}>
          <p>VAT({pricingInfo?.vat_ratio}%):</p>
          <h6>
            {" "}
            {formatCurrency({
              value: (
                (pricingInfo?.price * pricingInfo?.vat_ratio) /
                100
              ).toFixed(2),
              currencyCode: pricingInfo?.currency,
              decimal: 0,
            })}
          </h6>
        </div>
        <div className={styles.totalSection__item}>
          <p className="purple--text">Total</p>
          <h6 className="purple--text">
            {formatCurrency({
              value: (
                (pricingInfo?.price * pricingInfo?.vat_ratio) / 100 +
                pricingInfo?.price -
                (activeSubscription?.sub_balance ?? 0)
              ).toFixed(2),
              currencyCode: pricingInfo?.currency,
              decimal: 0,
            })}
          </h6>
        </div>
      </div>

      {upgradeDetails?.type !== "Free" ? (
        <>
          <div className="d-flex flex-column" style={{ gap: "5px" }}>
            {has_wallet &&
            pricingInfo?.supported_payment_gateway?.includes("lumi_wallet") ? (
              <label htmlFor="wallet">
                <primaryComponents.RadioButton
                  name="paymentType"
                  id="wallet"
                  checked={paymentMethod === "lumi_wallet"}
                  setRadioValue={() => {
                    setPaymentMethod("lumi_wallet");
                    setValue("isWalletPayment", true);
                  }}
                  value={true}
                />
                <span className="ml-2">Pay with Lumi wallet</span>
              </label>
            ) : null}
            {pricingInfo?.supported_payment_gateway?.includes("paystack") ? (
              <label
                htmlFor="transfer"
                className="d-flex justify-content-center align-items-center"
              >
                <primaryComponents.RadioButton
                  name="paymentType"
                  id="transfer"
                  checked={paymentMethod === "paystack"}
                  setRadioValue={() => {
                    setPaymentMethod("paystack");
                    setValue("isWalletPayment", false);
                  }}
                  value={false}
                />
                <span className="ml-2">
                  Paystack ( Pay with Card, Bank Transfer or USSD )
                </span>
              </label>
            ) : null}
            {pricingInfo?.supported_payment_gateway?.includes("stripe") ? (
              <label htmlFor="stripe">
                <primaryComponents.RadioButton
                  name="paymentType"
                  id="stripe"
                  checked={paymentMethod === "stripe"}
                  setRadioValue={() => {
                    setPaymentMethod("stripe");
                    setValue("isWalletPayment", false);
                  }}
                  value={false}
                />
                <span className="ml-2">Stripe</span>
              </label>
            ) : null}
          </div>

          <form
            id="subscriptionPayForm"
            onSubmit={handleSubmit(subscibeTolumi)}
          >
            {has_wallet && paymentMethod === "lumi_wallet" ? (
              <primaryComponents.SelectFieldV2
                name="subAccount"
                control={control}
                register={register}
                label="Select Payment Account"
                isRequired={true}
                value={
                  walletDetails?.wallet_id === ""
                    ? "Select payment account..."
                    : businessList?.find(
                        (item) => item?.id === walletDetails?.wallet_id,
                      )?.tag
                }
                options={businessList}
                nameKey="tag"
                idKey="id"
                onChange={(value) => {
                  setWalletDetails((prev) => ({
                    ...prev,
                    wallet_id: value,
                  }));
                }}
                errorMessage={errors.subAccount}
              />
            ) : null}
          </form>

          <primaryComponents.Button
            classNames="btn btn--primary cover mt-auto"
            form="subscriptionPayForm"
            type="submit"
            isDisabled={isCreatingSession}
            isLoading={isCreatingSession}
            loadingText={"Processing..."}
            style={{ position: "sticky", bottom: "3px" }}
          >
            Make Payment
          </primaryComponents.Button>
        </>
      ) : (
        <>
          <primaryComponents.Button
            classNames="btn btn--primary cover mt-auto"
            isDisabled={isCreatingFreePlan}
            isLoading={isCreatingFreePlan}
            loadingText={"Processing..."}
            onClick={startFreePlan}
            style={{ position: "sticky", bottom: "3px" }}
          >
            Start Free Plan
          </primaryComponents.Button>
        </>
      )}
    </div>
  );
};
export default SubscriptionPay;
