import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useValidation = (type) => {
  const schema = yup.object().shape({
    answer: yup.string().when([], {
      is: () => type === "pin",
      then: (schema) => schema.required("Answer is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    password: yup.string().required("Password is required"),
  });

  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      answer: "",
      password: "",
    },
  });
};

export default useValidation;
