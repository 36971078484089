/* eslint-disable no-useless-escape */
import "./SplitPayment.scss";
import useSplitPaymentLogic from "./useSplitPaymentLogic";
import SplitPaymentValidation from "./Validation";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../../../secondaryComponents";
import { Controller } from "react-hook-form";
import { formatCurrency } from "../../../../helpers";
import AddCustomerSection from "../AddCustomerSection";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const SplitPayment = ({
  closePaymentSession,
  openTransactionsession,
  orderDetails,
  selectBank,
}) => {
  const { store_currency } = useStoreCurrency();

  const {
    isConfirmingPayment,
    cashAmount,
    transferAmount,
    cardAmount,
    creditAmount,
    storeWalletAmount,
    prAmount,
    repaymentDate,
    customerDetails,
    storeDetails,
    isOtpWidget,
    setIsOtpWidget,
    setCustomerDetails,
    setCashAmount,
    setTransferAmount,
    setCardAmount,
    setCreditAmount,
    setStoreWalletAmount,
    setPrAmount,
    setRepaymentDate,
    setIsConfirmingPayment,
    payViaSplit,
    closeOtpAuthorizationPrompt,
    walletDetails,
    setWalletDetails,
  } = useSplitPaymentLogic({
    closePaymentSession,
    openTransactionsession,
    orderDetails,
    selectBank,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = SplitPaymentValidation(
    cashAmount,
    cardAmount,
    creditAmount,
    transferAmount,
    storeWalletAmount,
    prAmount,
  );

  return (
    <>
      <div className="cash-payment mt-4 mb-3">
        <div>
          <p className="split-payment-confirm">
            <span className="text-danger">*</span>Please confirm the amounts
            that are being split across these methods of payment
          </p>
          <div className="split-payment-group mt-2">
            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="cashAmount"
              >
                Cash Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="cashAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="cashAmount"
                      placeholder="Enter Amount"
                      value={cashAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCashAmount(value);
                      }}
                      errorMessage={errors.cashAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="transferAmount"
              >
                Transfer Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="transferAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="transferAmount"
                      placeholder="Enter Amount"
                      value={transferAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setTransferAmount(value);
                      }}
                      errorMessage={errors.transferAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="cardAmount"
              >
                Card Amount
              </label>
              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="cardAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="cardAmount"
                      placeholder="Enter Amount"
                      value={cardAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCardAmount(value);
                      }}
                      errorMessage={errors.cardAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="creditAmount"
              >
                Credit Amount
              </label>

              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="creditAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="creditAmount"
                      placeholder="Enter Amount"
                      value={creditAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setCreditAmount(value);
                      }}
                      errorMessage={errors.creditAmount}
                    />
                  )}
                />
              </div>
            </div>

            <div className="split-payment-input">
              <label
                className="split-payment-input__label"
                htmlFor="storeWalletAmount"
              >
                Store Wallet Amount
              </label>

              <div className="split-payment-input__field">
                <Controller
                  control={control}
                  name="storeWalletAmount"
                  render={({ field: { onChange } }) => (
                    <primaryComponents.AmountField
                      name="storeWalletAmount"
                      placeholder="Enter Amount"
                      value={storeWalletAmount}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setStoreWalletAmount(value);
                      }}
                      errorMessage={errors.storeWalletAmount}
                    />
                  )}
                />
              </div>
            </div>

            {storeDetails?.is_warehouse && (
              <div className="split-payment-input">
                <label
                  className="split-payment-input__label"
                  htmlFor="prAmount"
                >
                  PR Amount
                </label>

                <div className="split-payment-input__field">
                  <Controller
                    control={control}
                    name="prAmount"
                    render={({ field: { onChange } }) => (
                      <primaryComponents.AmountField
                        name="prAmount"
                        placeholder="Enter Amount"
                        value={prAmount}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setPrAmount(value);
                        }}
                        errorMessage={errors.prAmount}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <div className="d-flex justify-content-between w-100">
              <h6 className="mb-0 purple--text">Balance</h6>
              <h6 className="mb-0 purple--text">
                {formatCurrency({
                  value:
                    (Math.round(Number(orderDetails?.total_amount || 0) * 100) -
                      Math.round(
                        (Number(cardAmount) +
                          Number(transferAmount) +
                          Number(creditAmount) +
                          Number(cashAmount) +
                          Number(storeWalletAmount) +
                          Number(prAmount)) *
                          100,
                      )) /
                    100,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>

            {Math.round(Number(orderDetails?.total_amount || 0) * 100) <
            Math.round(
              (Number(cardAmount) +
                Number(transferAmount) +
                Number(creditAmount) +
                Number(cashAmount) +
                Number(storeWalletAmount) +
                Number(prAmount)) *
                100,
            ) ? (
              <p className="small-text-size error--text mb-0">
                The entered amount exceeds the total amount payable.
              </p>
            ) : null}

            {Math.round(Number(orderDetails?.total_amount || 0) * 100) >
            Math.round(
              (Number(cardAmount) +
                Number(transferAmount) +
                Number(creditAmount) +
                Number(cashAmount) +
                Number(storeWalletAmount) +
                Number(prAmount)) *
                100,
            ) ? (
              <p className="small-text-size error--text mb-0">
                The entered amount is lower than the total amount payable.
              </p>
            ) : null}
          </div>
        </div>
        {(creditAmount > 0 || storeWalletAmount > 0) && (
          <div>
            <div className="my-4">
              <AddCustomerSection
                setCustomerDetails={setCustomerDetails}
                customerDetails={customerDetails}
                showAddCustomer={storeWalletAmount > 0 ? false : true}
                showLoyalty={storeWalletAmount > 0 ? false : true}
                showWalletBalance={storeWalletAmount > 0 ? true : false}
                showStoreWallet={true}
                walletDetails={walletDetails}
                setWalletDetails={setWalletDetails}
              />
            </div>

            {creditAmount > 0 && (
              <primaryComponents.InputField
                type="date"
                label="Repayment date"
                isRequired={false}
                name="repayment_date"
                onChange={({ target: { value } }) => {
                  setRepaymentDate(value);
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={handleSubmit(payViaSplit)}
        >
          Confirm
        </primaryComponents.Button>
      </div>

      <secondaryComponents.Modal
        isActive={isOtpWidget !== null}
        closeModal={() => setIsOtpWidget(null)}
      >
        <secondaryComponents.StoreWalletOtpWidget
          debitSessionDetails={isOtpWidget}
          closeModal={() => setIsOtpWidget(null)}
          debitDetails={{
            cardAmount: Number(cardAmount),
            transferAmount: Number(transferAmount),
            creditAmount: Number(creditAmount),
            cashAmount: Number(cashAmount),
            storeWalletAmount: Number(storeWalletAmount),
            prAmount: Number(prAmount),
            repaymentDate: repaymentDate,
            selectBank: selectBank,
            orderDetails: orderDetails,
            customerDetails: customerDetails,
            setIsConfirmingPayment: setIsConfirmingPayment,
          }}
          currencyCode={store_currency}
          callBackFunc={() => {
            closeOtpAuthorizationPrompt();
            closePaymentSession();
          }}
          is_order={true}
          isViaSellMode={true}
          is_split={true}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default SplitPayment;
