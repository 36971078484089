import styles from "./CustomerForm.module.scss";
import primaryComponents from "../../primaryComponents";
import useCustomerForm from "./useCustomerForm";
import Validation from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

import secondaryComponents from "..";
const CustomerForm = ({
  customerInfo,
  isEditMode = false,
  isLoading,
  handleFetchCustomers = () => {},
  closeWidget = () => {},
  closeModal = () => {},
}) => {
  const {
    isLoyaltyEligible,
    isAddingCustomer,
    isEditingCustomer,
    customerDetails,
    setCustomerDetails,
    handleEditCustomer,
    handleAddCustomer,
    countriesStatesList,
    country,
    storeList,
    state,
    setState,
    setIsState,
  } = useCustomerForm({
    customerInfo,
    isEditMode,
    handleFetchCustomers,
    closeWidget,
    closeModal,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ customerDetails });

  return (
    <div className={styles.customerForm}>
      {isEditMode ? (
        <div className="mb-3">
          <FontAwesomeIcon
            onClick={() => {
              closeWidget();
            }}
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        </div>
      ) : null}

      <h4
        className={`font-weight-semibold ${!isEditMode ? "text-center" : ""}`}
      >
        {isEditMode ? "Customer Details" : "Add Customer"}
      </h4>
      {/* customer form */}
      <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
        <form
          onSubmit={handleSubmit(() => {
            if (isEditMode) {
              handleEditCustomer();
            } else {
              handleAddCustomer();
            }
          })}
          className={styles.formSection}
        >
          <div className="d-flex">
            <div style={{ width: "25em" }} className="m-2">
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="customerName"
                label="Customer Name"
                placeholder="Enter customer name"
                isRequired={true}
                defaultValue={customerDetails?.name}
                value={customerDetails?.name}
                onChange={({ target: { value } }) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                errorMessage={errors.customerName}
                onKeyUp={() =>
                  errors.customerName !== undefined && trigger("customerName")
                }
                register={register}
              />
            </div>

            <div style={{ width: "25em" }} className="mb-2">
              <primaryComponents.PhoneNumberInputFieldV2
                control={control}
                classNames="white"
                name="customerPhone"
                label="Customer Phone Number"
                placeholder="Phone Number"
                isRequired={true}
                defaultValue={customerDetails?.phone_number}
                value={customerDetails?.phone_number}
                onChange={({ target: { value } }) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    phone_number: value,
                  }));
                }}
                errorMessage={errors.customerPhone}
                onKeyUp={() =>
                  errors.customerPhone !== undefined && trigger("customerPhone")
                }
                register={register}
                phoneCodeEditable={true}
                onChangePhoneCode={(val) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    phone_code: val,
                  }));
                }}
                countryCode={customerDetails?.phone_code}
              />
            </div>
          </div>

          <div className="d-flex">
            <div style={{ width: "25em" }} className="m-2">
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white mr-1"
                name="customerEmail"
                label="Customer Email Address"
                placeholder="Enter customer email address"
                value={customerDetails?.email}
                onChange={({ target: { value } }) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    email: value,
                  }));
                }}
                errorMessage={errors.customerEmail}
                register={register}
              />
            </div>
            <div style={{ width: "25em" }} className="mb-2">
              <primaryComponents.DateInputV2
                control={control}
                classNames="white"
                name="customerDob"
                label="Customer DOB (optional)"
                placeholder="Enter Customer DOB"
                value={customerDetails?.date_of_birth}
                onChange={({ target: { value } }) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    date_of_birth: value,
                  }));
                }}
                register={register}
              />
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: "15em" }} className="mt-2">
              <primaryComponents.SelectFieldV2
                control={control}
                label="Country"
                value={
                  customerDetails?.country
                    ? customerDetails?.country
                    : "Select country"
                }
                onChange={(value) => {
                  const stateList = countriesStatesList.find(
                    (item) => item.name === value,
                  ).states;
                  const stateName = stateList.map((states) => states.name);
                  setCustomerDetails((prev) => ({
                    ...prev,
                    country: value,
                  }));
                  setState(stateName);
                }}
                name="customerCountry"
                defaultValue={country}
                options={countriesStatesList}
                nameKey="name"
                idKey="name"
                errorMessage={errors.customerCountry}
              />
            </div>

            <div style={{ width: "15em" }} className="m-2">
              <primaryComponents.SelectFieldV2
                control={control}
                classNames="white"
                name="customerState"
                label="State"
                placeholder="Select State"
                value={
                  customerDetails?.state
                    ? customerDetails?.state
                    : "Select state"
                }
                onChange={(value) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    state: value,
                  }));
                  setIsState(value);
                }}
                nameKey="name"
                idKey={"id"}
                errorMessage={errors.customerState}
                register={register}
                options={[
                  { name: "Select state", id: "Select state" },
                  ...state,
                ]}
                defaultValue={"Select state"}
              />
            </div>

            <div style={{ width: "15em" }} className="mt-3">
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="customerCity"
                label="City/Town"
                placeholder="Enter City"
                defaultValue={customerDetails?.city}
                value={customerDetails?.city}
                onChange={({ target: { value } }) => {
                  setCustomerDetails((prev) => ({
                    ...prev,
                    city: value,
                  }));
                }}
                errorMessage={errors.customerCity}
                onKeyUp={() =>
                  errors.customerCity !== undefined && trigger("customerCity")
                }
                register={register}
              />
            </div>
          </div>
          <div className="m-2">
            <primaryComponents.SelectFieldV2
              options={storeList}
              control={control}
              //name="customerStoreId"
              label={"Select store"}
              //isRequired={true}
              nameKey="name"
              value={
                customerDetails?.store_id
                  ? customerInfo?.store?.name
                  : "Select stores"
              }
              onChange={(value, label) => {
                setCustomerDetails((prev) => ({
                  ...prev,
                  store_id: value,
                  store_label: label,
                }));
              }}
              idKey={"id"}
              classNames="store-select"
              //register={register}
              //errorMessage={errors.customerStoreId}
            />

            <div className={`${globalStyles.cardWithBorder} mt-4 p-4`}>
              <div className="d-flex justify-content-between">
                <h6 className="mb-0 small-text-size">
                  Enable loyalty for this customer
                </h6>

                <primaryComponents.Switch
                  name={"loyalty"}
                  id={customerDetails}
                  isChecked={
                    customerDetails?.is_loyalty_eligible || isLoyaltyEligible
                  }
                  onChange={(value) => {
                    setCustomerDetails((prev) => ({
                      ...prev,
                      is_loyalty_eligible: value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between mt-5">
              {!isEditMode ? (
                <primaryComponents.Button
                  type="button"
                  classNames={"btn btn--outline"}
                  onClick={closeWidget}
                >
                  Cancel
                </primaryComponents.Button>
              ) : null}
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                isLoading={isEditingCustomer || isAddingCustomer}
                isDisabled={isEditingCustomer || isAddingCustomer}
                loadingText={isEditMode ? "Updating..." : "Adding..."}
              >
                {isEditMode ? "Update" : "Add Customer"}
              </primaryComponents.Button>
            </div>
          </div>
        </form>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};
export default CustomerForm;
