import styles from "./ProductBatchForm.module.scss";
import primaryComponents from "../../primaryComponents";
import useProductBatchForm from "./useProductBatchForm";
import Validation from "./Validation";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductBatchForm = ({
  handleFetchProductBatch = () => {},
  batchInfo,
}) => {
  const navigate = useNavigate();
  const {
    batchDetails,
    setBatchDetails,
    isEditingProductBatch,
    handleEditProductBatch,
  } = useProductBatchForm({
    handleFetchProductBatch,
    batchInfo,
  });

  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ batchDetails });

  return (
    <>
      <div className={styles.batchForm}>
        <div className="d-flex align-items-center mb-5" style={{ gap: "10px" }}>
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <h4 className="font-weight-semibold mb-0">Update Product Batch</h4>
        </div>

        {/* form section */}
        <form
          onSubmit={handleSubmit(handleEditProductBatch)}
          className={styles.formSection}
        >
          <div className={styles.cardSession}>
            <div className={styles.inputSection}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="productName"
                label="Product Name"
                value={batchDetails.product_name}
                disabled={true}
                register={register}
              />
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="productBatch"
                label="Product Batch"
                value={batchDetails.batch_id}
                disabled={true}
                register={register}
              />
            </div>

            <div className={styles.inputSection}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="costPrice"
                label="Cost Price"
                placeholder="Enter your cost price"
                isRequired={true}
                value={batchDetails.cost_price}
                onChange={({ target: { value } }) => {
                  setBatchDetails((prev) => ({
                    ...prev,
                    cost_price: value,
                  }));
                }}
                errorMessage={errors.costPrice}
                onKeyUp={() =>
                  errors.costPrice !== undefined && trigger("costPrice")
                }
                type="number"
                register={register}
                isDisabled
              />
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="sellingPrice"
                label="Selling Price"
                placeholder="Enter your selling price"
                isRequired={true}
                value={batchDetails.selling_price}
                onChange={({ target: { value } }) => {
                  setBatchDetails((prev) => ({
                    ...prev,
                    selling_price: value,
                  }));
                }}
                errorMessage={errors.sellingPrice}
                onKeyUp={() =>
                  errors.sellingPrice !== undefined && trigger("sellingPrice")
                }
                type="number"
                register={register}
                isDisabled
              />
            </div>

            <div className={styles.inputSection}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="stockQuantity"
                label="Stock Quantity"
                placeholder="Enter your stock quantity"
                isRequired={true}
                value={batchDetails.stock_quantity || 0}
                onChange={({ target: { value } }) => {
                  setBatchDetails((prev) => ({
                    ...prev,
                    stock_quantity: value,
                  }));
                }}
                errorMessage={errors.stockQuantity}
                onKeyUp={() =>
                  errors.stockQuantity !== undefined && trigger("stockQuantity")
                }
                type="number"
                register={register}
                isDisabled
              />
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="discountRate"
                label="Discount Rate"
                placeholder="Enter your discount rate"
                value={batchDetails.discount_rate || 0}
                onChange={({ target: { value } }) => {
                  setBatchDetails((prev) => ({
                    ...prev,
                    discount_rate: value,
                  }));
                }}
                type="number"
                register={register}
              />
            </div>
            <div style={{ maxWidth: "530px" }}>
              <primaryComponents.DateInputV2
                name={"expiry_date"}
                control={control}
                label="Expiry Date"
                placeholder="Expiry date"
                classNames="white"
                value={batchDetails?.expiry_date || ""}
                onChange={({ target: { value } }) => {
                  setBatchDetails((prev) => ({
                    ...prev,
                    expiry_date: value,
                  }));
                }}
              />
            </div>
            <div>
              <primaryComponents.Button
                classNames={"btn btn--primary ml-auto"}
                isLoading={isEditingProductBatch}
                isDisabled={isEditingProductBatch}
                loadingText={"Updating..."}
                type="submit"
              >
                Update
              </primaryComponents.Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProductBatchForm;
