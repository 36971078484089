import useEndOfDayForm from "./useEndOfDayForm";
import Validation from "./Validation";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import styles from "../SalesEndOfDay.module.scss";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import AllPaymentEOD from "../../PaymentEOD";
import SideModal from "../../SideModal/SideModal";

const EndOfDayForm = ({
  setIsEndOfDayDetails,
  getEndOfDayDetails,
  setEndOfDayFormDetails,
  endOfDayFormDetails,
  isGeneratingDetails,
  getPaymentEndOfDayDetails,
  isFetchingTnx,
  setIsPayment,
  endOfDayDetails,
  isPayment,
}) => {
  const {
    storeList,
    userList,
    isEODType,
    setEODType,
    business_list,
    printLoadingRef,
    downloadLoadingRef,
    downloadPaymentEOD,
    isLoading,
  } = useEndOfDayForm({
    setIsEndOfDayDetails,
    endOfDayFormDetails,
  });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation({ endOfDayFormDetails, isEODType });

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        className="mt-4"
      >
        <h4>End Of Day</h4>
        <p>
          You can customize how you want to receive your end of day with the
          options below
        </p>
        <form className={styles.formSection}>
          {/* store */}

          {/* option */}
          <div className="d-flex" style={{ gap: "15px" }}>
            <Controller
              name="isEODType"
              control={control}
              defaultValue={isEODType}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  name="isEODType"
                  value={isEODType}
                  id="Inventory"
                  isChecked={isEODType === "Inventory"}
                  setRadioValue={() => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      payment_method: [],
                      stores: [],
                      order_source: "",
                      sub_account: "",
                    }));
                    setEODType("Inventory");
                    onChange("Inventory");
                  }}
                >
                  <div>
                    <span className="font-weight-semibold">Inventory</span>
                  </div>
                </primaryComponents.BoxedRadioInput>
              )}
            />
            <Controller
              name="isEODType"
              control={control}
              defaultValue={isEODType}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  name="isEODType"
                  value={isEODType}
                  id="Payment"
                  isChecked={isEODType === "Payment"}
                  setRadioValue={() => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      payment_method: [],
                      stores: [],
                      order_source: "",
                      sub_account: "",
                    }));
                    setEODType("Payment");
                    onChange("Payment");
                  }}
                >
                  <div>
                    <span className="font-weight-semibold">Payment</span>
                  </div>
                </primaryComponents.BoxedRadioInput>
              )}
            />
          </div>
          {isEODType === "Payment" ? (
            <>
              <primaryComponents.MultipleSelectFieldV2
                options={[
                  {
                    label: "Transfer",
                    id: "offline_transfer",
                  },
                  { label: "Card", id: "pos_card" },
                ]}
                control={control}
                name="payment_method"
                label="Payment Method"
                isRequired={true}
                value={endOfDayFormDetails?.payment_method}
                onChange={(values) => {
                  setEndOfDayFormDetails((prevState) => ({
                    ...prevState,
                    payment_method: values,
                  }));
                }}
                idKey="id"
                nameKey="label"
                classNames="bank-type-select"
                register={register}
                errorMessage={errors.payment_method}
                giveControl={false}
              />
              <primaryComponents.SelectFieldV2
                name="sub_account"
                control={control}
                label="Select Sub Account"
                isRequired
                placeholder="Sub Account"
                value={endOfDayFormDetails?.sub_account || "Select"}
                options={business_list}
                onChange={(values) => {
                  setEndOfDayFormDetails((prevState) => ({
                    ...prevState,
                    sub_account: values,
                  }));
                }}
                nameKey="tag"
                idKey="id"
                errorMessage={errors.sub_account}
                giveControl={endOfDayFormDetails?.sub_account !== ""}
              />
            </>
          ) : (
            <>
              <primaryComponents.MultipleSelectFieldV2
                name="store"
                control={control}
                options={storeList}
                idKey="id"
                nameKey="name"
                label="Select store"
                isRequired
                placeholder="Select store(s)"
                errorMessage={errors.store}
                value={endOfDayFormDetails?.stores}
                onChange={(values) => {
                  setEndOfDayFormDetails((prevState) => ({
                    ...prevState,
                    stores: values,
                  }));
                }}
                giveControl={false}
              />
              <primaryComponents.SelectFieldV2
                name="Order source"
                control={control}
                options={[
                  { name: "Point of Sale", id: "INTERNAL" },
                  { name: "Online Orders", id: "STOREFRONT" },
                ]}
                idKey="id"
                label="Select Order Source"
                nameKey="name"
                value={endOfDayFormDetails.order_source?.label || "Select"}
                onChange={(value, label) => {
                  setEndOfDayFormDetails((prevState) => ({
                    ...prevState,
                    order_source: { value, label },
                    initiators:
                      value === "INTERNAL"
                        ? endOfDayFormDetails?.initiators
                        : [],
                  }));
                }}
                giveControl={endOfDayFormDetails.order_source !== ""}
              />

              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey={"can_filter_for_all_users"}
              >
                {endOfDayFormDetails?.order_source?.value === "INTERNAL" ? (
                  <>
                    {endOfDayFormDetails?.initiators?.some(
                      (item) => item.value === "select all",
                    ) ? (
                      <div
                        className="d-flex justify-content-end"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setEndOfDayFormDetails((prevState) => ({
                            ...prevState,
                            initiators: [],
                          }))
                        }
                      >
                        <span className="purple--text smallest-text-size">
                          Reset Initiator
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <primaryComponents.MultipleSelectFieldV2
                      name="initiator"
                      control={control}
                      options={[
                        { first_name: "Select All", id: "select all" },
                        ...userList,
                      ]}
                      idKey="id"
                      nameKey="first_name"
                      placeholder="Select Initiator(s)"
                      value={endOfDayFormDetails?.initiators}
                      onChange={(values) => {
                        const hasSelectAll =
                          endOfDayFormDetails?.initiators?.some(
                            (item) => item.value === "select all",
                          );
                        setEndOfDayFormDetails((prevState) => ({
                          ...prevState,
                          initiators: hasSelectAll ? [] : values,
                        }));
                      }}
                      isDisabled={endOfDayFormDetails?.initiators?.some(
                        (item) => item.value === "select all",
                      )}
                    />
                  </>
                ) : null}
              </secondaryComponents.NavigateWithPermission>
            </>
          )}

          {/* Date range */}
          <div className={styles.daterangeSection}>
            <div>
              <label>
                <span className="error--text">*</span>Start Date / Time
              </label>
              <div className="d-flex">
                <primaryComponents.DateInput
                  name="startDate"
                  control={control}
                  classNames={`white ${styles.dateInput}`}
                  value={endOfDayFormDetails.startDate}
                  isRequired={true}
                  errorMessage={errors.startDate}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      startDate: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.startDate !== undefined && trigger("startDate")
                  }
                  register={register}
                />
                <primaryComponents.TimeInputV2
                  name="startTime"
                  control={control}
                  register={register}
                  classNames={styles.timeInput}
                  value={endOfDayFormDetails.startTime}
                  isRequired={true}
                  errorMessage={errors.startTime}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      startTime: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.startTime !== undefined && trigger("startTime")
                  }
                />
              </div>
            </div>

            <span>To</span>

            <div>
              <label>
                <span className="error--text">*</span>End Date / Time
              </label>
              <div className="d-flex">
                <primaryComponents.DateInput
                  name="endDate"
                  control={control}
                  value={endOfDayFormDetails.endDate}
                  classNames={`white ${styles.dateInput}`}
                  isRequired={true}
                  errorMessage={errors.endDate}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      endDate: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.endDate !== undefined && trigger("endDate")
                  }
                  register={register}
                />
                <primaryComponents.TimeInputV2
                  name="endTime"
                  control={control}
                  register={register}
                  classNames={styles.endTime}
                  value={endOfDayFormDetails.endTime}
                  isRequired={true}
                  errorMessage={errors.endTime}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      endTime: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.endTime !== undefined && trigger("endTime")
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </motion.div>

      <primaryComponents.Button
        onClick={handleSubmit((values) => {
          if (isEODType === "Payment") {
            getPaymentEndOfDayDetails(values);
          } else {
            getEndOfDayDetails(values);
          }
        })}
        classNames="btn btn--primary cover mt-auto"
        isDisabled={isGeneratingDetails || isFetchingTnx}
        isLoading={isGeneratingDetails || isFetchingTnx}
        loadingText="Generating..."
        style={{ minHeight: "48px" }}
      >
        Generate End of Day
      </primaryComponents.Button>

      <SideModal
        width={800}
        isActive={isPayment}
        closeModal={() => setIsPayment(false)}
      >
        <AllPaymentEOD
          paymentEOD={endOfDayDetails}
          isFetchingTnx={isFetchingTnx}
          closeModal={() => setIsPayment(false)}
          printLoadingRef={printLoadingRef}
          downloadLoadingRef={downloadLoadingRef}
          downloadPaymentEOD={downloadPaymentEOD}
          isLoading={isLoading}
        />
      </SideModal>
    </>
  );
};
export default EndOfDayForm;
