import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const getSchema = (type) =>
  yup.object({
    statementFormat: yup
      .string()
      .label("statementFormat")
      .oneOf(["PDF", "CSV"]),
    startDate: yup.string().required("Start date is required"),
    endDate: yup.string().required("End date is required"),
    walletId:
      type === "debtor"
        ? yup.string().nullable()
        : yup.string().required("Wallet is required"),
  });

const Validation = (statementFormat, startDate, endDate, walletId, type) => {
  return useForm({
    resolver: yupResolver(getSchema(type)),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      walletId,
      statementFormat,
      startDate,
      endDate,
    },
  });
};

export default Validation;
