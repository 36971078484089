//import SubscriptionFeatureCollapse from "../SubscriptionFeatureCollapse";
import styles from "./SubscriptionFeatureTable.module.scss";
// import { motion } from "framer-motion";

const SubscriptionFeatureTable = ({
  children,
  title = "",
  isActive,
  toggleView,
}) => {
  return (
    <div className={styles.subscriptionFeatureTable}>
      {/* <SubscriptionFeatureCollapse
        isActive={isActive}
        toggleView={toggleView}
      /> */}
      <div className={styles.header}>
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            borderTopLeftRadius: "15px",
            backgroundColor: "#F3F1FC",
            padding: "13px",
            width: "23%",
          }}
        >
          <p className={`${styles.title} dark--text font-weight-bold`}>
            {title?.replace("_", " ")}
          </p>
        </div>
        {isActive ? (
          <>
            {/* <p>Free</p>
            <p className="pending--text">Essential</p>
            <p className="success--text">Growth</p>
            <p className="purple--text">Scale</p> */}
          </>
        ) : null}
      </div>
      <hr />
      <div style={{ marginTop: "-10px" }}>{children}</div>
    </div>
  );
};
export default SubscriptionFeatureTable;
