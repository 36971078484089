import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const getValidationSchema = (type) => {
  return yup.object({
    amount: yup.string().required("Amount is required"),
    bank: yup.number().required("Bank is required"),
    bank_destination:
      type === "transfer"
        ? yup.number().required("Destination bank is required")
        : yup.number().notRequired(),
    storeIds:
      type !== "transfer"
        ? yup.string().required("Store Name is required")
        : yup.string().notRequired(),
  });
};

const Validation = ({ type }) => {
  return useForm({
    resolver: yupResolver(getValidationSchema(type)),
    shouldFocusError: true,
    mode: "onBlur",
  });
};

export default Validation;
