import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const businessSchema = yup
  .object({
    name: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Business name is required"),
    size: yup.string().required("Business size is required"),
    business_email: yup
      .string()
      .email("Invalid email address")
      .max(40, "Maximum of 40 characters is allowed"),
    support_email: yup
      .string()
      .email("Invalid email address")
      .max(40, "Maximum of 40 characters is allowed"),
    business_phone_number: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
    support_phone_number: yup.string(),
    address: yup.string().max(200, "A maximum of 200 characters"),
  })
  .required();

const ProfileBusinessInfoFormValidation = ({ ...rest }) => {
  return useForm({
    resolver: yupResolver(businessSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      ...rest,
    },
  });
};

export default ProfileBusinessInfoFormValidation;
