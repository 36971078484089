import secondaryComponents from "../../../components/secondaryComponents";
import useStaffSection from "./useStaffSection";
import styles from "./StaffSection.module.scss";
import Images from "../../../assets/images";
import React from "react";
import primaryComponents from "../../../components/primaryComponents";

const StaffSection = () => {
  const {
    isfetchingOrders,
    isFetchingNextPage,
    search,
    setDateFilter,
    autoScrollRef,
    sectionOrders,
    setSourceFilter,
    options,
    setSource,
  } = useStaffSection();

  return (
    <>
      <div className={styles.staffSection}>
        <div className="d-flex  flex-wrap ml-auto" style={{ gap: "5px" }}>
          <div style={{ width: "250px" }}>
            <primaryComponents.SelectField
              options={options}
              idKey="id"
              nameKey="name"
              value={() => {
                if (localStorage.getItem("selectedOrderSource")) {
                  return options?.find(
                    (opt) =>
                      opt.id === localStorage.getItem("selectedOrderSource"),
                  )?.name;
                } else {
                  return "Select Order Source";
                }
              }}
              onChange={(value) => {
                setSource(value);
                localStorage.setItem("selectedOrderSource", value);
                setSourceFilter(value);
              }}
              floatError={false}
            />
          </div>
          <primaryComponents.DateRangeInput
            //classNames="ml-auto"
            startDate={search.get("start_date")}
            endDate={search.get("end_date")}
            onChange={setDateFilter}
            isToday={true}
          />
        </div>

        <secondaryComponents.LoaderHelper
          classNames={"mt-5"}
          isLoading={isfetchingOrders}
        >
          {sectionOrders?.length > 0 ? (
            <>
              <div className={styles.staffSection__cards}>
                {sectionOrders?.map((sectionOrder) => (
                  <React.Fragment key={sectionOrder?.id}>
                    <secondaryComponents.SectionCard
                      sectionOrder={sectionOrder}
                      autoScrollRef={autoScrollRef}
                    />
                  </React.Fragment>
                ))}
              </div>

              <secondaryComponents.LoaderHelper
                classNames="mt-4"
                isLoading={isFetchingNextPage && !isfetchingOrders}
              ></secondaryComponents.LoaderHelper>
            </>
          ) : (
            <div className="text-center mt-5">
              <img
                width="250px"
                height="262px"
                src={Images.storeProductEmpty}
                alt="empty state"
              />
              <h4>You haven&apos;t created an order yet </h4>
              <p>When you do, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </div>
    </>
  );
};

export default StaffSection;
