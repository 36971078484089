import React from "react";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../primaryComponents";
import useLocationchange from "./useLocationchange";
import style from "./StoreFrontLocationChange.module.scss";
import {
  LocationChangeEmptyStateIcon,
  PauseLocation,
} from "../../../assets/svgIcons";
import secondaryComponents from "..";
import { pathConstants } from "../../../routes/pathContants";

const StoreFrontLocationChangeModal = ({ primarColor }) => {
  const {
    countries,
    isLoadingCountries,
    selectedCountry,
    selectedState,
    selectedCity,
    isLoadingStoreLocations,
    storeLocations,
    location,
    stateInputRef,
    cityInputRef,
    businessId,
    setSelectedCity,
    setSelectedState,
    setSelectedCountry,
    handleFilterByLocation,
  } = useLocationchange();

  return (
    <div
      className={`${globalStyles.pageColumnGaps} p-3`}
      style={{ minHeight: "70vh" }}
    >
      <h5 className="text-center">Select Your Region To Start Your Order</h5>

      <secondaryComponents.LoaderHelper
        isLoading={isLoadingCountries}
        classNames={"mt-4"}
      >
        <div className={style.cardWrap}>
          <div className="w-100">
            <secondaryComponents.LoaderHelper
              classNames={"mt-2"}
              isLoading={countries?.length === 1 && !selectedCountry}
            >
              <primaryComponents.SelectField
                label="Select Country"
                value={
                  countries?.find(
                    (country) => country?.iso2 === selectedCountry,
                  )?.name || "Select country"
                }
                onChange={(value) => {
                  setSelectedCountry(value);
                  setSelectedState(null);
                  stateInputRef.current.setValue([], "clear");
                  cityInputRef?.current?.setValue([], "clear");
                  handleFilterByLocation({
                    key: "country",
                    value: value === "ALL_COUNTRIES" ? null : value,
                    clearState: true,
                    clearCity: true,
                  });
                }}
                name="country"
                options={[
                  { name: "All countries", iso2: "ALL_COUNTRIES" },
                  ...countries,
                ]}
                nameKey="name"
                idKey="iso2"
                backgroundColor={primarColor}
                floatError={false}
              />
            </secondaryComponents.LoaderHelper>
          </div>
          <div className="w-100">
            <primaryComponents.SelectField
              classNames="white"
              name="state"
              label={"Select State"}
              value={
                countries
                  ?.find((country) => country.iso2 === selectedCountry)
                  ?.available_states?.find(
                    (state) => state?.state_slug === selectedState,
                  )?.name || "Select state"
              }
              onChange={(value) => {
                setSelectedState(value);
                setSelectedCity(null);
                cityInputRef?.current?.setValue([], "clear");
                handleFilterByLocation({
                  key: "state",
                  value,
                  clearCity: true,
                });
              }}
              nameKey="name"
              idKey={"state_slug"}
              options={
                countries?.find((country) => country.iso2 === selectedCountry)
                  ?.available_states
              }
              backgroundColor={primarColor}
              floatError={false}
              selectInputRef={stateInputRef}
            />
          </div>
          <div className="w-100">
            <primaryComponents.SelectField
              classNames="white"
              name="city"
              label={"Select City"}
              value={
                countries
                  ?.find((country) => country.iso2 === selectedCountry)
                  ?.available_states?.find(
                    (state) => state?.state_slug === selectedState,
                  )
                  ?.available_cities?.find(
                    (city) => city?.city_slug === selectedCity,
                  )?.name || "Select city"
              }
              onChange={(value) => {
                setSelectedCity(value);
                handleFilterByLocation({
                  key: "city",
                  value,
                });
              }}
              nameKey="name"
              idKey={"city_slug"}
              options={
                countries
                  ?.find((country) => country.iso2 === selectedCountry)
                  ?.available_states?.find(
                    (state) => state?.state_slug === selectedState,
                  )?.available_cities
              }
              backgroundColor={primarColor}
              floatError={false}
              selectInputRef={cityInputRef}
            />
          </div>
        </div>

        <secondaryComponents.LoaderHelper
          isLoading={isLoadingStoreLocations}
          classNames={"mt-5"}
        >
          {storeLocations?.length > 0 ? (
            <div>
              {storeLocations?.map((item, index) => (
                <React.Fragment key={item?.id}>
                  {storeLocations?.length === index + 1 ? null : <hr />}

                  <div className="d-flex justify-content-between align-items-center py-3">
                    <div className="d-flex" style={{ gap: "5px" }}>
                      <PauseLocation color={primarColor} />
                      <div>
                        <h5 className="mb-0">{item?.name}</h5>
                        <p>{item?.store?.address}</p>

                        <a href={`tel:${item?.store?.phone_number}`}>
                          {item?.store?.phone_number}
                        </a>
                      </div>
                    </div>
                    <a
                      href={
                        item?.template_in_use === "template-1"
                          ? `${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/${location?.country?.iso2 || "glb"}?sfid=${item?.id}`
                          : window.location.origin +
                            pathConstants.MENU({
                              id: businessId,
                              path: `${
                                selectedCountry && location?.country?.iso2
                                  ? "/" + location?.country?.iso2
                                  : ""
                              }${
                                selectedState && location?.state?.state_slug
                                  ? "/" + location?.state?.state_slug
                                  : ""
                              }${
                                selectedCity && location?.city?.city_slug
                                  ? "/" + location?.city?.city_slug
                                  : ""
                              }`,
                            }) +
                            `?menu_id=${item?.id}`
                      }
                      style={{ textDecoration: "none" }}
                    >
                      <primaryComponents.Button
                        classNames="btn btn--primary"
                        style={{ background: primarColor }}
                      >
                        <span className="small-text-size m-1">
                          Visit Storefront
                        </span>
                      </primaryComponents.Button>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className="text-center mt-4">
              <LocationChangeEmptyStateIcon width="250" />
              <h5>No Data Available</h5>
            </div>
          )}
        </secondaryComponents.LoaderHelper>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default StoreFrontLocationChangeModal;
