import { useQuery } from "react-query";
import {
  getStorefrontProductList,
  getViewMenuDetail,
  getViewMenuList,
} from "../../../services/inventory";

export const useGetStorefrontMenus = (id) => {
  return useQuery(
    ["getStorefrontMenus", id],
    () => getViewMenuList({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: id ? true : false,
    },
  );
};

export const useGetViewStorefrontDetail = (id) => {
  return useQuery(
    ["getStorefrontDetails", id],
    () => getViewMenuDetail({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: id ? true : false,
    },
  );
};

export const useGetStorefrontProductList = ({
  queries = "",
  storefrontId,
  params = {},
}) => {
  return useQuery({
    queryKey: ["getProductList", queries, params],
    staleTime: Infinity,
    enabled: storefrontId ? true : false,
    queryFn: () =>
      getStorefrontProductList({
        storefrontId: storefrontId || undefined,
        queries,
        params,
      }).then((res) => {
        return res?.data;
      }),
  });
};
