import { useEffect } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { usePrint } from "../../hooks/usePrint";

const usePrintLogic = ({
  itemDetails,
  sectionPrintComponent,
  hiddenId,
  isTest = false,
  callBackFunc = () => {},
  onErrorCallBackFunc = () => {},
}) => {
  const { business_name, address, logo } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { print, paperSize } = usePrint({ isTest, isEnabled: false });

  const handlePrint = async () => {
    if (!sectionPrintComponent.current) return;

    const canvas = await html2canvas(sectionPrintComponent.current, {
      onclone: (clonedDoc) => {
        // Make the hidden section visible in the cloned DOM
        const hiddenSection = clonedDoc.getElementById(hiddenId);
        if (hiddenSection) hiddenSection.style.display = "block";
      },

      width: paperSize,
      scale: 5, // Increase quality
      useCORS: true, // Fixes cross-origin issues if any
      allowTaint: true,
    });

    // Convert canvas to image and open in new tab or save
    const imgData = canvas.toDataURL("image/png");

    print({
      imageData: imgData,
      onSuccess: callBackFunc,
      onError: onErrorCallBackFunc,
    });
  };

  useEffect(() => {
    if (itemDetails) handlePrint();
  }, [itemDetails]);

  return {
    paperSize,
    business_name,
    address,
    logo,
  };
};

export default usePrintLogic;
