import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CautionIcon } from "../../../../assets/svgIcons";
import PdfIcon from "../../../../assets/svgIcons/PdfIcon";
import ExcelIcon from "../../../../assets/svgIcons/ExcelIcon";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import Icon from "../../../../assets/icons";

const DownloadModal = ({
  setIsDownloadReport,
  downloadEndOfDay,
  closeModal,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <h6 className="text-center font-weight-semibold">
          Advanced End of Day
        </h6>
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      </div>
      <hr style={{ marginLeft: "-13px", marginRight: "-13px" }} />
      <div
        style={{
          backgroundColor: "#F1F1F1",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          borderRadius: "8px",
          margin: "20px 0",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="smallest-text-size dark--text">
          Select the format you want to download
        </p>
        <div
          style={{
            display: "flex",
            background: "#FFDDD2",
            padding: "8px",
            justifyItems: "center",
            gap: "5px",
          }}
        >
          <CautionIcon stroke="#15112D" />
          <p
            className="smallest-text-size dark--text"
            style={{ fontSize: "10px" }}
          >
            Click download will first generate your end of day before it
            automatically downloads.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <div>
            <PdfIcon />
          </div>
          <div>
            <ExcelIcon />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ gap: "5px" }}
          >
            <primaryComponents.Button
              onClick={() => {
                setIsDownloadReport(true);
                downloadEndOfDay({
                  delivery_method: "download",
                  download_format: "pdf",
                });
              }}
              classNames="btn btn--primary smallest-text-size"
              style={{ maxHeight: "30px", width: "150px" }}
            >
              Download Full Summary
            </primaryComponents.Button>
            <secondaryComponents.ToolTipV2
              tipText="Send to Email"
              position="left"
            >
              <primaryComponents.Button
                onClick={() =>
                  downloadEndOfDay({
                    delivery_method: "send_to_email",
                    download_format: "pdf",
                  })
                }
                classNames="btn btn--outline"
                style={{ height: "30px", width: "20px" }}
              >
                <img src={Icon.emailTwo} alt="" />
              </primaryComponents.Button>
            </secondaryComponents.ToolTipV2>
          </div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ gap: "5px" }}
          >
            <primaryComponents.Button
              onClick={() => {
                setIsDownloadReport(true);
                downloadEndOfDay({
                  delivery_method: "download",
                  download_format: "excel",
                });
              }}
              classNames="btn btn--primary smallest-text-size"
              style={{ maxHeight: "30px", width: "150px" }}
            >
              Download Full Summary
            </primaryComponents.Button>
            <secondaryComponents.ToolTipV2
              tipText="Send to Email"
              position="left"
            >
              <primaryComponents.Button
                onClick={() =>
                  downloadEndOfDay({
                    delivery_method: "send_to_email",
                    download_format: "excel",
                  })
                }
                classNames="btn btn--outline cover"
                style={{ height: "30px", width: "20px" }}
              >
                <img src={Icon.emailTwo} alt="" />
              </primaryComponents.Button>
            </secondaryComponents.ToolTipV2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadModal;
