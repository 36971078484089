import React from "react";
import styles from "./styles.module.scss";
import Icon from "../../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import Images from "../../../assets/images";
import { pathConstants } from "../../../routes/pathContants";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { useGetStorefrontOrderDetails } from "../../../hooks/api/queries/useOrder";
import secondaryComponents from "../../../components/secondaryComponents";
import { defaultDateDisplay } from "../../../helpers";
import primaryComponents from "../../../components/primaryComponents";

const OrderStatusTrack = () => {
  const navigate = useNavigate();
  const { menuId, id } = useParams();
  const { isLoading: isFetchingOrderDetails, data: orderDetails } =
    useGetStorefrontOrderDetails({ id });
  const orderStatus = {
    PLACED: 1,
    PENDING: 2,
    IN_PROGRESS: 3,
    ORDER_READY: 4,
    SHIPPED: 5,
    RETURNED: 6,
    CANCELED: 7,
    DELIVERED: 8,
    COMPLETED: 9,
  };

  return (
    <div className={styles.page}>
      <div
        className={`${globalStyles.cardWithBorder} ${styles.mainContentContainer}`}
      >
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingOrderDetails}
          classNames={"mt-5"}
        >
          <div className={styles.orderContainer}>
            <div
              className="d-flex justify-content-between flex-wrap"
              style={{ gap: "10px" }}
            >
              <div>
                <h4 className="mb-1">Order Status</h4>
                <p>Monitor your order progress here:</p>
              </div>
              <h4>
                Order ID:{" "}
                <span className="purple--text">{orderDetails?.id}</span>
              </h4>
            </div>

            <div className={styles.orderStatusConnections}>
              <div className={styles.statusConnections}>
                <img
                  src={
                    orderStatus[orderDetails?.status] >= orderStatus?.PLACED
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />
                <div className={styles.divider} />
                <img
                  src={
                    (orderDetails?.accepted_by &&
                      orderStatus[orderDetails?.status] >=
                        orderStatus?.PENDING) ||
                    orderStatus[orderDetails?.status] >=
                      orderStatus?.ORDER_READY
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />
                <div className={styles.divider} />
                <img
                  src={
                    (orderStatus?.[orderDetails?.status] >=
                      orderStatus?.PENDING &&
                      orderDetails?.section_items_status !== "RECEIVED" &&
                      orderDetails?.section_items_status !== "CANCELLED" &&
                      orderDetails?.accepted_by) ||
                    orderStatus[orderDetails?.status] >=
                      orderStatus?.ORDER_READY
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />
                <div className={styles.divider} />
                <img
                  src={
                    orderStatus[orderDetails?.status] >=
                    orderStatus?.ORDER_READY
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />

                {orderDetails?.delivery_address ? (
                  <>
                    <div className={styles.divider} />
                    <img
                      src={
                        orderStatus[orderDetails?.status] >=
                        orderStatus?.SHIPPED
                          ? Icon.orderStatusCheck
                          : Icon.orderStatusUncheck
                      }
                      alt=""
                    />
                  </>
                ) : null}
                {orderDetails?.status === "RETURNED" ? (
                  <>
                    <div className={styles.divider} />
                    <img src={Icon.orderStatusCheck} alt="" />
                  </>
                ) : null}
                {orderDetails?.status === "CANCELED" ? (
                  <>
                    <div className={styles.divider} />
                    <img src={Icon.orderStatusCheck} alt="" />
                  </>
                ) : null}
                <div className={styles.divider} />
                <img
                  src={
                    orderStatus[orderDetails?.status] >= orderStatus?.DELIVERED
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />
                <div className={styles.divider} />
                <img
                  src={
                    orderStatus[orderDetails?.status] >= orderStatus?.COMPLETED
                      ? Icon.orderStatusCheck
                      : Icon.orderStatusUncheck
                  }
                  alt=""
                />
              </div>
              <div className={styles.orderStatus}>
                <div className={styles.orderItem}>
                  <div>
                    <h6>Order Placed</h6>
                    <p>Waiting for merchant to confirm your order.</p>
                  </div>
                  <p>{defaultDateDisplay(orderDetails?.created_at)}</p>
                </div>
                <div className={styles.orderItem}>
                  <div>
                    <h6>Order Accepted</h6>
                    <p>Merchant accepted your order</p>
                  </div>
                </div>
                <div className={styles.orderItem}>
                  <div>
                    <h6>Preparing Your Order</h6>
                    <p>Your order is being prepared with love</p>
                  </div>
                </div>
                <div className={styles.orderItem}>
                  <div>
                    <h6>Order Ready</h6>
                    <p>Your order is now ready</p>
                  </div>
                </div>
                {orderDetails?.delivery_address ? (
                  <div className={styles.orderItem}>
                    <div>
                      <h6>Order Shipped</h6>
                      <p>Your order is on its way</p>
                    </div>
                  </div>
                ) : null}
                {orderDetails?.status === "RETURNED" ? (
                  <div className={styles.orderItem}>
                    <div>
                      <h6>Order Returned</h6>
                      <p>Your order has been returned</p>
                    </div>
                  </div>
                ) : null}
                {orderDetails?.status === "CANCELED" ? (
                  <div className={styles.orderItem}>
                    <div>
                      <h6>Order Canceled</h6>
                      <p>Your orderhas been canceled</p>
                    </div>
                  </div>
                ) : null}
                <div className={styles.orderItem}>
                  <div>
                    <h6 className="mt-3">
                      {orderDetails?.delivery_address
                        ? "Order Delivered"
                        : "Order Picked Up"}
                    </h6>
                    <p>Thanks for your patronage</p>
                  </div>
                </div>
                <div className={`${styles.orderItem}`}>
                  <div>
                    <h6>Order Completed</h6>
                  </div>
                </div>
              </div>
            </div>

            <h6 className="mt-4">
              Store Phone Number:{" "}
              <span className="gray--text">
                {orderDetails?.store?.phone_number}
              </span>
            </h6>

            {orderDetails?.dispatch_rider ? (
              <h6>
                Rider Phone Number:{" "}
                <span className="gray--text">
                  {orderDetails?.dispatch_rider?.phone_code}
                  {orderDetails?.dispatch_rider?.phone_number}
                </span>
              </h6>
            ) : null}

            <primaryComponents.Button
              classNames={"btn btn--primary ml-auto mt-3"}
              onClick={() => {
                navigate(pathConstants.MENU({ id: menuId }));
              }}
            >
              Continue Shopping
            </primaryComponents.Button>
          </div>
        </secondaryComponents.LoaderHelper>
      </div>

      <div className={styles.enlumiArrows}>
        <img src={Images.enlumi_arrows} alt="" />
      </div>
    </div>
  );
};

export default OrderStatusTrack;
