import httpClient from "../axiosBase/axiosHandler";

export const fetchAllSections = () => {
  return httpClient.get("/sections/");
};

export const addSection = (payload) => {
  return httpClient.post("/sections/", payload);
};

export const toggleSectionStatus = (id) => {
  return httpClient.patch(`/sections/${id}/toggle-status/`);
};

export const removeSection = ({ id }) => {
  return httpClient.delete(`/sections/${id}`);
};

export const updateSection = ({ id, name }) => {
  return httpClient.patch(`/sections/${id}/`, { name: name });
};

export const fetchAllSectionOrdersList = ({ queries, pageParam }) => {
  return httpClient.get(`/section-items/${queries}`, {
    params: { page: pageParam, per_page: 50 },
  });
};

export const startSectionOrder = ({ id }) => {
  return httpClient.patch(`/section-items/${id}/start/`);
};

export const completeSectionOrder = ({ id }) => {
  return httpClient.patch(`/section-items/${id}/complete/`);
};

export const getOrdersWithSection = ({ queries, pageParam }) => {
  return httpClient.get(`/section-items/linked-orders/${queries}`, {
    params: { per_page: 40, page: pageParam },
  });
};

export const getSectionItemDetails = ({ id }) => {
  return httpClient.get(`/section-items/${id}/print/`);
};

export const sendSectionItemReceiptToPrint = (payload) => {
  return httpClient.post("/section-items/send-to-terminal/", payload);
};

export const updatekDocketPrintStatus = ({ id }) => {
  return httpClient.patch(`/section-items/${id}/has-been-printed/`);
};
