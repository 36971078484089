import styles from "./StoreForm.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { useSelector } from "react-redux";
import useStoreForm from "./useStoreForm";
import Validation from "./Validation";
import { CautionIcon } from "../../../assets/svgIcons";
import globalStyles from "./../../../assets/styles/base/globalPage.module.scss";
import { useNavigate } from "react-router-dom";
import EditIconV2 from "../../../assets/svgIcons/EditIconV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationModal from "../LocationModal";
import { useEffect } from "react";

const StoreForm = ({
  isLoadingStates,
  states,
  countries,
  setSelectedCountry,
  storeInfo,
  isEditMode = false,
  handleFetchStores = () => {},
  closeWidget = () => {},
}) => {
  const navigate = useNavigate();
  const {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list,
    currencyList,
    isLoadingTimezones,
    timezones,
    bankAccount,
    isLocation,
    setIsLocation,
    setStoreDetails,
    submitStoreInfo,
    selectedHours,
    isLoadingRoles,
    roles,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isOtherSetting,
    setIsOtherSetting,
  } = useStoreForm({
    storeInfo,
    isEditMode,
    states,
    countries,
    handleFetchStores,
    closeWidget,
  });

  const {
    handleSubmit,
    control,
    register,
    trigger,
    setValue,
    formState: { errors },
  } = Validation({ storeDetails, isCheckboxChecked });
  const { currency_code, allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  useEffect(() => {
    const fields = {
      allowIncrementQty: "allow_increment_qty",
      allowReduceQty: "allow_reduce_qty",
      allowDeleteBills: "allow_delete_qty",
      allowCreditPayment: "allow_credit_payment",
      allowPublicRelation: "allow_public_relation",
      allowDeleteBillOrder: "allow_delete_bill_order",
      allowDiscount: "allow_discount",
      allowLoyalty: "allow_loyalty",
      allowGiftCard: "allow_giftcard",
      allowDelivery: "allow_delivery",
      allowCreditRepayment: "allow_credit_repayment",
      allowAddCreditDebt: "allow_add_credit_debt",
      allowDocketPrint: "allow_print_docket",
      allowCustomPricing: "allow_custom_price",
      allowSplitPayment: "allow_split_payment",
    };

    Object.entries(fields).forEach(([field, key]) =>
      setValue(field, isCheckboxChecked?.[key] || false),
    );
  }, [isCheckboxChecked, setValue]);

  return (
    <>
      <div className={styles.storeForm}>
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <h4 className="font-weight-semibold mb-0">
            {isEditMode
              ? `Update store (${storeInfo?.name})`
              : "Create a new store"}
          </h4>
        </div>

        {/* form section */}
        <form
          onSubmit={handleSubmit(submitStoreInfo)}
          className={styles.formSection}
        >
          <div className={styles.cardSession}>
            <h5 className="font-weight-semibold">Details</h5>
            <div className={styles.inputSection}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="storeName"
                label="Store Name"
                placeholder="Enter your store name"
                isRequired={true}
                value={storeDetails.name}
                onChange={({ target: { value } }) => {
                  setStoreDetails((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                errorMessage={errors.storeName}
                onKeyUp={() =>
                  errors.storeName !== undefined && trigger("storeName")
                }
                register={register}
              />
              <div className="w-100 mb-2">
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="storeCategory"
                  label="Business Category"
                  isRequired
                  value={
                    storeDetails?.store_category_label ||
                    "Select business category"
                  }
                  onChange={(value) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      store_category: value,
                    }));
                  }}
                  options={category_list}
                  nameKey="name"
                  idKey="id"
                  errorMessage={errors.storeCategory}
                />
              </div>
            </div>

            <div className={styles.inputSection}>
              <div className="w-100">
                <primaryComponents.PhoneNumberInputFieldV2
                  control={control}
                  classNames="white"
                  name="storePhone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  //defaultValue={storeDetails?.phone_number}
                  value={storeDetails?.phone_number}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      phone_number: value,
                    }));
                  }}
                  errorMessage={errors?.storePhone}
                  onKeyUp={() =>
                    errors.storePhone !== undefined && trigger("storePhone")
                  }
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      phone_code: val,
                    }));
                  }}
                  countryCode={storeDetails?.phone_code}
                />
              </div>
              <div className="w-100 mt-2">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white mr-1"
                  name="storeEmail"
                  label="Support Email Address"
                  placeholder="Enter business email address"
                  value={storeDetails?.support_email || ""}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      support_email: value,
                    }));
                  }}
                  errorMessage={errors.storeEmail}
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className={styles.cardSession}>
            <h5 className="font-weight-bold">Currency & VAT</h5>
            <div className={styles.inputSection}>
              <div className="w-100" style={{ marginTop: "-3px" }}>
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="storeCurrency"
                  label="Store currency"
                  isRequired={isEditMode ? false : true}
                  isDisabled={storeInfo?.has_sales_transaction}
                  value={
                    currencyList?.find(
                      (item) => item.currency === storeDetails?.currency_code,
                    )?.currency || "Select currency"
                  }
                  onChange={(value) => {
                    const symbol = currencyList?.find(
                      (item) => item?.currency === value,
                    )?.currency_symbol;

                    setStoreDetails((prev) => ({
                      ...prev,
                      currency_code: value,
                      currency_symbol: symbol,
                    }));
                  }}
                  options={currencyList}
                  nameKey="currency"
                  idKey="currency"
                />
              </div>
              <div className="w-100 mt-2">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white"
                  name="vat"
                  label="VAT Rate(%)"
                  placeholder="Enter vat rate"
                  value={storeDetails?.vat}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      vat: value,
                    }));
                  }}
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  errorMessage={errors.vat}
                  onKeyUp={() => errors.vat !== undefined && trigger("vat")}
                  register={register}
                />
              </div>
              <div className="w-100 mt-2">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white"
                  name="serviceCharge"
                  label={
                    <div className="d-flex" style={{ gap: "5px" }}>
                      Service Charge (%){" "}
                      <secondaryComponents.ToolTipV2
                        tipText={
                          "<span>Service charge <br><br> A service charge is an additional charge for the service provided with the purchase of a product or service. For example, a service charge is typically added to a bill in a restaurant to pay for the waiter or waitress who comes and services a table.</span>"
                        }
                        className="ml-2"
                      >
                        <CautionIcon />
                      </secondaryComponents.ToolTipV2>
                    </div>
                  }
                  placeholder="Enter service charge"
                  value={storeDetails?.service_charge}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      service_charge: value,
                    }));
                  }}
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  errorMessage={errors.serviceCharge}
                  onKeyUp={() =>
                    errors.serviceCharge !== undefined &&
                    trigger("serviceCharge")
                  }
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className={styles.cardSession}>
            <h5 className="font-weight-bold">Location & Time Zone</h5>
            <div className={styles.inputSection}>
              <div className="w-100">
                <primaryComponents.InputFieldV2
                  isRequired
                  control={control}
                  name="storeAddress"
                  label="Store Address"
                  placeholder="Enter your store address"
                  value={storeDetails?.address || ""}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      address: value,
                    }));
                  }}
                  className="white"
                  onKeyUp={() =>
                    errors.storeAddress !== undefined && trigger("storeAddress")
                  }
                  errorMessage={errors.storeAddress}
                />
              </div>
              <div className="w-100 mb-2">
                {!isLoadingTimezones ? (
                  <primaryComponents.SelectFieldV2
                    control={control}
                    classNames="white"
                    name="timeZone"
                    label="Time Zone"
                    options={timezones}
                    placeholder="Select your time zone for this store"
                    value={
                      timezones?.find(
                        (timezone) => timezone?.id === storeDetails?.timezone,
                      )?.name || "Select timezone"
                    }
                    onChange={(value) => {
                      setStoreDetails((prev) => ({
                        ...prev,
                        timezone: value,
                      }));
                    }}
                    register={register}
                    nameKey="name"
                    idKey="id"
                  />
                ) : null}
              </div>
            </div>

            <div className={`${globalStyles.cardWithBorder} mb-2`}>
              <div className={styles.storefront}>
                <CautionIcon />
                <p className="small-text-size">
                  The regions you add here will be available for your online
                  customers to choose from when you set up your{" "}
                  <span className="purple--text">
                    {" "}
                    storefront (Lumi Buiness E-commerce).
                  </span>
                </p>
              </div>
              <div
                className={`${styles.cardWithBorder} d-flex flex-wrap align-items-center`}
              >
                <div className={styles.locationCard}>
                  {storeDetails?.country_ids ? (
                    <>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {countries?.find(
                              (country) =>
                                `${country?.id}` ===
                                `${storeDetails?.country_ids?.[0]}`,
                            )?.name || "Select country"}
                          </span>{" "}
                        </p>
                      </div>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {storeDetails?.state_ids?.length || 0}
                          </span>{" "}
                          states selected
                        </p>
                      </div>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {
                              Object.values({
                                ...storeDetails?.city_ids,
                              }).flat()?.length
                            }
                          </span>{" "}
                          cities selected
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="small-text-size">
                      Click{" "}
                      <span
                        style={{ fontStyle: "italic" }}
                        className="font-weight-bold"
                      >
                        “Add Region”{" "}
                      </span>
                      <span>to select country, states & cities</span>
                    </p>
                  )}
                </div>
                <div className={styles.addRegion}>
                  <primaryComponents.Button
                    onClick={() => setIsLocation(true)}
                    type="button"
                    classNames={`${styles.locationBtn} btn btn--outline`}
                  >
                    <EditIconV2 />
                    <span className="p-1"> Add region </span>
                  </primaryComponents.Button>
                </div>
              </div>
            </div>
          </div>

          <div className={`mb-4 ${globalStyles.cardWithBorder}`}>
            <div className={`${styles.cardSession}`}>
              <div
                className="d-flex justify-content-center flex-wrap items-center align-items-center"
                style={{ alignContent: "center", gap: "8px" }}
                onClick={() => setIsOtherSetting(!isOtherSetting)}
              >
                <h5 className="default-text-size purple--text">
                  Optional Store Settings
                </h5>
                <primaryComponents.Button
                  type="button"
                  classNames={"btn btn--outline smallBtn"}
                >
                  <FontAwesomeIcon
                    className="purple--text"
                    icon={isOtherSetting ? "angle-up" : "angle-down"}
                  />
                </primaryComponents.Button>
              </div>
            </div>
            {isOtherSetting ? (
              <div className={globalStyles.cardWithBorder}>
                <div className={`mb-1 ${styles.cardSession}`}>
                  <div className="d-flex flex-wrap" style={{ gap: "30px" }}>
                    <div className="mb-2" style={{ width: "300px" }}>
                      <h5 className="font-weight-semibold mt-2">
                        Auto-Reverse Pending Bills
                      </h5>
                      <div className="w-5" style={{ width: "300px" }}>
                        <primaryComponents.EstimatedInput
                          name="orderReversalWindow"
                          control={control}
                          placeholder="720"
                          value={storeDetails?.order_reversal_window}
                          onChange={(e) => {
                            let inputValue = parseFloat(e.target.value);
                            if (isNaN(inputValue)) {
                              inputValue = 24;
                            }
                            const integerPart = Math.floor(inputValue);
                            let decimalPart = (
                              inputValue - integerPart
                            ).toFixed(2);

                            if (decimalPart > 0.59) {
                              decimalPart = 0.59;
                            }

                            const finalValue =
                              integerPart + parseFloat(decimalPart);

                            setStoreDetails((prev) => ({
                              ...prev,
                              order_reversal_window: finalValue,
                            }));
                          }}
                          isRequired={false}
                          domainOptions={[{ label: "Hours", value: "Hours" }]}
                          selectedDomain={selectedHours}
                          errorMessage={errors?.orderReversalWindow}
                          onKeyUp={() =>
                            errors?.orderReversalWindow !== undefined &&
                            trigger("orderReversalWindow")
                          }
                          register={register}
                          min={0.01}
                          step="0.01"
                        />
                        <p
                          className="gray--text mt-2"
                          style={{ fontSize: "10px" }}
                        >
                          <span className="error--text">Please Note: </span>
                          Auto-reversals will be defaulted to 720-hours if no
                          value is applied.
                        </p>
                        <div
                          className="d-flex align-items-center text-center mt-2"
                          style={{ gap: "5px" }}
                        >
                          <secondaryComponents.ToolTipV2
                            tipText={
                              "<span> Enables automatic reversal of pending or placed carts after a defined time, releasing items back to inventory and ensuring accurate stock levels.</span>"
                            }
                          >
                            <p>
                              <CautionIcon height="12" width="13" />{" "}
                              <span
                                className="small-text-size purple--text"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                What are pending checkouts?
                              </span>
                            </p>
                          </secondaryComponents.ToolTipV2>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2" style={{ width: "300px" }}>
                      <h5
                        className="font-weight-semibold d-flex align-items-center"
                        style={{ gap: "5px" }}
                      >
                        Credit Limit
                        <secondaryComponents.ToolTipV2
                          tipText={
                            "<span>Credit Limit <br><br> This credit limit will be applied on all customers that purchase from this store.</span>"
                          }
                          className="ml-2"
                        >
                          <CautionIcon />
                        </secondaryComponents.ToolTipV2>
                      </h5>
                      <div className="w-100">
                        <primaryComponents.InputFieldV2
                          control={control}
                          classNames="white"
                          name="credit_limit"
                          placeholder={
                            storeDetails?.currency_code
                              ? storeDetails?.currency_code
                              : currency_code + " 0"
                          }
                          value={storeDetails?.credit_limit}
                          onChange={({ target: { value } }) => {
                            setStoreDetails((prev) => ({
                              ...prev,
                              credit_limit: value,
                            }));
                          }}
                          type="number"
                          errorMessage={errors.credit_limit}
                          onKeyUp={() =>
                            errors.credit_limit !== undefined &&
                            trigger("credit_limit")
                          }
                          register={register}
                        />
                      </div>
                      <p className="gray--text" style={{ fontSize: "10px" }}>
                        <span className="error--text">Please Note: </span>This
                        credit limit will be applied on all customers that
                        purchase from this store
                      </p>
                    </div>
                    {allow_ledger_account ? (
                      <div style={{ width: "250px" }} className="mt-2">
                        <h5
                          className="font-weight-semibold d-flex align-items-center"
                          style={{ gap: "5px" }}
                        >
                          Accounting
                          <secondaryComponents.ToolTipV2
                            tipText={
                              "<span>Accounting <br><br> This account will be applied on this store.</span>"
                            }
                            className="ml-2"
                          >
                            <CautionIcon />
                          </secondaryComponents.ToolTipV2>
                        </h5>
                        <div className="w-100">
                          <primaryComponents.SelectFieldV2
                            control={control}
                            name="account"
                            nameKey="name"
                            idKey="id"
                            value={
                              storeInfo?.default_bank_account
                                ? storeInfo?.default_bank_account?.account_name
                                : "Assign default account"
                            }
                            options={bankAccount?.data?.map((bank) => ({
                              id: bank?.id,
                              name: bank?.account_name,
                            }))}
                            onChange={(value) => {
                              setStoreDetails((prev) => ({
                                ...prev,
                                default_bank_account: value,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.cardSession}>
                  <div>
                    <h5>Features</h5>
                    <p>Activate features that are useful for this store.</p>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Bills</h6>
                      <span className="small-text-size">
                        Generate bills for your customer&apos;s order before
                        payment.
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses in the hospitality industry
                        e.g Restaurants, lounges, etc.)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_generate_bill
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_generate_bill: !prev.allow_generate_bill,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_generate_bill
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  {storeDetails?.allow_generate_bill ? (
                    <div className={`${globalStyles.cardWithBorder} m-3`}>
                      <h6 className="small-text-size text-dark">
                        Setup Authentication For Your Bills{" "}
                      </h6>
                      <hr className="m-0 w-100" />
                      <div className="mt-0">
                        {/* <div className="d-flex justify-content-between flex-wrap mt-2 align-items-center">
                           <div>
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_increment_qty}
                              name={"increaseBills"}
                              id={"increaseBills"}
                              label="Require admin code to increase quantity of bill"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_increment_qty:
                                      !prev.allow_increment_qty,
                                  };
                                  if (!newState.allow_increment_qty) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      increase_bill_qty_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Require admin code to increase quantity of bill
                            </span>
                          </div> 
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="increaseBills"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.increase_bill_qty_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.increaseBills}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prev) => ({
                                    ...prev,
                                    increase_bill_qty_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={
                                  !isCheckboxChecked.allow_increment_qty
                                }
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div> */}
                        <div className="d-flex justify-content-between flex-wrap mt-2 align-items-center">
                          <div>
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_reduce_qty}
                              name={"reduceBills"}
                              id={"reduceBills"}
                              label="Require admin code to increase quantity of bill"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_reduce_qty: !prev.allow_reduce_qty,
                                  };
                                  if (!newState.allow_reduce_qty) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      reduce_bill_qty_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />{" "}
                            <span className="small-text-size">
                              Require admin code to reduce quantity of bill
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="reduceBills"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.reduce_bill_qty_requires_pin_from_roles
                                }
                                placeholder="Select Roles"
                                errorMessage={errors?.reduceBills}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prev) => ({
                                    ...prev,
                                    reduce_bill_qty_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_reduce_qty}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-2 align-items-center">
                          <div>
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked?.allow_delete_qty}
                              name={"deleteBills"}
                              id={"deleteBills"}
                              label="Require admin code to increase quantity of bill"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_delete_qty: !prev.allow_delete_qty,
                                  };
                                  if (!newState.allow_delete_qty) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      delete_bill_item_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />{" "}
                            <span className="small-text-size">
                              Require admin code to delete product from bill
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="deleteBills"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.delete_bill_item_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.deleteBills}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prev) => ({
                                    ...prev,
                                    delete_bill_item_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_delete_qty}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-2 align-items-center">
                          <div>
                            <primaryComponents.Checkbox
                              isChecked={
                                isCheckboxChecked.allow_delete_bill_order
                              }
                              name={"deleteBillOrder"}
                              id={"deleteBillOrder"}
                              label="Require admin code to delete bill order"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_delete_bill_order:
                                      !prev.allow_delete_bill_order,
                                  };
                                  if (!newState.allow_delete_bill_order) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      delete_bill_order_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />{" "}
                            <span className="small-text-size">
                              Require admin code to delete bill order
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="deleteBillOrder"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.delete_bill_order_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.deleteBillOrder}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prev) => ({
                                    ...prev,
                                    delete_bill_order_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={
                                  !isCheckboxChecked.allow_delete_bill_order
                                }
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Print Docket</h6>
                      <primaryComponents.Checkbox
                        isChecked={isCheckboxChecked.allow_print_docket}
                        name={"addDocketPrint"}
                        id={"addDocketPrint"}
                        label="Require admin code to reprint docket"
                        onChange={() => {
                          setIsCheckboxChecked((prev) => {
                            const newState = {
                              ...prev,
                              allow_print_docket: !prev.allow_print_docket,
                            };
                            if (!newState.allow_print_docket) {
                              setStoreDetails((prevDetails) => ({
                                ...prevDetails,
                                reprint_docket_requires_pin_from_roles: [],
                              }));
                            }

                            return newState;
                          });
                        }}
                      />{" "}
                      <span className="small-text-size">
                        Require admin code to reprint docket
                      </span>{" "}
                      <br />
                    </div>
                    <div className="w-50" style={{ minWidth: "200px" }}>
                      <secondaryComponents.LoaderHelper
                        isLoading={isLoadingRoles || !roles}
                      >
                        <primaryComponents.MultipleSelectFieldV2
                          name="addDocketPrint"
                          control={control}
                          options={roles}
                          isRequired={true}
                          value={
                            storeDetails?.reprint_docket_requires_pin_from_roles
                          }
                          placeholder="Select Roles."
                          errorMessage={errors?.addDocketPrint}
                          idKey="id"
                          nameKey="label"
                          label="Select Authorized Roles for This Operation"
                          onChange={(values) => {
                            setStoreDetails((prev) => ({
                              ...prev,
                              reprint_docket_requires_pin_from_roles: values,
                            }));
                          }}
                          isDisabled={!isCheckboxChecked.allow_print_docket}
                          giveControl={false}
                        />
                      </secondaryComponents.LoaderHelper>
                    </div>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Tables</h6>
                      <span className="small-text-size">
                        Create and assign tables on checkout for orders.
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses in the hospitality industry
                        e.g Restaurants, lounges, etc.)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_table
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_table: !prev.allow_table,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_table
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Section Display</h6>
                      <span className="small-text-size">
                        Assign orders to the right areas in the section
                        department.
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses in the hospitality industry
                        e.g Restaurants, lounges, etc.)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_section
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_section: !prev.allow_section,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_section
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Email Customer Receipt</h6>
                      <span className="small-text-size">
                        Allow Sending of Receipts to Customers on Sell Mode
                        After Checkout
                      </span>
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses in the hospitality industry
                        e.g Restaurants, lounges, etc.)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.send_order_receipt_to_customer_after_checkout
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          send_order_receipt_to_customer_after_checkout:
                            !prev.send_order_receipt_to_customer_after_checkout,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.send_order_receipt_to_customer_after_checkout
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Waybill</h6>
                      <span className="small-text-size">
                        Generate and view waybills for orders on checkout and
                        later.
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses with a warehouse)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_waybill
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_waybill: !prev.allow_waybill,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_waybill
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Prescription</h6>
                      <span className="small-text-size">
                        Generate and view prescriptions for orders on checkout.
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for Pharmacies)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_prescription
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_prescription: !prev.allow_prescription,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_prescription
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>

                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Custom Pricing</h6>
                      <span className="small-text-size">
                        Allow your cashiers effortlessly update product prices
                        on the spot while selling
                      </span>{" "}
                      <br />
                      <span
                        className="smallest-text-size"
                        style={{ fontStyle: "italic" }}
                      >
                        (Recommended for businesses with a warehouse)
                      </span>
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_custom_price
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_custom_price: !prev.allow_custom_price,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_custom_price
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <div className={`${globalStyles.cardWithBorder} m-3`}>
                    <h6 className="small-text-size text-dark">
                      Setup Authentication For Your Custom Pricing
                    </h6>
                    <hr className="m-0 w-100" />
                    <div className="mt-0">
                      <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                        <div>
                          <primaryComponents.Checkbox
                            isChecked={isCheckboxChecked.allow_custom_price}
                            name={"addCustomPricing"}
                            id={"addCustomPricing"}
                            label="Require admin pin for custom pricing"
                            onChange={() => {
                              setIsCheckboxChecked((prev) => {
                                const newState = {
                                  ...prev,
                                  allow_custom_price: !prev.allow_custom_price,
                                };
                                if (!newState.allow_custom_price) {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    order_custom_pricing_requires_pin_from_roles:
                                      [],
                                  }));
                                }

                                return newState;
                              });
                            }}
                          />{" "}
                          <span className="small-text-size">
                            Require Admin PIN for custom pricing
                          </span>
                        </div>
                        <div className="w-50" style={{ minWidth: "200px" }}>
                          <secondaryComponents.LoaderHelper
                            isLoading={isLoadingRoles || !roles}
                          >
                            <primaryComponents.MultipleSelectFieldV2
                              name="addCustomPricing"
                              control={control}
                              options={roles}
                              isRequired={true}
                              value={
                                storeDetails?.order_custom_pricing_requires_pin_from_roles
                              }
                              placeholder="Select Roles."
                              errorMessage={errors?.addCustomPricing}
                              idKey="id"
                              nameKey="label"
                              label="Select Roles"
                              onChange={(values) => {
                                setStoreDetails((prev) => ({
                                  ...prev,
                                  order_custom_pricing_requires_pin_from_roles:
                                    values,
                                }));
                              }}
                              isDisabled={!isCheckboxChecked.allow_custom_price}
                              giveControl={false}
                            />
                          </secondaryComponents.LoaderHelper>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Credit Payment</h6>
                      <span className="small-text-size">
                        Allow your cashiers to collect credit payment from
                        customers on checkout.
                      </span>{" "}
                    </div>
                    {/* <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_credit_payment
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_credit_payment: !prev.allow_credit_payment,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_credit_payment
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button> */}
                  </div>
                  {/* {storeDetails?.allow_credit_payment ? ( */}
                  <div className={`${globalStyles.cardWithBorder} m-3`}>
                    <h6 className="small-text-size text-dark">
                      Setup Authentication For Your Credit Payment
                    </h6>
                    <hr className="m-0 w-100" />
                    <div className="mt-0">
                      <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                        <div>
                          <primaryComponents.Checkbox
                            isChecked={isCheckboxChecked.allow_credit_payment}
                            name={"creditPayment"}
                            id={"creditPayment"}
                            label="Require admin code to increase quantity of bill"
                            onChange={() => {
                              setIsCheckboxChecked((prev) => {
                                const newState = {
                                  ...prev,
                                  allow_credit_payment:
                                    !prev.allow_credit_payment,
                                };
                                if (!newState.allow_credit_payment) {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    credit_sales_requires_pin_from_roles: [],
                                  }));
                                }

                                return newState;
                              });
                            }}
                          />{" "}
                          <span className="small-text-size">
                            Require admin code to sell to customers on credit
                          </span>
                        </div>
                        <div className="w-50" style={{ minWidth: "200px" }}>
                          <secondaryComponents.LoaderHelper
                            isLoading={isLoadingRoles || !roles}
                          >
                            <primaryComponents.MultipleSelectFieldV2
                              name="creditPayment"
                              control={control}
                              options={roles}
                              isRequired={true}
                              value={
                                storeDetails?.credit_sales_requires_pin_from_roles
                              }
                              placeholder="Select Roles."
                              errorMessage={errors?.creditPayment}
                              idKey="id"
                              nameKey="label"
                              label="Select Authorized Roles for This Operation"
                              onChange={(values) => {
                                setStoreDetails((prev) => ({
                                  ...prev,
                                  credit_sales_requires_pin_from_roles: values,
                                }));
                              }}
                              isDisabled={
                                !isCheckboxChecked.allow_credit_payment
                              }
                              giveControl={false}
                            />
                          </secondaryComponents.LoaderHelper>
                        </div>
                      </div>
                      {/* CreditRepayment */}
                      <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                        <div className="w-30">
                          <primaryComponents.Checkbox
                            isChecked={isCheckboxChecked.allow_credit_repayment}
                            name={"CreditRepayment"}
                            id={"CreditRepayment"}
                            label="Require admin code to add payment in debtors"
                            onChange={() => {
                              setIsCheckboxChecked((prev) => {
                                const newState = {
                                  ...prev,
                                  allow_credit_repayment:
                                    !prev.allow_credit_repayment,
                                };
                                if (!newState.allow_credit_repayment) {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    credit_repayment_requires_pin_from_roles:
                                      [],
                                  }));
                                }

                                return newState;
                              });
                            }}
                          />
                          <span className="small-text-size">
                            Require Admin Pin to Add Payment in Debtors
                          </span>
                        </div>
                        <div className="w-50" style={{ minWidth: "200px" }}>
                          <secondaryComponents.LoaderHelper
                            isLoading={isLoadingRoles || !roles}
                          >
                            <primaryComponents.MultipleSelectFieldV2
                              name="creditRepayment"
                              control={control}
                              options={roles}
                              isRequired={true}
                              value={
                                storeDetails?.credit_repayment_requires_pin_from_roles
                              }
                              placeholder="Select Roles."
                              errorMessage={errors?.creditRepayment}
                              idKey="id"
                              nameKey="label"
                              label="Select Authorized Roles for This Operation"
                              onChange={(values) => {
                                setStoreDetails((prevDetails) => ({
                                  ...prevDetails,
                                  credit_repayment_requires_pin_from_roles:
                                    values,
                                }));
                              }}
                              isDisabled={
                                !isCheckboxChecked.allow_credit_repayment
                              }
                              giveControl={false}
                            />
                          </secondaryComponents.LoaderHelper>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                        <div className="w-30 flex flex-wrap">
                          <primaryComponents.Checkbox
                            isChecked={isCheckboxChecked.allow_add_credit_debt}
                            name={"addCreditDebtl"}
                            id={"addCreditDebtl"}
                            label="Require admin code for debts in debtors"
                            onChange={() => {
                              setIsCheckboxChecked((prev) => {
                                const newState = {
                                  ...prev,
                                  allow_add_credit_debt:
                                    !prev.allow_add_credit_debt,
                                };
                                if (!newState.allow_add_credit_debt) {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    add_credit_debt_requires_pin_from_roles: [],
                                  }));
                                }

                                return newState;
                              });
                            }}
                          />
                          <span className="small-text-size">
                            Require Admin Pin to Add Debts in Debtors Balance
                          </span>
                        </div>
                        <div className="w-50" style={{ minWidth: "200px" }}>
                          <secondaryComponents.LoaderHelper
                            isLoading={isLoadingRoles || !roles}
                          >
                            <primaryComponents.MultipleSelectFieldV2
                              name="addCreditDebt"
                              control={control}
                              options={roles}
                              isRequired={true}
                              value={
                                storeDetails?.add_credit_debt_requires_pin_from_roles
                              }
                              placeholder="Select Roles."
                              errorMessage={errors?.addCreditDebt}
                              idKey="id"
                              nameKey="label"
                              label="Select Authorized Roles for This Operation"
                              onChange={(values) => {
                                setStoreDetails((prevDetails) => ({
                                  ...prevDetails,
                                  add_credit_debt_requires_pin_from_roles:
                                    values,
                                }));
                              }}
                              isDisabled={
                                !isCheckboxChecked.allow_add_credit_debt
                              }
                              giveControl={false}
                            />
                          </secondaryComponents.LoaderHelper>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : (
                    ""
                  )} */}
                  <hr />
                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Public Relations</h6>
                      <span className="small-text-size">
                        Allow your cashiers to checkout item with public
                        relation option on sell mode.
                      </span>{" "}
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.is_warehouse
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          is_warehouse: !prev.is_warehouse,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.is_warehouse
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  {storeDetails?.is_warehouse ? (
                    <>
                      <div className="d-flex justify-content-between flex-wrap m-3">
                        <div>
                          <h6>Conversion of Public Relations to Expense</h6>
                          <span className="small-text-size">
                            Allow conversion of public relation on sell mode to
                            expense.
                          </span>{" "}
                        </div>
                        <primaryComponents.Button
                          type="button"
                          classNames={`btn ${
                            !storeDetails?.convert_pr_checkout_to_expense
                              ? "btn--success"
                              : "btn--outline-red"
                          }`}
                          onClick={() =>
                            setStoreDetails((prev) => ({
                              ...prev,
                              convert_pr_checkout_to_expense:
                                !prev.convert_pr_checkout_to_expense,
                            }))
                          }
                        >
                          <span>
                            {!storeDetails?.convert_pr_checkout_to_expense
                              ? "Activate"
                              : "De-activate"}
                          </span>
                        </primaryComponents.Button>
                      </div>
                      <div className={`${globalStyles.cardWithBorder} m-3`}>
                        <h6 className="small-text-size text-dark">
                          Setup Authentication For Your Public Relations
                        </h6>
                        <hr className="m-0 w-100" />
                        <div className="mt-0">
                          <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                            <div className="w-30">
                              <primaryComponents.Checkbox
                                isChecked={
                                  isCheckboxChecked.allow_public_relation
                                }
                                name={"prBills"}
                                id={"prBills"}
                                label="Require admin code to increase quantity of bill"
                                onChange={() => {
                                  setIsCheckboxChecked((prev) => {
                                    const newState = {
                                      ...prev,
                                      allow_public_relation:
                                        !prev.allow_public_relation,
                                    };
                                    if (!newState.allow_public_relation) {
                                      setStoreDetails((prevDetails) => ({
                                        ...prevDetails,
                                        pr_sales_requires_pin_from_roles: [],
                                      }));
                                    }

                                    return newState;
                                  });
                                }}
                              />
                              <span className="small-text-size">
                                Require Admin Pin to sell to customers on PR
                              </span>
                            </div>
                            <div className="w-50" style={{ minWidth: "200px" }}>
                              <secondaryComponents.LoaderHelper
                                isLoading={isLoadingRoles || !roles}
                              >
                                <primaryComponents.MultipleSelectFieldV2
                                  name="publicRelation"
                                  control={control}
                                  options={roles}
                                  isRequired={true}
                                  value={
                                    storeDetails?.pr_sales_requires_pin_from_roles
                                  }
                                  placeholder="Select Roles."
                                  errorMessage={errors?.publicRelation}
                                  idKey="id"
                                  nameKey="label"
                                  label="Select Authorized Roles for This Operation"
                                  onChange={(values) => {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      pr_sales_requires_pin_from_roles: values,
                                    }));
                                  }}
                                  isDisabled={
                                    !isCheckboxChecked.allow_public_relation
                                  }
                                  giveControl={false}
                                />
                              </secondaryComponents.LoaderHelper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <hr />
                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Raven Bank</h6>
                      <span className="small-text-size">
                        Enable Raven Bank at checkout on sellmode
                      </span>{" "}
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.support_raven_payment
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          support_raven_payment: !prev.support_raven_payment,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.support_raven_payment
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  <div className={`${globalStyles.cardWithBorder} m-3`}>
                    <h6 className="small-text-size text-dark">
                      Setting Up Raven Bank In Sell Mode As Card Payment
                    </h6>
                    <hr className="m-0 w-100" />
                    <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                      <span className="small-text-size">
                        Enable Raven Bank In Sell Mode As Card Payment
                      </span>
                      <div className="w-50" style={{ minWidth: "100px" }}>
                        <primaryComponents.SelectFieldV2
                          control={control}
                          name="account"
                          nameKey="name"
                          idKey="id"
                          label={"Raven Bank at checkout on sellmode"}
                          value={
                            storeInfo?.support_raven_payment_on_checkout_flow
                              ? "Yes"
                              : "No"
                          }
                          options={["Yes", "No"]}
                          onChange={(value) => {
                            setStoreDetails((prev) => ({
                              ...prev,
                              support_raven_payment_on_checkout_flow:
                                value === "Yes",
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div className="d-flex justify-content-between flex-wrap m-3">
                    <div>
                      <h6>Flexible Order Options</h6>
                      <span className="small-text-size">
                        Allow cashiers to use options such as discounts, loyalty
                        programs, delivery, and gift cards in sell mode
                      </span>{" "}
                    </div>
                    <primaryComponents.Button
                      type="button"
                      classNames={`btn ${
                        !storeDetails?.allow_order_price_mod_on_sell_mode
                          ? "btn--success"
                          : "btn--outline-red"
                      }`}
                      onClick={() =>
                        setStoreDetails((prev) => ({
                          ...prev,
                          allow_order_price_mod_on_sell_mode:
                            !prev.allow_order_price_mod_on_sell_mode,
                        }))
                      }
                    >
                      <span>
                        {!storeDetails?.allow_order_price_mod_on_sell_mode
                          ? "Activate"
                          : "De-activate"}
                      </span>
                    </primaryComponents.Button>
                  </div>
                  {storeDetails?.allow_order_price_mod_on_sell_mode ? (
                    <div className={`${globalStyles.cardWithBorder} m-3`}>
                      <h6 className="small-text-size text-dark">
                        Setting Up Authentication For Managing Discounts,
                        Loyalty, Gift Cards, and Delivery Sales In Sell Mode
                      </h6>
                      <hr className="m-0 w-100" />
                      <div className="mt-0">
                        <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                          <div className="w-30">
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_discount}
                              name={"Discount"}
                              id={"Discount"}
                              label="Require admin code for discounting a sales in sell mode"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_discount: !prev.allow_discount,
                                  };
                                  if (!newState.allow_discount) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      order_discount_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Require Admin Pin to process discount in sell mode
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="discounting"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.order_discount_requires_pin_from_roles
                                }
                                placeholder="Select Roles"
                                errorMessage={errors?.discounting}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    order_discount_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_discount}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                          <div className="w-30">
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_loyalty}
                              name={"Loyalty"}
                              id={"Loyalty"}
                              label="Require admin code for loyalty-based sales in sell
                                mode"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_loyalty: !prev.allow_loyalty,
                                  };
                                  if (!newState.allow_loyalty) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      order_loyalty_requires_pin_from_roles: [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Require Admin PIN to Process Loyalty in Sell Mode
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="loyalty"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.order_loyalty_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.loyalty}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    order_loyalty_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_loyalty}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                          <div className="w-30">
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_giftcard}
                              name={"GiftCard"}
                              id={"GiftCard"}
                              label="Require admin code for processing gift card sales in sell mode"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_giftcard: !prev.allow_giftcard,
                                  };
                                  if (!newState.allow_giftcard) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      order_giftcard_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Require Admin Pin to process gift card in sell
                              mode
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="giftcard"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.order_giftcard_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.giftcard}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    order_giftcard_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_giftcard}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                          <div className="w-30">
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_delivery}
                              name={"Delivery"}
                              id={"Delivery"}
                              label="Require admin code for processing delivery sales in sell mode"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_delivery: !prev.allow_delivery,
                                  };
                                  if (!newState.allow_delivery) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      order_delivery_fee_requires_pin_from_roles:
                                        [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Requite Admin PIN to Process Delivery amount in
                              Sell Mode
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="delivery"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.order_delivery_fee_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.delivery}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    order_delivery_fee_requires_pin_from_roles:
                                      values,
                                  }));
                                }}
                                isDisabled={!isCheckboxChecked.allow_delivery}
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-wrap mt-1 align-items-center">
                          <div className="w-30">
                            <primaryComponents.Checkbox
                              isChecked={isCheckboxChecked.allow_split_payment}
                              name={"Split"}
                              id={"Split"}
                              label="Require admin code for processing split payment in sell mode"
                              onChange={() => {
                                setIsCheckboxChecked((prev) => {
                                  const newState = {
                                    ...prev,
                                    allow_split_payment:
                                      !prev.allow_split_payment,
                                  };
                                  if (!newState.allow_split_payment) {
                                    setStoreDetails((prevDetails) => ({
                                      ...prevDetails,
                                      split_sales_requires_pin_from_roles: [],
                                    }));
                                  }

                                  return newState;
                                });
                              }}
                            />
                            <span className="small-text-size">
                              Requite Admin PIN to Process Split Payment in Sell
                              Mode
                            </span>
                          </div>
                          <div className="w-50" style={{ minWidth: "200px" }}>
                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingRoles || !roles}
                            >
                              <primaryComponents.MultipleSelectFieldV2
                                name="splitPayment"
                                control={control}
                                options={roles}
                                isRequired={true}
                                value={
                                  storeDetails?.split_sales_requires_pin_from_roles
                                }
                                placeholder="Select Roles."
                                errorMessage={errors?.splitPayment}
                                idKey="id"
                                nameKey="label"
                                label="Select Authorized Roles for This Operation"
                                onChange={(values) => {
                                  setStoreDetails((prevDetails) => ({
                                    ...prevDetails,
                                    split_sales_requires_pin_from_roles: values,
                                  }));
                                }}
                                isDisabled={
                                  !isCheckboxChecked.allow_split_payment
                                }
                                giveControl={false}
                              />
                            </secondaryComponents.LoaderHelper>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className={`${styles.fixedBtn}`}>
            <primaryComponents.Button
              classNames={"btn btn--primary ml-auto"}
              isLoading={isEditMode ? isEditingStore : isAddingStore}
              isDisabled={isEditMode ? isEditingStore : isAddingStore}
              loadingText={isEditMode ? "Updating..." : "Creating..."}
            >
              {isEditMode ? "Update Store" : "Create Store"}
            </primaryComponents.Button>
          </div>
        </form>
      </div>

      <secondaryComponents.Modal
        isActive={isLocation}
        closeModal={() => setIsLocation(false)}
        width={650}
      >
        <LocationModal
          closeModal={() => setIsLocation(false)}
          isLoadingStates={isLoadingStates}
          states={states}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
          storeDetails={storeDetails}
          setStoreDetails={setStoreDetails}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default StoreForm;
