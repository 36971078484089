import React from "react";

const MobilePOSIcon = () => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6641 3H7.66406C7.11178 3 6.66406 3.44772 6.66406 4V21C6.66406 21.5523 7.11178 22 7.66406 22H17.6641C18.2163 22 18.6641 21.5523 18.6641 21V4C18.6641 3.44772 18.2163 3 17.6641 3Z"
        stroke="#15112D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6802 18.0161L12.6641 18"
        stroke="#15112D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MobilePOSIcon;
