// import styles from "../styles.module.scss";
// import Icon from "../../../../../../assets/icons";
import useStoreWalletDebit from "./useStoreWalletDebit";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../..";

const StoreWalletDebit = ({ setIsDebitWallet, customerDetails = {} }) => {
  const {
    debitDetails,
    storeList,
    isDebitingWallet,
    currencyCode,
    isOtpWidget,
    setIsOtpWidget,
    setDebitDetails,
    handleDebitWallet,
    storeWallets,
  } = useStoreWalletDebit({ setIsDebitWallet, customerDetails });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
  } = Validation({ debitDetails });

  return (
    <>
      <div
        className={globalStyles.pageColumnGaps}
        style={{ minHeight: "100%" }}
      >
        <div>
          <primaryComponents.Button
            classNames={"btn btn--outline smallBtn"}
            onClick={() => setIsDebitWallet(null)}
          >
            <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
          </primaryComponents.Button>
        </div>

        <div>
          <h4 className="mb-0">Debit Wallet</h4>
          <p>
            Provide the following information to debit wallet for your customer.
          </p>
        </div>

        <form
          id="debitWalletForm"
          className={globalStyles.pageColumnGaps}
          style={{ gap: "10px" }}
          onSubmit={handleSubmit(handleDebitWallet)}
        >
          <primaryComponents.SelectFieldV2
            control={control}
            idKey={"id"}
            nameKey={"name"}
            name="walletId"
            value={"Select wallet"}
            classNames="wallet-select"
            label={"Select wallet"}
            isRequired={true}
            options={storeWallets?.data
              ?.filter((data) => data?.tag)
              ?.map((data) => ({
                id: data?.id,
                name: (
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "30px" }}
                  >
                    <div>{data?.tag}</div>
                    <div
                      className={`${data?.is_subwallet ? "gray--squared" : "gray--primary"}`}
                    >
                      {data?.is_subwallet ? "Sub-wallet" : "Primary Wallet"}
                    </div>
                  </div>
                ),
              }))}
            onChange={(value) => {
              setDebitDetails((prev) => ({ ...prev, wallet_id: value }));
            }}
            errorMessage={errors.walletId}
          />
          <primaryComponents.SelectFieldV2
            control={control}
            name="store"
            label={"Which store is the customer making the payment to?"}
            options={storeList}
            idKey="id"
            nameKey="name"
            isRequired={true}
            onChange={(value) => {
              setDebitDetails((prev) => ({ ...prev, store_id: value }));
            }}
            value={"Select store"}
            classNames="store-select"
            errorMessage={errors.store}
          />

          <primaryComponents.AmountFieldV2
            name="amount"
            control={control}
            label={"Debit Amount"}
            isRequired={true}
            placeholder="Enter total debit amount"
            value={debitDetails?.amount}
            onChange={({ target: { value } }) => {
              setDebitDetails((prev) => ({ ...prev, amount: value }));
            }}
            classNames="white"
            errorMessage={
              errors.amount && {
                message: errors.amount.message,
              }
            }
            onKeyUp={() =>
              errors.amount !== undefined && trigger("accountNumber")
            }
            currency_code={currencyCode}
          />

          <primaryComponents.TextAreaV2
            control={control}
            type="text"
            label={"Description"}
            name="note"
            placeholder="Add description for this debit transcation"
            value={debitDetails?.note}
            onChange={({ target: { value } }) => {
              setDebitDetails((prev) => ({ ...prev, description: value }));
            }}
            errorMessage={errors.note}
            onKeyUp={() => errors.note !== undefined && trigger("note")}
            register={register}
          />
        </form>

        <primaryComponents.Button
          classNames="btn btn--primary cover mt-auto"
          loadingText="Debiting..."
          type="submit"
          form="debitWalletForm"
          isLoading={isDebitingWallet}
          isDisabled={isDebitingWallet}
        >
          Proceed
        </primaryComponents.Button>
      </div>

      <secondaryComponents.Modal
        isActive={isOtpWidget !== null}
        closeModal={() => setIsOtpWidget(null)}
      >
        <secondaryComponents.StoreWalletOtpWidget
          debitSessionDetails={isOtpWidget}
          closeModal={() => setIsOtpWidget(null)}
          debitDetails={debitDetails}
          currencyCode={currencyCode}
          setIsDebitWallet={setIsDebitWallet}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default StoreWalletDebit;
