import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  productName: yup.string().required("Product name is required"),
  productSku: yup.string().nullable(true),
  productCategory: yup.string().required("Category is required"),
  stores: yup
    .array()
    .min(1, "Minimum of one store is required")
    .required("Minimum of one store is required"),
  hasVariants: yup.boolean(),

  storeProperties: yup.array().when("hasVariants", {
    is: false,
    then: yup.array().of(
      yup.object().shape({
        cost_price: yup
          .number()
          .typeError("Cost price must be a number")
          .required("Cost price is required"),
        minimum_stock_quantity: yup.mixed().optional(),
        rebate: yup.mixed().optional(),
        stock_quantity: yup.mixed().optional(),
        pricings: yup.array().of(
          yup.object().shape({
            price: yup
              .number()
              .typeError("Selling price must be a number")
              .required("Selling price is required"),
            pricing_type_id: yup
              .string()
              .nullable()
              .required("Pricing type is required"),
          }),
        ),
      }),
    ),
  }),

  variantProperties: yup
    .array()
    .when("hasVariants", {
      is: true,
      then: yup.array().of(
        yup.object().shape({
          option: yup.string().required("Option is required"),
          value: yup.string().required("Value is required"),
          store_properties: yup.array().of(
            yup.object().shape({
              cost_price: yup
                .number()
                .typeError("Cost price must be a number")
                .required("Cost price is required"),
              minimum_stock_quantity: yup.mixed().optional(),
              rebate: yup.mixed().optional(),
              stock_quantity: yup.mixed().optional(),
              pricings: yup.array().of(
                yup.object().shape({
                  price: yup
                    .number()
                    .typeError("Selling price must be a number")
                    .required("Selling price is required"),
                  pricing_type_id: yup
                    .string()
                    .nullable()
                    .required("Pricing type is required"),
                }),
              ),
            }),
          ),
        }),
      ),
    })
    .required(),
});

export const Validation = ({
  rootFormDetails,
  storeProperties,
  variantProperties,
}) => {
  return useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      productName: rootFormDetails.product_name,
      productSku: rootFormDetails.sku,
      productCategory:
        rootFormDetails.category?.label || rootFormDetails.category,
      stores: rootFormDetails.stores,
      hasVariants: rootFormDetails.has_variant,
      storeProperties,
      variantProperties,
    },
  });
};
