import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const getValidationSchema = (isEODType) => {
  console.log(isEODType);
  return yup.object({
    store: yup.array().when([], {
      is: () => isEODType === "Inventory",
      then: (schema) =>
        schema.required("Store is required").min(1, "Store is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    startDate: yup.string().required("Start date is required"),
    endDate: yup.string().required("End date is required"),
    startTime: yup.string().optional(),
    endTime: yup.string().optional(),
    payment_method: yup.array().when([], {
      is: () => isEODType === "Payment",
      then: (schema) =>
        schema
          .required("Payment type is required")
          .min(1, "Payment type is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    sub_account: yup.string().when([], {
      is: () => isEODType === "Payment",
      then: (schema) => schema.required("Sub account is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};

const Validation = ({ endOfDayFormDetails, isEODType }) => {
  return useForm({
    resolver: yupResolver(getValidationSchema(isEODType)),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      store: endOfDayFormDetails?.stores || "",
      startDate: endOfDayFormDetails?.startDate || "",
      endDate: endOfDayFormDetails?.endDate || "",
      startTime: endOfDayFormDetails?.startTime || "",
      endTime: endOfDayFormDetails?.endTime || "",
      payment_method: endOfDayFormDetails?.payment_method || "",
      sub_account: endOfDayFormDetails?.sub_account || "",
    },
  });
};

export default Validation;
