import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useLogic from "./useLogic";
import Validation from "./Validation";

const SecurityQuestionAndPinSetupModal = ({
  openModal,
  setOpenTransferWidget,
  setIsSetUpModal,
}) => {
  const {
    answer,
    setAnswer,
    pin,
    setPin,
    securityQuestions,
    setQuestionId,
    handleSendSecurityPinAndAnswer,
    questionId,
    isSendingSecurityPinAndAnswer,
  } = useLogic({
    openModal,
    setOpenTransferWidget,
    setIsSetUpModal,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation();
  return (
    <>
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div className="align-items-left text-left">
            <h5>Setup Your PIN & Security Question & Answer</h5>
          </div>

          <div className="d-flex flex-wrap flex-column justify-content-between">
            <div className="mb-2">
              <h6 className="text-dark">Security PIN</h6>
              <p className="small-text-size">
                Create a 6-digit PIN to login into your account & confirm
                transactions{" "}
              </p>
            </div>
            <div className={`${globalStyles.cardWithBorder}`}>
              <primaryComponents.OtpInputField
                numOfInputs={6}
                onChange={setPin}
                value={pin}
                secret={true}
                name="pin"
              />
            </div>
          </div>
          <hr />

          <div className="align-items-left text-left">
            <h6>Security Question & Answer</h6>
            <p className="small-text-size">
              Create security questions & answer for an added layer of security
            </p>
          </div>
          <div className="d-flex flex-wrap flex-column justify-content-between">
            <div style={{ width: "450px" }}>
              <primaryComponents.SelectFieldV2
                control={control}
                name="question"
                label="Security Question"
                isRequired={true}
                options={securityQuestions}
                onChange={(value) => {
                  setQuestionId(value);
                }}
                nameKey="question"
                idKey="id"
              />
            </div>
            <div className="mt-2" style={{ width: "450px" }}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="answer"
                label="Security Answer"
                isRequired={true}
                placeholder="Enter the answer to your security question"
                value={answer}
                onChange={({ target: { value } }) => {
                  setAnswer(value);
                }}
                errorMessage={errors.answer}
                onKeyUp={() => errors.answer !== undefined && trigger("answer")}
                register={register}
                isDisabled={questionId === ""}
              />
            </div>
          </div>
          <div className="">
            <primaryComponents.Button
              onClick={handleSubmit(handleSendSecurityPinAndAnswer)}
              classNames={"btn btn--primary cover"}
              isDisabled={!pin || pin.length !== 6 || !answer}
              isLoading={isSendingSecurityPinAndAnswer}
            >
              Save
            </primaryComponents.Button>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
      <secondaryComponents.Modal></secondaryComponents.Modal>
    </>
  );
};

export default SecurityQuestionAndPinSetupModal;
