import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getOrderList } from "../../../../services/inventory";
import { useGetUserList } from "../../../../hooks/api/queries/useSettings";

export const useSalesTransactions = () => {
  const [search, setSearch] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const [isModal, setIsModal] = useState({
    isEndOfDay: search.get("endOfDay") ? true : false,
    isReturn: false,
  });
  const [currentStore, setCurrentStore] = useState(queryParams.get("store_id"));
  const [initiator, setInitiator] = useState(
    search.get("initiator_id") || null,
  );
  const [orderByStatus, setOrderByStatus] = useState(
    search.get("status") === "PLACED"
      ? "Abandoned cart"
      : search.get("status") || null,
  );
  const [startDate] = useState(search.get("start_date"));
  const [endDate] = useState(search.get("end_date"));

  const setDateFilter = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };
  const queries = getUrlQuerysection();

  const { data: userList, isLoading: isLoadingUsers } = useGetUserList();

  const {
    isLoading: isFetchingSalesTransactions,
    data: salesTransactions,
    refetch: handleFetchSalesTransactions,
  } = useQuery(
    ["getOrderList", queries],
    () => getOrderList(queries).then((res) => res?.data),
    { staleTime: Infinity },
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterHistoryByStore = (storeId) => {
    search.delete("page");
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const searchSalesTransactions = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterOrderByStatus = (value) => {
    if (!value) search.delete("status");
    else search.set("status", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByInitiator = (value) => {
    search.delete("page");
    if (value === "ALL_INITIATORS") search.delete("initiator_id");
    else search.set("initiator_id", value);
    setSearch(search, { replace: true });
  };

  const filterByStoreType = (value) => {
    search.delete("page");
    if (value === "ALL_STORES") search.delete("order_source");
    else search.set("order_source", value);
    setSearch(search, { replace: true });
  };

  const filterByStatus = (value, clearOrder = false) => {
    if (clearOrder) search.delete("orderId");
    search.delete("page");
    search.set("order_source", value);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("order_source")) filterByStatus("INTERNAL");
  }, []);

  return {
    isFetchingSalesTransactions,
    storeList,
    currentStore,
    metaDetails: salesTransactions?.meta || {},
    salesTransactions: salesTransactions?.data || [],
    search,
    initiator,
    orderByStatus,
    userList: userList || [],
    isLoadingUsers,
    isModal,
    startDate,
    endDate,
    setDateFilter,
    setIsModal,
    setOrderByStatus,
    setInitiator,
    filterByInitiator,
    filterOrderByStatus,
    handleFetchSalesTransactions,
    filterHistoryByStore,
    filterByStoreType,
    setCurrentStore,
    gotoPage,
    searchSalesTransactions,
    filterByStatus,
  };
};
