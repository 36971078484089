import styles from "../SalesEndOfDay.module.scss";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import useEndOfDayDetails from "./useEndOfDayDetails";
import { motion } from "framer-motion";
import { formatCurrency, setToDateMonthYearInWords } from "../../../../helpers";
import { useSelector } from "react-redux";
import Spinner from "../../../../assets/svgIcons/Spinner";
import Completed from "../../../../assets/svgIcons/Completed";
import Modal from "../../Modal/Modal";
import DownloadModal from "./DownloadModal";
import PrintIcon from "../../../../assets/svgIcons/PrintIcon";

const EndOfDayDetails = ({ endOfDayDetails, endOfDayFormDetails }) => {
  const {
    downloadUrl,
    loadingPercent,
    isDownloadReport,
    sucessEndOfDay,
    setIsDownloadReport,
    downloadEndOfDay,
    setDownloadModal,
    downloadModal,
    productSummaryLoadingRef,
    salesSummaryLoadingRef,
  } = useEndOfDayDetails({
    endOfDayFormDetails,
  });
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {isDownloadReport ? null : (
        <secondaryComponents.NavigateWithPermission
          permissionParentKey={"Inventory Management"}
          permissionChildKey={"can_filter_for_all_users"}
        >
          {" "}
          <div
            className="position-sticky"
            style={{
              top: "0",
              padding: "10px",
              zIndex: 9999,
            }}
          >
            <div
              className="d-flex justify-content-end p-0"
              style={{ gap: "10px" }}
            >
              <primaryComponents.Button
                className="btn btn--outline"
                onClick={() => setDownloadModal(true)}
              >
                Advanced End Of Day
              </primaryComponents.Button>
            </div>
          </div>
        </secondaryComponents.NavigateWithPermission>
      )}
      {isDownloadReport ? (
        <div className={styles.processingContainer}>
          {sucessEndOfDay ? (
            <div className="d-flex align-items-center">
              <h4 className="mr-2">Your Report is Ready</h4>
              <Completed />
            </div>
          ) : (
            <h4>We Are Processing Your Report</h4>
          )}
          {!sucessEndOfDay && <p>This usually take 2 to 3 mins</p>}
          <div className="d-flex align-items-center mt-4 mb-2">
            {!sucessEndOfDay && <Spinner />}
            <span className="ml-2">
              <b>{loadingPercent}</b>{" "}
              {sucessEndOfDay ? (
                <span style={{ color: "#19AE57" }}>processing</span>
              ) : (
                <span>processing</span>
              )}
            </span>
          </div>
          <div className={styles.progressContainer}>
            <div
              style={{
                width: loadingPercent,
                background: sucessEndOfDay ? "#19AE57" : "#7647ee",
              }}
              className={styles.progress}
            />
          </div>
          {sucessEndOfDay && downloadUrl && (
            <primaryComponents.Button
              onClick={() => window.open(downloadUrl, "_blank")}
              classNames="btn btn--primary cover"
              style={{ minHeight: "42px", width: "100%", marginTop: 60 }}
            >
              View Report
            </primaryComponents.Button>
          )}
        </div>
      ) : (
        <>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className={styles.endOfDayDetails}
          >
            <div>
              <h4>End Of Day Details</h4>

              <div className={`${styles.rowCover} mb-4`}>
                <div className={styles.rowItem}>
                  <p>Report Date:</p>
                  <p className="dark--text">
                    {endOfDayDetails?.report_start_date
                      ? `${setToDateMonthYearInWords(
                          endOfDayDetails?.report_start_date,
                        )} - ${setToDateMonthYearInWords(
                          endOfDayDetails?.report_end_date,
                        )}`
                      : "N/A"}
                  </p>
                </div>
                <div className={styles.rowItem}>
                  <p>Time:</p>
                  <p className="dark--text">
                    {endOfDayDetails?.report_start_time
                      ? `${endOfDayDetails?.report_start_time} - ${endOfDayDetails?.report_end_time}`
                      : "N/A"}
                  </p>
                </div>
                <div className={styles.rowItem}>
                  <p>Store(s):</p>
                  <p className="dark--text">
                    {endOfDayDetails?.store_names || "N/A"}
                  </p>
                </div>
                <div className={styles.rowItem}>
                  <p>Business:</p>
                  <p className="dark--text">
                    {endOfDayDetails?.business_name || "N/A"}
                  </p>
                </div>
                {endOfDayFormDetails?.initiators?.length > 0 ? (
                  <div className={styles.rowItem}>
                    <p>Initiators:</p>
                    <p className="dark--text">
                      {endOfDayFormDetails?.initiators?.map(
                        (initiator, index) =>
                          `${initiator?.label}${
                            endOfDayFormDetails?.initiators?.length !==
                            index + 1
                              ? ", "
                              : ""
                          }`,
                      ) || "N/A"}
                    </p>
                  </div>
                ) : null}
              </div>

              {/* summary section  */}
              <div className={styles.orderBody}>
                {/* succesful */}
                <div className={styles.orderBody__table}>
                  <div className={styles.orderBody__table__header}>
                    <h5>
                      Order Status{" "}
                      <span className="ml-3 success--squared small-text-size">
                        Successful
                      </span>
                    </h5>
                  </div>
                  <div className={styles.rowCover}>
                    {endOfDayDetails?.completed_order_data?.length > 0 ? (
                      <>
                        <div className={styles.rowItem}>
                          <p className={styles.item}>SALES</p>
                          <p className={styles.item}>COUNT</p>
                          <p className={styles.item}>AMOUNT</p>
                        </div>
                        {endOfDayDetails?.completed_order_data?.map(
                          (item, index) => (
                            <div className={styles.rowItem} key={index}>
                              <p className={styles.item}>
                                {item?.sales === "warehouse_checkout"
                                  ? "Warehouse"
                                  : item?.sales}
                              </p>
                              <p className={styles.item}>{item?.counts}</p>
                              <p className={styles.item}>
                                {formatCurrency({
                                  value: item?.amount?.replace(/[^\d.-]/g, ""),
                                  currencyCode: currency_code,
                                })}
                              </p>
                            </div>
                          ),
                        )}
                        <div className={styles.rowItem}>
                          <h6 className={styles.item}>
                            {endOfDayDetails?.completed_total_data?.[0]?.sales}
                          </h6>
                          <h6 className={styles.item}>
                            {endOfDayDetails?.completed_total_data?.[0]?.counts}
                          </h6>
                          <h6 className={styles.item}>
                            {formatCurrency({
                              value:
                                endOfDayDetails?.completed_total_data?.[0]?.amount.replace(
                                  /[^\d.-]/g,
                                  "",
                                ),
                              currencyCode: currency_code,
                            })}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <h6 className="text-center mt-2">No data available</h6>
                    )}
                  </div>
                </div>
                {/* failed */}
                <div className={styles.orderBody__table}>
                  <div className={styles.orderBody__table__header}>
                    <h5>
                      Order Status{" "}
                      <span className="ml-3 failed--text small-text-size">
                        Cancelled
                      </span>
                    </h5>
                  </div>
                  <div className={styles.rowCover}>
                    {endOfDayDetails?.canceled_order_data?.length > 0 ? (
                      <>
                        <div className={styles.rowItem}>
                          <p className={styles.item}>SALES</p>
                          <p className={styles.item}>COUNT</p>
                          <p className={styles.item}>AMOUNT</p>
                        </div>

                        {endOfDayDetails?.canceled_order_data?.map(
                          (item, index) => (
                            <div className={styles.rowItem} key={index}>
                              <h6 className={styles.item}>{item?.sales}</h6>
                              <h6 className={styles.item}>{item?.counts}</h6>
                              <h6 className={styles.item}>
                                {formatCurrency({
                                  value: item?.amount.replace(/[^\d.-]/g, ""),
                                  currencyCode: currency_code,
                                })}
                              </h6>
                            </div>
                          ),
                        )}
                      </>
                    ) : (
                      <h6 className="text-center mt-2">No data available</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          <div
            className="mt-auto d-flex"
            style={{
              gap: "10px",
              position: "sticky",
              bottom: "-10px",
              background: "#fff",
              padding: "10px 0",
            }}
          >
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() =>
                downloadEndOfDay({
                  delivery_method: "download",
                  type: "product_summary",
                  download_format: "pdf",
                })
              }
              loadingText="Proccessing..."
              isLoading={productSummaryLoadingRef.current}
            >
              <PrintIcon />
              <span className="smallest-text-size ml-2">
                Print Product Summary
              </span>
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--green"
              onClick={() =>
                downloadEndOfDay({
                  delivery_method: "download",
                  type: "sales_summary",
                  download_format: "pdf",
                })
              }
              loadingText="Proccessing..."
              isLoading={salesSummaryLoadingRef.current}
            >
              <PrintIcon />
              <span className="smallest-text-size ml-2">
                Print Sales Summary
              </span>
            </primaryComponents.Button>
          </div>

          <Modal
            isActive={downloadModal}
            width={500}
            closeModal={() => setDownloadModal(false)}
          >
            <DownloadModal
              closeModal={() => setDownloadModal(false)}
              setIsDownloadReport={setIsDownloadReport}
              downloadEndOfDay={downloadEndOfDay}
            />
          </Modal>
        </>
      )}
    </div>
  );
};
export default EndOfDayDetails;
