export const MicrosoftIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10619_51955)">
        <path
          d="M14.2478 14.2555H0V0.00769043H14.2478V14.2555Z"
          fill="#F1511B"
        />
        <path
          d="M29.979 14.2555H15.7314V0.00769043H29.979V14.2555Z"
          fill="#80CC28"
        />
        <path d="M14.2474 29.9923H0V15.7446H14.2474V29.9923Z" fill="#00ADEF" />
        <path
          d="M29.979 29.9923H15.7314V15.7446H29.979V29.9923Z"
          fill="#FBBC09"
        />
      </g>
      <defs>
        <clipPath id="clip0_10619_51955">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
