import { useDispatch, useSelector } from "react-redux";
import { useCloseOtpAuthorizationPrompt } from "./useCloseOtpAuthorizationPrompt";
import { useGetOrderActionDetails } from "./api/queries";
import { useEffect, useState } from "react";
import {
  clearAuthUseCaseProp,
  setAuthUseCaseProp,
  setVerifiedOTPCode,
} from "../store/modules/apiErrorHandler";
import { encodeToBase64 } from "../helpers";

export const useOtpAuthTrigger = ({ storeId = null, params = {} }) => {
  const [actionKey, setActionKey] = useState(null);
  const dispatch = useDispatch();
  const verified_otp_code = useSelector(
    (state) => state.apiErrorReducer?.verifiedOTPCode,
  );
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const { refetch: fetchOrderActionDetails, isLoading: isLoadingAuthAction } =
    useGetOrderActionDetails({
      store_id: storeId,
      action: actionKey,
      enabled: false,
      params,
    });

  const otpKeyCodes = useSelector((state) => state.apiErrorReducer?.authKeys);

  const handleOrderModuleAuth = async ({
    actionKey = null,
    onSuccessCallBack = () => {},
    onErrorCallBack = () => {},
    onSettledCallBack = () => {},
  }) => {
    if (getAuthCodeBaseOnKey(actionKey)) return onSuccessCallBack();
    await handleSetActionKey(actionKey);

    fetchOrderActionDetails()
      .then((res) => {
        if (res?.isError) return onErrorCallBack();
        onSuccessCallBack();
        dispatch(setVerifiedOTPCode(null));
        closeOtpAuthorizationPrompt();
      })
      .catch(() => {
        onErrorCallBack();
      })
      .finally(() => {
        onSettledCallBack();
      });
  };

  const handleSetActionKey = (actionKey) => {
    return new Promise((resolve) => {
      setActionKey(actionKey);
      resolve();
    });
  };

  const clearLatestOtpAuthCode = (actionKeys = []) => {
    actionKeys?.forEach((actionKey) => {
      dispatch(clearAuthUseCaseProp({ actionKey }));
    });
  };

  const getAuthCodeBaseOnKey = (actionKey) => {
    return otpKeyCodes?.[actionKey]?.code;
  };

  useEffect(() => {
    if (verified_otp_code && actionKey) {
      dispatch(
        setAuthUseCaseProp({
          code: encodeToBase64(verified_otp_code),
          actionKey,
        }),
      );
      dispatch(setVerifiedOTPCode(null));
    }
  }, [verified_otp_code]);

  return {
    isLoadingAuthAction,
    handleOrderModuleAuth,
    clearLatestOtpAuthCode,
    getAuthCodeBaseOnKey,
  };
};
