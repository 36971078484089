import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  toastError: {
    message: null,
  },
  subscriptionAuthorizationError: {
    message: null,
  },
  otpAuthorizationError: {
    message: null,
    action: null,
    object_id: null,
    object_type: null,
    otp_code: null,
  },
  authKeys: {
    use_custom_pricing_on_order: {
      code: null,
    },
    add_discount_to_order: {
      code: null,
    },
    add_delivery_fee_to_order: {
      code: null,
    },
    reduce_bill_quantity: {
      code: null,
    },
  },
  verifiedOTPCode: null,
};

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiErrorMessage: (state, action) => {
      state.toastError.message = action?.payload?.message;
    },
    setSubscriptionAuthorizationError: (state, action) => {
      state.subscriptionAuthorizationError.message = action?.payload?.message;
    },
    setOtpAuthorizationError: (state, action) => {
      state.otpAuthorizationError = action?.payload;
    },
    setAuthorizationErrorOtpCode: (state, action) => {
      state.otpAuthorizationError.otp_code = action?.payload;
    },
    setVerifiedOTPCode: (state, action) => {
      state.verifiedOTPCode = action?.payload;
    },
    setAuthUseCaseProp: (state, action) => {
      state.authKeys[action.payload.actionKey] = {
        ...state.authKeys[action.payload.actionKey],
        code: action.payload.code,
      };
    },
    clearAuthUseCaseProp: (state, action) => {
      state.authKeys[action.payload.actionKey] = {
        ...state.authKeys[action.payload.actionKey],
        code: null,
      };
    },
  },
});

export const {
  setApiErrorMessage,
  setSubscriptionAuthorizationError,
  setOtpAuthorizationError,
  setAuthorizationErrorOtpCode,
  setVerifiedOTPCode,
  setAuthUseCaseProp,
  clearAuthUseCaseProp,
} = apiErrorSlice.actions;
