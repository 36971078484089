import styles from "./MenuPage.module.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useMenuPage from "./useMenuPage";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../assets/icons";
// import AppertizerCard from "./components/AppertizerCard";
import CategorySection from "./components/CategorySection";
import MenuCart from "./components/MenuCart";
import CheckoutModal from "./components/CheckoutModal";
import OrderSummaryModal from "./components/OrderSummaryModal";
import PayForOrderModal from "./components/PayForOrderModal";
import { clearAllMenuCartItems } from "../../../store/modules/menuCart";
import { useDispatch } from "react-redux";
import {
  // ChevronLeftArrow,
  // ChevronRightArrow,
  Location,
} from "../../../assets/svgIcons";
import { FiFacebook, FiPhone, FiYoutube } from "react-icons/fi";
import { RiTwitterLine } from "react-icons/ri";
import { FaGoogle, FaInstagram, FaSnapchat } from "react-icons/fa";
import { PiTiktokLogo } from "react-icons/pi";

// const ITEM_WIDTH = 264;

const MenuPage = () => {
  const {
    orderId,
    menuId,
    menuObjectDetail,
    menuCategories,
    menuSearchText,
    showCartItems,
    setShowCartItems,
    isActiveCart,
    setIsActiveCart,
  } = useMenuPage();

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  // const filters = menuCategories?.map(
  //   (item) =>
  //     item?.category?.name
  //       ?.toLowerCase()
  //       ?.charAt(0)
  //       ?.CategorySection.toUpperCase() +
  //     item?.category?.name?.toLowerCase()?.slice(1),
  // );

  const dispatch = useDispatch();
  // const containerRef = useRef();
  const sectionRefs = useRef([]);

  const [selectedFilter, setSelectedFilter] = useState("All");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showOrderSummaryModal, setShowOrderSummaryModal] = useState(false);
  const [showOrder, setShowOrder] = useState([]);
  const [showPayForOrder, setShowPayForOrder] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState("DINE_IN");

  // const handleHorizontalScroll = (scrollAmount) => {
  //   const newScrollPosition = scrollPosition + scrollAmount;

  //   setScrollPosition(newScrollPosition);

  //   containerRef.current.scrollLeft = newScrollPosition;
  // };

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const scrollToCategory = (index) => {
    if (sectionRefs?.current) {
      const element = sectionRefs?.current?.[index];
      const offsetTop = element?.getBoundingClientRect()?.top + window?.scrollY;

      if (innerWidth < 850) {
        window?.scrollTo({
          top: offsetTop - 150,
          behavior: "smooth",
        });
        return;
      }

      window?.scrollTo({
        top: offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (innerWidth < 850) {
      setShowCartItems(true);
    }
  }, [innerWidth]);

  useEffect(() => {
    if (scrollPosition < 0) {
      setScrollPosition(0);
    }
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (menuId && orderId) {
      dispatch(clearAllMenuCartItems());
      localStorage.removeItem("order_id");
    }
  }, [menuId, orderId]);

  return (
    <secondaryComponents.MetaSetter
      title={`Lumi Merchant | Storefront - ${menuObjectDetail?.store?.name}`}
      description="View all items on storefront"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          // className={styles.menu}
        >
          <div className={styles.menuContainer}>
            <div className={styles.scrollSwipeContainer}>
              <div className={styles.swipeContainer}>
                <p>Swipe to see more categories</p>
              </div>
              <div className={styles.menuItemScrollContainer}>
                <div
                  className={`${styles.menuItemScroll} ${
                    selectedFilter === "All" ? styles.active : styles.inactive
                  }`}
                  style={{
                    color: selectedFilter === "All" ? primaryColor : "#696677",
                    borderColor:
                      selectedFilter === "All" ? primaryColor : "#696677",
                  }}
                  onClick={() => {
                    setSelectedFilter("All");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  All
                </div>

                {menuCategories?.map((categoryItem, index) => (
                  <div
                    key={index}
                    className={`
                        ${styles.menuItemScroll}
                        ${
                          categoryItem?.id === selectedFilter
                            ? styles.active
                            : styles.inactive
                        }
                      `}
                    style={{
                      color:
                        categoryItem?.id === selectedFilter
                          ? primaryColor
                          : "#696677",
                      borderColor:
                        categoryItem?.id === selectedFilter
                          ? primaryColor
                          : "#696677",
                    }}
                    onClick={() => {
                      setSelectedFilter(categoryItem?.id);
                      scrollToCategory(index);
                    }}
                  >
                    {categoryItem?.category?.name
                      ?.split(" ")
                      ?.map((item, index) => (
                        <span key={index}>
                          {item
                            ?.toLowerCase()
                            ?.split(" ")
                            ?.map(
                              (word) =>
                                word?.charAt(0)?.toUpperCase() + word?.slice(1),
                            )
                            ?.join(" ")}
                          &nbsp;
                        </span>
                      ))}
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.sidebar}>
              <div className={styles.businessContainer}>
                {/* <div className={styles.logo}>Logo&nbsp;</div> */}
                <div className={styles.businessName}>
                  <p className={styles.title}>{menuObjectDetail?.name}</p>
                  <div className={styles.location}>
                    <div className={styles.locIcon}>
                      <Location color={primaryColor} />
                    </div>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${menuObjectDetail?.store?.address
                        .split(" ")
                        .join("+")}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.place}
                    >
                      {menuObjectDetail?.store?.address}
                    </a>
                  </div>
                  {menuObjectDetail?.store?.phone_number && (
                    <div className={styles.location}>
                      <div className={styles.locIcon}>
                        <FiPhone color={primaryColor} size={14} />
                      </div>
                      <p className={styles.place}>
                        +{menuObjectDetail?.store?.phone_number}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {menuObjectDetail?.social_media &&
                menuObjectDetail?.social_media?.length > 0 && (
                  <div className={styles.socialContainer}>
                    {menuObjectDetail?.social_media?.map((social, index) => {
                      if (social.key === "twitter") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <RiTwitterLine color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "facebook") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FiFacebook color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "tiktok") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <PiTiktokLogo color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "instagram") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaInstagram color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "youtube") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FiYoutube color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "snapchat") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaSnapchat color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "google") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaGoogle color={primaryColor} />
                          </a>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                )}
              <hr />
              <div className={styles.filtersContainer}>
                <div className={styles.filter}>
                  <img src={Icon.menuFilter} alt="" />
                  <p className={styles.filterText}>Filters</p>
                </div>
                <div
                  className={styles.filterItem}
                  onClick={() => {
                    setSelectedFilter("All");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <p
                    style={{
                      background:
                        selectedFilter === "All" ? primaryColor : "transparent",
                      color: selectedFilter === "All" ? "#ffffff" : "#15112D",
                    }}
                    className={styles.filterText}
                  >
                    All
                  </p>
                </div>
                {menuCategories?.map((categoryItem, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedFilter(categoryItem?.id);
                      scrollToCategory(index);
                    }}
                    className={styles.filterItem}
                  >
                    <p
                      style={{
                        background:
                          categoryItem?.id === selectedFilter
                            ? primaryColor
                            : "transparent",
                        color:
                          categoryItem?.id === selectedFilter
                            ? "#ffffff"
                            : "#15112D",
                      }}
                      className={styles.filterText}
                    >
                      {categoryItem?.category?.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.contentContainer}>
                {menuCategories?.map((categoryItem, index) => {
                  // if (index === 0) {
                  //   return (
                  //     <div
                  //       key={index}
                  //       ref={(el) => (sectionRefs.current[index] = el)}
                  //       className={styles.appertizerContainer}
                  //     >
                  //       <div className={styles.appertizerHeading}>
                  //         <h4 className="mt-3">
                  //           {filters && filters.length > 0
                  //             ? `${filters[0]
                  //                 .toLowerCase()
                  //                 .split(" ")
                  //                 .map((word) =>
                  //                   word.length === 2
                  //                     ? word
                  //                     : word.charAt(0).toUpperCase() +
                  //                       word.slice(1),
                  //                 )
                  //                 .join(" ")}`
                  //             : ""}
                  //         </h4>
                  //         <div className={styles.control}>
                  //           <div
                  //             onClick={() =>
                  //               handleHorizontalScroll(-ITEM_WIDTH)
                  //             }
                  //             style={{ borderColor: primaryColor }}
                  //           >
                  //             <ChevronLeftArrow color={primaryColor} />
                  //           </div>
                  //           <div
                  //             onClick={() =>
                  //               handleHorizontalScroll(ITEM_WIDTH)
                  //             }
                  //             style={{ borderColor: primaryColor }}
                  //           >
                  //             <ChevronRightArrow color={primaryColor} />
                  //           </div>
                  //         </div>
                  //       </div>
                  //       <div
                  //         ref={containerRef}
                  //         className={styles.appertizerCardContainer}
                  //         style={{
                  //           background: menuObjectDetail?.secondary_colour
                  //             ? menuObjectDetail?.secondary_colour
                  //             : undefined,
                  //           padding: menuObjectDetail?.secondary_colour
                  //             ? 16
                  //             : undefined,
                  //           borderRadius: menuObjectDetail?.secondary_colour
                  //             ? 12
                  //             : undefined,
                  //         }}
                  //       >
                  //         {menuCategories?.length > 0 &&
                  //           menuCategories[0]?.storefront_items
                  //             ?.filter((item) =>
                  //               item?.name
                  //                 .toLowerCase()
                  //                 .includes(menuSearchText.toLowerCase()),
                  //             )
                  //             ?.map((item, index) => (
                  //               <AppertizerCard
                  //                 key={item.id}
                  //                 productIndex={index}
                  //                 productItem={item}
                  //                 store={menuObjectDetail?.store}
                  //                 menuObjectDetail={menuObjectDetail}
                  //               />
                  //             ))}
                  //       </div>
                  //     </div>
                  //   );
                  // }
                  return (
                    <div
                      key={categoryItem?.id}
                      ref={(el) => (sectionRefs.current[index] = el)}
                    >
                      <CategorySection
                        index={index}
                        categoryItem={categoryItem}
                        menuObjectDetail={menuObjectDetail}
                        menuSearchText={menuSearchText}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {showCartItems ? (
              <motion.div
                initial={{ x: 100 }}
                animate={{ x: 0 }}
                className={`${styles.cartSection} ${
                  isActiveCart ? styles.active : ""
                }`}
              >
                <MenuCart
                  isActiveCart={isActiveCart}
                  setIsActiveCart={setIsActiveCart}
                  setShowCartItems={setShowCartItems}
                  setShowCheckoutModal={setShowCheckoutModal}
                  menuObjectDetail={menuObjectDetail}
                />
              </motion.div>
            ) : null}

            <secondaryComponents.SideModal
              isActive={showCheckoutModal}
              closeModal={() => setShowCheckoutModal(false)}
              closeViaOverlay={false}
              width={600}
            >
              <CheckoutModal
                setShowCheckoutModal={setShowCheckoutModal}
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowOrderSummary={setShowOrder}
                setSelectedOrderType={setSelectedOrderType}
                selectedOrderType={selectedOrderType}
                menuObjectDetail={menuObjectDetail}
              />
            </secondaryComponents.SideModal>

            <secondaryComponents.SideModal
              isActive={showOrderSummaryModal}
              closeModal={() => setShowOrderSummaryModal(false)}
              closeViaOverlay={false}
            >
              <OrderSummaryModal
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowCartItems={setShowCartItems}
                setShowPayForOrder={setShowPayForOrder}
                showOrderSummary={showOrder}
                menuObjectDetail={menuObjectDetail}
              />
            </secondaryComponents.SideModal>

            <secondaryComponents.SideModal
              isActive={showPayForOrder}
              closeModal={() => setShowPayForOrder(false)}
              closeViaOverlay={false}
            >
              <PayForOrderModal
                setShowPayForOrder={setShowPayForOrder}
                setShowOrderSummaryModal={setShowOrderSummaryModal}
                setShowCartItems={setShowCartItems}
                selectedOrderType={selectedOrderType}
                menuObjectDetail={menuObjectDetail}
                showOrderSummary={showOrder}
              />
            </secondaryComponents.SideModal>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default MenuPage;
