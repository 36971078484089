/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AddProductStock.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import Icon from "../../../assets/icons";
import useAddProductStock from "./useAddProductStock";
import { Validation } from "./Validation";
import { Fragment, useEffect } from "react";
import { PricingWarning, WarningDialog } from "../../common";

const AddProductStock = ({
  selectedProduct,
  closeWidget,
  handleFetchproducts,
  refetchSelectedProduct,
}) => {
  const {
    storeList,
    storeProperties,
    isAddingStock,
    storeVariantProperties,
    variantsList,
    suppliers,
    isFetchingSuppliers,
    pricingTypes,
    isLoadingPricingTypes,
    modalViews,
    detectedPricing,
    resetModalViews,
    setStoreProperties,
    addMoreStoreOption,
    deleteStoreProperty,
    setPropertiesBasedOnStore,
    addStock,
    setVariantPropertyBasedOnStore,
    setPropertiesBasedOnVariantId,
    addStoreVariantOption,
    deleteStoreVariantOption,
    setStoreVariantProperties,
    updateSupplierAcrossStore,
    updateSupplierAcrossStoreVariant,
    setSelectedStore,
    bankAccount,
    isLoadingBankAccountsList,
    updateAccountAcrossStore,
    businessDetails,
  } = useAddProductStock({
    selectedProduct,
    closeWidget,
    handleFetchproducts,
    refetchSelectedProduct,
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = Validation({
    hasVariants: selectedProduct?.variants?.length > 0,
    storeProperties,
    storeVariantProperties,
  });

  useEffect(() => {
    window.setAddStockFormValue = setValue;
  }, []);

  useEffect(() => {
    setValue("storeVariantProperties", storeVariantProperties);
  }, [storeVariantProperties]);

  useEffect(() => {
    setValue("storeProperties", storeProperties);
  }, [storeProperties]);

  return (
    <>
      <div className={styles.addProductStock}>
        <div className={styles.addProductStock__header}>
          <FontAwesomeIcon
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
            onClick={closeWidget}
          />
          <h4>{selectedProduct.name}</h4>
          <div></div>
        </div>

        <div className={styles.storeList}>
          <h6 className="mb-0">Currently only available in: </h6>
          {selectedProduct?.stores?.map((store) => (
            <span key={store?.id} className="btn btn--success">
              {store?.name}
            </span>
          ))}
        </div>

        <form
          id="addStockForm"
          className={styles.formSection}
          onSubmit={handleSubmit(addStock)}
        >
          {selectedProduct?.variants?.length === 0 ? (
            <>
              {storeProperties?.map((storeItem, storePropertyIndex) => (
                <div className={styles.rowItem} key={storeItem?.uuid}>
                  <div className={styles.priceCover}>
                    <div className={styles.priceSection}>
                      {/* store field */}
                      <primaryComponents.SelectFieldV2
                        name={`storeProperties.${storePropertyIndex}.store_id`}
                        control={control}
                        options={selectedProduct?.stores}
                        isRequired={true}
                        value={
                          selectedProduct?.stores?.find(
                            (store) => store?.id === storeItem?.store_id,
                          )?.name
                        }
                        errorMessage={
                          errors?.storeProperties?.[storePropertyIndex]
                            ?.store_id
                        }
                        idKey="id"
                        nameKey="name"
                        label="Store"
                        onChange={(value) => {
                          setPropertiesBasedOnStore(value, storePropertyIndex);
                          setSelectedStore(value);
                        }}
                      />
                      <div className="mt-1">
                        <secondaryComponents.ToolTipV2 tipText="Turn on this feature to assign unique tracking codes for each item. This will help you manage and track individual inventory items more efficiently">
                          <primaryComponents.SelectFieldV2
                            name={`storeProperties.${storePropertyIndex}.item_level_tracking`}
                            control={control}
                            options={[
                              { name: "Yes", id: true },
                              { name: "No", id: false },
                            ]}
                            isRequired={true}
                            value={"No"}
                            idKey="id"
                            nameKey="name"
                            label="Activate Item Level Tracking"
                            onChange={(value) => {
                              const newStoreProperties = [...storeProperties];
                              newStoreProperties[
                                storePropertyIndex
                              ].item_level_tracking = value;
                              setStoreProperties(newStoreProperties);
                            }}
                          />
                        </secondaryComponents.ToolTipV2>
                      </div>

                      <div className="mt-2">
                        <primaryComponents.InputFieldV2
                          name={`storeProperties.${storePropertyIndex}.quantity`}
                          control={control}
                          classNames="white"
                          label="Quantity"
                          isRequired
                          type="number"
                          step={0.01}
                          min={0.01}
                          value={storeItem.quantity}
                          isDisabled={storeItem?.item_level_tracking}
                          errorMessage={
                            errors?.storeProperties?.[storePropertyIndex]
                              ?.quantity
                          }
                          onKeyUp={() =>
                            errors?.storeProperties?.[storePropertyIndex]
                              ?.quantity &&
                            trigger(
                              `storeProperties.${storePropertyIndex}.quantity`,
                            )
                          }
                          onChange={({ target: { value } }) => {
                            const newStoreProperties = [...storeProperties];
                            newStoreProperties[storePropertyIndex].quantity =
                              value;
                            setStoreProperties(newStoreProperties);
                          }}
                        />
                      </div>

                      {/* restock date */}
                      <primaryComponents.DateInputV2
                        name={`storeProperties.${storePropertyIndex}.restock_date`}
                        control={control}
                        classNames="white"
                        label="Restock Date"
                        value={
                          storeItem?.restock_date === undefined
                            ? selectedProduct?.store_product_properties?.find(
                                (item) =>
                                  item?.store_id === storeItem?.store_id,
                              )?.restock_date || ""
                            : storeItem?.restock_date
                        }
                        onChange={({ target: { value } }) => {
                          const newStoreProperties = [...storeProperties];
                          newStoreProperties[storePropertyIndex].restock_date =
                            value;
                          setStoreProperties(newStoreProperties);
                        }}
                      />

                      {/* expiry date */}
                      <primaryComponents.DateInputV2
                        name={`storeProperties.${storePropertyIndex}.expiry_date`}
                        control={control}
                        classNames="white"
                        label="Expiry Date"
                        value={
                          storeItem?.expiry_date === undefined
                            ? selectedProduct?.store_product_properties?.find(
                                (item) =>
                                  item?.store_id === storeItem?.store_id,
                              )?.expiry_date || ""
                            : storeItem?.expiry_date
                        }
                        errorMessage={
                          errors?.storeProperties?.[storePropertyIndex]
                            ?.expiry_date
                        }
                        onKeyUp={() =>
                          errors?.storeProperties?.[storePropertyIndex]
                            ?.expiry_date &&
                          trigger(
                            `storeProperties.${storePropertyIndex}.expiry_date`,
                          )
                        }
                        onChange={({ target: { value } }) => {
                          const newStoreProperties = [...storeProperties];
                          newStoreProperties[storePropertyIndex].expiry_date =
                            value;
                          setStoreProperties(newStoreProperties);
                        }}
                      />
                      <div className="mt-2">
                        <primaryComponents.InputFieldV2
                          control={control}
                          name={`storeProperties.${storePropertyIndex}.barcode`}
                          inputIcon={Icon.barCodeIcon}
                          classNames="white"
                          label="Barcode"
                          value={
                            storeItem?.barcode === undefined
                              ? selectedProduct?.store_product_properties?.find(
                                  (item) =>
                                    item?.store_id === storeItem?.store_id,
                                )?.barcode || ""
                              : storeItem?.barcode
                          }
                          onChange={({ target: { value } }) => {
                            const newStoreProperties = [...storeProperties];
                            newStoreProperties[storePropertyIndex].barcode =
                              value;
                            setStoreProperties(newStoreProperties);
                          }}
                        />
                      </div>

                      {storeItem?.pricings?.map(
                        (pricingProperty, pricingIndex) => (
                          <Fragment key={pricingProperty?.uuid}>
                            {/* pricing type */}

                            <secondaryComponents.LoaderHelper
                              isLoading={isLoadingPricingTypes}
                              classNames={"mb-2"}
                            >
                              <primaryComponents.SelectFieldV2
                                name={`storeProperties.${storePropertyIndex}.pricings.${pricingIndex}.pricing_type_id`}
                                control={control}
                                label="Pricing Type"
                                value={
                                  pricingTypes?.find(
                                    (pricingItem) =>
                                      pricingItem?.id ===
                                      pricingProperty?.pricing_type_id,
                                  )?.label || "Select..."
                                }
                                options={[]}
                                nameKey="label"
                                idKey="id"
                                isDisabled={true}
                                isSearchable={false}
                              />
                            </secondaryComponents.LoaderHelper>
                            {/* cost price */}
                            <div className="mt-2">
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storePropertyIndex}.cost_price`}
                                control={control}
                                type="number"
                                step={0.01}
                                classNames="white"
                                label={`Cost Price ${
                                  storeItem?.store_id
                                    ? `(${
                                        selectedProduct?.stores.find(
                                          (store) =>
                                            storeItem?.store_id === store?.id,
                                        )?.currency_symbol
                                      })`
                                    : ""
                                }`}
                                value={storeItem?.cost_price}
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[
                                    storePropertyIndex
                                  ].cost_price = value;
                                  setStoreProperties(newStoreProperties);
                                }}
                              />
                            </div>

                            {/* selling price */}
                            <div className="mt-2">
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storePropertyIndex}.pricings.${pricingIndex}.price`}
                                control={control}
                                type="number"
                                step={0.01}
                                classNames="white"
                                label={`Selling Price ${
                                  storeItem?.store_id
                                    ? `(${
                                        selectedProduct?.stores.find(
                                          (store) =>
                                            storeItem?.store_id === store?.id,
                                        )?.currency_symbol
                                      })`
                                    : ""
                                }`}
                                value={pricingProperty?.price}
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[
                                    storePropertyIndex
                                  ].pricings[pricingIndex].price = value;
                                  setStoreProperties(newStoreProperties);
                                }}
                              />
                            </div>

                            {/* discount */}
                            <primaryComponents.PercentField
                              label="Selling price discount"
                              name={`storeProperties.${storePropertyIndex}.pricings.${pricingIndex}.discount_rate`}
                              control={control}
                              isDisabled={true}
                              value={pricingProperty?.discount_rate}
                              dividedValue={
                                pricingProperty?.price === ""
                                  ? 0
                                  : pricingProperty.price
                              }
                            />

                            {/* discounted selling price */}
                            <div className="mt-2">
                              <primaryComponents.InputField
                                classNames="white"
                                label={`Discounted selling price ${
                                  storeItem?.store_id
                                    ? `(${
                                        selectedProduct?.stores.find(
                                          (store) =>
                                            storeItem?.store_id === store?.id,
                                        )?.currency_symbol
                                      })`
                                    : ""
                                }`}
                                isDisabled={true}
                                value={(() => {
                                  let sellingPrice =
                                    pricingProperty?.price === ""
                                      ? 0
                                      : pricingProperty?.price;
                                  let discountPercent =
                                    pricingProperty?.discount_rate === ""
                                      ? 0
                                      : pricingProperty?.discount_rate;
                                  let discount_rate =
                                    (sellingPrice * discountPercent) / 100;

                                  let discountedSellingPrice =
                                    sellingPrice - discount_rate;

                                  return discountedSellingPrice;
                                })()}
                              />
                            </div>

                            {/* profit */}
                            <div className="mt-2">
                              <primaryComponents.InputField
                                classNames="white"
                                label={`Profit ${
                                  storeItem?.store_id
                                    ? `(${
                                        selectedProduct?.stores.find(
                                          (store) =>
                                            storeItem?.store_id === store?.id,
                                        )?.currency_symbol
                                      })`
                                    : ""
                                }`}
                                isDisabled
                                value={
                                  (
                                    pricingProperty?.price -
                                    (pricingProperty.price *
                                      pricingProperty?.discount_rate) /
                                      100
                                  ).toFixed(2) - storeItem?.cost_price
                                }
                              />
                            </div>
                          </Fragment>
                        ),
                      )}
                    </div>

                    {storeItem?.item_level_tracking ? (
                      <div className="w-100">
                        <primaryComponents.ItemsInputField
                          inputIcon={Icon.barCodeIcon}
                          label={`Item Level Tracking (e.g. IMEI Numbers, Tracking numbers, etc). Quantity: ${
                            storeItem.quantity || 0
                          }`}
                          placeholder={
                            "Enter or scan each tacking numbers/IMEI number followed by a “comma”. E.g. 12345678901, 2345789012, ..."
                          }
                          items={storeItem?.items_number}
                          onChange={(value) => {
                            const newStoreProperties = [...storeProperties];
                            newStoreProperties[storePropertyIndex].quantity =
                              (newStoreProperties[storePropertyIndex]
                                .quantity || 0) + 1;
                            newStoreProperties[
                              storePropertyIndex
                            ].items_number.push(value);
                            setStoreProperties(newStoreProperties);
                          }}
                          onRemoveItem={(index) => {
                            const newStoreProperties = [...storeProperties];
                            newStoreProperties[storePropertyIndex].quantity =
                              newStoreProperties[storePropertyIndex].quantity -
                              1;
                            newStoreProperties[
                              storePropertyIndex
                            ].items_number.splice(index, 1);
                            setStoreProperties(newStoreProperties);
                          }}
                        />
                      </div>
                    ) : null}

                    {/* supplier section */}
                    <div className="d-flex">
                      <div className={styles.supplierSection}>
                        <div style={{ width: "350px" }}>
                          <primaryComponents.SelectFieldV2
                            control={control}
                            name={`storeProperties.${storePropertyIndex}.supplier`}
                            isDisabled={isFetchingSuppliers}
                            options={suppliers}
                            value={{
                              label: storeItem?.supplier
                                ? suppliers?.find(
                                    (item) => item?.id === storeItem?.supplier,
                                  )?.name
                                : "Select supplier",
                              value: storeItem?.supplier,
                            }}
                            idKey="id"
                            nameKey="name"
                            floatError={false}
                            onChange={(value) => {
                              const newStoreProperties = [...storeProperties];
                              newStoreProperties[storePropertyIndex].supplier =
                                value;
                              setStoreProperties(newStoreProperties);
                            }}
                            giveControl={false}
                          />
                          <div className="d-flex mt-2">
                            <p className="mr-2 dark--text">
                              Use this supplier across other stores
                            </p>
                            <primaryComponents.Checkbox
                              isChecked={storeItem?.useSupplierAcross}
                              name={`storeProperties.${storePropertyIndex}.useSupplierAcross`}
                              id={`storeProperties.${storePropertyIndex}.useSupplierAcross`}
                              onChange={(value) => {
                                const newStoreProperties = [...storeProperties];
                                newStoreProperties[
                                  storePropertyIndex
                                ].useSupplierAcross = value;
                                setStoreProperties(newStoreProperties);
                                updateSupplierAcrossStore(
                                  storeItem?.supplier,
                                  storePropertyIndex,
                                  value,
                                );
                              }}
                            />
                          </div>
                        </div>

                        {businessDetails?.allow_ledger_account ? (
                          <div style={{ width: "350px" }}>
                            <primaryComponents.SelectField
                              control={control}
                              name={`storeProperties.${storePropertyIndex}.ledger_account_id`}
                              options={bankAccount?.data?.map((bank) => ({
                                id: bank?.id,
                                name: bank?.account_name,
                              }))}
                              idKey="id"
                              nameKey="name"
                              value={"Select Account"}
                              onChange={(value) => {
                                const newStoreProperties = [...storeProperties];
                                newStoreProperties[
                                  storePropertyIndex
                                ].ledger_account_id = value;
                                setStoreProperties(newStoreProperties);
                              }}
                              // isDisabled={
                              //   !storeProperties?.[storePropertyIndex]?.store_id
                              // }
                              classNames="bank-name"
                              floatError={false}
                            />
                            <div className="d-flex mt-2">
                              <p className="mr-2 dark--text">
                                Use this account across other variants
                              </p>
                              <primaryComponents.Checkbox
                                isChecked={storeItem?.useAccountAcross}
                                name={`storeProperties.${storePropertyIndex}.useAccountAcross`}
                                id={`storeProperties.${storePropertyIndex}.useAccountAcross`}
                                onChange={(value) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[
                                    storePropertyIndex
                                  ].useAccountAcross = value;
                                  setStoreProperties(newStoreProperties);
                                  updateAccountAcrossStore(
                                    storeItem?.ledger_account_id,
                                    storePropertyIndex,
                                    value,
                                  );
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {storeProperties?.length > 1 ? (
                    <button
                      type="button"
                      onClick={() => deleteStoreProperty(storePropertyIndex)}
                      className={styles.delBtn}
                    >
                      <img src={Icon.deleteIcon} alt="" />
                    </button>
                  ) : null}
                </div>
              ))}
              <div className={styles.optionContainer}>
                {storeProperties.length < selectedProduct.stores.length ? (
                  <a className="purple--text" onClick={addMoreStoreOption}>
                    + Add more
                  </a>
                ) : null}
              </div>
            </>
          ) : (
            <>
              {storeVariantProperties?.map((storeItem, storeVariantIndex) => (
                <div className={styles.rowItem} key={storeVariantIndex}>
                  <div className={styles.priceCover}>
                    <div
                      className={`${styles.priceSection} ${styles.variantSection}`}
                    >
                      {/* store field */}
                      <primaryComponents.SelectFieldV2
                        name={`storeVariantProperties.${storeVariantIndex}.store_id`}
                        control={control}
                        options={storeList}
                        isRequired={true}
                        value={
                          selectedProduct?.stores?.find(
                            (store) => store?.id === storeItem?.store_id,
                          )?.name
                        }
                        errorMessage={
                          errors?.storeVariantProperties?.[storeVariantIndex]
                            ?.store_id
                        }
                        idKey="id"
                        nameKey="name"
                        label="Store"
                        onChange={(value) => {
                          setVariantPropertyBasedOnStore(
                            value,
                            storeVariantIndex,
                          );
                          setSelectedStore(value);
                        }}
                      />

                      {businessDetails?.allow_ledger_account ? (
                        <secondaryComponents.LoaderHelper
                          isLoading={isLoadingBankAccountsList}
                        >
                          <primaryComponents.SelectField
                            control={control}
                            name={`storeVariantProperties.${storeVariantIndex}.ledger_account_id`}
                            label={"Select Account"}
                            options={bankAccount?.data?.map((bank) => ({
                              id: bank?.id,
                              name: bank?.account_name,
                            }))}
                            isDisabled={
                              !storeVariantProperties?.[storeVariantIndex]
                                ?.store_id
                            }
                            value={
                              storeVariantProperties[storeVariantIndex]
                                ?.ledger_account_id || null
                            }
                            idKey="id"
                            nameKey="name"
                            onChange={(value) => {
                              const newStoreVariantProperties = [
                                ...storeVariantProperties,
                              ];
                              newStoreVariantProperties[
                                storeVariantIndex
                              ].ledger_account_id = value;
                              setStoreVariantProperties(
                                newStoreVariantProperties,
                              );
                            }}
                          />
                        </secondaryComponents.LoaderHelper>
                      ) : null}

                      <primaryComponents.MultipleSelectFieldV2
                        name={`storeVariantProperties.${storeVariantIndex}.variants`}
                        control={control}
                        options={variantsList}
                        isRequired={true}
                        placeholder="Select variant..."
                        errorMessage={
                          errors?.storeVariantProperties?.[storeVariantIndex]
                            ?.variants
                        }
                        idKey="id"
                        nameKey="name"
                        label="Variants"
                        onChange={(values) => {
                          let newValues = [];
                          values?.forEach((item) => {
                            newValues.push(item.value);
                          });
                          setPropertiesBasedOnVariantId(
                            newValues,
                            storeVariantIndex,
                          );
                        }}
                      />

                      <div className="mt-1" style={{ width: "200px" }}>
                        <secondaryComponents.ToolTipV2 tipText="Turn on this feature to assign unique tracking codes for each item. This will help you manage and track individual inventory items more efficiently">
                          <primaryComponents.SelectFieldV2
                            name={`storeVariantProperties.${storeVariantIndex}.item_level_tracking`}
                            control={control}
                            options={[
                              { name: "Yes", id: true },
                              { name: "No", id: false },
                            ]}
                            isRequired={true}
                            value={"No"}
                            idKey="id"
                            nameKey="name"
                            label="Activate Item Level Tracking"
                            onChange={(value) => {
                              const newStoreVariantProperties = [
                                ...storeVariantProperties,
                              ];
                              newStoreVariantProperties[
                                storeVariantIndex
                              ].item_level_tracking = value;
                              setStoreVariantProperties(
                                newStoreVariantProperties,
                              );
                            }}
                          />
                        </secondaryComponents.ToolTipV2>
                      </div>

                      {storeVariantProperties?.length > 1 ? (
                        <button
                          type="button"
                          onClick={() =>
                            deleteStoreVariantOption(storeVariantIndex)
                          }
                          className={styles.delBtn}
                        >
                          <img src={Icon.deleteIcon} alt="" />
                        </button>
                      ) : null}
                    </div>

                    {storeItem?.variants?.map((variantItem, variantIndex) => (
                      <div className={styles.supplierCover} key={variantIndex}>
                        <div
                          className={`${styles.priceSection} ${styles.variantPrice}`}
                          key={variantItem?.variant_id}
                        >
                          <h6 className="w-100 mb-0">
                            {variantItem?.variant_name || ""}
                          </h6>
                          <div className="mt-2">
                            <primaryComponents.InputFieldV2
                              name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.stock_quantity`}
                              control={control}
                              classNames="white"
                              label="Quantity"
                              isRequired
                              type="number"
                              step={0.01}
                              min={0.01}
                              value={variantItem.stock_quantity}
                              errorMessage={
                                errors?.storeVariantProperties?.[
                                  storeVariantIndex
                                ]?.variants?.[variantIndex]?.stock_quantity
                              }
                              onKeyUp={() =>
                                errors?.storeVariantProperties?.[
                                  storeVariantIndex
                                ]?.variants?.[variantIndex]?.stock_quantity &&
                                trigger(
                                  `storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.stock_quantity`,
                                )
                              }
                              isDisabled={storeItem?.item_level_tracking}
                              onChange={({ target: { value } }) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].stock_quantity = value;
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                              }}
                            />
                          </div>

                          <primaryComponents.DateInputV2
                            name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.restock_date`}
                            control={control}
                            classNames="white"
                            label="Restock Date"
                            value={variantItem.restock_date}
                            onChange={({ target: { value } }) => {
                              const newStoreVariantProperties = [
                                ...storeVariantProperties,
                              ];
                              newStoreVariantProperties[
                                storeVariantIndex
                              ].variants[variantIndex].restock_date = value;
                              setStoreVariantProperties(
                                newStoreVariantProperties,
                              );
                            }}
                          />

                          <primaryComponents.DateInputV2
                            name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.expiry_date`}
                            control={control}
                            classNames="white"
                            label="Expiry Date"
                            value={variantItem.expiry_date}
                            errorMessage={
                              errors?.storeProperties?.[storeVariantIndex]
                                ?.variants?.[variantIndex]?.expiry_date
                            }
                            onKeyUp={() =>
                              errors?.storeProperties?.[storeVariantIndex]
                                ?.variants?.[variantIndex]?.expiry_date &&
                              trigger(
                                `storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.expiry_date`,
                              )
                            }
                            onChange={({ target: { value } }) => {
                              const newStoreVariantProperties = [
                                ...storeVariantProperties,
                              ];
                              newStoreVariantProperties[
                                storeVariantIndex
                              ].variants[variantIndex].expiry_date = value;
                              setStoreVariantProperties(
                                newStoreVariantProperties,
                              );
                            }}
                          />

                          <div className="mt-2">
                            <primaryComponents.InputFieldV2
                              name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.barcode`}
                              control={control}
                              inputIcon={Icon.barCodeIcon}
                              label="Barcode"
                              classNames="white"
                              // type="number"
                              value={variantItem?.barcode || ""}
                              onChange={({ target: { value } }) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].barcode = value;
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                              }}
                            />
                          </div>
                          {variantItem?.pricings?.map(
                            (pricingProperty, pricingIndex) => (
                              <Fragment key={pricingProperty?.uuid}>
                                {/* pricing type */}
                                <secondaryComponents.LoaderHelper
                                  isLoading={isLoadingPricingTypes}
                                  classNames={"mt-2"}
                                >
                                  <primaryComponents.SelectFieldV2
                                    name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.pricings.${pricingIndex}.pricing_type_index`}
                                    control={control}
                                    label="Pricing Type"
                                    value={
                                      pricingTypes?.find(
                                        (pricingItem) =>
                                          pricingItem?.id ===
                                          pricingProperty?.pricing_type_id,
                                      )?.label || "Select..."
                                    }
                                    options={[]}
                                    nameKey="label"
                                    idKey="id"
                                    isDisabled={true}
                                    isSearchable={false}
                                  />
                                </secondaryComponents.LoaderHelper>

                                {/* cost price */}
                                <div className="mt-2">
                                  <primaryComponents.InputFieldV2
                                    name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.cost_price`}
                                    control={control}
                                    label="Cost Price"
                                    classNames="white"
                                    type="number"
                                    value={variantItem?.cost_price}
                                    onChange={({ target: { value } }) => {
                                      const newStoreVariantProperties = [
                                        ...storeVariantProperties,
                                      ];
                                      newStoreVariantProperties[
                                        storeVariantIndex
                                      ].variants[variantIndex].cost_price =
                                        value;
                                      setStoreVariantProperties(
                                        newStoreVariantProperties,
                                      );
                                    }}
                                  />
                                </div>

                                {/* selling price */}
                                <div className="mt-2">
                                  <primaryComponents.InputFieldV2
                                    name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.pricings.${pricingIndex}.price`}
                                    control={control}
                                    label="Selling Price"
                                    classNames="white"
                                    type="number"
                                    value={pricingProperty?.price}
                                    onChange={({ target: { value } }) => {
                                      const newStoreVariantProperties = [
                                        ...storeVariantProperties,
                                      ];
                                      newStoreVariantProperties[
                                        storeVariantIndex
                                      ].variants[variantIndex].pricings[
                                        pricingIndex
                                      ].price = value;
                                      setStoreVariantProperties(
                                        newStoreVariantProperties,
                                      );
                                    }}
                                  />
                                </div>

                                {/* discount */}

                                <primaryComponents.PercentField
                                  label="Selling price discount"
                                  name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.pricings.${pricingIndex}.discount_rate`}
                                  control={control}
                                  isDisabled={true}
                                  value={pricingProperty?.discount_rate}
                                  dividedValue={
                                    pricingProperty?.price === ""
                                      ? 0
                                      : pricingProperty.price
                                  }
                                />

                                {/* discounted selling price */}
                                <div className="mt-2">
                                  <primaryComponents.InputField
                                    classNames="white"
                                    label="Discounted selling price"
                                    isDisabled={true}
                                    value={(() => {
                                      let sellingPrice =
                                        pricingProperty?.price === ""
                                          ? 0
                                          : pricingProperty?.price;
                                      let discountPercent =
                                        pricingProperty?.discount_rate === ""
                                          ? 0
                                          : pricingProperty?.discount_rate;
                                      let discount_rate =
                                        (sellingPrice * discountPercent) / 100;

                                      let discountedSellingPrice =
                                        sellingPrice - discount_rate;

                                      return discountedSellingPrice;
                                    })()}
                                  />
                                </div>

                                {/* profit */}
                                <div className="mt-2">
                                  <primaryComponents.InputField
                                    classNames="white"
                                    label="Profit"
                                    isDisabled
                                    value={(
                                      pricingProperty?.price -
                                      (pricingProperty.price *
                                        pricingProperty?.discount_rate) /
                                        100 -
                                      variantItem?.cost_price
                                    ).toFixed(2)}
                                  />
                                </div>
                              </Fragment>
                            ),
                          )}
                        </div>

                        {/* item level tracking */}
                        {storeItem?.item_level_tracking ? (
                          <div className="w-100">
                            <primaryComponents.ItemsInputField
                              inputIcon={Icon.barCodeIcon}
                              label={`Item Level Tracking (e.g. IMEI Numbers, Tracking numbers, etc). Quantity: ${
                                variantItem.quantity || 0
                              }`}
                              placeholder={
                                "Enter or scan each tacking numbers/IMEI number followed by a “comma”. E.g. 12345678901, 2345789012, ..."
                              }
                              items={variantItem?.items_number}
                              onChange={(value) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].stock_quantity =
                                  (newStoreVariantProperties[storeVariantIndex]
                                    .variants[variantIndex].stock_quantity ||
                                    0) + 1;
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].items_number.push(
                                  value,
                                );
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                              }}
                              onRemoveItem={(index) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].stock_quantity =
                                  newStoreVariantProperties[storeVariantIndex]
                                    .variants[variantIndex].stock_quantity - 1;
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].items_number.splice(
                                  index,
                                  1,
                                );
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                              }}
                            />
                          </div>
                        ) : null}

                        {/* supplier section */}
                        <div className={styles.supplierSection}>
                          <div style={{ width: "200px" }}>
                            <primaryComponents.SelectFieldV2
                              isDisabled={isFetchingSuppliers}
                              options={suppliers}
                              name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.supplier`}
                              value={{
                                label: variantItem?.supplier
                                  ? suppliers?.find(
                                      (item) =>
                                        item?.id === variantItem?.supplier,
                                    )?.name
                                  : "Select supplier",
                                value: variantItem?.supplier,
                              }}
                              control={control}
                              idKey="id"
                              nameKey="name"
                              floatError={false}
                              onChange={(value) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].supplier = value;
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                              }}
                              giveControl={false}
                            />
                          </div>

                          <div className="d-flex">
                            <p className="mr-2 dark--text">
                              Use this supplier across other variants
                            </p>
                            <primaryComponents.Checkbox
                              isChecked={variantItem?.useSupplierAcross}
                              name={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.useSupplierAcross`}
                              id={`storeVariantProperties.${storeVariantIndex}.variants.${variantIndex}.useSupplierAcross`}
                              onChange={(value) => {
                                const newStoreVariantProperties = [
                                  ...storeVariantProperties,
                                ];
                                newStoreVariantProperties[
                                  storeVariantIndex
                                ].variants[variantIndex].useSupplierAcross =
                                  value;
                                setStoreVariantProperties(
                                  newStoreVariantProperties,
                                );
                                updateSupplierAcrossStoreVariant(
                                  storeVariantIndex,
                                  variantIndex,
                                  variantItem?.supplier,
                                  value,
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* check length of variants */}
                    {storeItem?.variants?.length === 0 ? (
                      <div className={`${styles.priceSection} `}>
                        <p className="mt-3 w-100 text-center">
                          Please select variants options
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}

              <div className={styles.optionContainer}>
                {storeVariantProperties?.length <
                selectedProduct?.stores?.length ? (
                  <a className="purple--text" onClick={addStoreVariantOption}>
                    + Add more
                  </a>
                ) : null}
              </div>
            </>
          )}
        </form>

        <secondaryComponents.NavigateWithPermission
          permissionParentKey={"Inventory Management"}
          permissionChildKey="can_restock_products_bulk_and_single_restock"
        >
          <primaryComponents.Button
            classNames={`btn btn--primary mt-auto ml-auto ${styles.formBtn}`}
            type="submit"
            form="addStockForm"
            isLoading={isAddingStock}
            loadingText="Adding..."
          >
            Add Stock
          </primaryComponents.Button>
        </secondaryComponents.NavigateWithPermission>
      </div>

      <secondaryComponents.Modal
        isActive={modalViews?.isWarning}
        closeModal={() => resetModalViews("isWarning", false)}
        width={500}
      >
        <WarningDialog
          closeModal={() => resetModalViews("isWarning", false)}
          title={"Pricing Warning"}
          handler={addStock}
        >
          <PricingWarning detectedPricing={detectedPricing} />
        </WarningDialog>
      </secondaryComponents.Modal>
    </>
  );
};
export default AddProductStock;
