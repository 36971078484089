import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cleanObject } from "../../../helpers";

const LoaderHelper = ({
  children,
  isLoading,
  classNames,
  fontSize = "20px",
  alignItem = "text-center",
  iconColor = "#7647ee",
}) => {
  return (
    <>
      {isLoading ? (
        <div className={`${alignItem} ${classNames}`}>
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            style={cleanObject({
              fontSize: fontSize,
              color: iconColor,
            })}
            className="fa-spin"
          />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default LoaderHelper;
