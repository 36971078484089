import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SocialMediaSchema = yup.object().shape({
  facebook: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  instagram: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  twitter: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  tiktok: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  youtube: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  snapchat: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
  google: yup
    .string()
    .notRequired()
    .test(
      "starts-with-https",
      "Link must start with https://",
      (value) => !value || value.startsWith("https://"), // Allow empty string or valid Link
    ),
});

const Validation = ({ socialMediaDetails }) => {
  return useForm({
    resolver: yupResolver(SocialMediaSchema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      facebook: socialMediaDetails.facebook,
      instagram: socialMediaDetails.instagram,
      twitter: socialMediaDetails.twitter,
      tiktok: socialMediaDetails.tiktok,
      youtube: socialMediaDetails.youtube,
      snapchat: socialMediaDetails.snapchat,
      google: socialMediaDetails.google,
    },
  });
};

export default Validation;
