import styles from "./AllFoodMenus.module.scss";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import useAllFoodMenus from "./useFoodMenu";
import { pathConstants } from "../../../../../routes/pathContants";
import { Image } from "@react-pdf/renderer";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContext } from "../../../../../hooks/context";
import Images from "../../../../../assets/images";
import {
  ArrowUp,
  CloseIcon,
  CopyIcon,
  StorefrontLink,
} from "../../../../../assets/svgIcons";
import { useSelector } from "react-redux";

const AllFoodMenus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { business_id } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [showAllStorefontsLink, setShowAllStorefontsLink] = useState(false);
  const {
    foodMenus,
    isFetchingMenu,
    isLoadingDelete,
    searchParams,
    metaDetails,
    deletePaymentMethod,
    setDeletePaymentMethod,
    gotoPage,
    handleFetchFoodMenu,
    handleSearchMenu,
    handleDeletePaymentMethod,
    // dataPaymentMethods,
  } = useAllFoodMenus();
  const tab = searchParams.get("tab");

  console.log(foodMenus);

  const menusTab = [
    "Storefront",
    // "Customize",
    // "Payment",
    // "Logistics Settings",
  ];

  const [selectedMenuTab, setSelectedMenuTab] = useState(
    tab === "customize"
      ? "Customize"
      : tab === "payment"
        ? "Payment Method Settings"
        : "Storefront",
  );
  const { pathname } = useLocation();

  useEffect(() => {
    if (location.state?.menuTab === "Storefront") {
      setSelectedMenuTab("Storefront");
    }
    if (location.state?.menuTab === "Customize") {
      setSelectedMenuTab("Customize");
    }
    if (location.state?.menuTab === "Payment") {
      setSelectedMenuTab("Payment");
    }
  }, [location]);

  const triggerToast = useContext(ToastContext);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory Menu"
      description={"View and Manage Menu for e.g Restaurant"}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className={`${styles.allFoodMenu} ${globalStyles.pageColumnGaps}`}
      >
        <div className={styles.menuHeading}>
          {selectedMenuTab === "shippings" ? (
            <>
              <h4 className="mb-0">Storefront</h4>
              <p>Create, manage, and share your menu(s) with your customers.</p>
            </>
          ) : (
            <>
              <h4 className="mb-0">Online & In-Store Self Checkout</h4>
              <p>
                Let customers shop online or in-store, scan a QR code, and
                checkout effortlessly.
              </p>
            </>
          )}
        </div>

        {foodMenus?.length > 0 && (
          <div className={globalStyles.rowNavigations}>
            {menusTab?.map((item, index) => (
              // eslint-disable-next-line
              <a
                key={index}
                className={`${globalStyles.rowNavigations__item} ${
                  item === selectedMenuTab ? globalStyles.active : ""
                }`}
                onClick={() => {
                  setSelectedMenuTab(item);
                  navigate(".", {
                    state: { menuTab: item },
                  });
                }}
              >
                {item}
              </a>
            ))}
            {/* eslint-disable-next-line */}
            <a
              className={`${globalStyles.rowNavigations__item} ${
                selectedMenuTab === "shippings" ? globalStyles.active : ""
              }`}
              onClick={() => {
                setSelectedMenuTab("shippings");
                navigate(pathConstants.INVENTORY_SHIPPINGS, {
                  state: { menuTab: "shippings" },
                });
              }}
            >
              Shipping
            </a>
            {/* eslint-disable-next-line */}
            <a
              className={`${globalStyles.rowNavigations__item} ${
                selectedMenuTab === "riders" ? globalStyles.active : ""
              }`}
              onClick={() => {
                setSelectedMenuTab("riders");
                navigate(pathConstants.INVENTORY_RIDERS, {
                  state: { menuTab: "riders" },
                });
              }}
            >
              Riders
            </a>
          </div>
        )}
        {pathname === pathConstants.INVENTORY_MENU ? (
          <>
            <div className={styles.pageHeaders} style={{ rowGap: "10px" }}>
              {selectedMenuTab === "Storefront" && (
                <p>{metaDetails?.total} Storefront</p>
              )}

              <div className={styles.mainBtnContainer}>
                {selectedMenuTab === "Storefront" && (
                  <>
                    {foodMenus?.[0]?.slug_url ? (
                      <>
                        <primaryComponents.Button
                          classNames="btn btn--outline ml-auto"
                          onClick={() => setShowAllStorefontsLink(true)}
                        >
                          <StorefrontLink />
                          <span style={{ fontSize: 15, marginLeft: 2 }}>
                            View Business Storefront
                          </span>
                        </primaryComponents.Button>
                      </>
                    ) : null}
                    <secondaryComponents.NavigateWithPermission
                      permissionParentKey={"Inventory Management"}
                      permissionChildKey="can_view_edit_and_create_storefront"
                    >
                      <primaryComponents.Button
                        classNames="btn btn--primary ml-auto"
                        onClick={() =>
                          navigate(pathConstants.INVENTORY_MENU_CREATE)
                        }
                      >
                        + Create Storefront
                      </primaryComponents.Button>
                    </secondaryComponents.NavigateWithPermission>
                  </>
                )}
              </div>
            </div>
            <div className={styles.allFoodMenu__content}>
              <div className={`${globalStyles.tableHeader}`}>
                <primaryComponents.SearchField
                  value={
                    searchParams.get("query") ? searchParams.get("query") : ""
                  }
                  placeholder={"Search for a storefront"}
                  triggerOnInput={true}
                  showsearchBtn={true}
                  trigger={(value) => handleSearchMenu(value)}
                  classNames={`${globalStyles.searchField}`}
                />
              </div>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingMenu}
                classNames={"mt-4"}
              >
                {foodMenus?.length ? (
                  <secondaryComponents.AllFoodMenusList
                    foodMenus={foodMenus}
                    metaDetails={metaDetails}
                    gotoPage={gotoPage}
                    handleFetchFoodMenu={handleFetchFoodMenu}
                    selectedMenuTab={selectedMenuTab}
                  />
                ) : (
                  <div className="text-center mt-5 mb-5">
                    <img src={Image.foodMenuEmptyState} width="250px" alt="" />
                    <h4 className="font-weight-semibold text-center">
                      You have no Storefront
                    </h4>
                    <p
                      style={{ maxWidth: "400px" }}
                      className="text-center mx-auto"
                    >
                      Use the “Create Storefront“ button above to add your
                      storefront. When you do, they would appear here.
                    </p>
                  </div>
                )}
              </secondaryComponents.LoaderHelper>
            </div>
          </>
        ) : (
          <Outlet />
        )}
      </motion.div>

      <secondaryComponents.Modal
        isActive={showAllStorefontsLink}
        closeModal={() => setShowAllStorefontsLink(false)}
        width={626}
      >
        <div className={styles.linksContainer}>
          <div className={styles.closeIcon}>
            <CloseIcon onClick={() => setShowAllStorefontsLink(false)} />
          </div>
          <div className={styles.headingContainer}>
            <h4>All Storefronts Link</h4>
            <p>
              Provide a single link for your customers to access all your
              storefronts in one place.
            </p>
          </div>
          <hr />
          <div className={styles.contentContainer}>
            <span>Select a template you want your customers to visit</span>
            <div className={styles.templateContainer}>
              {foodMenus?.find(
                (item) => item?.template_in_use === "template-2",
              ) && (
                <div className={styles.template}>
                  <div className={styles.imgContainer}>
                    <img
                      src={Images.template1}
                      alt=""
                      width={250}
                      height={180}
                    />
                    <div className={styles.badgeContainer}>
                      <div className={styles.badge}>Template 1</div>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <CopyToClipboard
                      text={
                        window.location.origin +
                        "/storefront/" +
                        `${
                          foodMenus?.[0]?.slug_url
                            ?.split("/storefront/")?.[1]
                            ?.split("-")?.[0]
                        }`
                      }
                      onCopy={() => triggerToast("Copied", "success")}
                    >
                      <primaryComponents.Button classNames="btn btn--primary smallBtn">
                        <span>
                          <CopyIcon />
                        </span>
                        <span className="ml-1">Copy Link</span>
                      </primaryComponents.Button>
                    </CopyToClipboard>
                    <primaryComponents.Button
                      classNames="btn btn--outline smallBtn"
                      onClick={() =>
                        window.open(
                          window.location.origin +
                            "/storefront/" +
                            `${
                              foodMenus?.[0]?.slug_url
                                ?.split("/storefront/")?.[1]
                                ?.split("-")?.[0]
                            }`,
                          "_blank",
                        )
                      }
                    >
                      <ArrowUp />
                      <span className="ml-2">Visit</span>
                    </primaryComponents.Button>
                  </div>
                </div>
              )}
              {foodMenus?.find(
                (item) => item?.template_in_use === "template-1",
              ) && (
                <div className={styles.template}>
                  <div className={styles.imgContainer}>
                    <img
                      src={Images.template2}
                      alt=""
                      width={250}
                      height={180}
                    />
                    <div className={styles.badgeContainer}>
                      <div className={styles.badge}>Template 2</div>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/glb?bsid=${business_id}`}
                      onCopy={() => triggerToast("Copied", "success")}
                    >
                      <primaryComponents.Button classNames="btn btn--primary smallBtn">
                        <span>
                          <CopyIcon />
                        </span>
                        <span className="ml-1">Copy Link</span>
                      </primaryComponents.Button>
                    </CopyToClipboard>
                    <primaryComponents.Button
                      classNames="btn btn--outline smallBtn"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_LUMI_STOREFRONT_APP_BASE_URL}/glb?bsid=${business_id}`,
                          "_blank",
                        );
                      }}
                    >
                      <ArrowUp />
                      <span className="ml-2">Visit</span>
                    </primaryComponents.Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </secondaryComponents.Modal>

      <secondaryComponents.ConfirmDialog
        title="Delete Payment Method"
        isOpen={deletePaymentMethod !== null && deletePaymentMethod !== false}
        setIsOpen={setDeletePaymentMethod}
        isLoading={isLoadingDelete}
        handler={() => handleDeletePaymentMethod()}
      >
        <p className="text-center">
          Are you sure you want to delete this payment method?
        </p>
        <p className="text-center">This action can not be undone</p>
      </secondaryComponents.ConfirmDialog>
    </secondaryComponents.MetaSetter>
  );
};

export default AllFoodMenus;
