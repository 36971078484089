import {
  useGetPrinters,
  usePingPrintDriverServer,
} from "./api/queries/usePrint";
import { usePrintMutation } from "./api/mutations/usePrint";
import { useContext, useState } from "react";
import { cleanObject } from "../helpers";
import { ToastContext } from "./context";

export const usePrint = ({ isEnabled = false, isTest = false }) => {
  const [defaultPaper] = useState(
    localStorage.getItem("default-LB-printer-paper")
      ? JSON.parse(localStorage.getItem("default-LB-printer-paper"))
      : null,
  );
  const [defaultPrinter] = useState(
    localStorage.getItem("default-LB-printer")
      ? JSON.parse(localStorage.getItem("default-LB-printer"))
      : null,
  );
  const { pingServer } = usePingPrintDriverServer();
  const { mutate: printViaServer } = usePrintMutation();
  const { data: printers } = useGetPrinters({
    isEnabled: isEnabled || false,
  });
  const triggerToast = useContext(ToastContext);

  const getPrinters = () => {
    return printers;
  };

  const print = async ({ onError = () => {}, ...params }) => {
    const isPingSuccessful = await pingServer();

    if (isPingSuccessful) return printViaDriverServer({ onError, ...params });

    if (isTest !== true) return printViaWindows({ onError, ...params });

    triggerToast(
      "Sorry, I can't detect a succesful configuration. Please follow the listed steps",
    );
    onError();
  };

  const printViaDriverServer = ({
    imageData,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const data = cleanObject({
      imageData: imageData,
      thermalWidth: defaultPaper?.id ? Number(defaultPaper?.id) : 576,
      vendorId: defaultPrinter?.vendorId || null,
      productId: defaultPrinter?.productId || null,
      serialNumber: defaultPrinter?.serialNumber || null,
    });

    printViaServer(data, {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    });
  };

  const printViaWindows = ({ imageData, onSuccess = () => {} }) => {
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";

    iframe.contentDocument.write(`
      <html>
        <body >
          <img src="${imageData}" style="max-width: 100%;" />
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() { window.close(); };
            };
          </script>
        </body>
      </html>
    `);
    iframe.contentDocument.close();
    onSuccess();
  };

  return {
    paperSize: defaultPaper?.id || 576,
    printerInfo: defaultPrinter || null,
    pingServer,
    print,
    getPrinters,
    printViaWindows,
    printViaDriverServer,
  };
};
