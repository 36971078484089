import styles from "./SalesEndOfDay.module.scss";
import useSalesEndOfDay from "./useSalesEndOfDay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EndOfDayDetails from "./EndOfDayDetails";
import EndOfDayForm from "./EndOfDayForm";

const SaleEndOfDay = ({ closeWidget }) => {
  const {
    isEndOfDayDetails,
    endOfDayDetails,
    endOfDayFormDetails,
    isGeneratingDetails,
    setEndOfDayFormDetails,
    getEndOfDayDetails,
    setIsEndOfDayDetails,
    isFetchingTnx,
    getPaymentEndOfDayDetails,
    setIsPayment,
    isPayment,
  } = useSalesEndOfDay();

  return (
    <div className={styles.endOfDayStatement}>
      <div>
        <FontAwesomeIcon
          onClick={() => {
            if (isEndOfDayDetails) setIsEndOfDayDetails(false);
            else closeWidget(false);
          }}
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </div>

      {!isEndOfDayDetails ? (
        <EndOfDayForm
          setIsEndOfDayDetails={setIsEndOfDayDetails}
          getEndOfDayDetails={getEndOfDayDetails}
          setEndOfDayFormDetails={setEndOfDayFormDetails}
          endOfDayFormDetails={endOfDayFormDetails}
          isGeneratingDetails={isGeneratingDetails}
          getPaymentEndOfDayDetails={getPaymentEndOfDayDetails}
          isFetchingTnx={isFetchingTnx}
          endOfDayDetails={endOfDayDetails}
          setIsPayment={setIsPayment}
          isPayment={isPayment}
        />
      ) : (
        <EndOfDayDetails
          endOfDayDetails={endOfDayDetails}
          endOfDayFormDetails={endOfDayFormDetails}
        />
      )}
    </div>
  );
};
export default SaleEndOfDay;
