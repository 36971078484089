import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useStoreWalletsDetails from "./useStoreWalletDetails";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import styles from "./styles.module.scss";
import { DownloadStatementIcon } from "../../../../../assets/svgIcons";
import { formatCurrency } from "../../../../../helpers";
import Icon from "../../../../../assets/icons";

const StoreWalletDetails = () => {
  const navigate = useNavigate();
  const {
    storeWalletDetails,
    isLoadingStoreWalletDetails,
    isFundWallet,
    isDebitWallet,
    isLoadingStoreWalletTnx,
    storeWalletTransactions,
    metaDetails,
    isDownloadStatement,
    setIsDownloadStatement,
    setIsDebitWallet,
    setIsFundWallet,
    gotoPage,
    isAddSubWallet,
    setIsAddSubWallet,
    filterByWalletId,
    storeWallets,
    hasRequiredParams,
  } = useStoreWalletsDetails();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Store Wallet Details"
      description="View the details of a store wallet"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <div className={globalStyles.pageHeaders}>
            <div>
              <h4
                className="d-flex align-items-center font-weight-semibold"
                style={{ textTransform: "capitalize" }}
              >
                {storeWalletDetails?.customer?.full_name}{" "}
                <span
                  className={`smallest-text-size ${storeWalletDetails?.is_active ? "success--squared" : "error--squared"} ml-3`}
                  style={{ padding: "4px 10px" }}
                >
                  {storeWalletDetails?.is_active ? "Active" : "In Active"}
                </span>
              </h4>

              {/* <p>
                Last active {defaultDateDisplay(storeWalletDetails?.update_at)}
              </p> */}
            </div>
            <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
              <primaryComponents.Button
                type="button"
                classNames={"btn btn--outline"}
                onClick={() => setIsAddSubWallet("add")}
              >
                Add Sub Wallet
              </primaryComponents.Button>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_credit_store_wallet"
              >
                <primaryComponents.Button
                  type="button"
                  classNames={"btn btn--primary"}
                  onClick={() =>
                    setIsFundWallet({
                      customerEmail: storeWalletDetails?.customer?.email,
                      customerId: storeWalletDetails?.customer?.id,
                      customerName: storeWalletDetails?.customer?.full_name,
                      customerPhoneCode:
                        storeWalletDetails?.customer?.phone_code,
                      customerPhoneNumber: storeWalletDetails?.customer?.phone,
                    })
                  }
                >
                  Fund Wallet
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_debit_store_wallet"
              >
                <primaryComponents.Button
                  type="button"
                  classNames={
                    "btn orange--border orange--text orange--subscription"
                  }
                  onClick={() =>
                    setIsDebitWallet({
                      customerEmail: storeWalletDetails?.customer?.email,
                      customerId: storeWalletDetails?.customer?.id,
                      customerName: storeWalletDetails?.customer?.full_name,
                      customerPhoneCode:
                        storeWalletDetails?.customer?.phone_code,
                      customerPhoneNumber: storeWalletDetails?.customer?.phone,
                    })
                  }
                >
                  Debit Wallet
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
              <secondaryComponents.ToolTipV2
                tipText="Download Statement of Account"
                position="left"
              >
                <primaryComponents.Button
                  classNames={"btn btn--outline"}
                  style={{ padding: "0 15px" }}
                  onClick={() => setIsDownloadStatement(true)}
                >
                  <DownloadStatementIcon />
                </primaryComponents.Button>
              </secondaryComponents.ToolTipV2>
            </div>
          </div>
          {storeWallets?.data?.length > 1 ? (
            <div
              className="d-flex flex-wrap align-items-center"
              style={{ gap: "10px" }}
            >
              <h2 className="small-text-size">Filter wallets</h2>
              <div style={{ width: "400px" }}>
                <primaryComponents.SelectField
                  idKey="id"
                  nameKey="name"
                  value={"All"}
                  options={[
                    {
                      id: "ALL",
                      name: "All",
                    },
                    ...(Array.isArray(storeWallets?.data)
                      ? storeWallets?.data
                          .filter((data) => data?.tag)
                          .map((data) => ({
                            id: data?.id,
                            name: (
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ gap: "30px" }}
                              >
                                <div>{data?.tag}</div>
                                <div
                                  className={`${
                                    data?.is_subwallet
                                      ? "gray--squared"
                                      : "gray--primary"
                                  }`}
                                >
                                  {data?.is_subwallet
                                    ? "Sub-wallet"
                                    : "Primary Wallet"}
                                </div>
                              </div>
                            ),
                          }))
                      : []),
                  ]}
                  onChange={(item) => filterByWalletId(item)}
                  isSearchable={false}
                  floatError={false}
                />
              </div>
            </div>
          ) : null}
          <secondaryComponents.LoaderHelper
            isLoading={isLoadingStoreWalletDetails}
            classNames="my-5"
          >
            <div className={styles.walletSection}>
              <div>
                <h6>Current Balance</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: hasRequiredParams
                      ? storeWalletDetails?.balance
                      : storeWalletDetails?.total_wallet_balance,
                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Total Funded</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: hasRequiredParams
                      ? storeWalletDetails?.total_funded
                      : storeWalletDetails?.total_wallet_funded,
                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Total Spent</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: hasRequiredParams
                      ? storeWalletDetails?.total_spent
                      : storeWalletDetails?.total_wallet_spent,

                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>

              <div>
                <h6>Tag</h6>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <span className="gray--text">
                    {hasRequiredParams
                      ? storeWalletDetails?.tag
                      : storeWalletDetails?.customer?.full_name}
                  </span>
                  {storeWalletDetails?.is_subwallet && hasRequiredParams ? (
                    <primaryComponents.Button
                      classNames={"btn btn--outline p-2"}
                      onClick={() => {
                        setIsAddSubWallet("edit");
                      }}
                      style={{ height: "20px" }}
                    >
                      <img
                        src={Icon.editWrite}
                        alt=""
                        width="14px"
                        height="14px"
                      />{" "}
                      Edit
                    </primaryComponents.Button>
                  ) : null}
                </div>
              </div>

              <div>
                <h6>Phone Number</h6>
                <span className="gray--text">
                  ({storeWalletDetails?.customer?.phone_code}){" "}
                  {storeWalletDetails?.customer?.phone}
                </span>
              </div>
              <div>
                <h6>Email Address</h6>
                <span className="gray--text">
                  {" "}
                  {storeWalletDetails?.customer?.email || "N/A"}
                </span>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          <div className="mt-4">
            <h5 className="font-weight-semibold">
              Store Wallet Usage Activity
            </h5>
            <secondaryComponents.LoaderHelper
              classNames="mt-5"
              isLoading={isLoadingStoreWalletTnx}
            >
              <secondaryComponents.StoreWalletTransactionTable
                metaDetails={metaDetails}
                storeWalletTransactions={storeWalletTransactions}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>

        <secondaryComponents.SideModal
          isActive={isFundWallet !== null}
          closeModal={() => setIsFundWallet(null)}
          width={600}
        >
          <secondaryComponents.StoreWalletFund
            setIsFundWallet={setIsFundWallet}
            customerDetails={isFundWallet}
          />
        </secondaryComponents.SideModal>

        <secondaryComponents.SideModal
          isActive={isDebitWallet !== null}
          closeModal={() => setIsDebitWallet(null)}
          width={600}
        >
          <secondaryComponents.StoreWalletDebit
            setIsDebitWallet={setIsDebitWallet}
            customerDetails={isDebitWallet}
          />
        </secondaryComponents.SideModal>

        <secondaryComponents.SideModal
          isActive={isDownloadStatement}
          closeModal={() => setIsDownloadStatement(false)}
          // width={600}
        >
          <secondaryComponents.StoreWalletDownloadStatement
            closeWidget={() => setIsDownloadStatement(false)}
            storeWallets={storeWallets}
          />
        </secondaryComponents.SideModal>
        <secondaryComponents.Modal
          isActive={isAddSubWallet !== null}
          closeModal={() => setIsAddSubWallet(null)}
          width={600}
        >
          <secondaryComponents.AddSubWallet
            isAddSubWallet={isAddSubWallet}
            storeWalletDetails={storeWalletDetails}
            closeModal={() => setIsAddSubWallet(null)}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default StoreWalletDetails;
