import "./CampaignCard.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../../../helpers";
import { useCampaignCardLogic } from "./CampaignCardLogic";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { pathConstants } from "../../../routes/pathContants";

const CampaignCard = ({ campaignDetails }) => {
  const navigate = useNavigate();
  const {
    isLoadingDeleteCampaign,
    showDeleteModal,
    setShowDeleteModal,
    toggleCampaign,
    handleCopyUrl,
    mutateDeleteCampaign,
  } = useCampaignCardLogic({
    campaign_id: campaignDetails?.id,
  });

  return (
    <>
      <div className="campaign-card">
        <div className="campaign-card__container">
          <div className="campaign-card__container-left">
            <h5 className="font-weight-semibold">
              <span className="mobile">Campaign Name:</span>
              {campaignDetails?.campaign_name || "N/A"}
            </h5>
            {/* <h5 className="font-weight-semibold">
              <span className="mobile">Reward Percentage Per Transaction:</span>
              {campaignDetails?.campaign_point_ratio
                ? `${isNaN(parseInt(campaignDetails?.campaign_point_ratio)) ? 0 : parseInt(campaignDetails?.campaign_point_ratio)}%`
                : "0%"}
            </h5> */}
          </div>
          <div className="campaign-card__container-middle">
            <h5 className="font-weight-semibold mobile">Campaign Url</h5>
            <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
              <p className="brand-url__heading text-right">
                {(campaignDetails?.campaign_url !== null &&
                  truncateString(campaignDetails?.campaign_url || "", 40)) ||
                  "N/A"}
              </p>
              <CopyToClipboard
                onCopy={() => handleCopyUrl()}
                text={
                  campaignDetails?.campaign_url !== null
                    ? campaignDetails?.campaign_url
                    : ""
                }
              >
                <primaryComponents.Button
                  classNames={"btn btn--outline smallBtn ml-auto"}
                >
                  <img className="mr-2" src={Icon.copy} alt="" />
                  <span>Copy Url</span>
                </primaryComponents.Button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="campaign-card__container-right">
            <primaryComponents.Button
              onClick={() =>
                navigate(
                  pathConstants.LOYALTY_EDIT_CAMPAIGN({
                    campaignId: campaignDetails?.id,
                  }),
                )
              }
              classNames={"btn btn--primary smallBtn"}
            >
              Edit
            </primaryComponents.Button>
            <primaryComponents.Button
              onClick={() => setShowDeleteModal(true)}
              classNames={"btn btn--outline-red smallBtn"}
            >
              <img src={Icon.deleteIcon} alt="" />
            </primaryComponents.Button>
            <div className="d-flex align-items-center">
              <span className="mr-3 mobile">Toggle Campaign Status</span>
              <primaryComponents.Switch
                isChecked={campaignDetails.status === "ACTIVE"}
                name={campaignDetails.id}
                onChange={toggleCampaign}
                id="campaignSwitch"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* delete menu modal */}
      <secondaryComponents.ConfirmDialog
        title="Delete Campaign"
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        isLoading={isLoadingDeleteCampaign}
        handler={() => mutateDeleteCampaign({ id: campaignDetails?.id })}
      >
        <p className="text-center">
          Are you sure you want to delete this campaign?
        </p>
        <p className="text-center">This action can not be undone</p>
      </secondaryComponents.ConfirmDialog>
    </>
  );
};

export default CampaignCard;
