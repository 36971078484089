import Validation from "./Validation";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import styles from "./FundsMovement.module.scss";
import useFundsMovement from "./useFundsMovement";
import { ArrowIcon, HomeIcon } from "../../../assets/svgIcons";
import { formatCurrency } from "../../../helpers";

const FundsMovement = ({
  bankInfo,
  handleFetchBankTransaction = () => {},
  handleFetchBanks = () => {},
  closeWidget = () => {},
  type,
}) => {
  const {
    fundsMovementDetails,
    bankAccount,
    setfundsMovementDetails,
    currency_code,
    handleFundsMovement,
    createFundsDepositMutation,
    createFundsTransferMutation,
    createFundsWithdrawMutation,
    originatingAccountDetails,
    destinationAccountDetails,
    setOriginatingAccountDetails,
    setDestinationAccountDetails,
    ledgerAccountsList,
    handleFetchBankAccountsList,
    setStoreDetails,
    storeDetails,
  } = useFundsMovement({
    closeWidget,
    bankInfo,
    handleFetchBanks,
    handleFetchBankTransaction,
    type,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ type });

  return (
    <div className={styles.allAccounting}>
      <FontAwesomeIcon
        onClick={() => closeWidget(false)}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "30px" }}
      />

      <div className={globalStyles.pageColumnGaps}>
        <div>
          <h4>
            {type === "deposit"
              ? "Transfer Cash"
              : type === "transfer"
                ? "Transfer"
                : "Withdraw"}
          </h4>
          <p className="small-text-size">
            {type === "deposit"
              ? "Transfer Cash into a bank account"
              : type === "transfer"
                ? "Transfer funds between bank accounts"
                : "Withdraw funds into your cash on hand account"}
          </p>
        </div>
        <div className={globalStyles.cardWithBorder}>
          <div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <p
                className="d-flex align-items-center success--squaredRadius"
                style={{ gap: "7px" }}
              >
                <HomeIcon width="15" height="15" />
                <span className="smallest-text-size"> Origin </span>
              </p>
              <span className="small-text-size">
                {type === "deposit" ? "Cash On Hand Account" : "Bank Account"}
              </span>
            </div>
            <p className="small-text-size">
              {type === "deposit"
                ? "Select a store you want to move cash out from"
                : "Select a bank account you want to take funds out from"}
            </p>
          </div>
          {type === "withdraw" || type === "transfer" ? (
            <div className="w-100 cover">
              <primaryComponents.SelectFieldV2
                control={control}
                onClick={handleFetchBankAccountsList}
                options={bankAccount?.data?.map((bank) => ({
                  id: bank?.id,
                  name: (
                    <div>
                      <div>{bank?.account_name}</div>
                      <span className="small-text-size">
                        {bank?.bank_name} ({bank?.account_number})
                      </span>
                    </div>
                  ),
                }))}
                idKey="id"
                nameKey="name"
                value={"Select Bank"}
                onChange={(value) => {
                  setOriginatingAccountDetails(
                    bankAccount?.data?.find((item) => item?.id === value),
                  );
                  setfundsMovementDetails((prev) => ({
                    ...prev,
                    [type === "withdraw"
                      ? "bank_account_id"
                      : "originating_account_id"]: value,
                  }));
                }}
                classNames="bank"
                name="bank"
                register={register}
                errorMessage={errors.bank}
              />
            </div>
          ) : (
            <div className="w-100 cover">
              <primaryComponents.SelectFieldV2
                options={ledgerAccountsList?.data?.map((store) => ({
                  id: store?.store?.id,
                  name: store?.store?.name,
                }))}
                control={control}
                name="storeIds"
                isRequired={true}
                nameKey="name"
                value={
                  fundsMovementDetails?.store_id
                    ? bankInfo?.store?.name
                    : "Select store"
                }
                onChange={(value) => {
                  setStoreDetails(
                    ledgerAccountsList?.data?.find(
                      (item) => item?.store?.id === value,
                    ),
                  );
                  setfundsMovementDetails((prev) => ({
                    ...prev,
                    store_id: value,
                  }));
                }}
                idKey={"id"}
                classNames="store-select"
                register={register}
                errorMessage={errors.storeIds}
              />
            </div>
          )}

          {fundsMovementDetails.bank_account_id ||
          fundsMovementDetails.originating_account_id ||
          (fundsMovementDetails.store_id && type === "deposit") ? (
            <div className={globalStyles.cardWithBackground}>
              <div
                className="text-center"
                style={{ background: "#fff", borderRadius: "8px" }}
              >
                <h6 className="smallest-text-size mt-1">
                  {type === "deposit" ? "Cash On Hand" : "Account Details"}
                </h6>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <span className="smallest-text-size">
                  {type === "deposit"
                    ? "Cash On Hand Balance:"
                    : "Bank Account Balance:"}
                </span>
                <h6>
                  {formatCurrency({
                    value:
                      type === "deposit"
                        ? storeDetails?.balance
                        : originatingAccountDetails?.balance,
                    currencyCode: currency_code,
                  })}
                </h6>
              </div>
              {type !== "deposit" ? (
                <div className="d-flex justify-content-between">
                  <span className="smallest-text-size">Bank Name:</span>
                  <span className="smallest-text-size">
                    {originatingAccountDetails?.bank_name}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}

          <hr />

          <div>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <p
                className="d-flex align-items-center success--squaredRadius"
                style={{ gap: "5px" }}
              >
                <ArrowIcon />
                <span className="smallest-text-size"> Destination </span>
              </p>
              <span className="small-text-size">
                {type === "withdraw" ? "Cash On Hand Account" : "Bank Account"}
              </span>
            </div>
            <p className="small-text-size">
              {type === "withdraw"
                ? "Select a store you want to withdraw cash into"
                : "Select a bank account you want to deposit the cash into"}
            </p>
          </div>
          {type === "deposit" || type === "transfer" ? (
            <div className="w-100 cover">
              <primaryComponents.SelectFieldV2
                control={control}
                onClick={handleFetchBankAccountsList}
                options={bankAccount?.data?.map((bank) => ({
                  id: bank?.id,
                  name: (
                    <div>
                      <div>{bank?.account_name}</div>
                      <span className="small-text-size">
                        {bank?.bank_name} ({bank?.account_number})
                      </span>
                    </div>
                  ),
                }))}
                idKey="id"
                nameKey="name"
                value={"Select Bank"}
                onChange={(value) => {
                  setDestinationAccountDetails(
                    bankAccount?.data?.find((item) => item?.id === value),
                  );
                  setfundsMovementDetails((prev) => ({
                    ...prev,
                    destination_account_id: value,
                  }));
                }}
                classNames={type === "deposit" ? "bank" : "bank_destination"}
                name={type === "deposit" ? "bank" : "bank_destination"}
                register={register}
                errorMessage={
                  type === "deposit" ? errors.bank : errors.bank_destination
                }
              />
            </div>
          ) : (
            <div className="w-100 cover">
              <primaryComponents.SelectFieldV2
                options={ledgerAccountsList?.data?.map((store) => ({
                  id: store?.store?.id,
                  name: store?.store?.name,
                }))}
                control={control}
                isRequired={true}
                nameKey="name"
                value={
                  fundsMovementDetails?.store_id
                    ? bankInfo?.store?.name
                    : "Select Store"
                }
                onChange={(value) => {
                  setStoreDetails(
                    ledgerAccountsList?.data?.find(
                      (item) => item?.store?.id === value,
                    ),
                  );
                  setfundsMovementDetails((prev) => ({
                    ...prev,
                    store_id: value,
                  }));
                }}
                idKey={"id"}
                classNames="store-select"
                name="storeIds"
                register={register}
                errorMessage={errors.storeIds}
              />
            </div>
          )}

          {fundsMovementDetails.destination_account_id ||
          (fundsMovementDetails.store_id && type === "withdraw") ? (
            <div className={globalStyles.cardWithBackground}>
              <div
                className="text-center"
                style={{ background: "#fff", borderRadius: "8px" }}
              >
                <h6 className="smallest-text-size mt-1">
                  {type !== "withdraw" ? "Account Details" : "Cash On Hand"}
                </h6>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <span className="smallest-text-size">
                  {type !== "withdraw"
                    ? "Bank Account Balance:"
                    : "Cash On Hand Balance"}
                </span>
                <h6>
                  {formatCurrency({
                    value:
                      type !== "withdraw"
                        ? destinationAccountDetails?.balance
                        : storeDetails?.balance,
                    currencyCode: currency_code,
                  })}{" "}
                </h6>
              </div>
              {type !== "withdraw" ? (
                <div className="d-flex justify-content-between">
                  <span className="smallest-text-size">Bank Name:</span>
                  <span className="smallest-text-size">
                    {destinationAccountDetails?.bank_name}
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className={styles.formSection}>
          <div>
            <p className="small-text-size">Amount</p>
            <span className="small-text-size">
              Enter the cash amount you want to deposit
            </span>
          </div>
          <div className="w-100 cover">
            <primaryComponents.AmountFieldV2
              control={control}
              classNames="white"
              name="amount"
              placeholder="Enter amount"
              isRequired={true}
              value={fundsMovementDetails?.amount}
              onChange={({ target: { value } }) => {
                setfundsMovementDetails((prev) => ({
                  ...prev,
                  amount: value,
                }));
              }}
              errorMessage={errors.amount}
              onKeyUp={() => errors.amount !== undefined && trigger("amount")}
              register={register}
            />
          </div>
          <div>
            <p className="small-text-size">Description</p>
          </div>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              placeholder="Enter description"
              value={fundsMovementDetails?.description}
              onChange={({ target: { value } }) => {
                setfundsMovementDetails((prev) => ({
                  ...prev,
                  description: value,
                }));
              }}
            />
          </div>
        </div>
      </div>

      <primaryComponents.Button
        onClick={handleSubmit(handleFundsMovement)}
        classNames={`btn btn--primary cover mt-5 ${styles.allAccounting__bottom}`}
        isDisabled={
          createFundsDepositMutation?.isLoading ||
          createFundsTransferMutation?.isLoading ||
          createFundsWithdrawMutation?.isLoading
        }
        isLoading={
          createFundsDepositMutation?.isLoading ||
          createFundsTransferMutation?.isLoading ||
          createFundsWithdrawMutation?.isLoading
        }
        loadingText={"Adding..."}
      >
        Submit
      </primaryComponents.Button>
    </div>
  );
};

export default FundsMovement;
