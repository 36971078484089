import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const fundCheckoutSchema = yup.object().shape({});

const FundCheckoutValidation = () => {
  return useForm({
    resolver: yupResolver(fundCheckoutSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {},
  });
};
export default FundCheckoutValidation;
