import { useSelector } from "react-redux";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const useDashboardLayout = () => {
  const [search, setSearch] = useSearchParams();
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );
  const { role: business_role, has_security_settings } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const [startDate] = useState(search.get("start_date"));
  const [endDate] = useState(search.get("end_date"));
  const [isSetUpModal, setIsSetUpModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [openTransferWidget, setOpenTransferWidget] = useState(false);

  const setDateFilter = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };

  return {
    verification_status,
    openTransferWidget,
    business_role,
    startDate,
    endDate,
    isSetUpModal,
    setIsSetUpModal,
    isWarningModal,
    setIsWarningModal,
    has_security_settings,
    setDateFilter,
    setOpenTransferWidget,
  };
};

export default useDashboardLayout;
