import Modal from "./Modal/Modal";
import ResponseModal from "./ResponseModal/ResponseModal";
import ProfilePersonalInfoForm from "./ProfilePersonalInfoForm/ProfilePersonalInfoForm";
import ProfileBusinessInfoForm from "./ProfileBusinessInfoForm/ProfileBusinessInfoForm";
import SettlementsTable from "./SettlementsTable/SettlementsTable";
import UsersTable from "./UsersTable/UsersTable";
import AccountTypeButtonGroup from "./AccountTypeButtonGroup/AccountTypeButtonGroup";
import TransactionsTable from "./TransactionsTable/TransactionsTable";
import RequestLoaderTrigger from "./RequestLoaderTrigger/RequestLoaderTrigger";
import ToastMessage from "./ToastMessage/ToastMessage";
// import PushNotifications from "./PushNotifications";
import CustomersTable from "./CustomersTable/CustomersTable";
import OnboardingGuard from "./OnboardingGuard/OnboardingGuard";
import AppGuard from "./AppGuard";
import CustomerTransactionsTable from "./CustomerTransactionsTable/CustomerTransactionsTable";
import CampaignCard from "./CampaignCard/CampaignCard";
import CampaignList from "./CampaignList/CampaignList";
import InviteUser from "./InviteUser/InviteUser";
import UserRoles from "./UserRoles/UserRoles";
import UserTableActions from "./UserTableActions/UserTableActions";
import CampaignStepper from "./CampaignStepper/CampaignStepper";
import CampaignForm from "./CampaignForm";
import ComingSoon from "./ComingSoon/ComingSoon";
import MetaSetter from "./MetaSetter/MetaSetter";
import ImageUploadModal from "./ImageUploadModal";
import AccountDetailWidget from "./AccountDetailWidget/AccountDetailWidget";
import UnverifiedAccountWidget from "./UnverifiedAccountWidget/UnverifiedAccountWidget";
import AccountVerificationWidget from "./AccountVerificationWidget/AccountVerificationWidget";
import FundTransfer from "./FundTransfer";
import WalletOption from "./WalletOption/WalletOption";
import KYBVerificationResponseWidget from "./KYBVerificationResponseWidget/KYBVerificationResponseWidget";
import WalletDetailWidget from "./WalletDetailWidget/WalletDetailWidget";
import LoaderHelper from "./LoaderHelper/LoaderHelper";
import OfferCard from "./OfferCard/OfferCard";
import Pagination from "./Pagination/Pagination";
import TransactionsFilter from "./TransactionsFilter/TransactionsFilter";
import ApiErrorHandler from "./ApiErrorHandler/ApiErrorHandler";
import EditInviteUser from "./EditInviteUser/EditInviteUser";
import DeleteInvitedUser from "./DeleteInvitedUser/DeleteInvitedUser";
import ApprovedTransfersTable from "./ApprovedTransfersTable/ApprovedTransfersTable";
import PendingTransfersTable from "./PendingTransferTable/PendingTransfersTable";
import TransferApprovalModal from "./TransferApprovalModal/TransferApprovalModal";
import TransfersFilter from "./TransfersFilter/TransfersFilter";
import SendReceiptWidget from "./SendReceiptWidget/SendReceiptWidget";
import SubAccountModal from "./SubAccountModal/SubAccountModal";
import ChartWidget from "./ChartWidget/ChartWidget";
import RevenueChart from "./RevenueChart/RevenueChart";
import RevenueChartTooltip from "./RevenueChartTooltip/RevenueChartTooltip";
import RecentTransactionTable from "./RecentTransactionsTable/RecentTransactionsTable";
import SubAccountsList from "./SubAccountsList/SubAccountsList";
import TerminalRequestTable from "./TerminalRequestTable/TerminalRequestTable";
import TerminalRequestForm from "./TerminalRequestForm/TerminalRequestForm";
import TerminalsTable from "./TerminalsTable/TerminalsTable";
import EditSubAccountForm from "./EditSubAccountForm/EditSubAccountForm";
import SubAccountCard from "./SubAccountCard/SubAccountCard";
import AddTransactionsTag from "./AddTransactionsTag/AddTransactionsTag";
import CashflowTrend from "./CashflowTrend/CashflowTrend";
import TransactionCount from "./TransactionCount/TransactionCount";
import TopFiveCustomersTable from "./TopFiveCustomersTable/TopFiveCustomersTable";
import AverageSpendChart from "./AverageSpendChart/AverageSpendChart";
import NewCustomersChart from "./NewCustomersChart/NewCustomersChart";
import ReturningCustomersChart from "./ReturningCustomersChart/ReturningCustomersChart";
import TotalCustomersChart from "./TotalCustomersChart/TotalCustomersChart";
import CustomerChartTooltip from "./CustomerChartToolTip/CustomerChartTooltip";
import AddSettlementAccount from "./AddSettlementAccount/AddSettlementAccount";
import ConfirmDialog from "./ConfirmDialog/ConfirmDialog";
import EditSettlementAccount from "./EditSettlementAccount/EditSettlementAccount";
import StatementOfAccount from "./StatementOfAccount/StatementOfAccount";
import BeneficiariesTable from "./BeneficiariesTable/BeneficiariesTable";
import DeleteBeneficiary from "./DeleteBeneficiary/DeleteBeneficiary";
import TagsTable from "./TagsTable/TagsTable";
import DeleteTagDialog from "./DeleteTagDialog/DeleteTagDialog";
import SpendAnalytics from "./SpendAnalytics/SpendAnalytics";
import RevenueAnalytics from "./RevenueAnalytics/RevenueAnalytics";
import IndividualKYBForm from "./IndividualKYBForm/IndividualKYBForm";
import SoleProprietorKYBForm from "./SoleProprietorKYBForm/SoleProprietorKYBForm";
import LLCKYBForm from "./LLCKYBForm/LLCKYBForm";
import BankVerificationOtpWidget from "./BankVerificationOtpWidget/BankVerificationOtpWidget";
import ProductCategoryModal from "./ProductCategoryModal/ProductCategoryModal";
import ProductStepper from "./ProductStepper/ProductStepper";
import StoreForm from "./StoreForm";
import CategoryForm from "./CategoryForm/CategoryForm";
import ProductCreationType from "./ProductCreationType";
import ProductCategoryTable from "./ProductCategoryTable/ProductCategoryTable";
import StoresTable from "./StoresTable";
import StoresTableModal from "./StoresTableModal/StoresTableModal";
import SalesTransactionsTable from "./SalesTransactionsTable";
import StoreDevicesTable from "./StoreDevicesTable/StoreDevicesTable";
import ProductCarting from "./ProductCarting/ProductCarting";
import ServiceLevelAgreementModal from "./ServiceLevelAgreementModal/ServiceLevelAgreementModal";
import ProductCard from "./SellModeProductItems/ProductCard/ProductCard";
import ProductGridCard from "./SellModeProductItems/ProductGridCard";
import ProductGridVariantCard from "./SellModeProductItems/ProductGridVariantCard";
import ProductVariantCard from "./SellModeProductItems/ProductVariantCard";
import EditProductCategory from "./EditProductCategory/EditProductCategory";
import CheckoutByTransfer from "./CheckoutByTransfer/CheckoutByTransfer";
import AddProductWidget from "./AddProductWidget/AddProductWidget";
import NavLink from "./NavLink/NavLink";
import StoreCart from "./StoreCart";
import SideModal from "./SideModal/SideModal";
import StoreCheckout from "./StoreCheckout/StoreCheckout";
import AddCustomerWidget from "./AddSingleCustomerWidget/AddCustomerWidget";
import GiftCardWidget from "./GiftCardWidget/GiftCardWidget";
import GiftCardSuccessModal from "./GiftCardSuccessModal/GiftCardSuccessModal";
import CustomerHistoryTable from "./CustomerHistoryTable/CustomerHistoryTable";
import GiftcardForm from "./GiftcardForm/GiftcardForm";
import GiftCardList from "./GiftCardList/GiftCardList";
import GiftCardItem from "./GiftCardItem/GiftCardItem";
import LinkCustomerWidget from "./LinkCustomerWidget/LinkCustomerWidget";
import LoyaltyCustomersTable from "./LoyaltyCustomersTable";
import LoyaltyCustomerTransactionTable from "./LoyaltyCustomerTransactionTable";
import StockHistoryTable from "./StockHistoryTable";
import PerformingProductsTable from "./PerforminProductsTable";
import InventoryProfitChart from "./InventoryProfitChart";
import InventoryRevenueChart from "./InventoryRevenueChart";
import PlanInventory from "./SubscriptionFeatures/Inventory";
import AddSingleServiceForm from "./AddSingleServiceForm/AddSingleServiceForm";
import ServiceCard from "./ServiceCard/ServiceCard";
import StockHistoryStatement from "./StockHistoryStatement";
import CustomersSearchField from "./CustomersSearchField";
import SingleProductForm from "./SingleProductForm";
import SalesEndOfDay from "./SalesEndOfDay";
import AllProductsTable from "./AllProductsTable";
import SingleServiceForm from "./SingleServiceForm";
import SellModeMenu from "./SellModeMenu";
import SellModeSideModal from "./SellModeSideModal";
import BillsWidget from "./BillsWidget";
import SalesWidget from "./SalesWidget";
import DeleteStockWidget from "./DeleteStockWidget";
import TransferStockWidget from "./TransferStockWidget";
import ReturnStockWidget from "./ReturnStockWidget";
import AddProductStock from "./AddProductStock";
import CreditLogTable from "./CreditLogTable/CreditLogTable";
import CustomerCreditLogTable from "./CustomerCreditLogTable/CustomerCreditLogTable";
import UpdateCreditWidget from "./UpdateCreditWidget/UpdateCreditWidget";
import ExpensesTable from "./ExpensesTable";
import TableHelper from "./TableHelper";
import CreateExpense from "./CreateExpense";
import NavigateWithPermission from "./NavigateWithPermission";
import RestaurantTable from "./RestaurantTable";
import AddOrderTableForm from "./AddOrderTableForm";
import ExpenseCategoryTable from "./ExpenseCategoryTable";
import CreateExpenseCategory from "./CreateExpenseCategory";
import ExpenseReview from "./ExpenseReview";
import DeletOrderTableDialog from "./DeleteOrderTableDialog";
import EditOrderTableForm from "./EditOrderTableForm";
import ExpenseAnalyticsChart from "./ExpenseAnalyticsChart";
import ExpenseCategoryDeletion from "./ExpenseCategoryDeletion";
import SupplierForm from "./SupplierForm";
import SuppliersTable from "./SuppliersTable";
import SupplierDeletion from "./SupplierDeletion";
import ReconciliationsTable from "./ReconciliationsTable";
import ReconcilePrompt from "./ReconcilePrompt";
import ReconcileStockEditView from "./ReconcileStockEditView";
import ReconciliationReviewPrompt from "./ReconciliationReviewPrompt";
import ProductsExport from "./ProductsExport";
import ProfitAndLostListItem from "./ProfitAndLostListItem";
import ProfitAndLostNetEarning from "./ProfitAndLostNetEarning";
import ProductSearch from "./ProductSearch";
import PendingCheckoutSessions from "./PendingCheckoutSessions";
import ConcatenateWithTooltip from "./ConcatenateWithTooltip";
import ProductBarcode from "./ProductBarcode";
import PlatformNavigator from "./PlatformNavigator";
import CreditForm from "./CreditForm";
import InvoiceTable from "./InvoicesTable";
import SubscribtionNotification from "./SubscribtionNotification";
import BulkRestockTable from "./BulkRestockTable";
import BulkRestockComboBox from "./BulkRestockComboBox";
import InvoiceHistoryTable from "./InvoiceHistoryTable";
import SubscriptionTable from "./SubscriptionTable";
import SubscriptionPay from "./SubscriptionPay";
import SubscriptionPeriod from "./SubscriptionFeatures/SubscriptionPeriod";
import SubscribeUpgradeNotification from "./SubscribeUpgradeNotification";
import SubscriptionPlanHeader from "./SubscriptionPlanHeader";
import FreePlanMobileView from "./SubscriptionFeatures/FreePlanMobileView";
import GenerateWaybillForm from "./GenerateWaybillForm";
import PrescriptionWidget from "./PrescriptionWidget";
import AllFoodMenusList from "./AllFoodMenusList";
import FoodMenuWidget from "./FoodMenuWidget";
import FoodMenuTable from "./FoodMenuTable";
import MenuGridCard from "./MenuGridCard";
import FormStepper from "./FormStepper";
import CompositeForm from "./CompositeForm";
import MainDashboardAnalyticsCard from "./MainDashboardAnalyticsCard";
import MainDashboardSetUpCard from "./MainDashboardSetUpCard";
import GetStartedVideo from "./GetStartedVideo";
import MainDasboardDropdown from "./MainDashboardDropdown";
import ToolTip from "./Tooltip";
import CreatePricingType from "./CreatePricingType";
import CountryChecker from "./CountryChecker";
import CountryItemPermission from "./CountryItemPermission";
import SectionCard from "./SectionCard";
import SectionWidget from "./SectionWidget";
import SectionDetailsWidget from "./SectionDetailsWidget";
import SectionsTable from "./SectionsTable";
import SectionDeletion from "./SectionDelete";
import SectionForm from "./SectionForm";
import ToolTipV2 from "./ToolTipV2";
import AddCustomer from "./AddCustomer";
import Notifications from "./Notifications";
import CustomerForm from "./CustomerForm";
import CustomerDeletion from "./CustomerDeletion";
import CustomerDetailsCard from "./CustomerDetailsCard";
import BvnConsent from "./BvnConsent";
import Tree from "./TreeFormWidget";
import BulkEditProduct from "./BulkEditProduct";
import CustomerUpdate from "./CustomerUpdate";
import BillUpdate from "./StoreCheckout/BillUpdate";
import CustomerLoyaltyTable from "./CustomerLoyaltyTable";
import AllStockTransferTable from "./AllStockTransferTable";
import BulkTransferTable from "./BulkTransferTable";
import StockTransferForm from "./StockTransferForm";
import SendCreditReminderWidget from "./SendCreditReminderWidget";
import UpdateCreditLogModal from "./UpdateCreditLogModal";
import TrackItemTable from "./TrackItemTable";
import SellModeStoreFrontOrders from "./SellModeStoreFrontOrders";
import MoreOption from "./MoreModal";
import MarketsCard from "./MarketsCard";
import AllAccountingsTable from "./AllAccountingsTable";
import AddBankAccount from "./AddBankAccount";
import BankAccountDeletion from "./BankAccountDeletion";
import AddCreditToBankAccount from "./AddCreditToBankAccount";
import AddDebitToBankAccount from "./AddDebitToBankAccount";
import AllBankTransactionTable from "./AllBankTransactionTable";
import AllShippingsList from "./AllShippingsList";
import CreateShippingForm from "./CreateShippingForm";
import UpdateCityModal from "./UpdateCityModal";
import RidersTable from "./RidersTable";
import RidersForm from "./RIdersForm";
import RidersDelete from "./RidersDelete";
import RejectOrderPop from "./RejectOrderPop";
import StoreFrontLocationChangeModal from "./StoreFrontLocationChangeModal";
import LocationModal from "./LocationModal";
import AllProductsBatchTable from "./AllProductsBatchTable";
import ProductBatchForm from "./ProductBatchForm";
import StoreWalletsTable from "./StoreWallets/StoreWalletsTable";
import StoreWalletDebit from "./StoreWallets/StoreWalletDebit";
import StoreWalletFund from "./StoreWallets/StoreWalletFund";
import StoreWalletOtpWidget from "./StoreWallets/StoreWalletOtpWidget";
import StoreWalletTransactionTable from "./StoreWallets/StoreWalletTransactionTable";
import StoreWalletCreationForm from "./StoreWallets/StoreWalletCreationForm";
import StoreWalletDownloadStatement from "./StoreWallets/StoreWalletDownloadStatement";
import ProductUnitForm from "./ProductUnitForm";
import AddCustomerWidgetModal from "./AddCustomerWidget";
import AddMultipleCustomers from "./AddMultipleCustomers";
import AuthorizationModal from "./AuthorizationModal";
import SupportOption from "./SupportModal";
import CreditLogTransaction from "./CreditLogTransaction";
import VerificationModal from "./SecurityQuestionAndPin/VerificationModal";
import SecurityQuestionAndPinSetupModal from "./SecurityQuestionAndPin/SecurityQuestionAndPinSetupModal";
import setUpModal from "./SecurityQuestionAndPin/SecurityQuestionAndPinSetupModal/setUpModal";
import PrinterConfigForm from "./PrinterConfiguration/PrinterConfigForm";
import FundsMovement from "./FundsMovement";
import AddSubWallet from "./StoreWallets/SubWallet/AddSubWallet";
import AllPaymentEOD from "./PaymentEOD";

const secondaryComponents = {
  ReturnStockWidget,
  TransferStockWidget,
  DeleteStockWidget,
  SalesWidget,
  BillsWidget,
  SellModeSideModal,
  SellModeMenu,
  Modal,
  AddSingleServiceForm,
  ResponseModal,
  ProfilePersonalInfoForm,
  ProfileBusinessInfoForm,
  SettlementsTable,
  UsersTable,
  AccountTypeButtonGroup,
  TransactionsTable,
  RequestLoaderTrigger,
  ToastMessage,
  // PushNotifications
  CustomersTable,
  OnboardingGuard,
  AppGuard,
  CustomerTransactionsTable,
  CampaignCard,
  CampaignList,
  InviteUser,
  UserRoles,
  UserTableActions,
  CampaignStepper,
  CampaignForm,
  ComingSoon,
  MetaSetter,
  ImageUploadModal,
  AccountDetailWidget,
  UnverifiedAccountWidget,
  AccountVerificationWidget,
  FundTransfer,
  WalletOption,
  KYBVerificationResponseWidget,
  WalletDetailWidget,
  LoaderHelper,
  OfferCard,
  Pagination,
  TransactionsFilter,
  ApiErrorHandler,
  EditInviteUser,
  DeleteInvitedUser,
  ApprovedTransfersTable,
  PendingTransfersTable,
  TransfersFilter,
  TransferApprovalModal,
  SendReceiptWidget,
  SubAccountModal,
  ChartWidget,
  RevenueChart,
  RevenueChartTooltip,
  RecentTransactionTable,
  SubAccountsList,
  TerminalRequestTable,
  TerminalRequestForm,
  TerminalsTable,
  EditSubAccountForm,
  SubAccountCard,
  AddTransactionsTag,
  CashflowTrend,
  TransactionCount,
  TopFiveCustomersTable,
  AverageSpendChart,
  TotalCustomersChart,
  ReturningCustomersChart,
  NewCustomersChart,
  CustomerChartTooltip,
  AddSettlementAccount,
  ConfirmDialog,
  EditSettlementAccount,
  StatementOfAccount,
  BeneficiariesTable,
  DeleteBeneficiary,
  TagsTable,
  DeleteTagDialog,
  SpendAnalytics,
  RevenueAnalytics,
  IndividualKYBForm,
  SoleProprietorKYBForm,
  LLCKYBForm,
  BankVerificationOtpWidget,
  ProductCategoryModal,
  ProductStepper,
  StoreForm,
  CategoryForm,
  ProductCreationType,
  ProductCategoryTable,
  StoresTable,
  StoresTableModal,
  SalesTransactionsTable,
  StoreDevicesTable,
  ProductCard,
  ProductCarting,
  ServiceLevelAgreementModal,
  EditProductCategory,
  CheckoutByTransfer,
  AddProductWidget,
  NavLink,
  StoreCart,
  SideModal,
  StoreCheckout,
  AddCustomerWidget,
  GiftCardWidget,
  GiftCardSuccessModal,
  CustomerHistoryTable,
  GiftcardForm,
  GiftCardList,
  GiftCardItem,
  LinkCustomerWidget,
  LoyaltyCustomersTable,
  LoyaltyCustomerTransactionTable,
  ProductVariantCard,
  StockHistoryTable,
  PerformingProductsTable,
  InventoryProfitChart,
  InventoryRevenueChart,
  PlanInventory,
  ServiceCard,
  StockHistoryStatement,
  CustomersSearchField,
  SingleProductForm,
  SalesEndOfDay,
  AllProductsTable,
  SingleServiceForm,
  AddProductStock,
  CreditLogTable,
  CustomerCreditLogTable,
  UpdateCreditWidget,
  ProductGridCard,
  ProductGridVariantCard,
  ExpensesTable,
  TableHelper,
  CreateExpense,
  NavigateWithPermission,
  RestaurantTable,
  AddOrderTableForm,
  ExpenseCategoryTable,
  CreateExpenseCategory,
  ExpenseReview,
  DeletOrderTableDialog,
  EditOrderTableForm,
  ExpenseAnalyticsChart,
  ExpenseCategoryDeletion,
  SupplierForm,
  SuppliersTable,
  SupplierDeletion,
  ReconciliationsTable,
  ReconcilePrompt,
  ReconcileStockEditView,
  ReconciliationReviewPrompt,
  ProductsExport,
  ProfitAndLostListItem,
  ProfitAndLostNetEarning,
  ProductSearch,
  PendingCheckoutSessions,
  ConcatenateWithTooltip,
  ProductBarcode,
  PlatformNavigator,
  CreditForm,
  InvoiceTable,
  SubscribtionNotification,
  BulkRestockTable,
  BulkRestockComboBox,
  InvoiceHistoryTable,
  SubscriptionTable,
  SubscriptionPay,
  SubscriptionPeriod,
  SubscribeUpgradeNotification,
  SubscriptionPlanHeader,
  FreePlanMobileView,
  GenerateWaybillForm,
  PrescriptionWidget,
  AllFoodMenusList,
  FoodMenuWidget,
  FoodMenuTable,
  MenuGridCard,
  FormStepper,
  CompositeForm,
  MainDashboardAnalyticsCard,
  MainDashboardSetUpCard,
  GetStartedVideo,
  MainDasboardDropdown,
  ToolTip,
  CreatePricingType,
  CountryChecker,
  CountryItemPermission,
  SectionCard,
  SectionWidget,
  SectionDetailsWidget,
  SectionsTable,
  SectionDeletion,
  SectionForm,
  ToolTipV2,
  AddCustomer,
  Notifications,
  CustomerForm,
  CustomerDeletion,
  CustomerDetailsCard,
  BvnConsent,
  Tree,
  BulkEditProduct,
  CustomerUpdate,
  BillUpdate,
  CustomerLoyaltyTable,
  AllStockTransferTable,
  BulkTransferTable,
  StockTransferForm,
  SendCreditReminderWidget,
  UpdateCreditLogModal,
  TrackItemTable,
  SellModeStoreFrontOrders,
  MoreOption,
  MarketsCard,
  AllAccountingsTable,
  AddBankAccount,
  BankAccountDeletion,
  AddCreditToBankAccount,
  AddDebitToBankAccount,
  AllBankTransactionTable,
  AllShippingsList,
  CreateShippingForm,
  UpdateCityModal,
  RidersTable,
  RidersForm,
  RidersDelete,
  RejectOrderPop,
  StoreFrontLocationChangeModal,
  LocationModal,
  AllProductsBatchTable,
  ProductBatchForm,
  StoreWalletsTable,
  StoreWalletDebit,
  StoreWalletFund,
  StoreWalletOtpWidget,
  StoreWalletTransactionTable,
  StoreWalletCreationForm,
  StoreWalletDownloadStatement,
  ProductUnitForm,
  AddCustomerWidgetModal,
  AddMultipleCustomers,
  AuthorizationModal,
  SupportOption,
  CreditLogTransaction,
  VerificationModal,
  SecurityQuestionAndPinSetupModal,
  setUpModal,
  PrinterConfigForm,
  FundsMovement,
  AddSubWallet,
  AllPaymentEOD,
};

export default secondaryComponents;
