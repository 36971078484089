import { formatCurrency } from "../../../helpers";

export const PricingWarning = ({ detectedPricing = [] }) => {
  return (
    <>
      {" "}
      <p>
        The cost price in the following{" "}
        {detectedPricing?.length === 1 ? "store is" : "stores are"} higher than
        the selling price <br />
      </p>
      <ul className="mt-2" style={{ listStyle: "disc" }}>
        {detectedPricing?.map((pricingInfo, index) => (
          <li key={index}>
            <span className="dark--text font-weight-semibold">
              [{pricingInfo?.store_name}]
            </span>{" "}
            -{" "}
            <span className="dark--text font-weight-semibold">
              {pricingInfo?.pricing_name} (
              {formatCurrency({
                value: pricingInfo?.cost_price,
                currencyCode: pricingInfo?.store_currency,
              })}
              )
            </span>
            {" > "}
            <span className="dark--text font-weight-semibold">
              {" "}
              (
              {formatCurrency({
                value: pricingInfo?.selling_price,
                currencyCode: pricingInfo?.store_currency,
              })}
              )
            </span>
          </li>
        ))}
      </ul>
      <p>
        Click
        <span className="dark--text font-weight-semibold"> Continue </span>
        to proceed or{" "}
        <span className="dark--text font-weight-semibold">Cancel</span> to
        review and adjust the pricing.
      </p>
    </>
  );
};
