import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  useDownloadStatementMutation,
  useDownloadDebtorStatementMutation,
} from "../../../../hooks/api/mutations/useInventory";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
//import { useParams } from "react-router-dom";

const useDownloadStatement = ({ closeWidget, type, customer }) => {
  const triggerToast = useContext(ToastContext);
  //const { id } = useParams();

  const [statementFormat, setStatementFormat] = useState("PDF");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [walletId, setWalletId] = useState();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const downloadStatement = useDownloadStatementMutation();
  const downloadDebtorStatement = useDownloadDebtorStatementMutation();

  const handleDownloadStatementGeneric = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    const mutateFn =
      type === "debtor" ? downloadDebtorStatement : downloadStatement;
    const payload =
      type === "debtor"
        ? cleanObject({
            customer_id: customer?.id,
            download_format: statementFormat.toLowerCase(),
            start_date: startDate,
            end_date: endDate,
          })
        : cleanObject({
            store_wallet_id: walletId,
            type: statementFormat.toLowerCase(),
            start_date: startDate,
            end_date: endDate,
          });

    mutateFn.mutate(payload, {
      onSuccess: (res) => {
        if (type === "debtor") {
          window.open(res?.data?.data);
        } else {
          triggerToast(
            "Statement of account downloaded successfully",
            "success",
          );
        }
        closeWidget();
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    statementFormat,
    setStatementFormat,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleDownloadStatementGeneric,
    downloadStatement,
    downloadDebtorStatement,
    setWalletId,
    walletId,
  };
};

export default useDownloadStatement;
