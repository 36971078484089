/* eslint-disable no-useless-escape */
import "./AmountField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";
import { formatNumberWithCommas, removeCommas } from "../../../helpers";

const AmountField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  value = "",
  register = () => {},
  onChange = () => {},
  onKeyUp = () => {},
  classNames = "",
  currency_code = "",
  floatError = true,
  ...rest
}) => {
  const { store_currency } = useStoreCurrency();

  return (
    <div className="amount-container">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`amount-container__input-section ${classNames} ${
          errorMessage !== undefined ? "error-border" : ""
        } ${isDisabled ? "isDisabled" : ""}`}
      >
        <label
          className="default-font-size"
          htmlFor={name}
          style={{ color: "#91919f" }}
        >
          {currency_code ? currency_code : store_currency}
        </label>
        <input
          // type="number"
          name={name}
          placeholder={placeholder}
          id={name}
          disabled={isDisabled}
          {...register(name, { required: isRequired })}
          value={formatNumberWithCommas(removeCommas(value)) || ""}
          onKeyUp={onKeyUp}
          onChange={(e) => {
            // eslint-disable-next-line
            if (e.target.value === NaN) return onChange("");
            e.target.value = removeCommas(e.target.value);
            onChange(e);
          }}
          {...rest}
        />
      </div>
      {floatError || errorMessage !== undefined ? (
        <p
          className={`error-message text-danger ${
            errorMessage !== undefined ? "show" : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage?.message}</span>}
        </p>
      ) : null}
    </div>
  );
};

export default AmountField;
