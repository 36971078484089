import {
  fetchAllSections,
  fetchAllSectionOrdersList,
  getOrdersWithSection,
  getSectionItemDetails,
} from "../../../services/section";
import { useInfiniteQuery, useQuery } from "react-query";

export const useGetSections = () => {
  return useQuery(
    ["sections"],
    () => fetchAllSections().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetSectionOrders = (queries = "") => {
  return useInfiniteQuery(
    ["sectionOrders", [queries]],
    ({ pageParam = 1 }) =>
      fetchAllSectionOrdersList({ queries, pageParam }).then(
        (res) => res?.data,
      ),
    {
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );
};

export const useGetOrdersWithSection = ({ queries = "", queryName = "" }) => {
  return useInfiniteQuery(
    [`ordersWithSection${queryName}`, [queries]],
    ({ pageParam = 1 }) =>
      getOrdersWithSection({ queries, pageParam }).then((res) => res?.data),
    {
      cacheTime: 0,
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );
};

export const useGetSectionItemDetails = ({
  enabled = true,
  id,
  onSuccess,
  onSettled,
}) => {
  return useQuery(
    ["sectionItemDetails", id],
    () => getSectionItemDetails({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: enabled && id ? true : false,
      onSuccess,
      onSettled,
      retry: () => {},
      cacheTime: 0,
    },
  );
};

export const useGetSectionItems = ({ enabled = true, queries = "" }) => {
  return useQuery(
    ["sectionItems", queries],
    () =>
      fetchAllSectionOrdersList({ queries, pageParam: 1 }).then(
        (res) => res?.data?.data,
      ),
    {
      enabled: enabled,
      staleTime: Infinity,
    },
  );
};
