import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  removeBillProduct,
  updateBillProduct,
} from "../../../../services/sales-point";
import useOrderflows from "../Orderflows/useOrderflows";
import { DEFAULT_PRICING_ID } from "../../../../helpers/constants";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";
import { useOtpAuthTrigger } from "../../../../hooks/useOtpAuthTrigger";
import { useSelector } from "react-redux";

const arrangeProductItem = (orderItems, storeId) => {
  const productItems = [];
  orderItems?.forEach((item) => {
    productItems.push({
      id: `${item?.product?.id || item?.service?.id}${item?.variant?.id || ""}`,
      product_id: item?.product?.id,
      cart_item_id: item?.id,
      is_from_cart: true,
      cart_item_name: item?.cart_item_name,
      quantity: item?.quantity,
      tags: item?.tags,
      unit: item?.product?.unit,
      total_price: item?.total_price,
      pricing_type_id: item?.pricing_type?.id,
      unit_price: item?.unit_price,
      discount_amount: item?.discount_amount,
      is_service: item?.service ? true : false,
      product: item?.product || item?.service,
      is_custom_price: item?.is_custom_price,
      variant_id: item?.variant?.id,
      pricing_type_label: item?.pricing_type_label,
    });
  });

  return productItems;
};

const loyaltyObject = {
  loyaltyType: "GIFT_CARD",
  redemption_code: "",
  customer_id: "",
  giftcard_amount: "",
  loyalty_point_amount: "",
  customerDetails: {
    customerName: "",
    customerPhoneNumber: "",
    customerPhoneCode: "",
    customerEmail: "",
  },
};

const useBillUpdate = ({ orderId, setBillWasUpdated, resetView }) => {
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [loyaltyDetails, setLoyaltyDetails] = useState(loyaltyObject);
  const { orderDetails, isLoading } = useOrderflows({
    orderId,
  });
  const [productItems, setProductItems] = useState([]);
  const [otp, setOtp] = useState("");
  const [isAction, setIsAction] = useState("");
  const [otherFields, setOtherFields] = useState({
    delivery_fee_amount: orderDetails?.delivery_fee_amount || "",
    discount_amount: orderDetails?.discount_amount || "",
    loyalty_debit_amount: loyaltyDetails?.loyalty_point_amount
      ? loyaltyDetails?.loyalty_point_amount
      : orderDetails?.loyalty_debit_amount,

    giftcard_debit_amount: loyaltyDetails?.giftcard_amount
      ? loyaltyDetails?.giftcard_amount
      : orderDetails?.giftcard_debit_amount,
  });
  const triggerToast = useContext(ToastContext);
  const {
    clearLatestOtpAuthCode,
    getAuthCodeBaseOnKey,
    handleOrderModuleAuth,
  } = useOtpAuthTrigger({
    storeId: orderDetails?.store?.id,
    params: { object_id: orderDetails?.id, object_type: "order" },
  });
  const [modals, setModals] = useState({
    isDiscount: false,
    isDelivery: false,
    isLoyalty: false,
  });

  const {
    cartDetails: { loyalty_auth_pin, giftcard_auth_pin },
  } = useSelector((state) => state.cartReducer);

  useEffect(() => {
    setProductItems(
      arrangeProductItem(orderDetails?.order_items, orderDetails?.store?.id),
    );
    queryClient.invalidateQueries(["orderDetails"]);
  }, [orderDetails?.order_items]);

  useEffect(() => {
    if (orderDetails?.delivery_fee_amount)
      setOtherFields((prev) => ({
        ...prev,
        delivery_fee_amount: orderDetails?.delivery_fee_amount,
      }));
  }, [orderDetails?.delivery_fee_amount]);

  useEffect(() => {
    if (orderDetails?.delivery_fee_amount)
      setOtherFields((prev) => ({
        ...prev,
        discount_amount: orderDetails?.discount_amount,
      }));
  }, [orderDetails?.discount_amount]);

  const addProductItem = (item) => {
    if (
      productItems?.find(
        (productItem) => `${productItem?.id}` === `${item?.id}`,
      )
    )
      return triggerToast("This item has been added to your order list");

    const productName = "store_item_properties";
    const unitPrice = item?.[productName]?.[0]?.pricings?.[0]?.price;
    const totalPrice = (
      unitPrice -
      (unitPrice * item?.[productName]?.[0]?.pricings?.[0]?.discount_rate) / 100
    ).toFixed(2);

    const data = {
      ...item,
      id: `${item?.id}`,
      cart_item_name: item?.name,
      tags: [],
      unit: item?.unit,
      quantity: 1,
      total_price: totalPrice,
      unit_price: unitPrice,
      pricing_type_id: item?.pricing_type_id,
      discount_amount: (
        (unitPrice * item?.[productName]?.[0]?.pricings?.[0]?.discount_rate) /
        100
      ).toFixed(2),
      product: { id: item?.id },
      [productName]: item?.[productName],
    };
    setProductItems((prev) => [data, ...prev]);
  };

  const { mutate: deleteBillItem, isLoading: isDeleting } = useMutation(
    (data) => removeBillProduct(data),
  );

  const { mutate: updateBill, isLoading: isUpdating } = useMutation((data) =>
    updateBillProduct(data),
  );

  const removeBillItem = ({ cartId, index } = {}) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    setIsAction("REMOVE_ITEM");
    deleteBillItem(
      { id: cartId },
      {
        onSuccess: () => {
          removeItemNotWithId(index);
          closeOtpAuthorizationPrompt();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const removeItemNotWithId = (index) => {
    const newProductItems = [...productItems];
    newProductItems.splice(index, 1);
    setProductItems(newProductItems);
  };

  const updateBillItem = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    setIsAction("UPDATE_ITEM");
    const newProductItems = [];
    productItems?.forEach((productItem) => {
      const data = {
        quantity: productItem?.quantity,
        pricing_type_id: productItem?.pricing_type_id || DEFAULT_PRICING_ID,
        custom_price: productItem?.custom_price,
      };
      if (productItem?.is_from_cart)
        data.cart_item_id = productItem?.cart_item_id;
      else {
        const key = productItem?.service_id ? "service_id" : "product_id";
        data[key] = productItem?.service_id
          ? productItem?.service_id
          : productItem?.product_id;
        data.tag_ids = productItem?.tags;
        data.store_id = orderDetails.store?.id;
        data.is_product = productItem?.service_id ? false : true;
        data.variant_id = productItem?.variant_id;
      }
      newProductItems.push(cleanObject(data));
    });

    updateBill(
      cleanObject({
        id: orderDetails?.id,
        items: newProductItems,
        delivery_fee:
          otherFields?.delivery_fee_amount &&
          otherFields?.delivery_fee_amount !== orderDetails?.delivery_fee_amount
            ? {
                amount: otherFields?.delivery_fee_amount,
                type: "flat",
              }
            : null,
        custom_pricing_auth_pin: getAuthCodeBaseOnKey(
          "use_custom_pricing_on_order",
        ),
        delivery_fee_auth_pin: getAuthCodeBaseOnKey(
          "add_delivery_fee_to_order",
        ),
        loyalty_auth_pin,
        giftcard_auth_pin,
        reduce_bill_qty_auth_pin: getAuthCodeBaseOnKey("reduce_bill_quantity"),
        discount_auth_pin: getAuthCodeBaseOnKey("add_discount_to_order"),
        discount:
          otherFields?.discount_amount &&
          otherFields?.discount_amount !== orderDetails?.discount_amount
            ? { amount: otherFields?.discount_amount, type: "flat" }
            : null,

        giftcard:
          Number(loyaltyDetails?.giftcard_amount) > 0
            ? {
                redemption_code: loyaltyDetails.redemption_code,
                amount: Number(loyaltyDetails?.giftcard_amount),
              }
            : null,

        loyalty_point:
          Number(loyaltyDetails?.loyalty_point_amount) > 0
            ? {
                customer_id: loyaltyDetails.customer_id,
                amount: Number(loyaltyDetails?.loyalty_point_amount),
              }
            : null,
      }),
      {
        onSuccess: () => {
          onSuccessHandler();
          closeOtpAuthorizationPrompt();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const onSuccessHandler = () => {
    queryClient.invalidateQueries(["orderDetails"]);
    queryClient.invalidateQueries(["sectionItems"]);
    queryClient.invalidateQueries(["getOrders"]);
    setLoyaltyDetails(loyaltyObject);
    clearLatestOtpAuthCode([
      "use_custom_pricing_on_order",
      "add_discount_to_order",
      "add_delivery_fee_to_order",
      "reduce_bill_quantity",
    ]);
    setBillWasUpdated(true);
    resetView("isTnxReceipt");
  };

  return {
    otp,
    setOtp,
    orderDetails,
    productItems,
    isUpdating,
    isLoading,
    otherFields,
    isAction,
    isDeleting,
    modals,
    loyaltyDetails,
    setLoyaltyDetails,
    handleOrderModuleAuth,
    setModals,
    setOtherFields,
    removeBillItem,
    updateBillItem,
    addProductItem,
    setProductItems,
    removeItemNotWithId,
  };
};
export default useBillUpdate;
