import styles from "./SectionView.module.scss";
import {
  cleanObject,
  defaultDateDisplay,
  truncateString,
} from "../../../helpers";
import { useEffect, useRef } from "react";
import { useCloseOtpAuthorizationPrompt } from "../../../hooks/useCloseOtpAuthorizationPrompt";
import { useGetSectionItemDetails } from "../../../hooks/api/queries/useSection";
import { useMarkDocketHasPrinted } from "../../../hooks/api/mutations/useSection";
import usePrintLogic from "../usePrintLogic";

export const DocketView = ({
  section_id,
  closePrint = () => {},
  isDocket = false,
  invalidateQueries = () => {},
}) => {
  const docketPrintComponent = useRef(null);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const { data: sectionDetails, refetch: refetchSectionItems } =
    useGetSectionItemDetails(
      cleanObject({
        id: section_id,
        enabled: false,
        onSuccess: () => {
          closeOtpAuthorizationPrompt();
        },
      }),
    );

  const { handleMarkDocketHasPrinted } = useMarkDocketHasPrinted({
    id: section_id,
    callBackFunc: () => {
      invalidateQueries();
      closeOtpAuthorizationPrompt();
      closePrint();
    },
  });

  const clearAfterPrint = () => {
    if (isDocket && !sectionDetails?.is_printed)
      return handleMarkDocketHasPrinted();
    closeOtpAuthorizationPrompt();
    closePrint();
  };

  const { paperSize, logo } = usePrintLogic({
    itemDetails: sectionDetails,
    sectionPrintComponent: docketPrintComponent,
    hiddenId: "docket-hidden-section",
    callBackFunc: clearAfterPrint,
    onErrorCallBackFunc: closePrint,
  });

  useEffect(() => {
    refetchSectionItems();
  }, []);

  return (
    <>
      <div
        id="docket-hidden-section"
        ref={docketPrintComponent}
        style={{ display: "none", maxWidth: `${paperSize}px` }}
        className={`${styles.sectionView} `}
      >
        {logo ? (
          <img
            style={{ width: "100px", maxHeight: "100px" }}
            className="mb-2"
            src={logo}
            alt="business logo"
          />
        ) : null}

        <div className="text-center mb-2">
          {sectionDetails?.order?.store?.name ? (
            <p style={{ fontSize: "16px" }}>
              {truncateString(sectionDetails?.order?.store?.name, 150)}
            </p>
          ) : null}
          {sectionDetails?.order?.store?.address ? (
            <p style={{ fontSize: "16px" }} className="dark--text">
              {truncateString(sectionDetails?.order?.store?.address, 150)}
            </p>
          ) : null}
        </div>

        <div className={styles.sectionView__item}>
          <p>Queue Number:</p>
          <h4 className="mb-0">
            {sectionDetails?.order?.queue_number || "N/A"}
          </h4>
        </div>
        <div className={styles.sectionView__item}>
          <p>Order Date:</p>
          <p>{defaultDateDisplay(sectionDetails?.created_at)}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Order For:</p>
          <p>{sectionDetails?.section?.name}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Order ID:</p>
          <p>{sectionDetails?.order?.id}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Table:</p>
          <p>{sectionDetails?.order?.table?.name || "N/A"}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Order Type:</p>
          <p>{sectionDetails?.order?.order_type?.replace("_", " ")}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Cashier Name:</p>
          <p>{sectionDetails?.order?.initiator?.first_name || "N/A"}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Customer Name:</p>
          <p>{sectionDetails?.order?.customer?.full_name || "N/A"}</p>
        </div>
        <div className={styles.sectionView__item}>
          <p>Order:</p>
          <p>
            {sectionDetails?.docket_items?.map((item, index) => (
              <span key={item?.id}>
                {" "}
                {item?.quantity} x{" "}
                <span className="dark--text">
                  {item?.cart_item?.cart_item_name}
                </span>
                {index === sectionDetails?.docket_items?.length - 1 ? "" : ","}
              </span>
            ))}
          </p>
        </div>

        <div>
          <p>Notes:</p>
          <p>{sectionDetails?.order?.notes || "N/A"}</p>
        </div>
      </div>
    </>
  );
};
