import styles from "./AddMultipleProducts.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AddMultipleProductsLogic from "./AddMultipleProductsLogic";
import { platformHandler } from "../../../../hooks/context";
import { useContext } from "react";
import { getPlatform } from "../../../../helpers";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddMultipleProducts = () => {
  const navigate = useNavigate();
  const { isUploadingFile, inputRef, option, setOption, handleProductUpload } =
    AddMultipleProductsLogic();
  const { platform } = useContext(platformHandler);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className={`${styles.mainContainer} ${globalStyles.pageColumnGaps}`}
    >
      <div>
        <primaryComponents.Button
          classNames="btn btn--outline back__btn"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
      </div>
      <div>
        <h4>Multi-Product Upload</h4>
        <p>
          Upload products in bulk for both single and composite types of product
          using a .csv file
        </p>
      </div>
      <div className={styles.optionContainer}>
        <secondaryComponents.WalletOption
          title="Single Product"
          subtitle="Upload a set of single products"
          onChange={(option) => {
            setOption(option);
          }}
          name="inventory_option"
          checked={option === "single"}
          value={"single"}
          style={{ maxWidth: "100%" }}
        />
        <secondaryComponents.WalletOption
          title="Composite Product"
          subtitle="Upload a set of composite products"
          onChange={(option) => {
            setOption(option);
          }}
          name="inventory_option"
          checked={option === "composite"}
          value={"composite"}
          style={{ maxWidth: "100%" }}
        />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.headingContainer}>
          <p>
            Follow step-by-step guide below for uploading your set of{" "}
            {option !== "single" ? "composite" : "single"} products
          </p>
          <input
            style={{ display: "none" }}
            type="file"
            name="products"
            id="products"
            onChange={handleProductUpload}
            ref={inputRef}
            accept={
              ".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
          />
          <label htmlFor="products">
            <primaryComponents.Button
              isLoading={isUploadingFile}
              isDisabled={isUploadingFile}
              classNames={"btn btn--primary"}
              loadingText="Uploading..."
              onClick={() => inputRef.current.click()}
              style={{ height: 32 }}
            >
              <img className="mr-2" src={Icon.upload} width={16} alt="" />
              <span style={{ fontSize: 14 }}>Upload CSV File</span>
            </primaryComponents.Button>
          </label>
        </div>
        <div className={styles.setupGuideContainer}>
          {option !== "single" ? (
            <>
              <div className={styles.attentionContainer}>
                <div className="d-flex align-items-center">
                  <img className="mr-1" src={Icon.attention} alt="" />
                  <h6 className="mb-0">ATTENTION</h6>
                </div>
                <p>
                  Please note that to use composite product you must have
                  already created each single product that make up the composite
                </p>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 1</span> of 5
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Download the sample csv spreadsheet</h6>
                  <p>
                    This spreadsheet will serve as a template for organizing
                    your customer data.
                  </p>
                  <a
                    href={
                      "https://enlumi-research-app.s3.eu-west-2.amazonaws.com/develop/business/templates/bulk-composite-product-upload/test.csv"
                    }
                    className="purple--text"
                  >
                    Click here to download template
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 2</span> of 5
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Obtain your product ID</h6>
                  <p>
                    In order to successfully upload your products, you will need
                    your unique product ID.
                  </p>
                  <a
                    href={getPlatform(platform) + "/inventory/products"}
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to find out how to obtain your product ID
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 3</span> of 5
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Obtain your store ID</h6>
                  <p>
                    In order to successfully upload your products to your store,
                    you will need your unique store ID.
                  </p>
                  <a
                    href={getPlatform(platform) + "/inventory/stores"}
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to find out how to obtain your store ID
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 4</span> of 5
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Prepare your product list</h6>
                  <p>
                    Ensure you have a complete list of product units ready for
                    upload.
                  </p>
                  <a
                    href={
                      "https://enlumidatainc.zohodesk.com/portal/en/kb/articles/list-of-products-metrics-and-their-symbols-on-lumi-business"
                    }
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    please consult the list provided for a reference guide on
                    common product units
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 5</span> of 5
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Watch full video tutorial</h6>
                  <p>
                    For a detailed walkthrough of the bulk upload process, we
                    recommend watching our comprehensive video tutorial. This
                    video will guide you through each step of the process, from
                    organizing your data in the spreadsheet to successfully
                    uploading your products to your store.
                  </p>
                  <a
                    href={"https://youtu.be/QbhA_pBhpWI"}
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to watch tutorial
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 1</span> of 4
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Download the sample csv spreadsheet</h6>
                  <p>
                    This spreadsheet will serve as a template for organizing
                    your customer data.
                  </p>
                  <a
                    href={
                      "https://enlumi-research-app.s3.eu-west-2.amazonaws.com/static/New+Product+Template.csv"
                    }
                    className="purple--text"
                  >
                    Click here to download template
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 2</span> of 4
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Obtain your store ID</h6>
                  <p>
                    In order to successfully upload your products to your store,
                    you will need your unique store ID.
                  </p>
                  <a
                    href={getPlatform(platform) + "/inventory/stores"}
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to find out how to obtain your store ID
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 3</span> of 4
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Prepare your product list</h6>
                  <p>
                    Ensure you have a complete list of product units ready for
                    upload.
                  </p>
                  <a
                    href={
                      "https://enlumidatainc.zohodesk.com/portal/en/kb/articles/list-of-products-metrics-and-their-symbols-on-lumi-business"
                    }
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    please consult the list provided for a reference guide on
                    common product units
                  </a>
                </div>
              </div>
              <div className={styles.stepContainer}>
                <div className={styles.badge}>
                  <span>Step 4</span> of 4
                </div>
                <div className={styles.shortLine} />
                <div className={styles.stepContent}>
                  <h6>Watch full video tutorial</h6>
                  <p>
                    For a detailed walkthrough of the bulk upload process, we
                    recommend watching our comprehensive video tutorial. This
                    video will guide you through each step of the process, from
                    organizing your data in the spreadsheet to successfully
                    uploading your products to your store.
                  </p>
                  <a
                    href={"https://youtu.be/r9xoYTTXAe0"}
                    className="purple--text"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to watch tutorial
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AddMultipleProducts;
