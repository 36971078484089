import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useRootProperties from "../../hooks/useRootProperties";
import { useGetSections } from "../../hooks/api/queries/useSection";

const useSectionLayoutLogic = () => {
  const { isLoading } = useRootProperties();
  const [search, setSearch] = useSearchParams();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { data: sections } = useGetSections();

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const handleSearchSection = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByStatusAndDate = (params = { status: null, date: null }) => {
    search.set("status", params?.status);
    search.delete("page");
    if (search.get("start_date") || params?.date) {
      search.set("start_date", search.get("start_date") || params?.date);
      search.set("end_date", search.get("end_date") || params?.date);
    } else {
      search.delete("start_date");
      search.delete("end_date");
    }

    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("store_id") && storeList?.length > 0) {
      search.set("store_id", storeList?.[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList]);

  return {
    isLoading,
    storeList,
    search,
    sections,
    setSearch,
    filterByStoreId,
    handleSearchSection,
    filterByStatusAndDate,
  };
};
export default useSectionLayoutLogic;
