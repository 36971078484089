export const LoveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.26469 2.83225L8.27363 2.83529L8.28824 2.84917L8.39692 2.88408C8.56529 2.93818 8.70617 2.99341 8.83389 3.06365L8.8519 3.07356L8.87067 3.08196L9.16821 3.21507C9.19755 3.23202 9.23857 3.25974 9.29424 3.29981C9.30401 3.30684 9.317 3.31627 9.33069 3.3262C9.34993 3.34015 9.37053 3.35509 9.38547 3.36577C9.41434 3.38642 9.45195 3.41284 9.48947 3.43573L9.48944 3.43578L9.49578 3.43951C9.51463 3.45064 9.53182 3.46071 9.5476 3.46996C9.6176 3.51098 9.65984 3.53574 9.69563 3.56318L9.99936 3.79604L10.3034 3.56366C11.1431 2.92199 12.1597 2.57634 13.2046 2.58373V2.58375H13.2082C13.6874 2.58375 14.1579 2.6515 14.5991 2.79957L14.5991 2.79959L14.6036 2.80105C17.3408 3.69096 18.4073 6.72418 17.5418 9.49347C17.0414 10.9266 16.2252 12.2339 15.1583 13.3017C13.5918 14.8183 11.8746 16.1664 10.0271 17.3304C8.16831 16.1729 6.43682 14.8221 4.85169 13.2936C3.79105 12.2247 2.97388 10.9247 2.4655 9.49115C1.58647 6.72611 2.64937 3.6909 5.42286 2.78325L5.42288 2.78331L5.43035 2.78073C5.63005 2.71187 5.83562 2.66271 6.04172 2.63388H6.10818H6.14447L6.18037 2.62864C6.38954 2.59812 6.59788 2.58375 6.80818 2.58375H6.89207C7.36526 2.59885 7.82197 2.68168 8.26469 2.83225ZM14.5023 4.24975L14.4949 4.24696L14.4875 4.24439C13.8776 4.03467 13.2261 4.36708 13.0123 4.96571L13.0122 4.96567L13.0088 4.97577C12.8077 5.57925 13.121 6.24251 13.7276 6.46079C14.0585 6.58621 14.2915 6.92007 14.2915 7.29971V7.30745C14.2716 7.61699 14.3674 7.92145 14.5639 8.16007C14.7765 8.41825 15.081 8.55333 15.3773 8.58162L15.4077 8.58452L15.4383 8.5837C16.0368 8.56764 16.5386 8.09287 16.5819 7.48525L16.5832 7.46751V7.44971V7.35564C16.6103 5.98232 15.7775 4.73377 14.5023 4.24975Z"
        fill="#E02020"
        stroke="#15112D"
      />
    </svg>
  );
};
