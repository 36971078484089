import { useContext, useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../primaryComponents";
import {
  useCompleteDebitTnx,
  useResendDebitTnxOtp,
} from "../../../../hooks/api/mutations/useInventory";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import useCountDown from "../../../../hooks/useCountDown";
import { useSelector } from "react-redux";
import { checkoutWithSplit } from "../../../../services/sales-point";

const StoreWalletOtpWidget = ({
  debitSessionDetails,
  closeModal,
  debitDetails,
  currencyCode,
  is_order = false,
  isViaSellMode = false,
  is_split = false,
  setIsDebitWallet = () => {},
  callBackFunc = () => {},
}) => {
  const { currency_code, business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [otp, setOtp] = useState("");
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const completeDebitTnx = useCompleteDebitTnx(isViaSellMode);
  const resendDebitTnxOtp = useResendDebitTnxOtp();
  const { countDown, setCountDown } = useCountDown({ defaultStartNum: 60 });

  const handleCompleteDebitTnx = (value) => {
    setOtp(value);
    if (value?.length !== 6) return;
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));

    if (is_split) {
      if (
        Number(debitDetails?.cardAmount) +
          Number(debitDetails?.transferAmount) +
          Number(debitDetails?.creditAmount) +
          Number(debitDetails?.cashAmount) +
          Number(debitDetails?.storeWalletAmount) +
          Number(debitDetails?.prAmount) -
          Number(debitDetails?.orderDetails?.total_amount) !==
        0
      ) {
        return triggerToast(
          `Please ensure that your estimated balance is ${currency_code} 0`,
          "warning",
        );
      } else {
        debitDetails?.setIsConfirmingPayment(true);
        const data = cleanObject({
          order_id: debitDetails?.orderDetails?.id,
          cash_amount: Number(debitDetails?.cashAmount),
          transfer_amount: Number(debitDetails?.transferAmount),
          card_amount: Number(debitDetails?.cardAmount),
          credit_amount: Number(debitDetails?.creditAmount),
          pr_amount: Number(debitDetails?.prAmount),
          repayment_date: debitDetails?.repaymentDate,
          ledger_account_id: debitDetails?.selectBank,
        });
        if (
          Number(debitDetails?.creditAmount) > 0 ||
          Number(debitDetails?.storeWalletAmount) > 0
        ) {
          data.customer_name = debitDetails?.customerDetails?.customerName;
          data.customer_phone_number =
            debitDetails?.customerDetails?.customerPhoneNumber;
          data.customer_phone_code =
            debitDetails?.customerDetails?.customerPhoneCode ||
            business_phone_code;
          data.customer_email = debitDetails?.customerDetails?.customerEmail;
        }

        if (Number(debitDetails?.storeWalletAmount) > 0) {
          data.store_wallet_otp_code = value;
          data.store_wallet_session_id = debitSessionDetails?.session_id;
        }

        checkoutWithSplit(data)
          .then(() => {
            triggerToast("Payment successful", "success");
            queryClient.invalidateQueries([
              "orderDetails",
              debitDetails?.orderDetails?.id,
            ]);
            callBackFunc();
          })
          .catch(() => {})
          .finally(() => {
            debitDetails?.setIsConfirmingPayment(false);
            setRequestLoaderProgress(100);
          });
      }
    } else {
      completeDebitTnx.mutate(
        {
          otp_code: value,
          session_id: debitSessionDetails?.session_id,
        },
        {
          onSuccess: () => {
            triggerToast(
              `A sum of ${currencyCode} ${debitDetails?.amount} has been debited from customer: (${debitDetails?.customer?.customerName})${is_order ? " which is use to process this order" : ""}`,
              "success",
            );
            queryClient.invalidateQueries(["getStoreWallets"]);
            queryClient.invalidateQueries(["getStoreWalletDetails"]);
            queryClient.invalidateQueries(["getStoreWalletTransaction"]);
            callBackFunc();
            setIsDebitWallet(null);
            closeModal();
          },
          onSettled: () => setRequestLoaderProgress(100),
        },
      );
    }
  };

  const handleResendOtp = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    resendDebitTnxOtp.mutate(
      {
        session_id: debitSessionDetails?.session_id,
      },
      {
        onSuccess: () => {
          triggerToast("OTP resent successfully", "success");
          setCountDown(60);
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return (
    <div className={`p-3 text-center ${globalStyles.pageColumnGaps}`}>
      <div>
        <h5 className="purple--text">Store Wallet Verification</h5>
        <p>Enter the 6-digit code sent to your email and phone number</p>
      </div>

      <div className="mx-auto" style={{ width: "300px" }}>
        <primaryComponents.OtpInputField
          onChange={handleCompleteDebitTnx}
          numOfInputs={6}
          value={otp}
        />
      </div>

      <p>
        Didn&apos;t get code?{" "}
        {resendDebitTnxOtp?.isLoading ? (
          <span className="purple--text">Resending...</span>
        ) : (
          <>
            {countDown !== 0 && (
              <span className="purple--text"> {countDown}</span>
            )}
            {countDown === 0 && (
              <span
                className="purple--text cursor-pointer"
                onClick={() => handleResendOtp()}
              >
                Resend
              </span>
            )}
          </>
        )}
      </p>
    </div>
  );
};
export default StoreWalletOtpWidget;
