import { useMutation } from "react-query";
import {
  fetchAllSections,
  fetchAllSectionOrdersList,
  startSectionOrder,
  addSection,
  removeSection,
  updateSection,
  toggleSectionStatus,
  completeSectionOrder,
  sendSectionItemReceiptToPrint,
  updatekDocketPrintStatus,
} from "../../../services/section";
import { useQueryClient } from "react-query";

export const useKitchenUpdated = () => {
  const queryCache = useQueryClient();
  return useMutation(fetchAllSections, {
    onSuccess: (data) => {
      queryCache.invalidateQueries("sections");
    },
  });
};

export const useSectionOrdersMutation = () => {
  return useMutation((data) => fetchAllSectionOrdersList(data));
};

export const useAddSectionMutation = () => {
  return useMutation((data) => addSection(data));
};

export const useEditSectionMutation = () => {
  return useMutation((data) => updateSection(data));
};

export const useUpdateSectionToggleMutation = () => {
  return useMutation((data) => toggleSectionStatus(data));
};

export const useDeleteSectionMutation = () => {
  return useMutation((data) => removeSection(data));
};

export const useStartSectionOrderMutation = () => {
  return useMutation((data) => startSectionOrder(data));
};

export const useCompleteSectionOrderMutation = () => {
  return useMutation((data) => completeSectionOrder(data));
};

export const useSendSectionItemReceiptToPrint = () => {
  return useMutation((data) => sendSectionItemReceiptToPrint(data));
};

export const useMarkDocketHasPrinted = ({ id, callBackFunc = () => {} }) => {
  const markDocketHasPrinted = useMutation((data) =>
    updatekDocketPrintStatus(data),
  );

  const handleMarkDocketHasPrinted = () => {
    markDocketHasPrinted.mutate(
      { id },
      {
        onSuccess: () => {
          callBackFunc();
        },
      },
    );
  };

  return {
    handleMarkDocketHasPrinted,
    isLoading: markDocketHasPrinted.isLoading,
  };
};
