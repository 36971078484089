import React from "react";

const SendToCustomerIcon = () => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7891_35185)">
        <rect width="24" height="24" fill="white" />
        <path
          d="M17 2H7C5.89543 2 5 2.89543 5 4V22L7.5 20L9.5 22L12 20L14.5 22L16.5 20L19 22V4C19 2.89543 18.1046 2 17 2Z"
          stroke="#15112D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 6H15"
          stroke="#15112D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 10H15"
          stroke="#15112D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 14H10"
          stroke="#15112D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="14" y="14" width="17" height="16" rx="4" fill="#E4DAFC" />
      <path
        d="M27.9998 16.8236L22.2707 22.2157M27.9998 16.8236L17 20.2745L22.2707 22.2157L27.9998 16.8236ZM27.9998 16.8236L24.3332 27.1764L22.2707 22.2157L27.9998 16.8236Z"
        stroke="#15112D"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_7891_35185">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendToCustomerIcon;
