import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { warehouseCheckout } from "../../../../services/sales-point";
import { useMutation } from "react-query";
import { useCloseOtpAuthorizationPrompt } from "../../../../hooks/useCloseOtpAuthorizationPrompt";
import { useSelector } from "react-redux";
import { useGetCustomerDetails } from "../../../../hooks/api/queries";

const WarehouseCheckoutPaymentLogic = ({
  closePaymentSession,
  orderDetails,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [customer, setCustomer] = useState(null);
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  const { data: fetchedCustomer, refetch } = useGetCustomerDetails(
    customerDetails?.customerId,
  );

  const { mutate: updateWarehouse, isLoading: isUpdating } = useMutation(
    (data) => warehouseCheckout(data),
  );

  useEffect(() => {
    if (customerDetails?.customerId) {
      refetch();
    }
  }, [customerDetails?.customerId]);

  useEffect(() => {
    if (fetchedCustomer) {
      setCustomer(fetchedCustomer);
    }
  }, [fetchedCustomer]);

  useEffect(() => {
    if (orderDetails?.customer)
      setCustomerDetails({
        customerId: orderDetails?.customer?.id,
        customerPhoneCode: orderDetails?.customer?.phone_code,
        customerName: orderDetails?.customer?.full_name,
        customerPhoneNumber: orderDetails?.customer?.phone,
        customerEmail: orderDetails?.customer?.email,
      });
  }, [orderDetails]);

  const payViaWarehouseCheckout = () => {
    if (!customerDetails.customerPhoneNumber)
      return triggerToast("Customer phone number is required");

    setIsConfirmingPayment(true);
    updateWarehouse(
      {
        order_id: orderId,
        customer_name: customerDetails?.customerName,
        customer_phone_number: customerDetails?.customerPhoneNumber,
        customer_phone_code:
          customerDetails?.customerPhoneCode || business_phone_code,
        customer_email: customerDetails?.customerEmail,
      },
      {
        onSuccess: () => {
          setIsConfirmingPayment(false);
          triggerToast("Payment confirmation successful", "success");
          closePaymentSession();
          closeOtpAuthorizationPrompt();
        },
        onError: (error) => {
          setIsConfirmingPayment(false);
          if (error?.response === undefined) return;
        },
      },
    );
  };

  return {
    isConfirmingPayment,
    payViaWarehouseCheckout,
    customer,
    isUpdating,
    customerDetails,
    setCustomerDetails,
  };
};
export default WarehouseCheckoutPaymentLogic;
