/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import styles from "../SingleProductForm/SingleProductForm.module.scss";
import primaryComponents from "../../primaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Images from "../../../assets/images";
import { useEffect } from "react";
import useSingleProductForm from "../SingleProductForm/useSingleProductForm";
import Icon from "../../../assets/icons";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "..";
import { PricingWarning, WarningDialog } from "../../common";

const SingleServiceForm = ({
  isUpdatingProduct,
  isEditMode = false,
  productDetails = null,
  handleProductUpdate,
}) => {
  const {
    productImages,
    inputRef,
    rootFormDetails,
    categories,
    storeList,
    isUploadingImage,
    storeProperties,
    isCreatePricing,
    pricingTypes,
    isLoadingPricingTypes,
    booleanLabel,
    sections,
    isLoadingSections,
    setProductImages,
    setIsCreatePricing,
    setStoreProperties,
    setRootFormDetails,
    modalViews,
    detectedPricing,
    resetModalViews,
    // handleProductImageUpload,
    createOrUpdateProduct,
    setIsStorePropertiesUpdated,
    addNewCategory,
    addNewPricingForStoreProperty,
    removePricingForStoreProperty,
    handleMultipleImageUpload,
    deleteImage,
  } = useSingleProductForm({
    isEditMode,
    productDetails,
    handleProductUpdate,
    isServiceType: true,
    storePropertiesKey: "store_service_properties",
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = Validation({
    rootFormDetails,
    storeProperties,
  });

  useEffect(() => {
    window.setProductFormValue = setValue;
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        className={styles.singleProductForm}
      >
        <form id="productForm" onSubmit={handleSubmit(createOrUpdateProduct)}>
          <div className={styles.topFormSection}>
            <div className={styles.topFormSection__left}>
              {/* product name */}
              <primaryComponents.InputFieldV2
                name="productName"
                control={control}
                register={register}
                placeholder={"Enter service name"}
                classNames="white"
                label="Service Name"
                value={rootFormDetails.name}
                isRequired
                errorMessage={errors?.productName}
                onKeyUp={() => errors?.productName && trigger("productName")}
                onChange={({ target: { value } }) => {
                  setRootFormDetails((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
              />

              {/* category field */}
              <div className="mb-2">
                <label>
                  <span className="error--text">*</span>Category
                </label>
                <Controller
                  name="productCategory"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.TagsMultiSelect
                      options={categories}
                      idKey="id"
                      nameKey="name"
                      placeholder="Select category"
                      onChange={(item) => {
                        onChange(item?.label);
                        setRootFormDetails((prevState) => ({
                          ...prevState,
                          category: item,
                        }));
                      }}
                      onCreateOption={(value) => {
                        addNewCategory(value);
                        onChange(value);
                      }}
                      isSearchable={true}
                      isMulti={false}
                      value={rootFormDetails?.category}
                      giveControl={false}
                      height="50px"
                      fontSize="16px"
                    />
                  )}
                />
                {/* error message */}
                {errors.productCategory?.message && (
                  <p className="text-danger">
                    <FontAwesomeIcon
                      icon={["fas", "exclamation-circle"]}
                      className="mr-2"
                      style={{ fontSize: "13px" }}
                    />
                    <span style={{ fontSize: "13px" }}>
                      {errors.productCategory?.message}
                    </span>
                  </p>
                )}
              </div>

              {/* store field */}
              <primaryComponents.MultipleSelectFieldV2
                name="stores"
                control={control}
                options={storeList}
                isRequired={true}
                value={rootFormDetails.stores}
                placeholder="Select store..."
                errorMessage={errors?.stores}
                idKey="id"
                nameKey="name"
                label="Select Store To Make Service Available At"
                onChange={(values) => {
                  setRootFormDetails((prevState) => ({
                    ...prevState,
                    stores: values,
                  }));
                  setIsStorePropertiesUpdated(true);
                }}
              />

              <div className={styles.flexArrange}>
                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingSections}
                  classNames={"mt-2"}
                >
                  {sections?.length > 0 ? (
                    <primaryComponents.SelectFieldV2
                      name="section"
                      control={control}
                      label="Select section"
                      value={
                        sections?.find(
                          (section) => section.id === rootFormDetails?.section,
                        )?.name || "Select..."
                      }
                      options={sections}
                      nameKey="name"
                      idKey="id"
                      classNames="w-100"
                      onChange={(value) => {
                        setRootFormDetails((prevState) => ({
                          ...prevState,
                          section: value,
                        }));
                      }}
                      isSearchable={true}
                    />
                  ) : null}
                </secondaryComponents.LoaderHelper>

                {/* show on sell mode */}
                <primaryComponents.SelectFieldV2
                  classNames="w-100"
                  name="showProductOnSellMode"
                  control={control}
                  label="Show this service in sell mode?"
                  isRequired={true}
                  value={
                    booleanLabel[rootFormDetails.show_product_in_sell_mode]
                  }
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  nameKey="label"
                  idKey="value"
                  onChange={(value) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      show_product_in_sell_mode: value,
                    }));
                  }}
                  isSearchable={false}
                />
              </div>
            </div>

            {/* profile section */}
            <div className={styles.topFormSection__right}>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                name="product-img"
                id="product-img"
                onChange={handleMultipleImageUpload}
              />
              <primaryComponents.Button
                classNames={`btn btn--outline ${styles.uploadBtn}`}
                onClick={() => inputRef.current.click()}
                isLoading={isUploadingImage}
                loadingText={"uploading..."}
                primarySpinner={false}
                type="button"
              >
                <img className="mr-1" src={Icon.addCriclePlus} alt="" />
                Add Product Images
              </primaryComponents.Button>
              <div className={styles.imagesContainer}>
                {productImages.length > 0 ? (
                  productImages.map((image, index) => (
                    <div key={index} className={styles.imageDeleteContainer}>
                      <div className={styles.imageContainer}>
                        <img
                          src={
                            image.url
                              ? image.url
                              : image.preview
                                ? image.preview
                                : Images.product_avatar
                          }
                          alt=""
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (image.url) {
                            setProductImages((prev) =>
                              prev.filter((item) => item.id !== image.id),
                            );
                            deleteImage(image.id);
                          } else {
                            setProductImages((prev) =>
                              prev.filter(
                                (item) => item.preview !== image.preview,
                              ),
                            );
                          }
                        }}
                        className={styles.deleteContainer}
                      >
                        <img src={Icon.deleteIcon} alt="" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.imageDeleteContainer}>
                    <div className={styles.imageContainer}>
                      <img src={Images.product_avatar} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`${styles.bottomFormSection} ${styles.bottomFormSectionService}`}
          >
            {storeProperties?.length > 0 ? (
              <>
                {storeProperties.map((item, storeIndex) => (
                  <div className={`${styles.priceCover}`} key={item.store_id}>
                    <div>
                      <h6>{item?.store_name || ""}</h6>
                      <div
                        className={`${styles.priceSection} ${styles.priceSectionBigInput}`}
                      >
                        <primaryComponents.InputFieldV2
                          name={`storeProperties.${storeIndex}.cost_price`}
                          control={control}
                          type="number"
                          label={`Cost Price (${
                            storeList.find(
                              (store) => store?.id === item?.store_id,
                            )?.currency_symbol
                          })`}
                          placeholder="0"
                          classNames="white"
                          isRequired
                          isDisabled={
                            storeIndex > 0 && rootFormDetails?.use_same_pricing
                          }
                          value={storeProperties[storeIndex].cost_price}
                          errorMessage={
                            errors?.storeProperties?.[storeIndex]?.cost_price
                          }
                          onKeyUp={() =>
                            errors?.storeProperties?.[storeIndex]?.cost_price &&
                            trigger(`storeProperties.${storeIndex}.cost_price`)
                          }
                          onChange={({ target: { value } }) => {
                            const newStoreProperties = [...storeProperties];
                            newStoreProperties[storeIndex].cost_price = value;
                            setStoreProperties(newStoreProperties);
                            setIsStorePropertiesUpdated(true);
                          }}
                        />

                        {item?.pricings?.map(
                          (pricingProperty, pricingIndex) => {
                            const pricingDetailsProperty = pricingTypes?.find(
                              (pricingItem) =>
                                pricingItem?.id ===
                                pricingProperty?.pricing_type_id,
                            );

                            return (
                              <div
                                className={`${styles.priceSection} ${styles.priceSectionBigInput} ${globalStyles.borderTopPurple} ${globalStyles.borderTop} mt-0 pt-3`}
                                style={{ minWidth: "100%" }}
                                key={pricingProperty?.uuid}
                              >
                                {/* pricing type */}
                                <div>
                                  <label htmlFor="">
                                    <span className="text-danger">*</span>

                                    <span>
                                      Price Type
                                      {item?.pricings?.length ===
                                      pricingIndex + 1 ? (
                                        <>
                                          {" ( "}
                                          <span
                                            className="purple--text"
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setIsCreatePricing(true)
                                            }
                                          >
                                            Create new
                                          </span>
                                          {" )"}
                                        </>
                                      ) : null}
                                    </span>
                                  </label>
                                  {/* pricing type */}
                                  <secondaryComponents.LoaderHelper
                                    isLoading={isLoadingPricingTypes}
                                    classNames={"mt-2"}
                                  >
                                    <primaryComponents.SelectFieldV2
                                      name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.pricing_type_id`}
                                      control={control}
                                      giveControl={false}
                                      value={{
                                        label: pricingDetailsProperty?.label,
                                        value: pricingDetailsProperty?.id,
                                      }}
                                      options={pricingTypes}
                                      nameKey="label"
                                      idKey="id"
                                      onChange={(value) => {
                                        const newStoreProperties = [
                                          ...storeProperties,
                                        ];
                                        newStoreProperties[storeIndex].pricings[
                                          pricingIndex
                                        ].pricing_type_id = value;
                                        setStoreProperties(newStoreProperties);
                                        setIsStorePropertiesUpdated(true);
                                      }}
                                      errorMessage={
                                        errors?.storeProperties?.[storeIndex]
                                          ?.pricings?.[pricingIndex]
                                          ?.pricing_type_id
                                      }
                                      isDisabled={
                                        (storeIndex > 0 &&
                                          rootFormDetails?.use_same_pricing) ||
                                        pricingIndex === 0
                                      }
                                      isSearchable={false}
                                    />
                                  </secondaryComponents.LoaderHelper>
                                </div>
                                {/* selling price */}
                                <primaryComponents.InputFieldV2
                                  name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.price`}
                                  control={control}
                                  type="number"
                                  step={0.01}
                                  label={`Selling Price (${
                                    storeList.find(
                                      (store) => store?.id === item?.store_id,
                                    )?.currency_symbol
                                  })`}
                                  placeholder="0"
                                  classNames="white"
                                  isRequired
                                  isDisabled={
                                    storeIndex > 0 &&
                                    rootFormDetails?.use_same_pricing
                                  }
                                  value={pricingProperty?.price}
                                  errorMessage={
                                    errors?.storeProperties?.[storeIndex]
                                      ?.pricings?.[pricingIndex]?.price
                                  }
                                  onKeyUp={() =>
                                    errors?.storeProperties?.[storeIndex]
                                      ?.pricings?.[pricingIndex]?.price &&
                                    trigger(
                                      `storeProperties.${storeIndex}.pricings.${pricingIndex}.price`,
                                    )
                                  }
                                  onChange={({ target: { value } }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[storeIndex].pricings[
                                      pricingIndex
                                    ].price = value;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                />

                                {/* discount */}
                                <primaryComponents.PercentField
                                  label="Selling price discount"
                                  name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.discount_rate`}
                                  control={control}
                                  value={pricingProperty?.discount_rate}
                                  isDisabled={
                                    storeIndex > 0 &&
                                    rootFormDetails?.use_same_pricing
                                  }
                                  onChange={({ percentValue }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[storeIndex].pricings[
                                      pricingIndex
                                    ].discount_rate = percentValue;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                  dividedValue={
                                    pricingProperty?.price === ""
                                      ? 0
                                      : pricingProperty?.price
                                  }
                                />

                                {/* discounted selling price */}
                                <primaryComponents.InputField
                                  type="number"
                                  classNames="white"
                                  label={`Discounted selling price (${
                                    storeList.find(
                                      (store) => store?.id === item?.store_id,
                                    )?.currency_symbol
                                  })`}
                                  isDisabled={true}
                                  value={(() => {
                                    let sellingPrice =
                                      pricingProperty?.price === ""
                                        ? 0
                                        : pricingProperty?.price;
                                    let discountPercent =
                                      pricingProperty?.discount_rate === ""
                                        ? 0
                                        : pricingProperty?.discount_rate;
                                    let discount_rate =
                                      (sellingPrice * discountPercent) / 100;

                                    let discountedSellingPrice =
                                      sellingPrice - discount_rate;

                                    return discountedSellingPrice;
                                  })()}
                                />

                                {/* profit */}
                                <primaryComponents.InputField
                                  type="number"
                                  classNames="white"
                                  label={`Profit (${
                                    storeList.find(
                                      (store) => store?.id === item?.store_id,
                                    )?.currency_symbol
                                  })`}
                                  isDisabled={true}
                                  value={(
                                    pricingProperty?.price -
                                    (pricingProperty?.price *
                                      pricingProperty?.discount_rate) /
                                      100 -
                                    item?.cost_price
                                  ).toFixed(2)}
                                />

                                {pricingIndex > 0 ? (
                                  <div
                                    className="d-flex align-items-center my-auto"
                                    style={{
                                      minWidth: "fit-content",
                                      maxWidth: "fit-content",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn--outline-red"
                                      style={{
                                        height: "30px",
                                        width: "28px",
                                        padding: "0",
                                        borderRadius: "3px",
                                      }}
                                      onClick={() =>
                                        removePricingForStoreProperty(
                                          storeIndex,
                                          pricingIndex,
                                        )
                                      }
                                    >
                                      <img src={Icon.deleteIcon} alt="" />
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            );
                          },
                        )}
                      </div>

                      {/* use pricing across */}

                      <div className={styles.useAcrossSection}>
                        <a
                          className="purple--text"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            addNewPricingForStoreProperty(storeIndex)
                          }
                        >
                          + Add another price option
                        </a>
                        {storeIndex === 0 ? (
                          <label
                            className="d-flex align-items-center"
                            style={{ cursor: "pointer" }}
                            htmlFor="useSamePricing"
                          >
                            <primaryComponents.Checkbox
                              name="useSamePricing"
                              id="useSamePricing"
                              isChecked={rootFormDetails?.use_same_pricing}
                              onChange={(value) => {
                                setRootFormDetails((prevState) => ({
                                  ...prevState,
                                  use_same_pricing: value,
                                }));
                                if (value) {
                                  if (!rootFormDetails.has_variant)
                                    setIsStorePropertiesUpdated(true);
                                }
                              }}
                            />
                            <span className="ml-2 mt-1">
                              Use this pricing properties across other stores
                            </span>
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <h5 className="text-center my-4">
                Please select stores to proceed with pricing
              </h5>
            )}
          </div>
        </form>
      </motion.div>
      {/* button section */}
      <div className={styles.buttonSection}>
        <primaryComponents.Button
          classNames={`btn btn--primary ${styles.createBtn}`}
          type="submit"
          form="productForm"
          isLoading={isUpdatingProduct}
          isDisabled={isUpdatingProduct}
          loadingText={isEditMode ? "Updating..." : "Creating..."}
        >
          {isEditMode ? "Update Service" : "Create Service"}
        </primaryComponents.Button>
      </div>
      {/* pricing section */}
      <secondaryComponents.Modal
        isActive={isCreatePricing}
        closeModal={() => setIsCreatePricing(false)}
        width={400}
      >
        <secondaryComponents.CreatePricingType
          closeWidget={() => setIsCreatePricing(false)}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        isActive={modalViews?.isWarning}
        closeModal={() => resetModalViews("isWarning", false)}
        width={500}
      >
        <WarningDialog
          closeModal={() => resetModalViews("isWarning", false)}
          title={"Pricing Warning"}
          handler={createOrUpdateProduct}
        >
          <PricingWarning detectedPricing={detectedPricing} />
        </WarningDialog>
      </secondaryComponents.Modal>
    </>
  );
};
export default SingleServiceForm;
