import "./Dashboard.scss";
import secondaryComponents from "../../components/secondaryComponents";
import { Outlet, useNavigate } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import useDashboardLayout from "./useDashboard";
import { pathConstants } from "../../routes/pathContants";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";
import SecurityQuestionAndPinSetupModal from "../../components/secondaryComponents/SecurityQuestionAndPin/SecurityQuestionAndPinSetupModal";

const DashboardLayout = () => {
  const {
    verification_status,
    openTransferWidget,
    business_role,
    startDate,
    endDate,
    has_security_settings,
    setDateFilter,
    setOpenTransferWidget,
    isSetUpModal,
    setIsSetUpModal,
    isWarningModal,
    setIsWarningModal,
  } = useDashboardLayout();
  const navigate = useNavigate();

  return (
    <>
      <div className={`dashboard ${globalStyles.pagePaddings}`}>
        <div className={`dashboard__content ${globalStyles.pageColumnGaps}`}>
          <div>
            <h4 className="mb-0">Summary</h4>
            <p>Payment Summary: a snapshot of your business performance</p>
          </div>
          <div className="dashboard__content__actions">
            <primaryComponents.DateRangeInput
              startDate={startDate}
              endDate={endDate}
              onChange={setDateFilter}
              classNames=""
            />
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Payments"}
              permissionChildKey="can_request_transfer"
            >
              <primaryComponents.Button
                onClick={() => {
                  if (!has_security_settings) {
                    setIsWarningModal(true);
                  } else {
                    setOpenTransferWidget(true);
                  }
                }}
                classNames="btn btn--primary"
              >
                {business_role !== "OWNER" && business_role !== "ADMIN"
                  ? "Request Transfer"
                  : "Transfer Funds"}
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey={"can_submit_bvn_and_kyb"}
            >
              <primaryComponents.Button
                onClick={() => {
                  if (
                    verification_status !== "pending_review" &&
                    verification_status !== "verified"
                  )
                    navigate(pathConstants.KYB_VERIFICATION);
                }}
                classNames={`btn btn--primary ${
                  verification_status === "pending_review"
                    ? "pending--rounded"
                    : verification_status === "verified"
                      ? "success--rounded"
                      : ""
                }`}
                style={{ borderRadius: "25px", padding: "0 25px" }}
              >
                {verification_status === "unverified" ||
                verification_status === ""
                  ? "Verify business"
                  : verification_status === "pending_review"
                    ? "Verification pending"
                    : "Account verified"}
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <secondaryComponents.SubAccountsList />

          <Outlet />
        </div>
      </div>

      {/* transfer widget */}
      <secondaryComponents.SideModal
        isActive={openTransferWidget}
        closeModal={() => setOpenTransferWidget(false)}
      >
        <secondaryComponents.FundTransfer
          closeWidget={() => setOpenTransferWidget(false)}
        />
      </secondaryComponents.SideModal>

      {/* security pin */}
      <secondaryComponents.Modal
        width={400}
        isActive={isWarningModal}
        closeModal={() => setIsWarningModal(false)}
      >
        <secondaryComponents.setUpModal
          setIsSetUpModal={setIsSetUpModal}
          setIsWarningModal={setIsWarningModal}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        isActive={isSetUpModal}
        width={500}
        closeModal={() => setIsSetUpModal(false)}
      >
        <SecurityQuestionAndPinSetupModal
          setIsSetUpModal={setIsSetUpModal}
          setOpenTransferWidget={setOpenTransferWidget}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default DashboardLayout;
