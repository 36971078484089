import primaryComponents from "../../../primaryComponents";
import CashPaymentLogic from "./CashPaymentLogic";
import { formatCurrency } from "../../../../helpers";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const CashPayment = ({ orderDetails, closePaymentSession }) => {
  const { store_currency } = useStoreCurrency();

  const { isConfirmingPayment, amount, payViaCash } = CashPaymentLogic({
    closePaymentSession,
    orderDetails,
  });

  return (
    <>
      <div className="cash-payment mt-5">
        <primaryComponents.InputField
          label="Please confirm that you have received the cash amount below from your customer"
          placeholder={`${store_currency}`}
          isRequired={true}
          isDisabled={true}
          name="amount"
          value={formatCurrency({
            value: orderDetails?.total_amount
              ? orderDetails?.total_amount
              : amount,
            currencyCode: store_currency,
          })}
          classNames="dark--text font-weight-bold"
        />
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={payViaCash}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default CashPayment;
