import { useSelector } from "react-redux";
import { useGetUserList } from "../../../../hooks/api/queries/useSettings";
import { useContext, useRef, useState } from "react";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader } from "../../../../hooks/context";
import { useMutation } from "react-query";
import { downloadPaymentEndOfDay } from "../../../../services/sales-point";

const useEndOfDayForm = ({ endOfDayFormDetails }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const printLoadingRef = useRef(false);
  const downloadLoadingRef = useRef(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { data: userList } = useGetUserList();
  const [isEODType, setEODType] = useState("Inventory");

  const { mutate: paymentEndOfDay, isLoading } = useMutation((data) =>
    downloadPaymentEndOfDay(data),
  );

  const downloadPaymentEOD = ({ delivery_method }) => {
    if (delivery_method === "download") {
      downloadLoadingRef.current = true;
    } else if (delivery_method === "print") {
      printLoadingRef.current = true;
    }
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    paymentEndOfDay(
      cleanObject({
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        payment_method: endOfDayFormDetails?.payment_method?.map(
          (item) => item?.value,
        ),
        sub_account_id: endOfDayFormDetails?.sub_account,
      }),
      {
        onSuccess: (res) => {
          const receiptLink = res?.data?.data?.url;
          if (receiptLink) return window.open(receiptLink, "_blank");
        },
        onSettled: () => {
          if (delivery_method === "download") {
            downloadLoadingRef.current = false;
          } else if (delivery_method === "print") {
            printLoadingRef.current = false;
          }
          setRequestLoaderProgress(100);
        },
      },
    );
  };

  return {
    storeList,
    userList,
    isEODType,
    setEODType,
    business_list,
    printLoadingRef,
    downloadLoadingRef,
    downloadPaymentEOD,
    isLoading,
  };
};
export default useEndOfDayForm;
