import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { VerificationIcon } from "../../../../assets/svgIcons/VerificationIcon";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";

const SetUpModal = ({ setIsWarningModal, setIsSetUpModal }) => {
  return (
    <>
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div className="align-items-center text-center">
            <VerificationIcon />
            <p className="small-text-size text-dark">Security Update</p>
          </div>
          <div
            className={`${globalStyles.cardWithBorder}`}
            style={{ gap: "16px" }}
          >
            <div>
              <h4>Security PIN and Security Question</h4>
            </div>

            <p className="small-text-size">
              To strengthen the security of your data and finances, we have
              implemented mandatory security measures to prevent unauthorized
              transactions and access. This includes two-factor authentication
              (2FA) for updating sensitive credentials. These enhancements are
              designed to protect your operations and give you greater peace of
              mind while using our platform.
            </p>
          </div>
          <primaryComponents.Button
            onClick={() => {
              setIsWarningModal(false);
              setIsSetUpModal(true);
            }}
            classNames={"btn btn--primary cover"}
          >
            Set up
          </primaryComponents.Button>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </>
  );
};

export default SetUpModal;
