import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./AllSections.module.scss";
import Images from "../../../assets/images";
import useAllSection from "./useAllSection";
import primaryComponents from "../../../components/primaryComponents";
import { pathConstants } from "../../../routes/pathContants";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

const AllSections = () => {
  const { sections, isfetchingSections } = useAllSection();
  return (
    <secondaryComponents.LoaderHelper
      classNames={"mt-4"}
      isLoading={isfetchingSections}
    >
      <secondaryComponents.MetaSetter
        title="Lumi Merchant | Section"
        description="View all sections"
      >
        <secondaryComponents.RequestLoaderTrigger>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.3 },
            }}
            exit={{ opacity: 0 }}
            className={`${styles.allKitchens} ${globalStyles.pageColumnGaps}`}
          >
            <div>
              <h4 className="mb-0">Sections</h4>
              <p>Choose your section to manage order</p>
            </div>
            {sections?.length > 0 ? (
              <div className={styles.kitchenList}>
                {sections?.map((section) => (
                  <div className={styles.kitchenList__item} key={section?.id}>
                    <img src={Images.section} alt={section?.name} />
                    <h4 className="font-weight-normal">{section?.name}</h4>
                    <primaryComponents.Button
                      onClick={() =>
                        window.open(
                          pathConstants.SECTION_MODE({
                            section_id: section?.id,
                          }),
                          "_blank",
                        )
                      }
                      classNames="btn btn--primary"
                    >
                      View Section
                    </primaryComponents.Button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mt-5">
                <img
                  width="320px"
                  height="262px"
                  src={Images.sectionStaffEmpty}
                  alt="empty state"
                />
                <h4>No Sections available </h4>
                <p>
                  Meet your manager or admin to create sections for your tasks.
                </p>
              </div>
            )}
          </motion.div>
        </secondaryComponents.RequestLoaderTrigger>
      </secondaryComponents.MetaSetter>
    </secondaryComponents.LoaderHelper>
  );
};
export default AllSections;
