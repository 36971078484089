import primaryComponents from "../../primaryComponents";
import ProfileBusinessInfoFormLogic from "./ProfileBusinessInfoFormLogic";
import ProfileBusinessInfoFormValidation from "./ProfileBusinessInfoFormValidation";
import localData from "../../../localdata";
import { useEffect, useState } from "react";

const ProfileBusinessInfoForm = ({ businessLogo }) => {
  const [logo, setLogo] = useState(businessLogo);
  const {
    isSubmitting,
    isEdited,
    formData,
    setIsEdited,
    updateBusinessInfo,
    setFormData,
  } = ProfileBusinessInfoFormLogic({ businessLogo });

  const {
    register,
    handleSubmit,
    setError,
    control,
    trigger,
    formState: { errors },
  } = ProfileBusinessInfoFormValidation({ ...formData });

  useEffect(() => {
    window.setBusinessInfoError = setError;
  }, []);

  useEffect(() => {
    if (businessLogo !== logo) {
      updateBusinessInfo({ isLogo: true });
      setLogo(businessLogo);
    }
  }, [businessLogo]);

  return (
    <form
      onSubmit={handleSubmit(() => {
        updateBusinessInfo({ isLogo: false });
      })}
      className="mt-4 business-info__form"
    >
      <primaryComponents.InputFieldV2
        control={control}
        label="Business Name"
        placeholder="Enter your business name"
        isRequired={true}
        type="text"
        name="name"
        value={formData?.name}
        errorMessage={errors.name}
        onChange={({ target: { value } }) => {
          setFormData((prev) => ({ ...prev, name: value }));
          setIsEdited(true);
        }}
        onKeyUp={() => errors.name !== undefined && trigger("name")}
        register={register}
      />

      <div className="mt-1">
        <primaryComponents.SelectFieldV2
          control={control}
          name="size"
          label="Business size"
          isRequired={true}
          placeholder="Enter business size"
          value={formData?.size}
          options={localData.businessSizeOptions}
          errorMessage={errors.size}
          onChange={(value) => {
            setFormData((prev) => ({ ...prev, size: value }));
            setIsEdited(true);
          }}
        />
      </div>

      {/* business email */}
      <div className="mt-1">
        <primaryComponents.InputFieldV2
          control={control}
          label="Business Email Address"
          type="email"
          isRequired={true}
          placeholder="Enter business email address"
          name="business_email"
          value={formData?.business_email}
          errorMessage={errors.business_email}
          register={register}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, business_email: value }));
            setIsEdited(true);
          }}
          onKeyUp={() =>
            errors.business_email !== undefined && trigger("business_email")
          }
        />
      </div>
      {/* support email */}
      <div className="mt-1">
        <primaryComponents.InputFieldV2
          control={control}
          label="Support/Chargeback Email Address"
          type="email"
          isRequired={false}
          placeholder="Enter support email address"
          name="support_email"
          value={formData?.support_email}
          errorMessage={errors.support_email}
          register={register}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, support_email: value }));
            setIsEdited(true);
          }}
          onKeyUp={() =>
            errors.support_email !== undefined && trigger("support_email")
          }
        />
      </div>
      <div className="mt-1">
        <primaryComponents.PhoneNumberInputFieldV2
          control={control}
          label="Business Phone Number"
          isRequired={false}
          placeholder="Enter business phone number"
          name="business_phone_number"
          value={formData?.business_phone_number}
          errorMessage={errors.business_phone_number}
          register={register}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, business_phone_number: value }));
            setIsEdited(true);
          }}
          onKeyUp={() =>
            errors.business_phone_number !== undefined &&
            trigger("business_phone_number")
          }
        />
      </div>
      <div className="mt-1">
        <primaryComponents.PhoneNumberInputFieldV2
          control={control}
          label="Support Phone Number"
          isRequired={false}
          placeholder="Enter business phone number"
          name="support_phone_number"
          value={formData?.support_phone_number}
          errorMessage={errors.support_phone_number}
          register={register}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, support_phone_number: value }));
            setIsEdited(true);
          }}
          onKeyUp={() =>
            errors.support_phone_number !== undefined &&
            trigger("support_phone_number")
          }
        />
      </div>
      <div className="mt-1">
        <primaryComponents.InputFieldV2
          control={control}
          label="Business Address"
          isRequired={false}
          placeholder="Enter your business address"
          name="address"
          value={formData?.address}
          errorMessage={errors.address}
          register={register}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, address: value }));
            setIsEdited(true);
          }}
          onKeyUp={() => errors.address !== undefined && trigger("address")}
        />
      </div>
      <div className="d-flex justify-content-end">
        <primaryComponents.Button
          isDisabled={!isEdited || isSubmitting}
          isLoading={isSubmitting}
          loadingText="Saving..."
          classNames={"btn btn--primary"}
          type="submit"
        >
          Save Changes
        </primaryComponents.Button>
      </div>
    </form>
  );
};

export default ProfileBusinessInfoForm;
