import React from "react";

export const ArrowUp = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2426 3.75781V10.3575M3.75732 12.2431L12.2426 3.75781L3.75732 12.2431ZM12.2426 3.75781L5.64293 3.75782L12.2426 3.75781Z"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
