import { useContext, useState } from "react";
import primaryComponents from "../../primaryComponents";
import { ToastContext } from "../../../hooks/context";
import { formatNumberWithCommas } from "../../../helpers";
import { useOtpAuthTrigger } from "../../../hooks/useOtpAuthTrigger";

const Discount = ({
  setDiscount,
  discount,
  currency_symbol,
  closeModal,
  totalAmount,
  storeId,
  params = {},
}) => {
  const triggerToast = useContext(ToastContext);
  const [discountVal, setDiscountVal] = useState(discount);
  const { handleOrderModuleAuth, isLoadingAuthAction } = useOtpAuthTrigger({
    storeId,
    params,
  });
  const [decimalVal, setDecimalVal] = useState(
    (Number(discountVal) / Number(totalAmount)) * 100,
  );
  const [isAmountType, setIsAmountType] = useState(true);

  return (
    <>
      <div
        className="p-3"
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <h4>Apply Discount</h4>

        <div className="d-flex my-1" style={{ gap: "10px" }}>
          <primaryComponents.BoxedRadioInput
            name={"valueType"}
            id="DISCOUNT_AMOUNT"
            isChecked={isAmountType}
            setRadioValue={() => {
              setIsAmountType(true);
            }}
          >
            Amount
          </primaryComponents.BoxedRadioInput>
          <primaryComponents.BoxedRadioInput
            name={"valueType"}
            id="DISCOUNT_PERCENT"
            isChecked={!isAmountType}
            setRadioValue={() => {
              setIsAmountType(false);
            }}
          >
            Percentage
          </primaryComponents.BoxedRadioInput>
        </div>

        <div
          style={{
            border: "1px solid #ddd",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <primaryComponents.InputField
            label={isAmountType ? "Amount" : "Percentage"}
            classNames="white"
            placeholder={
              isAmountType
                ? `Enter discount amount e.g. ${currency_symbol}1000`
                : "Enter discount (%)"
            }
            isRequired={false}
            value={isAmountType ? discountVal : decimalVal}
            type="number"
            min="0"
            name="discount"
            floatError={false}
            onChange={({ target: { value } }) => {
              const realValue = value;
              value = `${value}`;
              const [integerPart, decimalPart] = value?.split(".");
              value =
                decimalPart || value?.includes(".")
                  ? `${integerPart}.${decimalPart || 0}`
                  : integerPart;

              if (isAmountType) {
                setDiscountVal(realValue);
                setDecimalVal((value / totalAmount) * 100);
              } else {
                setDecimalVal(realValue);
                setDiscountVal((value * totalAmount) / 100);
              }
            }}
          />
          <span className="purple--text small-text-size">
            Amount: {`${currency_symbol}${formatNumberWithCommas(discountVal)}`}
          </span>
        </div>

        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          onClick={() => {
            if (Number(totalAmount) < Number(discountVal))
              return triggerToast(
                "Discount cannot be more than the total price of items",
              );

            handleOrderModuleAuth({
              actionKey: "add_discount_to_order",
              onSuccessCallBack: () => {
                const floatValue =
                  discountVal === "" ? discountVal : Number(discountVal);
                setDiscount(floatValue);
                closeModal();
              },
            });
          }}
          isLoading={isLoadingAuthAction}
          isDisabled={isLoadingAuthAction}
        >
          Apply
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default Discount;
