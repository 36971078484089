import { useEffect, useState } from "react";
import { useGetSectionOrders } from "../../../hooks/api/queries/useSection";
import { useParams, useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { formatDateYYYYMMDD } from "../../../helpers";

const useStaffSection = () => {
  const { section_id } = useParams();
  const [search, setSearch] = useSearchParams();
  const { ref: autoScrollRef, inView } = useInView();
  const [source, setSource] = useState();

  const queries = (() => {
    let params = `?section_id=${section_id}`;
    for (const entry of search.entries()) {
      params = `${params}&${entry[0]}=${entry[1]}`;
    }
    return params;
  })();

  useEffect(() => {
    if (!search.has("status")) {
      setSearch(
        new URLSearchParams({
          status: "RECEIVED",
          start_date: formatDateYYYYMMDD(new Date()),
          end_date: formatDateYYYYMMDD(new Date()),
        }),
        { replace: true },
      );
    }
  }, []);

  const {
    isLoading: isfetchingOrders,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetSectionOrders(queries);

  const sectionItems = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    sectionItems.set(`${page.meta.current_page}`, page.data);
  });
  const sections = [].concat(...sectionItems.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  const setDateFilter = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const setSourceFilter = (value) => {
    if (value === "ALL") search.delete("order_source");
    else search.set("order_source", value || source);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const options = [
    { name: "All", id: "ALL" },
    { id: "INTERNAL", name: "Point of Sales" },
    { id: "STOREFRONT", name: "Online Orders" },
  ];

  useEffect(() => {
    const savedSource = localStorage.getItem("selectedOrderSource");
    if (savedSource) {
      setSource(savedSource);
    }
  }, []);

  return {
    isfetchingOrders,
    isFetchingNextPage,
    search,
    autoScrollRef,
    sectionOrders: sections || [],
    setDateFilter,
    setSourceFilter,
    options,
    source,
    setSource,
  };
};

export default useStaffSection;
