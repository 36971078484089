import React from "react";

export const CardPaymentIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 6.75L6.25 24.25C6.25 25.6307 7.36929 26.75 8.75 26.75L21.25 26.75C22.6307 26.75 23.75 25.6307 23.75 24.25L23.75 6.75C23.75 5.36928 22.6307 4.25 21.25 4.25L8.75 4.25C7.36929 4.25 6.25 5.36929 6.25 6.75Z"
        fill="white"
        stroke="#15112D"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 13.8464C13.986 14.2871 13.75 14.866 13.75 15.5C13.75 16.8807 14.8693 18 16.25 18C17.6307 18 18.75 16.8807 18.75 15.5C18.75 14.866 18.514 14.2871 18.125 13.8464"
        stroke="#FD561F"
        strokeWidth="0.8125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 11.749C18.75 10.3683 17.6307 9.24902 16.25 9.24902C14.8693 9.24902 13.75 10.3683 13.75 11.749C13.75 13.1297 14.8693 14.249 16.25 14.249C17.6307 14.249 18.75 13.1297 18.75 11.749Z"
        stroke="#F7AD3C"
        strokeWidth="0.8125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
