import { useMutation } from "react-query";
import {
  createBank,
  createBankCredit,
  createBankDebit,
  createFundsDeposit,
  createFundsTransfer,
  createFundsWithdraw,
  removeBank,
  updateBank,
} from "../../../services/accounting";

export const useCreateBankMutation = () => {
  return useMutation((data) => createBank(data));
};

export const useEditBankMutation = () => {
  return useMutation((data) => updateBank(data));
};

export const useDeleteBankMutation = () => {
  return useMutation((data) => removeBank(data));
};

export const useCreateBankCreditMutation = () => {
  return useMutation((data) => createBankCredit(data));
};

export const useCreateBankDebitMutation = () => {
  return useMutation((data) => createBankDebit(data));
};

export const useFundsDepositMutation = () => {
  return useMutation((data) => createFundsDeposit(data));
};

export const useFundsTransferMutation = () => {
  return useMutation((data) => createFundsTransfer(data));
};

export const useFundsWithdrawMutation = () => {
  return useMutation((data) => createFundsWithdraw(data));
};
