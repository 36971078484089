import React from "react";

export const WithdrawIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66675 5.83333C1.66675 5.3731 2.03985 5 2.50008 5H17.5001C17.9603 5 18.3334 5.3731 18.3334 5.83333V14.1667C18.3334 14.6269 17.9603 15 17.5001 15H2.50008C2.03985 15 1.66675 14.6269 1.66675 14.1667V5.83333Z"
        stroke="#0B0C0E"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99992 11.6667C10.9204 11.6667 11.6666 10.9205 11.6666 10C11.6666 9.07957 10.9204 8.33337 9.99992 8.33337C9.07944 8.33337 8.33325 9.07957 8.33325 10C8.33325 10.9205 9.07944 11.6667 9.99992 11.6667Z"
        stroke="#0B0C0E"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.01342 10.0134L5 10"
        stroke="#0B0C0E"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0134 10.0134L15 10"
        stroke="#0B0C0E"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
