import { useEffect, useState } from "react";
import {
  useGetCustomerStoreWallets,
  useGetStoreWalletDetails,
  useGetStoreWalletTransaction,
} from "../../../../../hooks/api/queries";
import { useParams, useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useUrlSearch } from "../../../../../hooks/useUrlSearch";

const useStoreWalletsDetails = () => {
  const params = useParams();
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [searchParams] = useSearchParams();
  const [isFundWallet, setIsFundWallet] = useState(null);
  const [isDebitWallet, setIsDebitWallet] = useState(null);
  const [isDownloadStatement, setIsDownloadStatement] = useState(false);
  const [isAddSubWallet, setIsAddSubWallet] = useState(null);

  const hasRequiredParams =
    searchParams.has("store_wallet_id") || searchParams.has("store_wallet_id");

  const selectedWalletId = search.get("store_wallet_id") || params?.id;
  const [walletId, setWalletId] = useState(selectedWalletId);

  const { data: storeWalletDetails, isLoading: isLoadingStoreWalletDetails } =
    useGetStoreWalletDetails(walletId);

  const { data, isLoading: isLoadingStoreWalletTnx } =
    useGetStoreWalletTransaction({ queries });

  const { data: storeWallets, isLoading: isLoadingStoreWallets } =
    useGetCustomerStoreWallets({
      customer_id: storeWalletDetails?.customer?.id,
    });

  const { gotoPage } = useUrlSearch();

  useEffect(() => {
    if (storeWalletDetails) {
      search.set("customer_id", storeWalletDetails?.customer?.id);
      setSearch(search, { replace: true });
    }
  }, [storeWalletDetails, search, setSearch]);

  useEffect(() => {
    setWalletId(search.get("store_wallet_id") || params?.id);
  }, [params?.id, search]);

  const filterByWalletId = (value) => {
    if (value !== "ALL") {
      search.set("store_wallet_id", value);
    } else {
      search.delete("store_wallet_id");
    }
    setSearch(search, { replace: true });
  };

  return {
    storeWalletDetails,
    isLoadingStoreWalletDetails,
    isLoadingStoreWalletTnx,
    storeWalletTransactions: data?.data || [],
    metaDetails: data?.meta || {},
    isFundWallet,
    isDebitWallet,
    isDownloadStatement,
    setIsDownloadStatement,
    setIsDebitWallet,
    setIsFundWallet,
    gotoPage,
    isAddSubWallet,
    setIsAddSubWallet,
    filterByWalletId,
    storeWallets,
    isLoadingStoreWallets,
    hasRequiredParams,
    setWalletId,
  };
};

export default useStoreWalletsDetails;
