import "./AddCustomerWidget.scss";
import primaryComponents from "../../primaryComponents";
import { Link } from "react-router-dom";
import Icon from "../../../assets/icons";
import { useState } from "react";
import secondaryComponents from "..";
import { UserDangerIcon, UserSuccessIcon } from "../../../assets/svgIcons";

const AddCustomerWidgetModal = ({
  closeModal,
  customers,
  handleFetchCustomers,
  isEditMode,
}) => {
  const [isCreateCustomer, setIsCreateCustomer] = useState(false);
  const [isCreateMultipleCustomer, setIsCreateMultipleCustomer] =
    useState(false);
  return (
    <>
      <div className="add-product-widget">
        <div>
          <h5 className="font-weight-semibold">Add New Customers</h5>
          <p className="smallest-text-size">Add new customers to your stores</p>
        </div>
        <Link
          className="add-product-widget__item"
          onClick={() => {
            setIsCreateCustomer(true);
          }}
        >
          <div className="icon orange">
            <UserDangerIcon />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">Single Customer</p>
            <p className="smallest-text-size">Create a new customer record</p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <Link
          className="add-product-widget__item"
          onClick={() => {
            setIsCreateMultipleCustomer(true);
          }}
        >
          <div className="icon green">
            <UserSuccessIcon />
          </div>
          <div>
            <p className="font-weight-semibold dark--text">
              Bulk Upload Customers
            </p>
            <p className="smallest-text-size">
              Migrate all your customers into Lumi Business with .CSV bulk
              upload
            </p>
          </div>
          <img className="ml-auto" src={Icon.chevron_right_arrow} alt="" />
        </Link>
        <div className="add-product-widget__action">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={closeModal}
          >
            Cancel
          </primaryComponents.Button>
        </div>
      </div>

      {/* single upload */}
      <secondaryComponents.Modal
        isActive={isCreateCustomer}
        closeModal={() => {
          setIsCreateCustomer(false);
        }}
        width={650}
      >
        <secondaryComponents.CustomerForm
          closeWidget={() => setIsCreateCustomer(false)}
          customerInfo={customers}
          closeModal={closeModal}
          isEditMode={isEditMode}
          handleFetchCustomers={handleFetchCustomers}
        />
      </secondaryComponents.Modal>

      {/* batch upload */}
      <secondaryComponents.Modal
        isActive={isCreateMultipleCustomer}
        closeModal={() => {
          setIsCreateMultipleCustomer(false);
        }}
        width={700}
      >
        <secondaryComponents.AddMultipleCustomers
          closeWidget={() => setIsCreateMultipleCustomer(false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default AddCustomerWidgetModal;
