/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./SectionCard.module.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "..";
import { SectionCardLogic } from "./SectionCardLogic";
import { truncateString } from "../../../helpers";
import React from "react";
import { SectionView } from "../../printViews";
import TransactionDetailsV2 from "../StoreCheckout/TransactionDetailsV2";
import PrintTypeOption from "../StoreCheckout/PrintTypeOption";
import ReceiptTerminalsV2 from "../StoreCheckout/ReceiptTerminalsV2";
import { useQueryClient } from "react-query";

const SectionCard = ({ sectionOrder, autoScrollRef }) => {
  const {
    sectionDetails,
    orderId,
    isEnabledPrint,
    isPrintModal,
    isTerminal,
    setIsTerminal,
    setIsPrintModal,
    setIsEnabledPrint,
    setOrderId,
    setSectionDetails,
  } = SectionCardLogic();
  const queryClient = useQueryClient();
  const sectionStatus = sectionOrder?.status;

  const invalidateQueries = () => {
    queryClient.invalidateQueries(["sectionOrders"]);
  };

  return (
    <>
      <div className={styles.sectionCard} ref={autoScrollRef}>
        {/* Header */}
        <div
          className={`${styles.sectionCard__header} ${
            sectionStatus === "RECEIVED"
              ? styles.received
              : sectionStatus === "IN_PROGRESS"
                ? styles.progress
                : sectionStatus === "COMPLETED"
                  ? styles.completed
                  : ""
          }`}
        >
          <h1 className="mb-0 mr-2"> {sectionOrder?.order?.queue_number}</h1>
          <div>
            <p className="small-text-size">Order Number: </p>
            <a
              className="purple--text small-text-size"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => setOrderId(sectionOrder?.order?.id)}
            >
              {sectionOrder?.order?.id}
            </a>
          </div>

          <p className="smallest-text-size pending--rounded ml-auto">
            {(sectionOrder?.allow_order_type ||
              sectionOrder?.order?.order_source === "INTERNAL") &&
              sectionOrder?.order?.order_type?.replace("_", " ")}{" "}
            (
            {sectionOrder?.order?.order_source === "INTERNAL"
              ? "SELL"
              : "ONLINE"}
            )
          </p>
        </div>

        <div className={styles.sectionCard__mobile}>
          {/* Table */}
          <div className="d-flex justify-content-between">
            <p>Table : </p>

            <p className="dark--text">
              {sectionOrder?.order?.table?.id || "N/A"}
            </p>
          </div>

          {/* Order */}
          <div>
            <p>
              Orders
              {sectionOrder?.docket_items?.find((item) => item.has_reduced) ? (
                <span
                  className="ml-1 failed--text smallest-text-size"
                  style={{ padding: "5px 10px" }}
                >
                  QTY Updated
                </span>
              ) : null}
              :{" "}
            </p>

            <p>
              {sectionOrder?.docket_items?.map((item, index) => (
                <React.Fragment key={item?.id}>
                  {" "}
                  {item?.quantity} x{" "}
                  <span className="dark--text">
                    {truncateString(item?.cart_item?.cart_item_name, 20)}{" "}
                  </span>
                  {index === sectionOrder?.docket_items?.length - 1 ? "" : ","}
                </React.Fragment>
              ))}
            </p>
          </div>

          {/* Notes */}
          <div>
            <p>Notes:</p>
            <p className="small-text-size">
              {truncateString(sectionOrder?.order?.notes, 150) || "N/A"}
            </p>
          </div>
        </div>

        {/* Button */}
        <div
          className="d-flex justify-content-center mt-auto"
          style={{ gap: "10px" }}
        >
          {sectionStatus === "RECEIVED" || sectionStatus === "IN_PROGRESS" ? (
            <>
              <primaryComponents.Button
                classNames="btn btn--outline cover"
                onClick={() => setIsPrintModal(true)}
                loadingText={"Proccessing..."}
              >
                {sectionOrder?.is_printed ? "Reprint" : "Print"}
              </primaryComponents.Button>
              <primaryComponents.Button
                onClick={() => {
                  // setIsPrintAction(true);
                  setSectionDetails(sectionOrder);
                }}
                classNames="btn btn--primary cover"
                // isLoading={isPrintAction}
              >
                {sectionStatus === "RECEIVED" ? "Start" : "Mark as ready"}
              </primaryComponents.Button>
            </>
          ) : null}

          {sectionStatus === "COMPLETED" || sectionStatus === "CANCELLED" ? (
            <primaryComponents.Button
              classNames="btn btn--primary"
              style={{ width: "180px" }}
              onClick={() => setSectionDetails(sectionOrder)}
            >
              View Order
            </primaryComponents.Button>
          ) : null}
        </div>
      </div>

      {/* Receipt widget */}
      <secondaryComponents.SideModal
        isActive={sectionDetails !== null}
        width="500px"
        closeModal={() => setSectionDetails(null)}
      >
        <secondaryComponents.SectionDetailsWidget
          closeModal={() => setSectionDetails(null)}
          sectionDetails={sectionDetails}
        />
      </secondaryComponents.SideModal>

      {/* orderflow */}
      <secondaryComponents.SideModal
        isActive={orderId !== null}
        width="1000px"
        closeModal={() => setOrderId(null)}
      >
        <TransactionDetailsV2
          orderId={orderId}
          showActionButtons={false}
          isOrderFlow={false}
          closeModal={() => setOrderId(null)}
        />
      </secondaryComponents.SideModal>

      {/* print view */}
      {isEnabledPrint ? (
        <SectionView
          section_id={sectionOrder?.id}
          allowPrint={isEnabledPrint}
          setAllowPrint={setIsEnabledPrint}
          isDocket={true}
          invalidateQueries={invalidateQueries}
        />
      ) : null}

      <secondaryComponents.SideModal
        width={500}
        isActive={isTerminal}
        closeModal={() => setIsTerminal(false)}
      >
        <ReceiptTerminalsV2
          isOrderFlow={false}
          sectionId={sectionOrder?.id}
          closeModal={() => setIsTerminal(false)}
        />
      </secondaryComponents.SideModal>

      <secondaryComponents.Modal
        width={400}
        isActive={isPrintModal}
        closeModal={() => setIsPrintModal(false)}
      >
        <PrintTypeOption
          printOnDeviceAction={() => setIsEnabledPrint(true)}
          printOnDevicePOS={() => setIsTerminal(true)}
          closeModal={() => setIsPrintModal(false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default SectionCard;
