import { useState, useContext } from "react";
import { useGetSecurityQuestions } from "../../../../hooks/api/queries/useSettings";
import {
  getProfile,
  setUpSecurityPinAndQuestion,
} from "../../../../services/settings";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import { useMutation } from "react-query";
import { setProfileDetails } from "../../../../store/modules/profile";
import { useDispatch } from "react-redux";

const useLogic = ({ openModal, setOpenTransferWidget, setIsSetUpModal }) => {
  const [isUpModal, setIsUpModal] = useState(false);
  const [answer, setAnswer] = useState("");
  const [questionId, setQuestionId] = useState("");
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();

  const { data: securityQuestions, isLoadingSecurityQuestions } =
    useGetSecurityQuestions();

  const {
    mutate: sendSecurityPinAndAnswer,
    isLoading: isSendingSecurityPinAndAnswer,
  } = useMutation((data) => setUpSecurityPinAndQuestion(data));

  const handleSendSecurityPinAndAnswer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({
      security_pin: pin,
      security_question_id: questionId,
      security_answer: answer,
    });

    sendSecurityPinAndAnswer(data, {
      onSuccess: () => {
        getProfile().then((response) => {
          dispatch(setProfileDetails(response.data.data));
        });
        triggerToast(
          "Your Security PIN & Security Question & Answer has been setup successfully",
          "success",
        );
        openModal("openTransferWidget");
        setOpenTransferWidget(false);
        setIsSetUpModal(false);
      },
      onError: () => {
        setIsSetUpModal(false);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    isUpModal,
    setIsUpModal,
    answer,
    setAnswer,
    pin,
    setPin,
    setQuestionId,
    questionId,
    securityQuestions,
    isLoadingSecurityQuestions,
    handleSendSecurityPinAndAnswer,
    isSendingSecurityPinAndAnswer,
  };
};
export default useLogic;
