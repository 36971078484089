import httpClient from "../axiosBase/axiosHandler";

export const getMetacareAnalytics = () => {
  return httpClient.get("/metabase-analytics/");
};

export const getMetacareAnalyticsDetails = (id) => {
  return httpClient.get(`/metabase-analytics/${id}`);
};

export const getProfitAndLostData = (queries) => {
  return httpClient.get(`/metrics/profit-lost/${queries}`);
};

export const getAccountingBanksList = (queries) => {
  return httpClient.get(`/ledger-accounts/${queries}`);
};

export const getAccountingBanksListWithSpreadFilter = (
  queryParams = {},
  removeLedgerFilter = true,
) => {
  const newQueryParams = { ...queryParams };
  if (removeLedgerFilter) delete newQueryParams["ledger_account_id"];
  return httpClient.get("/ledger-accounts/", { params: { ...newQueryParams } });
};

export const getBankDetails = (bankId) => {
  return httpClient.get(`/ledger-accounts/${bankId}/`);
};

export const createBank = (data) => {
  return httpClient.post("/ledger-accounts/", { ...data });
};

export const updateBank = ({ id, data }) => {
  return httpClient.patch(`/ledger-accounts/${id}/`, { ...data });
};

export const removeBank = ({ id }) => {
  return httpClient.delete(`/ledger-accounts/${id}/`);
};

export const getBankTransactions = (queries) => {
  return httpClient.get(
    `/ledger-accounts/ledger_account_transaction_list/${queries}`,
  );
};

export const createBankCredit = (data) => {
  return httpClient.post("/ledger-accounts/enter_credit/", { ...data });
};

export const createBankDebit = (data) => {
  return httpClient.post("/ledger-accounts/enter_debit/", { ...data });
};

export const createFundsDeposit = (data) => {
  return httpClient.post("/ledger-accounts/deposit/", { ...data });
};

export const createFundsWithdraw = (data) => {
  return httpClient.post("/ledger-accounts/withdraw/", { ...data });
};

export const createFundsTransfer = (data) => {
  return httpClient.post("/ledger-accounts/transfer/", { ...data });
};

export const getBankList = () => {
  return httpClient.get("/ledger-accounts/list_bank_accounts/");
};

export const getLedgerAccountList = () => {
  return httpClient.get("/ledger-accounts/list_cash_on_hand_accounts/");
};
