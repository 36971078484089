import styles from "./CustomerDetails.module.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import useCustomerDetails from "./useCustomerDetails";
import {
  formatCurrency,
  convertToTilteCase,
  formatDate,
  formatNumberWithCommas,
} from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../../assets/icons";
import { pathConstants } from "../../../../routes/pathContants";
import { setToDateMonthYearInWords } from "../../../../helpers";
import SendCreditReminderWidget from "../../../../components/secondaryComponents/SendCreditReminderWidget";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
import { CreditWalletIcon, StoreWalletIcon } from "../../../../assets/svgIcons";

const CustomerDetails = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const {
    sendMessage,
    showEditModal,
    showUpdateCreditLimit,
    customerDetails,
    isFetchingCustomerDetails,
    customerLoyaltyActivities,
    customerLoyaltyActivitiesMetaDetails,
    modals,
    tableTab,
    selectedTableTab,
    storeWalletTransactions,
    storeWalletTnxMetaDetails,
    isLoadingStoreWallets,
    orders,
    orderMetails,
    isLoadingOrderData,
    setSelectedTableTab,
    resetModals,
    toggleCustomerLoyalty,
    gotoPage,
    setShowUpdateCreditLimit,
    setShowEditModal,
    setSendMessage,
  } = useCustomerDetails(customerId);

  const store_currency = customerDetails?.store?.currency_code;
  const storeWalletDetails = customerDetails?.store_wallet?.[0];
  const store_wallet_currency = storeWalletDetails?.parent_store?.currency_code;

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customer Details"
      description="View the details of a customer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`${globalStyles.pageColumnGaps}`}
          >
            <Link
              to={pathConstants.CUSTOMERS}
              style={{ textDecoration: "none" }}
            >
              <primaryComponents.Button classNames="btn btn--outline">
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
            </Link>

            <secondaryComponents.LoaderHelper
              isLoading={isFetchingCustomerDetails}
              classNames="mt-5"
            >
              <div className={globalStyles.pageHeaders}>
                <div>
                  <h4 className="font-weight-semibold">
                    {customerDetails?.full_name
                      ? convertToTilteCase(customerDetails?.full_name)
                      : ""}
                  </h4>
                  <p>
                    Joined{" "}
                    {setToDateMonthYearInWords(customerDetails?.created_at)}
                  </p>
                </div>
                <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
                  <primaryComponents.Button
                    onClick={() => setSendMessage(true)}
                    classNames="btn btn--outline p-3"
                  >
                    <img src={Icon.email} alt="" />
                  </primaryComponents.Button>
                  <primaryComponents.Button classNames="btn btn--outline">
                    <span className="mr-3">Enable Loyalty</span>
                    <primaryComponents.Switch
                      name={"loyalty"}
                      id={customerId}
                      isChecked={customerDetails?.is_loyalty_eligible}
                      onChange={() => toggleCustomerLoyalty(customerId)}
                    />
                  </primaryComponents.Button>
                  <primaryComponents.Button
                    onClick={() => setShowEditModal(true)}
                    classNames="btn btn--primary"
                  >
                    Update Customer
                  </primaryComponents.Button>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_create_store_wallet"
                  >
                    <primaryComponents.Button
                      onClick={() => resetModals("isCreateWallet")}
                      classNames="btn btn--success"
                    >
                      Create Wallet
                    </primaryComponents.Button>
                  </secondaryComponents.NavigateWithPermission>
                </div>
              </div>

              <div className={styles.customerCard}>
                <div>
                  <p>Customer&apos;s Interaction</p>

                  <div
                    className="d-flex align-items-center ml-auto"
                    style={{ gap: "20px", width: "fit-content" }}
                  >
                    {storeWalletDetails ? (
                      <Link
                        to={pathConstants.INVENTORY_STORE_WALLET_DETAILS({
                          id: storeWalletDetails?.id,
                        })}
                        className="purple--text d-flex align-items-center small-text-size"
                        style={{ textDecoration: "none", gap: "10px" }}
                      >
                        <StoreWalletIcon />
                        Manage Store Wallet
                      </Link>
                    ) : null}
                    <span
                      style={{
                        height: "25px",
                        width: "1px",
                        background: "#ddd",
                      }}
                    ></span>
                    <Link
                      to={pathConstants.CREDIT_LOG_DETAILS({
                        id: customerDetails?.id,
                      })}
                      className="purple--text d-flex align-items-center small-text-size"
                      style={{ textDecoration: "none", gap: "10px" }}
                    >
                      <CreditWalletIcon />
                      Manage Debts & Credit Limits
                    </Link>
                  </div>

                  <hr />

                  <div className={`${styles.customerCard__details} mt-4`}>
                    {storeWalletDetails ? (
                      <secondaryComponents.MainDashboardAnalyticsCard
                        isFetchingAnalytics={isFetchingCustomerDetails}
                        value={formatCurrency({
                          value: storeWalletDetails?.balance,
                          currencyCode: store_wallet_currency,
                        })}
                        title={
                          <p className="mb-0 small-text-size dark--text">
                            Store Wallet Balance
                          </p>
                        }
                        color="2"
                        backgroundColor={"#F2F2F2"}
                      />
                    ) : null}

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={formatCurrency({
                        value: customerDetails?.credit_limit,
                        currencyCode: store_currency,
                      })}
                      title={
                        <div className="d-flex align-items-center">
                          <p className="mb-0 small-text-size dark--text mr-2">
                            Credit Limit
                          </p>
                          <secondaryComponents.NavigateWithPermission
                            permissionParentKey={"Customers"}
                            permissionChildKey={
                              "can_update_customer_credit_limit"
                            }
                          >
                            <primaryComponents.Button
                              classNames={"btn btn--outline p-2"}
                              onClick={() => {
                                setShowUpdateCreditLimit(true);
                              }}
                              style={{ height: "25px" }}
                            >
                              <img
                                src={Icon.editWrite}
                                alt=""
                                width="16px"
                                height="16px"
                              />{" "}
                              Edit
                            </primaryComponents.Button>
                          </secondaryComponents.NavigateWithPermission>
                        </div>
                      }
                      color="4"
                      isHtmlTitle={true}
                      backgroundColor={"#F2F2F2"}
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={formatCurrency({
                        value:
                          customerDetails?.outstanding_balance <= 0
                            ? Math.abs(customerDetails?.outstanding_balance)
                            : -Math.abs(customerDetails?.outstanding_balance),
                        currencyCode: store_currency,
                      })}
                      title={
                        <p className="mb-0 small-text-size dark--text">
                          {customerDetails?.outstanding_balance > 0
                            ? "Debt Owed"
                            : customerDetails?.outstanding_balance < 0
                              ? "Deposit"
                              : "Settled"}
                        </p>
                      }
                      color="1"
                      backgroundColor={"#F2F2F2"}
                      customer={customerDetails}
                    />
                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={formatCurrency({
                        value: customerDetails?.total_purchases || 0,
                        currencyCode: store_currency,
                      })}
                      title={
                        <p className="mb-0 small-text-size">
                          Total Amount Spent
                        </p>
                      }
                      color="6"
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={formatCurrency({
                        value: customerDetails?.average_spent || 0,
                        currencyCode: store_currency,
                      })}
                      title={
                        <p className="mb-0 small-text-size">Average Spend</p>
                      }
                      color="3"
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={
                        formatNumberWithCommas(
                          customerDetails?.total_completed_transactions,
                        ) || "-"
                      }
                      title={
                        <p className="mb-0 small-text-size">
                          Total Number of Transactions
                        </p>
                      }
                      color="5"
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={formatCurrency({
                        value:
                          customerDetails?.wallet?.balance_naira_equiv || 0,
                        currencyCode: store_currency,
                      })}
                      title={
                        <p className="mb-0 small-text-size">Cashback Balance</p>
                      }
                      color="8"
                    />
                  </div>
                </div>

                <hr />

                <div className="mt-4">
                  <p>Customer&apos;s Personal Info</p>
                  <div className={styles.customerCard__details}>
                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={
                        customerDetails?.phone
                          ? "(" +
                            customerDetails?.phone_code +
                            ") " +
                            customerDetails?.phone
                          : "N/A"
                      }
                      title={
                        <p className="mb-0 small-text-size">Phone Number</p>
                      }
                      color="8"
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={
                        customerDetails?.date_of_birth
                          ? formatDate(customerDetails?.date_of_birth)
                          : "N/A"
                      }
                      title={<p className="mb-0 small-text-size">Birthday</p>}
                      color="4"
                    />

                    <secondaryComponents.MainDashboardAnalyticsCard
                      isFetchingAnalytics={isFetchingCustomerDetails}
                      value={
                        customerDetails?.country
                          ? customerDetails?.country
                          : "N/A"
                      }
                      text={customerDetails?.city ? customerDetails?.city : ""}
                      title={<p className="mb-0 small-text-size">Location</p>}
                      color="3"
                    />
                  </div>
                </div>
              </div>
            </secondaryComponents.LoaderHelper>
            <div>
              <div className={`${globalStyles.rowNavigations} mb-4`}>
                {tableTab?.map((item, index) => (
                  // eslint-disable-next-line
                  <a
                    key={index}
                    className={`${globalStyles.rowNavigations__item} ${
                      item === selectedTableTab ? globalStyles.active : ""
                    }`}
                    onClick={() => {
                      setSelectedTableTab(item);
                      navigate(".", {
                        state: { tableTab: item },
                      });
                    }}
                  >
                    {item?.split(" ")?.map((item, index) => (
                      <span key={index}>{item}&nbsp;</span>
                    ))}
                  </a>
                ))}
              </div>

              <div className={globalStyles.tableWrapper}>
                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingStoreWallets || isLoadingOrderData}
                  classNames={"pt-5"}
                >
                  {selectedTableTab === "Sales Transactions" ? (
                    <secondaryComponents.SalesTransactionsTable
                      metaDetails={orderMetails}
                      salesTransactions={orders}
                      handleFetchSalesTransactions={() => {}}
                      gotoPage={gotoPage}
                    />
                  ) : selectedTableTab === "Loyalty Activity" ? (
                    <secondaryComponents.CustomerLoyaltyTable
                      customers={customerLoyaltyActivities}
                      gotoPage={gotoPage}
                      metaDetails={customerLoyaltyActivitiesMetaDetails}
                    />
                  ) : (
                    <secondaryComponents.StoreWalletTransactionTable
                      metaDetails={storeWalletTnxMetaDetails}
                      storeWalletTransactions={storeWalletTransactions}
                      gotoPage={gotoPage}
                    />
                  )}
                </secondaryComponents.LoaderHelper>
              </div>
            </div>
          </motion.div>

          {/* edit customer modal section */}
          <secondaryComponents.Modal
            isActive={showEditModal}
            closeModal={() => {
              setShowEditModal(false);
            }}
            width={600}
          >
            <secondaryComponents.CustomerForm
              customerInfo={customerDetails}
              closeWidget={() => {
                setShowEditModal(false);
              }}
              isEditMode={true}
            />
          </secondaryComponents.Modal>

          {/* send message */}
          <secondaryComponents.SideModal
            isActive={sendMessage}
            closeModal={() => setSendMessage(false)}
            width={650}
          >
            <SendCreditReminderWidget
              closeWidget={() => setSendMessage(false)}
              customer={customerDetails}
              type="message"
            />
          </secondaryComponents.SideModal>

          {/* update credit limit */}
          <secondaryComponents.Modal
            isActive={showUpdateCreditLimit}
            closeModal={() => setShowUpdateCreditLimit(false)}
            width={500}
          >
            <secondaryComponents.UpdateCreditLogModal
              closeModal={() => setShowUpdateCreditLimit(false)}
              customer={customerDetails}
            />
          </secondaryComponents.Modal>

          {/* create store wallet */}
          <secondaryComponents.Modal
            isActive={modals?.isCreateWallet}
            closeModal={() => resetModals("isCreateWallet", false)}
            width={600}
          >
            <secondaryComponents.StoreWalletCreationForm
              closeModal={() => resetModals("isCreateWallet", false)}
              customerDetails={{
                customerEmail: customerDetails?.email,
                customerId: customerDetails?.id,
                customerName: customerDetails?.full_name,
                customerPhoneCode: customerDetails?.phone_code,
                customerPhoneNumber: customerDetails?.phone,
              }}
            />
          </secondaryComponents.Modal>
        </>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default CustomerDetails;
