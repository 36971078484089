import { useState, useContext, useEffect } from "react";
import { useStep } from "react-hooks-helper";
import { useSelector } from "react-redux";
import {
  fetchBankList,
  initiateTransfer,
  resolveAccountNumber,
} from "../../../services/transfers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive, reArrangeBusinessList } from "../../../helpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useGetTagList } from "../../../hooks/api/queries/useInventory";

const useFundTransfer = ({ closeWidget, externalRequest }) => {
  const [pin, setPin] = useState("");
  const [transferDetails, setTransferDetails] = useState({
    save_beneficiary: false,
    account_number: externalRequest?.account_number || "",
    account_name: externalRequest?.account_name || null,
    bank_code: externalRequest?.bank_code || "",
    bank_name: externalRequest?.bank_name || "",
    amount: externalRequest?.amount || "",
    narration: "",
    password: "",
    business_id: "",
    business_name: "",
    tag_ids: [],
    transfer_charge: 0,
    security_pin: pin,
  });
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { index, navigation } = useStep({ steps: 3, initialStep: 0 });
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );
  const {
    profileDetails: { role: business_role, business_list },
  } = useSelector((state) => state.profileDetailsReducer);
  // const { currency_code } = useSelector(
  //   (state) => state.profileDetailsReducer.businessDetails,
  // );
  const [businessList, setBusinessList] = useState([]);
  const canTransferFunds =
    verification_status !== "pending_review" &&
    verification_status !== "unverified";
  const queryClient = useQueryClient();

  useEffect(() => {
    if (canTransferFunds)
      setBusinessList(reArrangeBusinessList(business_list, "NGN"));
  }, []);

  useEffect(() => {
    if (
      externalRequest?.useExternalDetails &&
      transferDetails?.business_id !== ""
    )
      handleResolveAccountNumber(
        externalRequest?.bank_code,
        externalRequest?.account_number,
      );
  }, [transferDetails?.business_id]);

  const { data: tagsList } = useGetTagList();

  const { data: bankList, isLoading: isFetchingBankList } = useQuery(
    ["getBankList", transferDetails?.business_id],
    () =>
      fetchBankList(`?business_id=${transferDetails?.business_id}`).then(
        (res) => {
          return res.data?.data;
        },
      ),
    {
      enabled:
        transferDetails?.business_id !== "" &&
        !externalRequest?.useExternalDetails,
    },
  );

  //   resolve account number
  const { mutate: resolveAccount } = useMutation((data) =>
    resolveAccountNumber(data),
  );
  const handleResolveAccountNumber = (bankCode, accountNumber) => {
    resolveAccount(
      {
        bank_code: bankCode,
        account_number: accountNumber,
        business_id: transferDetails?.business_id,
      },
      {
        onSuccess: (res) => {
          const data = res?.data?.data;
          setTransferDetails((prev) => ({
            ...prev,
            account_name: data?.account_name,
            transfer_charge: data?.transfer_charge,
          }));
          window.clearAccountErrors("account_number");
        },
        onError: (error) => {
          if (
            error?.response?.status === 400 ||
            error?.response?.status === 422
          )
            window.setAccountError(
              "account_number",
              {
                type: "validate",
                message:
                  error?.response?.data?.errors?.account_number[0] ||
                  error?.response?.data?.detail ||
                  "Account number is invalid",
              },
              { shouldFocus: true },
            );
        },
      },
    );
  };

  //   transfer funds handler
  const { mutate: transferFunds, isLoading: isTranferringFunds } = useMutation(
    (data) => initiateTransfer(data),
  );
  const handleFundTransfer = () => {
    if (externalRequest?.useExternalDetails)
      return externalRequest?.callBackFunc({
        purpose: transferDetails?.narration,
        tag_ids: transferDetails?.tag_ids,
        //password: transferDetails?.password,
        business_id: transferDetails?.business_id,
        security_pin: pin,
      });
    setRequestLoaderProgress(getRandomIntInclusive(20, 40));
    transferFunds(transferDetails, {
      onSuccess: () => {
        let message =
          business_role !== "OWNER" && business_role !== "ADMIN"
            ? "Transfer Succesfully Scheduled, Awaiting Approval"
            : "Transfer successful";
        queryClient.invalidateQueries(["getTransfers"]);
        queryClient.invalidateQueries(["getDashboardDetails"]);
        queryClient.invalidateQueries(["getProfileDetails"]);
        queryClient.invalidateQueries(["businessDetails"]);
        triggerToast(message, "success");
        closeWidget(false);
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const fillFormWithBeneficiary = (item) => {
    const bankCode = bankList.find(
      (object) => object.bank_code === item.bank_code,
    )?.bank_code;
    if (bankCode === undefined)
      return triggerToast(
        "This beneficiary bank is not available for the selected sub-account",
        "warning",
      );
    setTransferDetails((prev) => ({
      ...prev,
      ...item,
    }));
    navigation.go(0);
  };

  const handleNextStep = () => {
    navigation.next();
  };
  const handlePreviousStep = () => {
    navigation.previous();
  };

  return {
    isFetchingBankList,
    isTranferringFunds,
    bankList: bankList || [],
    canTransferFunds,
    verification_status,
    business_role,
    businessList,
    navigation,
    tagsList: tagsList || [],
    index,
    transferDetails,
    setTransferDetails,
    handleNextStep,
    handlePreviousStep,
    handleResolveAccountNumber,
    handleFundTransfer,
    fillFormWithBeneficiary,
    pin,
    setPin,
  };
};

export default useFundTransfer;
