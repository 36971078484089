import { profileDetailsSlice } from "./profile";
import { apiErrorSlice } from "./apiErrorHandler";
import { dashboardSlice } from "./dashboard";
import { cartSlice } from "./storeCart";
import { tableSlice } from "./table";
import { menuCartSlice } from "./menuCart";
import { paymentsSlice } from "./payments";

export const rootReducer = {
  profileDetailsReducer: profileDetailsSlice.reducer,
  apiErrorReducer: apiErrorSlice.reducer,
  dashboardReducer: dashboardSlice.reducer,
  cartReducer: cartSlice.reducer,
  tableReducer: tableSlice.reducer,
  menuCartReducer: menuCartSlice.reducer,
  paymentsReducer: paymentsSlice.reducer,
};
