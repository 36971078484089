import { motion } from "framer-motion";
import styles from "./BillUpdate.module.scss";
import primaryComponents from "../../../primaryComponents";
import { formatCurrency, truncateString } from "../../../../helpers";
import secondaryComponents from "../..";
import Icon from "../../../../assets/icons";
import useBillUpdate from "./useBillUpdate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PricingTypeSelector from "./PricingTypeSelector";
import { PlusCircle } from "../../../../assets/svgIcons";
import DeliveryFee from "../../StoreCart/DeliveryFee";
import Discount from "../../StoreCart/Discount";
import Loyalty from "../../StoreCart/Loyalty";

const BillUpdate = ({
  orderId,
  setBillWasUpdated,
  resetView,
  isOrderFlow = true,
}) => {
  const {
    // tagList,
    orderDetails,
    productItems,
    isUpdating,
    otherFields,
    isLoading,
    modals,
    loyaltyDetails,
    setLoyaltyDetails,
    handleOrderModuleAuth,
    setModals,
    setOtherFields,
    addProductItem,
    removeBillItem,
    updateBillItem,
    setProductItems,
    removeItemNotWithId,
  } = useBillUpdate({
    orderId,
    setBillWasUpdated,
    resetView,
  });
  const store_currency = orderDetails?.store?.currency_code;
  const totalItemAmount = productItems?.reduce(
    (accumulator, item) =>
      Number(item?.quantity) * Number(item?.unit_price) + Number(accumulator),
    0,
  );

  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={styles.billDetails}
      >
        {!isOrderFlow ? (
          <FontAwesomeIcon
            onClick={() => setBillWasUpdated(null)}
            className="d-block ml-auto"
            icon="times"
            style={{ fontSize: "25px" }}
          />
        ) : null}
        <h4 className="mt-4 mb-3">Update Bill Information</h4>

        {/* order details */}
        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames={"mt-4"}
        >
          <div className={styles.orderCover}>
            <div className={styles.orderDetails}>
              <p>ITEM</p>
              <p>QUANTITY</p>
              <p>UNIT(S)</p>
              <p>UNIT AMOUNT</p>
              <p>TOTAL AMOUNT</p>
            </div>
            {productItems?.map((item, index) => (
              <div className={styles.orderDetails} key={item?.id}>
                <p className="d-flex al">
                  <img
                    width={20}
                    height={20}
                    style={{ cursor: "pointer", marginTop: "-2px" }}
                    src={Icon.deleteIcon}
                    className="mr-2"
                    alt=""
                    onClick={() => {
                      if (item?.cart_item_id) {
                        removeBillItem({ cartId: item.cart_item_id, index });
                      } else {
                        removeItemNotWithId(index);
                      }
                    }}
                  />
                  <span>{truncateString(item?.cart_item_name, 100)}</span>
                </p>
                <div className={"d-flex"} style={{ gap: "5px" }}>
                  <primaryComponents.Button
                    classNames={"btn btn--outline"}
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => {
                      const newProductItems = [...productItems];
                      if (newProductItems[index].quantity - 1 < 0) return;
                      if (newProductItems[index].is_from_cart)
                        return handleOrderModuleAuth({
                          actionKey: "reduce_bill_quantity",
                          onSuccessCallBack: () => {
                            newProductItems[index].quantity =
                              Number(newProductItems[index].quantity) - 1;
                            setProductItems(newProductItems);
                          },
                        });

                      newProductItems[index].quantity =
                        Number(newProductItems[index].quantity) - 1;
                      setProductItems(newProductItems);
                    }}
                  >
                    -
                  </primaryComponents.Button>
                  <primaryComponents.CartInputField
                    classNames={`${styles.input}`}
                    defaultValue={item?.quantity}
                    updateCart={(val) => {
                      if (val <= 0) return;
                      const newProductItems = [...productItems];
                      newProductItems[index].quantity = val;
                      setProductItems(newProductItems);
                    }}
                  />
                  <primaryComponents.Button
                    classNames={"btn btn--primary"}
                    style={{ width: "40px", height: "40px" }}
                    onClick={() => {
                      const newProductItems = [...productItems];
                      newProductItems[index].quantity =
                        Number(newProductItems[index].quantity) + 1;
                      setProductItems(newProductItems);
                    }}
                  >
                    +
                  </primaryComponents.Button>
                </div>
                <p className={"text-left"}>{item?.unit?.unit || "N/A"}</p>

                <p className={"text-left"}>
                  {formatCurrency({
                    value: Number(item?.unit_price),
                    currencyCode: orderDetails?.store?.currency_code,
                  })}
                  <br />
                  <secondaryComponents.ToolTipV2 tipText="Update Pricing Type">
                    <PricingTypeSelector
                      itemId={item?.product?.id}
                      isService={item?.is_service}
                      currentPricingId={item?.pricing_type_id}
                      storeInfo={orderDetails?.store}
                      params={{
                        object_id: orderDetails?.id,
                        object_type: "order",
                      }}
                      item={item}
                      onSetPricingType={(
                        pricingId,
                        unitPrice,
                        pricingTypelabel,
                      ) => {
                        const newProductItems = [...productItems];
                        newProductItems[index].pricing_type_id = pricingId;
                        newProductItems[index].pricing_type_label =
                          pricingTypelabel;
                        newProductItems[index].unit_price = unitPrice;
                        newProductItems[index].custom_price = null;
                        newProductItems[index].is_custom_price = false;
                        setProductItems(newProductItems);
                      }}
                      onSetPricingCustomPrice={(customPrice) => {
                        const newProductItems = [...productItems];
                        newProductItems[index].custom_price = customPrice;
                        newProductItems[index].is_custom_price = true;
                        newProductItems[index].unit_price = customPrice;
                        setProductItems(newProductItems);
                      }}
                    />
                  </secondaryComponents.ToolTipV2>
                </p>

                <p className={"text-left"}>
                  {formatCurrency({
                    value: Number(item?.quantity) * Number(item?.unit_price),
                    currencyCode: store_currency,
                  })}
                </p>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-between">
            <p className="dark--text">Subtotal:</p>
            <h6 className="purple--text">
              {formatCurrency({
                value: totalItemAmount,
                currencyCode: orderDetails?.store?.currency_code,
              })}
            </h6>
          </div>

          {/* add new product section */}
          <div className="mt-3 mb-3">
            {/* search section */}
            <secondaryComponents.ProductSearch
              addProduct={addProductItem}
              orderDetails={orderDetails}
              filterByComposite={false}
              excludeManageModeOnlyProducts={true}
              isBillUpdate={true}
            />
          </div>

          <div className={"d-flex mt-4"} style={{ gap: "15px" }}>
            <primaryComponents.Button
              classNames="btn cover"
              style={{
                background: "#E8F7EE",
                borderColor: "#47BE79",
                color: "#47BE79",
              }}
              onClick={() =>
                setModals((prev) => ({ ...prev, isLoyalty: true }))
              }
            >
              <PlusCircle />
              <span className="ml-2">Loyalty</span>
            </primaryComponents.Button>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_apply_discount"
            >
              <primaryComponents.Button
                classNames="btn cover"
                style={{
                  background: "#FEF7EB",
                  borderColor: "#F9BD63",
                  color: "#F9BD63",
                }}
                onClick={() =>
                  setModals((prev) => ({ ...prev, isDiscount: true }))
                }
              >
                <PlusCircle />
                <span className="ml-2">Discount</span>
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_apply_delivery_fee"
            >
              <primaryComponents.Button
                classNames="btn cover"
                style={{
                  background: "#F2EDFE",
                  borderColor: "#7647EE",
                  color: "#7647EE",
                }}
                onClick={() =>
                  setModals((prev) => ({ ...prev, isDelivery: true }))
                }
              >
                <PlusCircle />
                <span className="ml-2">Delivery</span>
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div
            className="mt-2"
            style={{
              padding: "15px",
              borderRadius: "8px",
              border: "1px solid #ddd",
            }}
          >
            <h5 className="mb-3">Price Summary</h5>
            <div className="mt-3 d-flex justify-content-between">
              <p>Subtotal:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value: totalItemAmount,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Delivery Fee:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value: otherFields?.delivery_fee_amount || 0,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Discount:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value: otherFields?.discount_amount || 0,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Gift Card:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value: loyaltyDetails?.giftcard_amount
                    ? loyaltyDetails?.giftcard_amount
                    : otherFields.giftcard_debit_amount || 0,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Loyalty Amount:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value: loyaltyDetails?.loyalty_point_amount
                    ? loyaltyDetails?.loyalty_point_amount
                    : otherFields.loyalty_debit_amount || 0,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>VAT:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value:
                    (Number(orderDetails?.vat_percentage || 0) *
                      Number(totalItemAmount)) /
                    100,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Service Charge:</p>
              <h6 className="mb-0">
                {formatCurrency({
                  value:
                    (Number(orderDetails?.service_charge_percentage || 0) *
                      Number(totalItemAmount)) /
                    100,
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <p>Total:</p>
              <h6 className="purple--text mb-0">
                {formatCurrency({
                  value:
                    totalItemAmount +
                    Number(otherFields?.delivery_fee_amount) +
                    -Number(
                      loyaltyDetails?.loyalty_point_amount
                        ? loyaltyDetails?.loyalty_point_amount
                        : otherFields?.loyalty_debit_amount,
                    ) -
                    Number(
                      loyaltyDetails?.giftcard_amount
                        ? loyaltyDetails?.giftcard_amount
                        : otherFields?.giftcard_debit_amount,
                    ) -
                    Number(otherFields?.discount_amount) +
                    Number(
                      (Number(orderDetails?.vat_percentage || 0) *
                        Number(totalItemAmount)) /
                        100,
                    ) +
                    Number(
                      (Number(orderDetails?.service_charge_percentage || 0) *
                        Number(totalItemAmount)) /
                        100,
                    ),
                  currencyCode: store_currency,
                })}
              </h6>
            </div>
          </div>

          <div className={styles.billDetails__bottom}>
            <div className="ml-auto" style={{ width: "300px" }}>
              <primaryComponents.Button
                classNames="btn btn--primary cover"
                onClick={() => updateBillItem()}
                isDisabled={isUpdating}
                isLoading={isUpdating}
                loadingText={"Updating..."}
              >
                Continue
              </primaryComponents.Button>
            </div>
          </div>
        </secondaryComponents.LoaderHelper>
      </motion.div>

      {!isLoading ? (
        <>
          <secondaryComponents.Modal
            width={550}
            isActive={modals?.isDelivery}
            closeModal={() =>
              setModals((prev) => ({ ...prev, isDelivery: false }))
            }
          >
            <DeliveryFee
              setDeliveryFee={(value) => {
                setOtherFields((prev) => ({
                  ...prev,
                  delivery_fee_amount: value,
                }));
              }}
              deliveryFee={otherFields?.delivery_fee_amount}
              currency_symbol={store_currency}
              storeId={orderDetails?.store?.id}
              closeModal={() =>
                setModals((prev) => ({ ...prev, isDelivery: false }))
              }
              params={{ object_id: orderDetails?.id, object_type: "order" }}
            />
          </secondaryComponents.Modal>

          <secondaryComponents.Modal
            width={550}
            isActive={modals?.isDiscount}
            closeModal={() =>
              setModals((prev) => ({ ...prev, isDiscount: false }))
            }
          >
            <Discount
              setDiscount={(value) =>
                setOtherFields((prev) => ({
                  ...prev,
                  discount_amount: value,
                }))
              }
              discount={otherFields?.discount_amount}
              currency_symbol={store_currency}
              closeModal={() =>
                setModals((prev) => ({ ...prev, isDiscount: false }))
              }
              totalAmount={totalItemAmount}
              storeId={orderDetails?.store?.id}
              params={{ object_id: orderDetails?.id, object_type: "order" }}
            />
          </secondaryComponents.Modal>

          <secondaryComponents.Modal
            width={700}
            isActive={modals?.isLoyalty}
            closeModal={() => {
              setModals((prev) => ({ ...prev, isLoyalty: false }));
              setLoyaltyDetails({
                loyaltyType: "GIFT_CARD",
                redemption_code: "",
                customer_id: "",
                giftcard_amount: "",
                loyalty_point_amount: "",
                customerDetails: {
                  customerName: "",
                  customerPhoneNumber: "",
                  customerPhoneCode: "",
                  customerEmail: "",
                },
              });
            }}
          >
            {/* {campaigns?.length > 0 ? ( */}
            <Loyalty
              setLoyaltyDetails={setLoyaltyDetails}
              loyaltyDetails={loyaltyDetails}
              currency_symbol={store_currency}
              closeModal={() =>
                setModals((prev) => ({ ...prev, isLoyalty: false }))
              }
            />
          </secondaryComponents.Modal>
        </>
      ) : null}
    </>
  );
};
export default BillUpdate;
