import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import styles from "./styles.module.scss";
import { useSocialMedia } from "./useSocialMedia";
import Validation from "./validation";
import { PiYoutubeLogo } from "react-icons/pi";
import { GoArrowUpRight } from "react-icons/go";

const SocialMedia = () => {
  const { menuProperties } = useOutletContext();
  const {
    isLoadingStorefrontSocialMedia,
    isEditing,
    socialMediaDetails,
    setIsEditing,
    setSocialMediaDetails,
    submitSocialMediaDetails,
  } = useSocialMedia({ menuProperties });

  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ socialMediaDetails });

  return (
    <div className={styles.container}>
      <div className={styles.socialHeading}>
        <div className={styles.social}>
          <img src={Icon.socialMedia} alt="" />
          <div>
            <h5>Social Media</h5>
            <p>Add social media links to your storefront</p>
          </div>
        </div>
        {isEditing ? (
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={handleSubmit(submitSocialMediaDetails)}
            loadingText={"Saving..."}
            isLoading={isLoadingStorefrontSocialMedia}
            isDisabled={isLoadingStorefrontSocialMedia}
          >
            <span>Save</span>
          </primaryComponents.Button>
        ) : (
          <primaryComponents.Button
            classNames="btn btn--primary"
            onClick={() => setIsEditing(true)}
            loadingText={"Saving..."}
            isLoading={isLoadingStorefrontSocialMedia}
            isDisabled={isLoadingStorefrontSocialMedia}
          >
            <span>Edit</span>
          </primaryComponents.Button>
        )}
      </div>
      {isEditing && (
        <div className={styles.socialContainer}>
          <div className={styles.socialItem}>
            <p className="dark--text">Facebook</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="facebook"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.facebook}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  facebook: value,
                }));
              }}
              errorMessage={errors.facebook}
              onKeyUp={() =>
                errors.facebook !== undefined && trigger("facebook")
              }
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <p className="dark--text">Instagram</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="instagram"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.instagram}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  instagram: value,
                }));
              }}
              errorMessage={errors.instagram}
              onKeyUp={() =>
                errors.instagram !== undefined && trigger("instagram")
              }
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <p className="dark--text">Twitter(X)</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="twitter"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.twitter}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  twitter: value,
                }));
              }}
              errorMessage={errors.twitter}
              onKeyUp={() => errors.twitter !== undefined && trigger("twitter")}
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <p className="dark--text">Tiktok</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="tiktok"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.tiktok}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  tiktok: value,
                }));
              }}
              errorMessage={errors.tiktok}
              onKeyUp={() => errors.tiktok !== undefined && trigger("tiktok")}
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <p className="dark--text">Youtube</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="youtube"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.youtube}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  youtube: value,
                }));
              }}
              errorMessage={errors.youtube}
              onKeyUp={() => errors.youtube !== undefined && trigger("youtube")}
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <p className="dark--text">Snapchat</p>
            <primaryComponents.InputFieldV2
              control={control}
              name="snapchat"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.snapchat}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  snapchat: value,
                }));
              }}
              errorMessage={errors.snapchat}
              onKeyUp={() =>
                errors.snapchat !== undefined && trigger("snapchat")
              }
              register={register}
            />
          </div>
          <div className={styles.socialItem}>
            <div className={styles.googleLink}>
              <p className="dark--text">Google Review</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://youtu.be/Mvx5CLol-8I"
              >
                <span className="d-flex align-items-center">
                  <PiYoutubeLogo size={16} className="mr-1" /> How to setup your
                  google review
                  <GoArrowUpRight size={16} className="ml-1" />
                </span>
              </a>
            </div>
            <primaryComponents.InputFieldV2
              control={control}
              name="google"
              label=""
              placeholder="Insert link here"
              value={socialMediaDetails.google}
              onChange={({ target: { value } }) => {
                setSocialMediaDetails((prev) => ({
                  ...prev,
                  google: value,
                }));
              }}
              errorMessage={errors.google}
              onKeyUp={() => errors.google !== undefined && trigger("google")}
              register={register}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
