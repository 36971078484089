import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const PrinterConfiguration = () => {
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Printer Settings"
      description={"Manage printer connections"}
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Printer</h4>
            <p>Configure Your Printer Settings</p>
          </div>

          <secondaryComponents.PrinterConfigForm />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default PrinterConfiguration;
