// import AddCustomerSection from "../../StoreCheckout/AddCustomerSection";
import useStoreWalletFund from "./useStoreWalletFund";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const StoreWalletFund = ({ setIsFundWallet, customerDetails = {} }) => {
  const {
    fundDetails,
    storeList,
    isFundingWallet,
    setFundDetails,
    handleFundWallet,
    storeWallets,
  } = useStoreWalletFund({
    customerDetails,
    setIsFundWallet,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
  } = Validation({ fundDetails });

  return (
    <div className={globalStyles.pageColumnGaps} style={{ minHeight: "100%" }}>
      <div>
        <primaryComponents.Button
          classNames={"btn btn--outline smallBtn"}
          onClick={() => setIsFundWallet(null)}
        >
          <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
        </primaryComponents.Button>
      </div>
      <div>
        <h4 className="mb-0">Fund Wallet</h4>
        <p>
          Fund customer&apos;s wallet instantly for quick and convenient
          transactions
        </p>
      </div>

      <form
        className={globalStyles.pageColumnGaps}
        style={{ gap: "10px" }}
        onSubmit={handleSubmit(handleFundWallet)}
        id="fundWalletForm"
      >
        <primaryComponents.SelectFieldV2
          control={control}
          idKey={"id"}
          nameKey={"name"}
          name="walletId"
          isRequired={true}
          value={"Select wallet"}
          classNames="wallet-select"
          label={"Select wallet"}
          options={storeWallets?.data
            ?.filter((data) => data?.tag)
            ?.map((data) => ({
              id: data?.id,
              name: (
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "30px" }}
                >
                  <div>{data?.tag}</div>
                  <div
                    className={`${data?.is_subwallet ? "gray--squared" : "gray--primary"}`}
                  >
                    {data?.is_subwallet ? "Sub-wallet" : "Primary Wallet"}
                  </div>
                </div>
              ),
            }))}
          onChange={(value) => {
            setFundDetails((prev) => ({ ...prev, wallet_id: value }));
          }}
          errorMessage={errors.walletId}
        />

        <primaryComponents.SelectFieldV2
          control={control}
          name="store"
          label={"Which store is the customer making the payment to?"}
          options={storeList}
          idKey="id"
          nameKey="name"
          isRequired={true}
          onChange={(value) => {
            setFundDetails((prev) => ({ ...prev, store_id: value }));
          }}
          value={"Select store"}
          classNames="store-select"
          errorMessage={errors.store}
        />

        <primaryComponents.InputFieldV2
          type="number"
          name="amount"
          control={control}
          label={"Credit Amount"}
          isRequired={true}
          placeholder="Enter total credit amount"
          value={fundDetails?.amount}
          onChange={({ target: { value } }) =>
            setFundDetails((prev) => ({ ...prev, amount: value }))
          }
          classNames="white"
          errorMessage={
            errors.amount && {
              message: errors.amount.message,
            }
          }
          onKeyUp={() => errors.amount !== undefined && trigger("amount")}
          register={register}
        />

        <primaryComponents.SelectFieldV2
          control={control}
          name="paymentMethod"
          options={["transfer", "card", "cash"]}
          label={"Payment Method"}
          isRequired={true}
          value={fundDetails?.payment_method || "Select Payment Type"}
          onChange={(value) =>
            setFundDetails((prev) => ({ ...prev, payment_method: value }))
          }
          idKey="value"
          register={register}
          errorMessage={errors.paymentMethod}
        />

        <primaryComponents.TextAreaV2
          control={control}
          type="text"
          label={"Description"}
          name="note"
          placeholder="Add description for this fund wallet"
          value={fundDetails?.description}
          onChange={({ target: { value } }) =>
            setFundDetails((prev) => ({ ...prev, description: value }))
          }
          errorMessage={errors.note}
          onKeyUp={() => errors.note !== undefined && trigger("note")}
          register={register}
        />
      </form>

      <primaryComponents.Button
        classNames="btn btn--primary cover mt-auto"
        loadingText="Funding..."
        type={"submit"}
        form={"fundWalletForm"}
        isLoading={isFundingWallet}
        isDisabled={isFundingWallet}
      >
        Proceed
      </primaryComponents.Button>
    </div>
  );
};

export default StoreWalletFund;
