import { useState, useContext } from "react";
import { useGetUserSecurityQuestion } from "../../../../hooks/api/queries/useSettings";
import { useMutation } from "react-query";
import {
  verifySecurityAnswerWithPinAndPassword,
  verifySecurityPinWithQuestionAnswerAndPassword,
} from "../../../../services/settings";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";

const useLogic = ({
  setVerificationModal,
  setIsConfirmedPin,
  setIsConfirmedAnswer,
}) => {
  const [answere, setAnswere] = useState("");
  const [password, setPassword] = useState("");
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [pin, setPin] = useState("");
  const { data: question, isLoadingQuestion } = useGetUserSecurityQuestion();

  const { mutate: sendAnswer, isLoading: isSendingAnswer } = useMutation(
    (data) => verifySecurityPinWithQuestionAnswerAndPassword(data),
  );

  const { mutate: sendPin, isLoading: isSendingPin } = useMutation((data) =>
    verifySecurityAnswerWithPinAndPassword(data),
  );

  const handleSendAnswer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({
      security_answer: answere,
      password: password,
    });

    sendAnswer(data, {
      onSuccess: () => {
        triggerToast("Great! Your answer matched.", "success");
        setVerificationModal(null);
        setIsConfirmedAnswer(true);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const handleSendPin = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({
      security_pin: pin,
      password: password,
    });

    sendPin(data, {
      onSuccess: () => {
        triggerToast("Great! Your pin matched.", "success");
        setVerificationModal(null);
        setIsConfirmedPin(true);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    pin,
    setPin,
    answere,
    password,
    setPassword,
    setAnswere,
    question,
    isLoadingQuestion,
    handleSendAnswer,
    isSendingAnswer,
    handleSendPin,
    isSendingPin,
  };
};

export default useLogic;
