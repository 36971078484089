import { useQuery, useQueryClient } from "react-query";
import {
  getBusiness,
  getSecurityQuestions,
  getTeamMembers,
  getUserSecurityQuestion,
} from "../../../services/settings";
import { getProfileDetails } from "../../../services/auth";
import { useDispatch } from "react-redux";
import { setProfileDetails } from "../../../store/modules/profile";

export const useGetUserList = () => {
  return useQuery(
    ["getCustomerDetails"],
    () => getTeamMembers().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetProfileDetails = (parameters) => {
  const accessToken = parameters?.accessToken;
  const isProfileEnabled = parameters?.isProfileEnabled;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  if (accessToken) queryClient.invalidateQueries(["getProfileDetails"]);
  return useQuery(
    ["getProfileDetails"],
    () =>
      getProfileDetails(accessToken).then((res) => {
        if (res?.data?.data) dispatch(setProfileDetails(res?.data?.data));
        return res?.data?.data;
      }),
    {
      staleTime: Infinity,
      enabled: isProfileEnabled,
    },
  );
};

export const useGetBusinessDetails = () => {
  const dispatch = useDispatch();
  return useQuery(
    ["getBusiness"],
    () =>
      getBusiness().then((res) => {
        if (res?.data?.data) dispatch(setProfileDetails(res?.data?.data));
        return res?.data?.data;
      }),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetUserSecurityQuestion = () => {
  return useQuery(
    ["getUserSecurityQuestion"],
    () => getUserSecurityQuestion().then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetSecurityQuestions = () => {
  return useQuery(
    ["getSecurityQuestions"],
    () => getSecurityQuestions().then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};
