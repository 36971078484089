import { useDispatch, useSelector } from "react-redux";
import { setOtpAuthorizationError } from "../../../../store/modules/apiErrorHandler";
import secondaryComponents from "../../../secondaryComponents";

export const ManagerOtpPermission = ({ children }) => {
  const dispatch = useDispatch();
  const { message, action, object_id, object_type } = useSelector(
    (state) => state.apiErrorReducer.otpAuthorizationError,
  );

  const closeApiErrorHandler = () => {
    dispatch(
      setOtpAuthorizationError({
        message: null,
        object_id: null,
        object_type: null,
        action: null,
        otp: null,
      }),
    );
  };

  return (
    <>
      {children}
      <secondaryComponents.Modal
        isActive={message !== null}
        closeModal={() => {
          closeApiErrorHandler();
        }}
        width={500}
      >
        <secondaryComponents.AuthorizationModal
          action={action}
          object_id={object_id}
          object_type={object_type}
          closeWidget={() => {
            closeApiErrorHandler();
          }}
          isApprovalMessage={message}
        />
      </secondaryComponents.Modal>
    </>
  );
};
