import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./TransferStockDetails.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import useTransferStockDetails from "./useTransferStockDetails";
import {
  convertToTilteCase,
  formatTimeFromTimestamp,
  removeSpecialCharacter,
  setToDateMonthYearInWords,
} from "../../../../../helpers";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import useValidation from "../CreateTransfer/useValidation";
import { pathConstants } from "../../../../../routes/pathContants";

const TransferStockDetails = () => {
  const navigate = useNavigate();
  const statusMap = {
    APPROVED: "success--squared",
    AWAITING_APPROVAL: "pending--squared",
    REVIEW: "pending--squared",
    DECLINED: "failed--text",
  };
  const {
    params,
    isFetchingStockTransferItems,
    isFetchingStockTransferDetails,
    stockTransferDetails,
    stockTransferItems,
    // isFetchingNextPage,
    // autoScrollRef,
    handleApproveStockTransfer,
    handleRejectStockTransfer,
  } = useTransferStockDetails();
  const validation = useValidation({
    transferData: stockTransferItems,
  });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Stock Transfer Details"
      description="View stock transfer details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={globalStyles.pageColumnGaps}
        >
          <div className="d-flex" style={{ alignItems: "center" }}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            <h4 className="ml-3">Stock Transfer Review</h4>
          </div>

          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingStockTransferDetails}
          >
            <div className={globalStyles.pageHeaders}>
              <div className="m-2">
                {/* <h4>{stockTransferDetails?.id}</h4>
                <p>
                  {setToDateMonthYearInWords(stockTransferDetails?.created_at)}
                </p> */}
              </div>
              {stockTransferDetails?.status === "AWAITING_APPROVAL" ? (
                <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_approve_or_decline_stock_transfer"
                  >
                    <primaryComponents.Button
                      classNames="btn btn--outline"
                      onClick={() => {
                        navigate(
                          pathConstants.INVENTORY_STOCK_TRANSFER_EDIT({
                            id: params,
                          }),
                        );
                      }}
                    >
                      <span>
                        {" "}
                        &nbsp; &nbsp; &nbsp; &nbsp;Update&nbsp; &nbsp; &nbsp;
                        &nbsp;{" "}
                      </span>
                    </primaryComponents.Button>
                    <primaryComponents.Button
                      classNames="btn btn--outline-red"
                      onClick={handleRejectStockTransfer}
                    >
                      <span>
                        {" "}
                        &nbsp; &nbsp; &nbsp; &nbsp;Reject&nbsp; &nbsp; &nbsp;
                        &nbsp;{" "}
                      </span>
                    </primaryComponents.Button>
                    <primaryComponents.Button
                      classNames="btn btn--primary"
                      onClick={handleApproveStockTransfer}
                    >
                      <span>
                        {" "}
                        &nbsp; &nbsp; &nbsp;Approve &nbsp; &nbsp; &nbsp;{" "}
                      </span>
                    </primaryComponents.Button>
                  </secondaryComponents.NavigateWithPermission>
                </div>
              ) : (
                <primaryComponents.Button
                  classNames="btn btn--outline"
                  onClick={() => {
                    navigate(
                      pathConstants.INVENTORY_STOCK_TRANSFER_EDIT({
                        id: params,
                      }),
                    );
                  }}
                >
                  <span>Update</span>
                </primaryComponents.Button>
              )}
            </div>

            <div>
              <div className={styles.transferDetails__card}>
                <div>
                  <h6>Transferred From</h6>
                  <p>{stockTransferDetails?.transfer_from_store?.name}</p>
                </div>
                <div>
                  <h6>Transferred To</h6>
                  <p>{stockTransferDetails?.transfer_to_store?.name}</p>
                </div>
                <div>
                  <h6>Initiated By</h6>
                  <p>
                    {stockTransferDetails?.initiated_by?.first_name +
                      " " +
                      stockTransferDetails?.initiated_by?.last_name}
                  </p>
                </div>
                {/* <div>
                  <h6>Approved By</h6>
                  <p>
                    {stockTransferDetails?.reviewed_by
                      ? stockTransferDetails?.reviewed_by?.first_name +
                        " " +
                        stockTransferDetails?.reviewed_by?.last_name
                      : "N/A"}
                  </p>
                </div> */}
                <div>
                  <h6>Status</h6>
                  <p className={`${statusMap[stockTransferDetails?.status]}`}>
                    {stockTransferDetails?.status === "AWAITING_APPROVAL"
                      ? convertToTilteCase(
                          removeSpecialCharacter(stockTransferDetails?.status),
                        )
                      : stockTransferDetails?.status}
                  </p>
                </div>

                <div className="mt-3">
                  <h6>Date</h6>
                  {setToDateMonthYearInWords(stockTransferDetails?.created_at)}
                </div>

                <div className="mt-3">
                  <h6>Time</h6>
                  {formatTimeFromTimestamp(stockTransferDetails?.created_at)}
                </div>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingStockTransferItems}
          >
            <secondaryComponents.BulkTransferTable
              transferData={stockTransferItems}
              isReviewMode={true}
              validation={validation}
            />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default TransferStockDetails;
