import { useGetOrderDetails } from "../../../hooks/api/queries/useOrder";
import usePrintLogic from "../usePrintLogic";
import styles from "../DocketView/SectionView.module.scss";
import {
  defaultDateDisplay,
  formatCurrency,
  formatDate,
  truncateString,
} from "../../../helpers";
import React, { useRef } from "react";

export const OrderReceipt = ({
  orderId,
  closePrint = () => {},
  invalidateQueries = () => {},
}) => {
  const orderDetailsPrintComponent = useRef(null);
  const { data: orderDetails } = useGetOrderDetails({
    id: orderId,
    isEnabled: true,
  });

  const clearAfterPrint = () => {
    invalidateQueries();
    closePrint();
  };

  const { paperSize, logo } = usePrintLogic({
    itemDetails: orderDetails,
    sectionPrintComponent: orderDetailsPrintComponent,
    hiddenId: "orderdetails-hidden-section",
    callBackFunc: clearAfterPrint,
    onErrorCallBackFunc: closePrint,
  });

  return (
    <div
      id="orderdetails-hidden-section"
      ref={orderDetailsPrintComponent}
      style={{ display: "none", maxWidth: `${paperSize}px` }}
      className={styles.sectionView}
    >
      {logo ? (
        <img
          style={{ width: "100px", maxHeight: "100px" }}
          src={logo}
          alt="business logo"
        />
      ) : null}

      <div className="text-center mb-4">
        <p style={{ fontSize: "18px" }} className="dark--text">
          {truncateString(orderDetails?.store?.name, 150)}
        </p>
        {orderDetails?.store?.address ? (
          <p style={{ fontSize: "18px" }} className="dark--text">
            {truncateString(orderDetails?.store?.address, 150)}
          </p>
        ) : null}
      </div>

      {orderDetails?.queue_number ? (
        <div className={styles.sectionView__item}>
          <p>Queue Number:</p>
          <p className="mb-0">{orderDetails?.queue_number || "N/A"}</p>
        </div>
      ) : null}

      <div className={styles.sectionView__item}>
        <p>Order ID:</p>
        <p className="mb-0">{orderDetails?.id}</p>
      </div>

      <div className={styles.sectionView__item}>
        <p>Date:</p>
        <p className="mb-0">{defaultDateDisplay(orderDetails?.created_at)}</p>
      </div>

      {orderDetails?.table ? (
        <div className={styles.sectionView__item}>
          <p>Table:</p>
          <p className="mb-0">{orderDetails?.table?.name}</p>
        </div>
      ) : null}

      <div className={styles.sectionView__item}>
        <p>Payment mode:</p>
        <p className="mb-0">
          {orderDetails?.payment_method === "warehouse_checkout"
            ? "PR"
            : orderDetails?.payment_method || "N/A"}
        </p>
      </div>

      {orderDetails?.payment_method === "split" ? (
        <>
          {Object.keys(orderDetails?.split_mode)?.map((key, index) => (
            <div className={styles.sectionView__item} key={index}>
              <p style={{ fontSize: "18px" }}>{key?.replace("_", " ")}</p>
              <p style={{ fontSize: "18px" }}>
                {formatCurrency({
                  value: orderDetails?.split_mode?.[key],
                  currencyCode: orderDetails?.store?.currency_code,
                })}
              </p>
            </div>
          ))}
        </>
      ) : null}

      <div className={styles.sectionView__item}>
        <p>Customer:</p>
        <p className="mb-0">{orderDetails?.customer?.first_name || "N/A"}</p>
      </div>

      {orderDetails?.outstanding_balance ? (
        <div className={styles.sectionView__item}>
          <p>Outstanding Balance:</p>
          <p className="mb-0">{formatDate(orderDetails?.customer?.name)}</p>
        </div>
      ) : null}

      <div className={styles.sectionView__item}>
        <p>Cashier:</p>
        <p className="mb-0">{orderDetails?.cashier?.first_name}</p>
      </div>

      <div className="mt-3">
        <h5>Order Items</h5>

        <table
          width="100%"
          style={{
            borderCollapse: "collapse",
            fontSize: "18px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          <tr
            style={{
              borderTop: "1px solid #222222",
              borderBottom: "1px solid #222222",
              paddingTop: "2px",
              paddingBottom: "2px",
            }}
          >
            <th
              align="left"
              width="30%"
              style={{
                borderRight: "1px solid #222222",
                paddingRight: "1px",
              }}
            >
              Item
            </th>
            <th align="left" width="15%" style={{ paddingLeft: "1px" }}>
              Qty
            </th>
            <th align="left" width="25%">
              Unit Price({orderDetails?.store?.currency_code})
            </th>
            <th align="left" width="25%">
              Total Price({orderDetails?.store?.currency_code})
            </th>
          </tr>

          {orderDetails?.order_items?.map((order_item) => (
            <tr style={{ padding: "10px 0" }} key={order_item?.id}>
              <td
                style={{
                  borderRight: "1px solid #222222",
                  paddingRight: "1px",
                }}
              >
                {order_item.cart_item_name}
              </td>
              <td style={{ paddingLeft: "1px" }}>{order_item.quantity}</td>
              <td>{order_item.unit_price}</td>
              <td>{order_item.total_price}</td>
            </tr>
          ))}
        </table>
        <div
          className={`${styles.sectionView__item} ${styles.smallSize} mb-2`}
          style={{
            borderTop: "1px solid #222222",
          }}
        >
          <p>Sub Total</p>
          <p>{orderDetails?.total_item_amount}</p>
        </div>

        {orderDetails?.discount_amount ? (
          <div
            className={`${styles.sectionView__item} ${styles.smallSize} mb-2`}
          >
            <p>Discount amount</p>
            <p>
              {formatCurrency({
                value: orderDetails?.discount_amount,
                currencyCode: orderDetails?.store?.currency_code,
              })}
            </p>
          </div>
        ) : null}

        {orderDetails?.delivery_fee_amount ? (
          <div
            className={`${styles.sectionView__item} ${styles.smallSize} mb-2`}
          >
            <p>Delivery Fee</p>
            <p>
              {formatCurrency({
                value: orderDetails?.delivery_fee_amount,
                currencyCode: orderDetails?.store?.currency_code,
              })}
            </p>
          </div>
        ) : null}

        {orderDetails?.vat ? (
          <div
            className={`${styles.sectionView__item} ${styles.smallSize} mb-2`}
          >
            <p>Vat ({orderDetails.vat_percentage}%)</p>
            <p>
              {formatCurrency({
                value: orderDetails?.vat,
                currencyCode: orderDetails?.store?.currency_code,
              })}
            </p>
          </div>
        ) : null}

        {orderDetails?.service_charge ? (
          <div
            className={`${styles.sectionView__item} ${styles.smallSize} mb-2`}
          >
            <p>Service Charge ({orderDetails?.service_charge_percentage}%)</p>
            <p>
              {formatCurrency({
                value: orderDetails?.service_charge,
                currencyCode: orderDetails?.store?.currency_code,
              })}
            </p>
          </div>
        ) : null}
      </div>

      <div
        className={styles.sectionView__item}
        style={{ borderTop: "1px solid black" }}
      >
        <p>Total Amount</p>
        <p>
          {formatCurrency({
            value: orderDetails?.total_amount,
            currencyCode: orderDetails?.store?.currency_code,
          })}
        </p>
      </div>

      {orderDetails?.has_inventory_items ? (
        <div className="mt-3">
          <h5>Items Tracking Number</h5>
          <table
            width="100%"
            style={{
              borderCollapse: "collapse",
              fontSize: "18px",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            <tr
              style={{
                borderTop: "1px solid #222222",
                borderBottom: "1px solid #222222",
                padding: "2px 0",
              }}
            >
              <th
                align="left"
                width="30%"
                style={{
                  borderRight: "1px solid #222222",
                  paddingRight: "1px",
                }}
              >
                Item
              </th>
              <th align="left" width="70%" style={{ paddingLeft: "1px;" }}>
                Number
              </th>
            </tr>
            {orderDetails?.order_items?.map((order_item) => (
              <React.Fragment key={order_item?.id}>
                {order_item?.inventory_items?.length > 0 && (
                  <tr
                    style={{
                      borderBottom: "1px solid #222222",
                      padding: "5px 0",
                    }}
                  >
                    <td
                      style={{
                        borderRight: "1px solid #222222",
                        paddingRight: "1px",
                      }}
                    >
                      {order_item?.cart_item_name}
                    </td>
                    <td style={{ paddingLeft: "1px" }}>
                      {order_item.inventory_items?.map((inventory_item) => (
                        <React.Fragment key={inventory_item}>
                          {inventory_item?.item_number},&nbsp;
                        </React.Fragment>
                      ))}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </table>
        </div>
      ) : null}
    </div>
  );
};
