import React from "react";

const PdfIcon = () => {
  return (
    <svg
      width="215"
      height="116"
      viewBox="0 0 261 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_7753_40459)">
        <rect x="6" y="6" width="249" height="124" rx="8" fill="white" />
        <rect
          x="6.5"
          y="6.5"
          width="248"
          height="123"
          rx="7.5"
          stroke="#FDEFD8"
        />
        <g clipPath="url(#clip0_7753_40459)">
          <g clipPath="url(#clip1_7753_40459)">
            <path
              d="M158.937 106.511H103.859V27.54H145.264L158.937 41.038V106.511Z"
              fill="#EAEAEA"
            />
            <path
              d="M118.309 77.9736C117.548 77.9736 116.726 77.6899 115.988 76.8273C114.795 75.4359 114.746 73.8462 115.857 72.4629C116.87 71.2037 118.858 70.0638 121.764 69.0771C122.88 68.6982 124.104 68.3484 125.41 68.0372C126.216 66.5749 126.995 64.9739 127.722 63.2891C128.396 61.7284 128.979 60.1983 129.46 58.7457C127.562 55.5615 126.16 52.3127 126.195 49.9507C126.23 47.9693 127.324 46.6956 129.195 46.4569C130.856 46.2457 132.12 47.0519 132.659 48.669C133.402 50.9068 132.754 54.6699 131.57 58.4797C132.531 60.0195 133.572 61.508 134.688 62.9392C135.672 64.2048 136.675 65.385 137.663 66.4491C142.339 66.3556 145.444 67.0134 146.987 67.8421C148.61 68.7111 149.263 70.08 148.783 71.5987C148.199 73.4383 146.818 74.3218 144.991 74.0171C142.631 73.6302 139.627 71.265 136.87 68.4113C134.904 68.4885 132.944 68.6661 130.996 68.9433C129.486 69.1545 128.035 69.4173 126.677 69.7188C124.742 73.1191 122.485 76.155 120.33 77.3723C119.718 77.7401 119.023 77.947 118.309 77.9736ZM124.017 70.396C120.736 71.3391 118.301 72.5048 117.366 73.6689C116.833 74.3331 116.857 74.8636 117.455 75.5617C117.821 75.9873 118.306 76.2985 119.377 75.6923C120.71 74.941 122.359 73.0256 124.017 70.4024V70.396ZM139.598 68.3984C141.855 70.5169 143.901 71.8825 145.304 72.1066C146.037 72.2275 146.593 72.0856 146.938 71.0054C147.034 70.7055 147.211 70.1477 146.074 69.5382C144.794 68.8659 142.479 68.4774 139.598 68.4048V68.3984ZM130.779 60.8207C130.376 61.9299 129.942 63.0182 129.499 64.0452C129.015 65.1738 128.467 66.3363 127.873 67.5003C128.799 67.3246 129.753 67.1682 130.727 67.0312C132.316 66.8087 133.795 66.6512 135.166 66.5588C134.46 65.7526 133.786 64.9288 133.16 64.1242C132.362 63.1037 131.551 61.9847 130.779 60.8271V60.8207ZM129.441 48.3756C128.375 48.511 128.151 49.1156 128.13 49.9878C128.104 51.4276 128.876 53.6316 130.226 56.1644C131.032 53.1172 131.269 50.6311 130.821 49.2833C130.574 48.5384 130.187 48.2837 129.439 48.3804L129.441 48.3756Z"
              fill="#D9251C"
            />
            <path
              d="M145.264 41.2137H158.937L145.264 27.54V41.2137Z"
              fill="#C9C9C9"
            />
            <path
              d="M103.588 89.5312H158.937V106.512H103.588V89.5312Z"
              fill="#D9251C"
            />
            <path
              d="M119.409 93.2183H123.077C124.96 93.2183 126.24 94.2791 126.24 95.9591C126.24 97.8939 124.781 98.7693 123.031 98.7693H121.324V102.081H119.409V93.2183ZM121.327 97.2489H122.79C123.678 97.2489 124.305 96.9088 124.305 96.0027C124.305 95.0966 123.643 94.7467 122.83 94.7467H121.324L121.327 97.2489Z"
              fill="white"
            />
            <path
              d="M127.935 93.2183H131.449C134.029 93.2183 135.72 94.9192 135.72 97.5408C135.72 100.12 134.082 102.086 131.367 102.086H127.935V93.2183ZM129.852 100.536H131.29C132.989 100.536 133.74 99.2723 133.74 97.5456C133.74 96.0946 133.037 94.7677 131.301 94.7677H129.85L129.852 100.536Z"
              fill="white"
            />
            <path
              d="M137.659 93.2183H143.848V94.7902H139.531V96.962H143.573V98.5339H139.531V102.081H137.659V93.2183Z"
              fill="white"
            />
          </g>
          <rect
            x="7"
            y="6"
            width="247"
            height="124"
            fill="url(#paint0_linear_7753_40459)"
          />
          <rect
            x="15.375"
            y="104.375"
            width="41.25"
            height="19.25"
            rx="9.625"
            fill="#F3F1FC"
          />
          <rect
            x="15.375"
            y="104.375"
            width="41.25"
            height="19.25"
            rx="9.625"
            stroke="#E4DAFC"
            strokeWidth="0.75"
          />
          <path
            d="M24.232 119V109.2H27.578C28.362 109.2 29.006 109.331 29.51 109.592C30.014 109.853 30.3873 110.203 30.63 110.642C30.8726 111.081 30.994 111.58 30.994 112.14C30.994 112.672 30.8726 113.162 30.63 113.61C30.3966 114.049 30.028 114.403 29.524 114.674C29.02 114.935 28.3713 115.066 27.578 115.066H25.632V119H24.232ZM25.632 113.904H27.522C28.2593 113.904 28.782 113.745 29.09 113.428C29.4073 113.101 29.566 112.672 29.566 112.14C29.566 111.571 29.4073 111.132 29.09 110.824C28.782 110.516 28.2593 110.362 27.522 110.362H25.632V113.904ZM32.5034 119V109.2H35.5134C36.6521 109.2 37.5948 109.401 38.3414 109.802C39.0881 110.203 39.6388 110.773 39.9934 111.51C40.3574 112.238 40.5394 113.106 40.5394 114.114C40.5394 115.122 40.3574 115.99 39.9934 116.718C39.6388 117.446 39.0881 118.011 38.3414 118.412C37.6041 118.804 36.6614 119 35.5134 119H32.5034ZM33.9034 117.824H35.4574C36.3628 117.824 37.0814 117.675 37.6134 117.376C38.1454 117.077 38.5234 116.653 38.7474 116.102C38.9808 115.542 39.0974 114.879 39.0974 114.114C39.0974 113.339 38.9808 112.672 38.7474 112.112C38.5234 111.552 38.1454 111.123 37.6134 110.824C37.0814 110.525 36.3628 110.376 35.4574 110.376H33.9034V117.824ZM42.2241 119V109.2H48.3141V110.348H43.6241V113.526H47.5441V114.646H43.6241V119H42.2241Z"
            fill="#15112D"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7753_40459"
          x="0"
          y="0"
          width="261"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_7753_40459"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7753_40459"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7753_40459"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7753_40459"
          x1="130.5"
          y1="6"
          x2="130.263"
          y2="130"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.612635" stopColor="#D9D9D9" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_7753_40459">
          <rect
            width="247"
            height="124"
            fill="white"
            transform="translate(7 6)"
          />
        </clipPath>
        <clipPath id="clip1_7753_40459">
          <rect
            width="55.3493"
            height="78.9707"
            fill="white"
            transform="translate(103.588 27.54)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfIcon;
