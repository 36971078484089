import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../../hooks/context";
import { checkoutwithCard } from "../../../../services/sales-point";
import { cleanObject } from "../../../../helpers";
import { useParams } from "react-router-dom";
import { useGetInfo } from "../../../../hooks/useGetInfo";
import { useDispatch, useSelector } from "react-redux";
import { processRavenPayment } from "../../../../store/modules/payments";

const CardPaymentLogic = ({
  closePaymentSession,
  selectBank,
  orderDetails,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [selectedCardPayments, setSelectedCardPayments] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  const { store_id } = useParams();
  const { getStoreInfo } = useGetInfo();
  const storeDetails = getStoreInfo(store_id);
  const supportRavenPayment =
    storeDetails?.support_raven_payment_on_checkout_flow;
  const dispatch = useDispatch();
  const { raven } = useSelector((state) => state.paymentsReducer);

  // const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  // const fetchCardPayments = async () => {
  //   return await getTransactionViaCard().then((response) => response.data.data);
  // };
  // const { data: cardPayments, isLoading: isLoadingcardPayments } = useQuery(
  //   "cards",
  //   fetchCardPayments,
  //   {
  //     enabled: isEnabled,
  //     onSuccess: () => {
  //       setIsEnabled(false);
  //     },
  //
  //   }
  // );

  // const payViaCard = () => {
  //   setIsConfirmingPayment(true);
  //   checkoutwithCard({ order_id: orderId, reference: selectedCardPayments })
  //     .then(() => {
  //       setIsConfirmingPayment(false);
  //       triggerToast("Payment confirmation successful", "success");
  //       closePaymentSession();
  //     })
  //     .catch((error) => {
  //       setIsConfirmingPayment(false);
  //       if (error?.response?.status === 400)
  //         triggerToast(error?.response?.data?.detail, "warning");
  //     });
  // };

  const { isLoading: loadingExternal, mutate: paidExternally } = useMutation(
    (data) => checkoutwithCard(data),
  );

  const payViaExternal = () => {
    paidExternally(
      cleanObject({
        order_id: orderId,
        payment_provider: raven?.paymentType || null,
        payment_reference: raven?.rrn || null,
        ledger_account_id: selectBank,
      }),
      {
        onSuccess: () => {
          triggerToast("Payment linked successful", "success");
          closePaymentSession();
        },
      },
    );
  };

  useEffect(() => {
    if (!supportRavenPayment) return;

    dispatch(
      processRavenPayment({
        amount: orderDetails?.total_amount,
        allowFeedBack: false,
      }),
    );
  }, []);

  useEffect(() => {
    if (raven?.hasCompletedPayment) payViaExternal();
  }, [raven?.hasCompletedPayment]);

  return {
    // cardPayments,
    // isLoadingcardPayments,
    selectedCardPayments,
    isEnabled,
    // isConfirmingPayment,
    supportRavenPayment,
    loadingExternal,
    setSelectedCardPayments,
    setIsEnabled,
    // payViaCard,
    payViaExternal,
  };
};
export default CardPaymentLogic;
