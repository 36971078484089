import httpClient from "../axiosBase/axiosHandler";

export const getPrinters = () => {
  return httpClient.get("/print", {
    baseURL: process.env.REACT_APP_LUMI_PRINT_DRIVER_BASE_URL,
  });
};

export const pingPrintDriverServer = () => {
  return httpClient.get("/print/ping", {
    baseURL: process.env.REACT_APP_LUMI_PRINT_DRIVER_BASE_URL,
  });
};

export const print = (data) => {
  return httpClient.post("/print", data, {
    baseURL: process.env.REACT_APP_LUMI_PRINT_DRIVER_BASE_URL,
  });
};
