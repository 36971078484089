export const DownloadIcon = ({ ...rest }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.5 9.33334V13.3333C2.5 14.0697 3.09695 14.6667 3.83333 14.6667H13.1667C13.9031 14.6667 14.5 14.0697 14.5 13.3333V9.33334"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50008 11.3333L11.8334 7.70373M8.50008 2V11.3333V2ZM8.50008 11.3333L5.16675 7.70367L8.50008 11.3333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
