import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { VerificationIcon } from "../../../../assets/svgIcons/VerificationIcon";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import Validation from "./Validation";
import LoaderHelper from "../../LoaderHelper/LoaderHelper";
import useLogic from "./useLogic";

const VerificationModal = ({
  type,
  setVerificationModal,
  setIsConfirmedPin,
  setIsConfirmedAnswer,
}) => {
  const {
    pin,
    setPin,
    answere,
    setAnswere,
    question,
    password,
    setPassword,
    isLoadingQuestion,
    handleSendAnswer,
    isSendingAnswer,
    handleSendPin,
    isSendingPin,
  } = useLogic({
    setVerificationModal,
    setIsConfirmedPin,
    setIsConfirmedAnswer,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation(type);

  return (
    <secondaryComponents.RequestLoaderTrigger>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className={`${globalStyles.pageColumnGaps}`}
      >
        <div className="align-items-center text-center">
          <VerificationIcon />
          <p className="small-text-size text-dark">Verification</p>
        </div>
        <div>
          <h6 className="mb-0">
            {type === "pin"
              ? "Enter Your Security Answer To Reset Your PIN"
              : "Enter Your Security PIN To Reset Your Security Question & Answer"}
          </h6>
        </div>

        <div
          className={`${globalStyles.cardWithBorder}`}
          style={{ gap: "16px" }}
        >
          {type === "pin" ? (
            <div>
              <p className="small-text-size text-dark">Security Question:</p>
              <LoaderHelper isLoading={isLoadingQuestion}>
                <p className="smallest-text-size">
                  {question?.security_question}
                </p>
              </LoaderHelper>
            </div>
          ) : null}
          {type === "pin" ? (
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="answer"
              placeholder="Enter your answer"
              value={answere}
              onChange={({ target: { value } }) => {
                setAnswere(value);
              }}
              errorMessage={errors.answer}
              onKeyUp={() => errors.answer !== undefined && trigger("answer")}
              register={register}
            />
          ) : (
            <>
              <p className="small-text-size text-dark">
                Enter your Security PIN
              </p>
              <primaryComponents.OtpInputField
                numOfInputs={6}
                onChange={setPin}
                value={pin}
                secret={true}
              />
            </>
          )}
          <primaryComponents.PasswordInputField
            control={control}
            classNames="white"
            name="password"
            label={"Password"}
            isRequired={true}
            placeholder="Enter your password"
            value={password}
            onChange={({ target: { value } }) => {
              setPassword(value);
            }}
            errorMessage={errors.password}
            onKeyUp={() => errors.password !== undefined && trigger("password")}
            register={register}
          />
        </div>
        <primaryComponents.Button
          onClick={() =>
            type === "pin"
              ? handleSubmit(handleSendAnswer)()
              : handleSubmit(handleSendPin)()
          }
          classNames={"btn btn--primary cover"}
          isLoading={isSendingAnswer || isSendingPin}
        >
          Continue
        </primaryComponents.Button>
      </motion.div>
    </secondaryComponents.RequestLoaderTrigger>
  );
};

export default VerificationModal;
