import { useContext, useState } from "react";
import { useGenerateInvoice } from "../../../../hooks/api/mutations/useInventory";
import { ToastContext } from "../../../../hooks/context";
import {
  useAcceptOrRejectOrdereMutation,
  useCancelOrderMutation,
} from "../../../../hooks/api/mutations/useOrder";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {
  printOrderReceipt,
  printPaymentReceipt,
} from "../../../../services/sales-point";
import { usePrint } from "../../../../hooks/usePrint";

const useTransactionDetails = ({ orderId, handleRefetchOrder = () => {} }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [isPrintModal, setIsPrintModal] = useState(false);
  const [isEditDate, setIsEditDate] = useState(false);
  const [isOrderCancel, setIsOrderCancel] = useState(false);
  const [isOrderCustomer, setIsOrderCustomer] = useState(false);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [customerOrderDetails, setCustomerOrderDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [allowPrint, setAllowPrint] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [allowPaymentReceipt, setAllowPaymentReceipt] = useState(false);
  const { pingServer } = usePrint({ isEnabled: false });

  const printReceipt = async (billWasUpdated = false) => {
    const isPingSuccessful = await pingServer();
    if (isPingSuccessful) return setAllowPrint(true);

    setIsPrinting(true);
    printOrderReceipt(orderId, billWasUpdated)
      .then((response) => {
        const receiptLink =
          response?.data?.data?.url || response?.data?.data?.bill_url;
        window.open(receiptLink, "_blank");
        setIsPrinting(false);
      })
      .catch(() => {
        setIsPrinting(false);
      });
  };

  const printPaymentsReceipt = async () => {
    const isPingSuccessful = await pingServer();
    if (isPingSuccessful) return setAllowPrint(true);

    setAllowPaymentReceipt(true);
    printPaymentReceipt(orderId)
      .then((response) => {
        const receiptLink =
          response?.data?.data?.url || response?.data?.data?.bill_url;
        window.open(receiptLink, "_blank");
        setAllowPaymentReceipt(false);
      })
      .catch(() => {
        setAllowPaymentReceipt(false);
      });
  };

  const { handlegenerateInvoice, isLoading: isGeneratingInvoice } =
    useGenerateInvoice();

  const { mutate: cancelOrder, isLoading: isCancellingOrder } =
    useCancelOrderMutation();
  const cancelCurrentOrder = () => {
    cancelOrder(
      { orderId: orderId },
      {
        onSuccess: () => {
          triggerToast("Order Cancelled Successfully", "warning");
          handleRefetchOrder();
          queryClient.invalidateQueries(["getOrders"]);
        },
      },
    );
  };

  const { isLoading: isProcessingOrder, mutate: mutateAcceptOrReject } =
    useAcceptOrRejectOrdereMutation();
  const acceptOrRejectOrder = ({ isAccept = true, rejectionReason = null }) => {
    mutateAcceptOrReject(
      {
        id: orderId,
        isAccept,
        rejection_reason: rejectionReason,
      },
      {
        onSuccess: (res) => {
          setShowRejectionReason(false);
          handleRefetchOrder();
          triggerToast(res?.data?.detail, "success");
        },
      },
    );
  };

  return {
    isGeneratingInvoice,
    isCancellingOrder,
    isPrintModal,
    isEditDate,
    isOrderCustomer,
    customerOrderDetails,
    isProcessingOrder,
    showRejectionReason,
    isOrderCancel,
    allowPrint,
    isPrinting,
    setAllowPrint,
    setIsOrderCancel,
    acceptOrRejectOrder,
    setShowRejectionReason,
    setCustomerOrderDetails,
    setIsOrderCustomer,
    setIsEditDate,
    setIsPrintModal,
    cancelCurrentOrder,
    printReceipt,
    handlegenerateInvoice,
    printPaymentsReceipt,
    allowPaymentReceipt,
  };
};
export default useTransactionDetails;
