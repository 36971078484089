import { useState, useRef, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  addMultipleCompositeProducts,
  addMultipleProducts,
} from "../../../../services/inventory";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../../routes/pathContants";

const AddMultipleProductsLogic = () => {
  const [option, setOption] = useState("single");
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const inputRef = useRef();
  const navigate = useNavigate();

  const handleProductUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return triggerToast("Please select a file", "warning");

    if (event.target.files[0].size > 10000000) {
      return triggerToast("File size should be less than 10mb", "warning");
    }

    setIsUploadingFile(true);
    setRequestLoaderProgress(40);

    const formData = new FormData();
    formData.append("file", file);

    if (option !== "single") {
      addMultipleCompositeProducts(formData)
        .then(() => {
          setIsUploadingFile(false);
          setRequestLoaderProgress(100);
          navigate(pathConstants.INVENTORY_PRODUCTS);
          inputRef.current.value = null;
          triggerToast("File uploaded successfully", "success");
        })
        .catch(() => {
          setIsUploadingFile(false);
          setRequestLoaderProgress(100);
          inputRef.current.value = null;
        });
    } else {
      addMultipleProducts(formData)
        .then(() => {
          setIsUploadingFile(false);
          setRequestLoaderProgress(100);
          navigate(pathConstants.INVENTORY_PRODUCTS);
          inputRef.current.value = null;
          triggerToast("File uploaded successfully", "success");
        })
        .catch(() => {
          setIsUploadingFile(false);
          setRequestLoaderProgress(100);
          inputRef.current.value = null;
        });
    }
  };

  return {
    isUploadingFile,
    inputRef,
    option,
    setOption,
    handleProductUpload,
  };
};

export default AddMultipleProductsLogic;
