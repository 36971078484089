import styles from "./MenuPageLayout.module.scss";
import { Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import { useMenuPageLayout } from "./useMenuPageLayout";
import { CartIcon, Location } from "../../assets/svgIcons";
import Icon from "../../assets/icons";
import { IoIosArrowDown } from "react-icons/io";
import { RiTwitterLine } from "react-icons/ri";
import { FiFacebook, FiYoutube } from "react-icons/fi";
import { PiTiktokLogo } from "react-icons/pi";
import { FaGoogle, FaInstagram, FaSnapchat } from "react-icons/fa";

const MenuPageLayout = () => {
  const {
    primaryColor,
    countryFlag,
    isLoadingMenuObject,
    isLoadingMenuObjectDetail,
    menuCartItems,
    menuSearchText,
    showLocationChange,
    tempMenuId,
    showCartItems,
    menuObject,
    menuObjectDetail,
    isActiveCart,
    setIsActiveCart,
    setShowCartItems,
    setShowLocationChange,
    searchProduct,
  } = useMenuPageLayout();

  return (
    <>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingMenuObject || isLoadingMenuObjectDetail}
        classNames={"mt-5"}
      >
        <div className={styles.menuLayout}>
          <div
            style={{
              background: "white",
            }}
            className={styles.navbar}
          >
            {menuObjectDetail?.banner_url ? null : (
              <>
                {menuObjectDetail?.logo_url && (
                  <img
                    src={menuObjectDetail?.logo_url}
                    alt="logo"
                    className={styles.mainLogo}
                  />
                )}
              </>
            )}
            <primaryComponents.SearchField
              value={menuSearchText}
              placeholder={"Search products"}
              triggerOnInput={true}
              showsearchBtn={false}
              trigger={(value) => searchProduct(value)}
              classNames={`${styles.search}`}
              borderColor={primaryColor}
            />

            <div className={styles.navbar__left}>
              <div className={styles.navbar__right}>
                <div
                  onClick={() => setIsActiveCart(!isActiveCart)}
                  className={styles.rightItem}
                  style={{ position: "relative" }}
                >
                  <CartIcon color={primaryColor} />
                  <p>Cart</p>
                  <span
                    style={{ background: primaryColor + "E6" }}
                    className={styles.cartCount}
                  >
                    {menuCartItems?.length}
                  </span>
                </div>
                <div
                  onClick={() => setShowLocationChange(true)}
                  className={styles.rightItem}
                >
                  <div style={{ fontSize: 16 }}>
                    {menuObjectDetail?.store?.countries[0]?.emoji ||
                      countryFlag}
                  </div>
                  <p style={{ textDecoration: "underline" }}>
                    {menuObjectDetail?.store?.countries[0]?.name ||
                      menuObjectDetail?.store?.country}{" "}
                    <img src={Icon.arrow_down_alt} alt="arrow" />
                  </p>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <primaryComponents.Button
                  classNames={"btn"}
                  style={{
                    borderColor: primaryColor,
                    background: "#fff",
                    color: primaryColor,
                  }}
                  onClick={() => setShowLocationChange(true)}
                >
                  {" "}
                  <Location color={primaryColor} />
                  <span className="dark--text ml-2 mr-3">
                    {menuObjectDetail?.store?.countries[0]?.name ||
                      menuObjectDetail?.store?.country}
                  </span>
                  <IoIosArrowDown
                    color={primaryColor ? primaryColor : "#7647ee"}
                    size={20}
                  />
                </primaryComponents.Button>
              </div>

              {menuObjectDetail?.allow_payment &&
              (menuObjectDetail?.allow_in_store_checkout ||
                menuObjectDetail?.allow_delivery) ? (
                <primaryComponents.Button
                  classNames={`btn ${styles.cartBtn}`}
                  style={{
                    borderColor: primaryColor,
                    background: "#fff",
                    color: primaryColor,
                    padding: "0 15px",
                    overflow: "visible",
                  }}
                  onClick={() => setShowCartItems(true)}
                >
                  <CartIcon color={primaryColor ? primaryColor : "#7647ee"} />
                  <span
                    className={`${styles.cart} small-text-size`}
                    style={{
                      background: primaryColor,
                      color: "#fff",
                    }}
                  >
                    {menuCartItems?.length}
                  </span>
                </primaryComponents.Button>
              ) : null}
            </div>
          </div>
          <div
            className={styles.mobileSocialMedia}
            style={{
              marginBottom:
                menuObjectDetail?.banner_url && window.innerWidth < 850
                  ? 0
                  : -40,
            }}
          >
            <div>
              {menuObjectDetail?.social_media &&
                menuObjectDetail?.social_media.length > 0 && (
                  <div className={styles.socialContainer}>
                    {menuObjectDetail?.social_media?.map((social, index) => {
                      if (social.key === "twitter") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <RiTwitterLine color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "facebook") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FiFacebook color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "tiktok") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <PiTiktokLogo color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "instagram") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaInstagram color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "youtube") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FiYoutube color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "snapchat") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaSnapchat color={primaryColor} />
                          </a>
                        );
                      } else if (social.key === "google") {
                        return (
                          <a
                            key={index}
                            href={social.link}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.iconContainer}
                          >
                            <FaGoogle color={primaryColor} />
                          </a>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                )}
            </div>
            <p
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              style={{ color: primaryColor, cursor: "pointer" }}
            >
              Contact
            </p>
          </div>
          {menuObjectDetail?.banner_url && (
            <div className={styles.bannerContainer}>
              <img
                src={menuObjectDetail?.banner_url}
                className={styles.banner}
                alt="banner"
              />
              {menuObjectDetail?.logo_url && (
                <div className={styles.logoContainer}>
                  <img
                    src={menuObjectDetail?.logo_url}
                    style={{
                      maxWidth: "140px",
                      maxHeight: "80px",
                      width: "100%",
                    }}
                    alt="logo"
                    className={styles.logo}
                  />
                </div>
              )}
            </div>
          )}

          <div className={styles.menuOutlet}>
            <Outlet
              context={{
                menuSearchText,
                showCartItems,
                menuObject,
                menuObjectDetail,
                isActiveCart,
                setIsActiveCart,
                setShowCartItems,
              }}
            />
            <div className={styles.bottomContent}>
              <div className={styles.storefrontLoc}>
                <div>
                  <div className={styles.address}>
                    <img src={Icon.StorefrontLocation} alt="" />
                    <p>Address</p>
                  </div>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${menuObjectDetail?.store?.address
                      .split(" ")
                      .join("+")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {menuObjectDetail?.store?.address}
                  </a>
                </div>
                <div>
                  <div className={styles.address}>
                    <img src={Icon.StorefrontPhone} alt="" />
                    <p>Phone</p>
                  </div>
                  <p className={styles.place}>
                    +{menuObjectDetail?.store?.phone_number}
                  </p>
                </div>
              </div>
              <div className={styles.lumiPoweredBy}>
                <img src={Logo.powered_logo} alt="logo" />
                <div>
                  <p className={"small-text-size dark--text"}>Powered by</p>
                  <h4 className={"purple--text"}>Lumi Business</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.Modal
        isActive={showLocationChange}
        closeModal={() => setShowLocationChange(false)}
        width={1000}
        closeViaOverlay={tempMenuId ? true : false}
      >
        <secondaryComponents.StoreFrontLocationChangeModal
          primarColor={primaryColor}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default MenuPageLayout;
