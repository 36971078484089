import { useQuery } from "react-query";
import {
  getPrinters,
  pingPrintDriverServer,
} from "../../../services/printDriver";

export const useGetPrinters = ({ isEnabled = false }) => {
  return useQuery(
    ["getPrinters"],
    () => getPrinters().then((res) => res?.data?.printers),
    { retry: () => {}, enabled: isEnabled },
  );
};

export const usePingPrintDriverServer = () => {
  const { refetch, ...data } = useQuery(
    ["pingPrintDriverServer"],
    () =>
      pingPrintDriverServer().then((res) => {
        return res?.data;
      }),
    { enabled: false, retry: () => {} },
  );

  const pingServer = async () => {
    return new Promise((resolve) => {
      refetch()
        .then((res) => {
          if (res?.isError) resolve(false);
          else resolve(true);
        })
        .catch(() => resolve(false));
    });
  };

  return { pingServer, ...data };
};
