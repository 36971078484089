import { useSelector } from "react-redux";
import { useGetPricingTypes } from "./api/queries";

export const useGetInfo = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { data: pricingTypes } = useGetPricingTypes();

  const getStoreInfo = (store_id) => {
    const storeInfo = storeList?.find(
      (storeItem) => `${storeItem?.id}` === `${store_id}`,
    );

    return storeInfo;
  };

  const getPricingInfo = (pricing_id) => {
    const pricingInfo = pricingTypes?.find(
      (pricingItem) => `${pricingItem?.id}` === `${pricing_id}`,
    );

    return pricingInfo;
  };

  return {
    getStoreInfo,
    getPricingInfo,
  };
};
