import { useState } from "react";
import { useMutation } from "react-query";
import { salesEndOfDayView } from "../../../services/sales-point";
import { cleanObject } from "../../../helpers";
import { getEODPayment } from "../../../services/transaction";

const useStockHistoryStatement = () => {
  const [isEndOfDayDetails, setIsEndOfDayDetails] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [endOfDayDetails, setEndOfDayDetails] = useState();
  const [endOfDayFormDetails, setEndOfDayFormDetails] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    stores: [],
    initiators: [],
    order_source: "",
    payment_method: [],
    sub_account: "",
  });

  const { isLoading: isGeneratingDetails, mutate: getEndOfDayView } =
    useMutation((data) => salesEndOfDayView(data));
  const getEndOfDayDetails = () => {
    getEndOfDayView(
      cleanObject({
        store_id: endOfDayFormDetails?.stores
          .map((item) => item?.value)
          ?.join("|"),
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        initiator_id: endOfDayFormDetails?.initiators?.some(
          (item) => item?.value === "select all",
        )
          ? []
          : endOfDayFormDetails?.initiators?.length > 0
            ? endOfDayFormDetails?.initiators
                ?.map((item) => item?.value)
                ?.join("|")
            : null,
        order_source: endOfDayFormDetails?.order_source?.value,
      }),
      {
        onSuccess: (res) => {
          setEndOfDayDetails(res?.data?.data);
          setIsEndOfDayDetails(true);
        },
      },
    );
  };

  const { isLoading: isFetchingTnx, mutate: getEODPaymentView } = useMutation(
    (data) => getEODPayment(data),
  );

  const getPaymentEndOfDayDetails = () => {
    getEODPaymentView(
      cleanObject({
        payment_method: endOfDayFormDetails?.payment_method
          .map((item) => item?.value)
          ?.join("|"),
        sub_account_id: endOfDayFormDetails?.sub_account,
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
      }),
      {
        onSuccess: (res) => {
          setEndOfDayDetails(res?.data?.data);
          setIsPayment(true);
        },
      },
    );
  };

  return {
    isEndOfDayDetails,
    endOfDayDetails,
    endOfDayFormDetails,
    isGeneratingDetails,
    setEndOfDayFormDetails,
    getEndOfDayDetails,
    setEndOfDayDetails,
    setIsEndOfDayDetails,
    isFetchingTnx,
    setIsPayment,
    isPayment,
    getPaymentEndOfDayDetails,
  };
};
export default useStockHistoryStatement;
