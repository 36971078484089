import { useState, useEffect } from "react";
import {
  useGetAccountingBanksList,
  useGetBankTransactionList,
  useGetLedgerAccountList,
} from "../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../helpers";
import { useSearchParams } from "react-router-dom";
const useAllTransaction = () => {
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [showAddDebit, setShowAddDebit] = useState(false);
  const [fundsMovement, setFundsMovement] = useState(null);

  const {
    isLoading: isLoadingLedgerAccountsList,
    data: ledgerAccountsList,
    refetch: handleFetchLedgerAccountsList,
  } = useGetLedgerAccountList();

  const queries = getUrlQuerysection();
  const {
    isLoading: isLoadingBankTransaction,
    data: bankTransaction,
    refetch: handleFetchBankTransaction,
  } = useGetAccountingBanksList({ queries });

  const {
    isLoading: isLoadingTransactions,
    data: Transactions,
    refetch: handleFetchTransactions,
  } = useGetBankTransactionList({ queries });

  const [search, setSearch] = useSearchParams(
    ledgerAccountsList?.data[0]?.store?.id,
  );

  const [currentStore] = useState(
    search.get("store_id") || ledgerAccountsList?.data[0]?.store?.id,
  );

  const filterByStore = (value) => {
    search.set("store_id", value);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("store_id") || search.get("store_id") === undefined) {
      handleFetchLedgerAccountsList();
      if (ledgerAccountsList?.data?.length > 0) {
        const storeId = ledgerAccountsList.data[0]?.store?.id;
        if (storeId) {
          search.set("store_id", storeId);
          setSearch(search, { replace: true });
          filterByStore(storeId);
        }
      }
    } else {
      handleFetchBankTransaction();
      handleFetchTransactions();
    }
  }, [ledgerAccountsList, search, setSearch]);

  return {
    filterByStore,
    isLoadingBankTransaction,
    isLoadingTransactions,
    handleFetchTransactions,
    bankTransaction: bankTransaction?.data || [],
    Transactions: Transactions?.data || [],
    currentStore,
    showAddCredit,
    setShowAddCredit,
    handleFetchBankTransaction,
    showAddDebit,
    setShowAddDebit,
    fundsMovement,
    setFundsMovement,
    isLoadingLedgerAccountsList,
    ledgerAccountsList,
  };
};

export default useAllTransaction;
