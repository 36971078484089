import "./RadioButton.scss";
import { useState } from "react";

const RadioButton = ({
  value,
  name,
  id,
  isDisabled = false,
  checked = false,
  setRadioValue = () => {},
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <span className={"custom-radio"}>
      <input
        type="radio"
        className={"custom-radio__input"}
        value={value}
        name={name}
        id={id}
        disabled={isDisabled}
        defaultChecked={isChecked}
        onChange={(e) => {
          setRadioValue(e?.target?.value);
          setIsChecked(!isChecked);
        }}
        {...props}
      />
      <label htmlFor={id} className={"custom-radio__tag"}></label>
    </span>
  );
};
export default RadioButton;
