import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import useSellModeStoreFrontOrders from "./useSellModeStoreFrontOrders";
import primaryComponents from "../../primaryComponents";
import Pagination from "../Pagination/Pagination";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import secondaryComponents from "..";
import Orderflows from "../StoreCheckout/Orderflows";
import styles from "../BillsWidget/BillsWidget.module.scss";

const SellModeStoreFrontOrders = () => {
  const {
    instance,
    isFetchingOrders,
    orders,
    metaDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
  } = useSellModeStoreFrontOrders();

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div
        className={`d-flex flex-column ${styles.billsWidgetContent}`}
        style={{ gap: "20px", marginTop: "20px" }}
      >
        <div
          className={`${globalStyles.tableWrapper} ${globalStyles.addBorder}`}
        >
          <div
            className={`${globalStyles.tableHeader} justify-content-between`}
          >
            <primaryComponents.SearchField
              placeholder="Search for a product"
              trigger={(value) => handleSearchOrders(value)}
              triggerOnInput={true}
              classNames={globalStyles.searchField}
            />

            <div style={{ maxWidth: "200px", width: "100%" }}>
              <primaryComponents.SelectField
                value="Status"
                options={[
                  { label: "All", value: "" },
                  { label: "Placed", value: "PLACED" },
                  { label: "In Progress", value: "IN_PROGRESS" },
                  { label: "Pending", value: "PENDING" },
                  { label: "Order_Ready", value: "ORDER_READY" },
                  { label: "Completed", value: "COMPLETED" },
                  { label: "Shipped", value: "SHIPPED" },
                  { label: "Delivered", value: "DELIVERED" },
                  { label: "Returned", value: "RETURNED" },
                  { label: "Canceled", value: "CANCELED" },
                ]}
                onChange={(value) => {
                  if (value === "Status") return;
                  handleFilterOrderByStatus(value);
                }}
                nameKey="label"
                idKey="value"
                floatError={false}
              />
            </div>
          </div>
          <LoaderHelper isLoading={isFetchingOrders} classNames={"mt-5"}>
            <secondaryComponents.TableHelper
              getTableBodyProps={getTableBodyProps}
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              handleScroll={true}
            >
              <h4 className="mt-5 text-center">No Data Available</h4>
            </secondaryComponents.TableHelper>
          </LoaderHelper>
        </div>
        {orders?.length > 0 && (
          <Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        )}
      </div>

      <secondaryComponents.SideModal
        isActive={selectedOrder !== null}
        closeModal={() => setSelectedOrder(null)}
        width={1100}
      >
        <Orderflows
          closeModal={() => setSelectedOrder(null)}
          orderId={selectedOrder}
          defaultLanding={"tnxReceipt"}
          viaSaleRecord={true}
        />
      </secondaryComponents.SideModal>
    </>
  );
};
export default SellModeStoreFrontOrders;
