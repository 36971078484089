import usePrintLogic from "../usePrintLogic";
import { useRef } from "react";

export const TestView = ({
  allowPrint,
  closePrint = () => {},
  invalidateQueries = () => {},
}) => {
  const testPrintComponent = useRef(null);

  const clearAfterPrint = () => {
    invalidateQueries();
    closePrint();
  };

  const { paperSize } = usePrintLogic({
    isTest: true,
    itemDetails: allowPrint,
    sectionPrintComponent: testPrintComponent,
    hiddenId: "test-hidden-section",
    callBackFunc: clearAfterPrint,
    onErrorCallBackFunc: closePrint,
  });

  return (
    <>
      <div
        id="test-hidden-section"
        ref={testPrintComponent}
        style={{
          display: "none",
          maxWidth: `${paperSize}px`,
          minHeight: "1000px",
          border: "2px solid black",
          textAlign: "center",
          padding: "50px 20px",
          margin: "10px 0 30px",
        }}
      >
        <h4>
          Congratulations
          <br /> your setup was successful
        </h4>
      </div>
    </>
  );
};
