import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getAllRoles, sendUserInvite } from "../../../services/settings";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

const InviteUserLogic = (setShowInviteModal) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [businessId, setBusinessId] = useState([]);
  const [storeId, setStoreId] = useState([]);
  const [isInviting, setIsInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [roleDescription, setRoleDescription] = useState("");
  const { profileDetails, storeList } = useSelector(
    (state) => state.profileDetailsReducer,
  );

  const { data: dataRoles } = useQuery(["roles"], () =>
    getAllRoles().then((res) => res.data?.data),
  );

  const resetForm = () => {
    setEmail("");
    window.resetInviteForm({ email: "" });
    setRole("");
    window.resetInviteForm({ role: "" });
    window.clearInviteError();
  };

  const inviteUser = () => {
    setIsInviting(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    sendUserInvite({
      email: email,
      role_id: role,
      businesses_id: businessId,
      store_id: storeId.length > 0 ? storeId : undefined,
    })
      .then(() => {
        setIsInviting(false);
        triggerToast("Invitation sent successful", "success");
        setRequestLoaderProgress(100);
        resetForm();
        setShowInviteModal(false);
      })
      .catch((error) => {
        setIsInviting(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400) {
          setErrorMessage(error?.response?.data?.detail);
        }
      });
  };

  return {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    inviteUser,
    resetForm,
    userRoles: dataRoles
      ?.filter((item) => item?.label !== "BUSINESS MANAGER")
      .map((item) => ({
        ...item,
        label:
          item.label.charAt(0).toUpperCase() +
          item.label.slice(1).toLowerCase(),
      })),
    roleDescription,
    setRoleDescription,
    subAccounts: profileDetails?.business_list,
    businessId,
    setBusinessId,
    storeList,
    storeId,
    setStoreId,
  };
};
export default InviteUserLogic;
