import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FundWallerValidationSchema = yup.object({
  walletId: yup.string().required("Wallet is required"),
  store: yup.string().required("Store is required"),
  amount: yup.string().required("Amount is required"),
  paymentMethod: yup.string().required("Payment method is required"),
  note: yup.string(),
});

export const Validation = ({ fundDetails }) => {
  return useForm({
    resolver: yupResolver(FundWallerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      walletId: fundDetails?.wallet_id,
      store: fundDetails?.store_id,
      amount: fundDetails?.amount,
      paymentMethod: fundDetails?.payment_method,
      note: fundDetails?.description,
    },
  });
};
