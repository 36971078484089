import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import VerificationModal from "../../../components/secondaryComponents/SecurityQuestionAndPin/VerificationModal";
import SecurityPinLogic from "./SecurityPinLogic";
import Validation from "../../../components/secondaryComponents/SecurityQuestionAndPin/SecurityQuestionAndPinSetupModal/Validation";
import styles from "./SecurityPin.module.scss";

const SecurityPin = () => {
  const {
    pin,
    setPin,
    verificationModal,
    setVerificationModal,
    setIsConfirmedAnswer,
    confirmedAnswer,
    confirmedPin,
    setIsConfirmedPin,
    handleSendPin,
    isSendingPin,
    securityQuestions,
    isSendingSecurityQuestion,
    handleSendSecurityQuestion,
    questionId,
    answer,
    setQuestionId,
    setAnswer,
  } = SecurityPinLogic();
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Users -  Security Pin And Question"
      description="Configure your account’s security"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Security</h4>
            <p>Configure your account’s security</p>
          </div>

          <div
            className={`${globalStyles.cardWithBorder}`}
            style={{ gap: "16px" }}
          >
            <div className="d-flex flex-wrap justify-content-between">
              <div>
                <h6 className="mb-0 default-text-size">Security Pin</h6>
                <p className="small-text-size">
                  Create a 6-digit PIN to login into your account & confirm
                  transactions{" "}
                </p>
              </div>
              <primaryComponents.Button
                onClick={() =>
                  confirmedAnswer
                    ? handleSendPin()
                    : setVerificationModal("pin")
                }
                classNames={"btn btn--primary"}
                isLoading={isSendingPin}
                isDisabled={confirmedAnswer ? pin.length !== 6 : false}
              >
                {confirmedAnswer ? "Save" : "Reset"}
              </primaryComponents.Button>
            </div>
            {confirmedAnswer && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div
                  className={`${globalStyles.cardWithBorder} d-flex flex-column justify-content-center align-items-center`}
                >
                  <div className="p-3">
                    <p className="default-text-size text-dark p-2">
                      Reset Your 6-digit Pin
                    </p>
                    <p className="smallest-text-size p-2 mb-2">
                      Make sure to enter an easily memorizable pin.
                    </p>

                    <primaryComponents.OtpInputField
                      numOfInputs={6}
                      onChange={setPin}
                      value={pin}
                      secret={true}
                    />
                  </div>
                </div>
              </div>
            )}
            <hr />
            <div className="d-flex flex-wrap justify-content-between">
              <div>
                <h6 className="mb-0 default-text-size">
                  Security Question & Answer
                </h6>
                <p className="small-text-size">
                  Create security questions & answer for an added layer of
                  security
                </p>
              </div>
              <primaryComponents.Button
                onClick={() =>
                  confirmedPin
                    ? handleSendSecurityQuestion()
                    : setVerificationModal("question")
                }
                isLoading={isSendingSecurityQuestion}
                classNames={"btn btn--primary"}
                isDisabled={
                  confirmedPin ? answer === "" || questionId === "" : false
                }
              >
                {confirmedPin ? "Save" : "Reset"}
              </primaryComponents.Button>
            </div>
            {confirmedPin && (
              <div className={`${globalStyles.pageColumnGaps} p-3`}>
                <div className={styles.inputSection}>
                  <div className="w-50">
                    <primaryComponents.SelectFieldV2
                      control={control}
                      name="question"
                      label="Security Question"
                      isRequired={true}
                      options={securityQuestions}
                      onChange={(value) => {
                        setQuestionId(value);
                      }}
                      nameKey="question"
                      idKey="id"
                    />
                  </div>
                  <div className="w-50">
                    <primaryComponents.InputFieldV2
                      control={control}
                      classNames="white"
                      name="answer"
                      label="Security Answer"
                      isRequired={true}
                      placeholder="Enter the answer to your security question"
                      value={answer}
                      onChange={({ target: { value } }) => {
                        setAnswer(value);
                      }}
                      errorMessage={errors.answer}
                      onKeyUp={() =>
                        errors.answer !== undefined && trigger("answer")
                      }
                      register={register}
                      isDisabled={questionId === ""}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </motion.div>
        <secondaryComponents.Modal
          isActive={verificationModal !== null}
          closeModal={() => setVerificationModal(null)}
          width={400}
        >
          <VerificationModal
            type={verificationModal}
            setVerificationModal={setVerificationModal}
            setIsConfirmedAnswer={setIsConfirmedAnswer}
            setIsConfirmedPin={setIsConfirmedPin}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default SecurityPin;
