import { useOutletContext, useParams, useSearchParams } from "react-router-dom";

const useMenuPage = () => {
  const [search] = useSearchParams();
  const { id } = useParams();
  const {
    menuSearchText,
    showCartItems,
    setShowCartItems,
    isActiveCart,
    setIsActiveCart,
    menuObject: menuCategories,
    menuObjectDetail,
  } = useOutletContext();
  const orderId = search.get("orderId");

  return {
    orderId,
    menuId: id,
    menuCategories,
    menuObjectDetail: menuObjectDetail,
    menuSearchText,
    showCartItems,
    setShowCartItems,
    isActiveCart,
    setIsActiveCart,
  };
};

export default useMenuPage;
