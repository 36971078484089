import React from "react";

export const CopyIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4932_26914)">
        <path
          d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33325 10.0007H2.66659C1.93021 10.0007 1.33325 9.40372 1.33325 8.66732V2.66732C1.33325 1.93094 1.93021 1.33398 2.66659 1.33398H8.66659C9.40299 1.33398 9.99992 1.93094 9.99992 2.66732V3.33398"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <rect width="16" height="16" fill="white" />
      </defs>
    </svg>
  );
};
