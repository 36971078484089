/* eslint-disable jsx-a11y/anchor-is-valid */
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import useStoreCustomerForm from "./useStoreCustomerForm";
import styles from "./StoreCustomerForm.module.scss";
import Icon from "../../../../assets/icons";
import TableView from "../../TableView";
import { setTableDetails } from "../../../../store/modules/table";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { NoteIcon, WalletIcon } from "../../../../assets/svgIcons";
import AddCustomerSection from "../AddCustomerSection";

const StoreCustomerForm = ({ orderId, resetView }) => {
  const { resetView: resetMenuViews, setTableCardId } = useOutletContext();
  const dispatch = useDispatch();
  const {
    isSubmitting,
    isTable,
    tableDetails,
    numberOfDiners,
    showTables,
    isAddCustomer,
    customerDetails,
    isLoadingSections,
    orderType,
    notes,
    orderDetails,
    isLoadingOrderDetails,
    showKitchen,
    setNotes,
    setOrderType,
    setIsAddCustomer,
    setCustomerDetails,
    setNumberOfDiners,
    submitCustomerDetails,
    setIsTable,
  } = useStoreCustomerForm({
    orderId,
    resetView,
  });

  return (
    <>
      <secondaryComponents.LoaderHelper
        classNames={"mt-4"}
        isLoading={isLoadingOrderDetails}
      >
        <div className={styles.storeCustomerForm}>
          <div className={styles.checkout_heading_container}>
            <h3>Checkout</h3>
            <p>Add additional customer information</p>
          </div>

          {!orderDetails?.customer ? (
            <AddCustomerSection
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
            />
          ) : null}

          {showTables && orderDetails?.table === null ? (
            <div className={styles.bordererd}>
              <div className={styles.restaurant_table}>
                <div className={styles.restaurant_heading_container}>
                  <div className={styles.restaurant_heading}>
                    <img src={Icon.restaurantTableIcon} alt="" />
                    <h5 className="mb-0">Restaurant Table</h5>
                  </div>
                  <p className="smallest-text-size">
                    This table will be assigned to your customer&rsquo;s bill
                    for easy tracking
                  </p>
                </div>
                <primaryComponents.Button
                  onClick={() => setIsTable(true)}
                  classNames="btn btn--primary smallBtn"
                >
                  Select Table
                </primaryComponents.Button>
              </div>
              {tableDetails && (
                <div className={styles.selected_table_container}>
                  <div className={styles.selected_table}>
                    <p className={styles.table_name}>
                      <span>Table {tableDetails.name}</span> selected
                    </p>
                    <img
                      onClick={() => dispatch(setTableDetails(null))}
                      src={Icon.tableCloseIcon}
                      alt=""
                    />
                  </div>
                </div>
              )}
              {tableDetails ? (
                <primaryComponents.SelectField
                  name="numberOfDiners"
                  options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                  label="Number of Diners"
                  nameKey={"numberOfDiners"}
                  idKey={"id"}
                  value={numberOfDiners}
                  onChange={(value) => {
                    setNumberOfDiners(value);
                  }}
                />
              ) : null}
            </div>
          ) : null}

          {!isLoadingSections && showKitchen ? (
            <div className={styles.bordererd}>
              <div>
                <div className={styles.restaurant_heading}>
                  <WalletIcon />

                  <h5 className="mb-0">Order Type</h5>
                </div>
              </div>

              <div className="d-flex" style={{ gap: "15px" }}>
                <primaryComponents.BoxedRadioInput
                  style={{ height: "35px" }}
                  isChecked={orderType === "DINE_IN"}
                  value={true}
                  setRadioValue={() => setOrderType("DINE_IN")}
                  name="orderType"
                >
                  Dine-in
                </primaryComponents.BoxedRadioInput>
                <primaryComponents.BoxedRadioInput
                  name="orderType"
                  style={{ height: "35px" }}
                  isChecked={orderType === "TAKE_OUT"}
                  value={false}
                  setRadioValue={() => setOrderType("TAKE_OUT")}
                >
                  Take-out
                </primaryComponents.BoxedRadioInput>
              </div>
            </div>
          ) : null}

          <div className={styles.bordererd}>
            <div className={styles.restaurant_heading}>
              <NoteIcon />

              <h5 className="mb-0">Order Notes</h5>
            </div>
            <primaryComponents.TextArea
              floatError={false}
              value={notes}
              onChange={({ target: { value } }) => {
                setNotes(value);
              }}
            />
          </div>

          <div className={styles.storeCustomerForm__bottom}>
            <primaryComponents.Button
              classNames="btn btn--primary cover"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText="Submitting"
              onClick={submitCustomerDetails}
            >
              Next
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>

      <secondaryComponents.Modal
        closeModal={() => setIsTable(false)}
        isActive={isTable}
        width={1360}
      >
        <TableView
          resetView={() => setIsTable(false)}
          setTableCardId={setTableCardId}
          openBillsWidget={() => resetMenuViews("isOpenBillsWidget")}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        closeModal={() => setIsAddCustomer(false)}
        isActive={isAddCustomer}
        width={500}
      >
        <secondaryComponents.AddCustomer
          closeModal={() => setIsAddCustomer(false)}
          addCustomer={setCustomerDetails}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default StoreCustomerForm;
