import CopyToClipboard from "react-copy-to-clipboard";
import secondaryComponents from "../../../../../components/secondaryComponents";
import ProfileLogic from "../ProfileLogic";
import { motion } from "framer-motion";
import primaryComponents from "../../../../../components/primaryComponents";
import Icon from "../../../../../assets/icons";
import { useState } from "react";

const BusinessProfile = () => {
  const { business_name, referral_code, triggerToast, logo } = ProfileLogic();
  const [businessLogo, setBusinessLogo] = useState(logo || null);
  const [showImageModal, setShowImageModal] = useState(false);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Business Profile"
      description="Edit your business profile details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
        >
          <div className="profile-page__section">
            <div className="profile-page__section__left">
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{
                  gap: "10px",
                  background: "#F2F2F2",
                  padding: 8,
                  borderRadius: 4,
                }}
              >
                <div>
                  <p className="dark--text">Your Referral url link</p>
                </div>

                <secondaryComponents.ToolTipV2 tipText="Copy referral link">
                  <CopyToClipboard
                    text={`${window.location.origin}/signup?referral_code=${referral_code}`}
                    onCopy={() => triggerToast("Copied", "success")}
                  >
                    <primaryComponents.Button
                      classNames={"btn btn--outline smallBtn"}
                      style={{ padding: "2px 8px", height: "25px" }}
                    >
                      <img width="15px" src={Icon.copy} alt="" />{" "}
                      <span className="ml-2">Copy</span>
                    </primaryComponents.Button>
                  </CopyToClipboard>
                </secondaryComponents.ToolTipV2>
              </div>
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{
                  gap: "10px",
                  background: "#F2F2F2",
                  padding: 8,
                  borderRadius: 4,
                }}
              >
                <div className="d-flex align-items-center">
                  <p className="dark--text mr-1">Your Referral Code:</p>
                  <h6 className="mb-0">{referral_code}</h6>
                </div>

                <secondaryComponents.ToolTipV2 tipText="Copy referral code">
                  <CopyToClipboard
                    text={referral_code}
                    onCopy={() => triggerToast("Copied", "success")}
                  >
                    <primaryComponents.Button
                      classNames={"btn btn--outline smallBtn"}
                      style={{ padding: "2px 8px", height: "25px" }}
                    >
                      <img width="15px" src={Icon.copy} alt="" />{" "}
                      <span className="ml-2">Copy</span>
                    </primaryComponents.Button>
                  </CopyToClipboard>
                </secondaryComponents.ToolTipV2>
              </div>
              <hr style={{ width: "calc(100% + 30px)" }} />
              {businessLogo ? (
                <div className="profile-page__section__left__logo">
                  <img src={businessLogo} alt="business logo" />
                </div>
              ) : (
                <div className="profile-page__section__left__avatar">
                  {business_name?.charAt(0)}
                </div>
              )}

              <p className="dark--text">{business_name}</p>

              <primaryComponents.Button
                classNames={"btn btn--outline smalBtn"}
                onClick={() => setShowImageModal(true)}
              >
                Upload business logo
              </primaryComponents.Button>
            </div>
            <div className="profile-page__section__right">
              <secondaryComponents.ProfileBusinessInfoForm
                businessLogo={businessLogo}
              />
            </div>
          </div>
        </motion.div>

        <secondaryComponents.Modal
          width={1000}
          isActive={showImageModal}
          closeModal={() => setShowImageModal(false)}
        >
          <secondaryComponents.ImageUploadModal
            closeModal={() => setShowImageModal(false)}
            setImage={(val) => setBusinessLogo(val)}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default BusinessProfile;
