import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = {
  raven: {
    popUpRaven: false,
    amount: null,
    hasCompletedPayment: false,
    allowFeedBack: true,
    rrn: null,
    paymentType: null,
  },
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    processRavenPayment: (state, action) => {
      state.raven.popUpRaven = true;
      state.raven.amount = action?.payload?.amount || null;
      state.raven.allowFeedBack =
        action?.payload?.allowFeedBack !== undefined
          ? action?.payload?.allowFeedBack
          : true;
      state.raven.hasCompletedPayment = false;
    },
    closeRavenPayment: (state) => {
      state.raven.amount = null;
      state.raven.hasCompletedPayment = true;
    },
    resetPopUpRaven: (state) => {
      state.raven.popUpRaven = false;
    },
    setRavenCredential: (state, action) => {
      state.raven.rrn = action?.payload?.rrn || null;
      state.raven.paymentType = action?.payload?.paymentType || null;
    },
    resetHasCompletedPayment: (state) => {
      state.raven.hasCompletedPayment = false;
    },
  },
});

export const {
  processRavenPayment,
  closeRavenPayment,
  resetPopUpRaven,
  resetHasCompletedPayment,
  setRavenCredential,
} = paymentsSlice.actions;
