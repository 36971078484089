import styles from "./BulkRestock.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBulkRestock } from "./useBulkRestock";
import { Validation } from "./Validation";
import { formatCurrency } from "../../../../helpers";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PricingWarning, WarningDialog } from "../../../../components/common";

const BulkRestock = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    restockData,
    suppliers,
    subtotal,
    totalPrice,
    otherRestockInfo,
    isLoadingDocUpload,
    isRestocking,
    storeList,
    currentStore,
    storeError,
    modalViews,
    detectedPricing,
    resetModalViews,
    setCurrentStore,
    setStoreError,
    setOtherRestockInfo,
    setRestockData,
    handleAppendRestockData,
    restockProducts,
    handleFetchSuppliers,
    handleInvoiceUpload,
    filterByStore,
    bankAccount,
    isLoadingBankAccountsList,
  } = useBulkRestock();
  const { allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
  } = Validation({ restockData, otherRestockInfo });

  useEffect(() => {
    setValue("restockProperties", restockData);
    setValue("supplierName", otherRestockInfo?.supplier_id);
    setValue("invoiceDate", otherRestockInfo?.invoice_date);
  }, [restockData, otherRestockInfo]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Product"
      description="Bulk restock products"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          className={styles.bulkRestockPage}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          {/* content section */}
          <form
            className={styles.bulkRestockPage__content}
            onSubmit={handleSubmit(restockProducts)}
            id="supplierForm"
          >
            <div className={styles.bulkRestockPage__content__header}>
              <h4>Restock </h4>
              <div className={styles.supplierSection}>
                <div>
                  <primaryComponents.SelectFieldV2
                    name="supplierName"
                    control={control}
                    label={"Supplier Name"}
                    options={suppliers}
                    isRequired={true}
                    nameKey={"name"}
                    idKey={"id"}
                    giveControl={false}
                    value={{
                      label:
                        otherRestockInfo?.supplier_id !== ""
                          ? suppliers?.find(
                              (item) =>
                                item?.id === otherRestockInfo?.supplier_id,
                            )?.name
                          : "Select supplier",
                      value: otherRestockInfo?.supplier_id,
                    }}
                    onChange={(value) => {
                      setOtherRestockInfo((prev) => ({
                        ...prev,
                        supplier_id: value,
                      }));
                    }}
                    floatError={false}
                    errorMessage={
                      errors.supplierName && {
                        message: errors.supplierName.message,
                      }
                    }
                  />
                  <p
                    style={{ cursor: "pointer", fontSize: "14px" }}
                    onClick={() => resetModalViews("isCreateSupplier", true)}
                    className="mb-3 purple--text font-weight-semibold"
                  >
                    + Add Supplier
                  </p>
                </div>
                <div className="mt-2">
                  <primaryComponents.InputFieldV2
                    name="invoiceNumber"
                    control={control}
                    label={"Invoice/Receipt Number"}
                    classNames="white"
                    value={otherRestockInfo?.invoice_number}
                    onChange={({ target: { value } }) => {
                      setOtherRestockInfo((prev) => ({
                        ...prev,
                        invoice_number: value,
                      }));
                    }}
                  />
                </div>
                <div className="mt-2">
                  <primaryComponents.InputFieldV2
                    name="orderNumber"
                    control={control}
                    label={"Order Number"}
                    classNames="white"
                    value={otherRestockInfo?.order_number}
                    onChange={({ target: { value } }) => {
                      setOtherRestockInfo((prev) => ({
                        ...prev,
                        order_number: value,
                      }));
                    }}
                  />
                </div>
                <primaryComponents.DateInputV2
                  name="invoiceDate"
                  control={control}
                  label={"Invoice/Receipt Date"}
                  isRequired={true}
                  value={otherRestockInfo?.invoice_date}
                  onChange={({ target: { value } }) => {
                    setOtherRestockInfo((prev) => ({
                      ...prev,
                      invoice_date: value,
                    }));
                  }}
                  errorMessage={
                    errors.invoiceDate && {
                      message: errors.invoiceDate.message,
                    }
                  }
                />
              </div>
              <div className="d-flex flex-wrap w-100" style={{ gap: "10px" }}>
                <div style={{ minWidth: "270px" }}>
                  <primaryComponents.SelectField
                    options={storeList}
                    idKey="id"
                    nameKey="name"
                    onChange={(value) => {
                      setCurrentStore(value);
                      filterByStore(value);
                    }}
                    value={
                      storeList?.find(
                        (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                      )?.name || "Select store"
                    }
                    floatError={false}
                    errorMessage={
                      storeError !== "" ? { message: storeError } : undefined
                    }
                    classNames="store-select"
                    isSearchable={false}
                  />
                </div>

                {allow_ledger_account ? (
                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingBankAccountsList}
                  >
                    <div style={{ minWidth: "270px" }}>
                      <primaryComponents.SelectField
                        options={bankAccount?.data?.map((bank) => ({
                          id: bank?.id,
                          name: bank?.account_name,
                        }))}
                        idKey="id"
                        nameKey="name"
                        value={"Select Account"}
                        onChange={(value) => {
                          setOtherRestockInfo((prev) => ({
                            ...prev,
                            ledger_account_id: value,
                          }));
                        }}
                        floatError={false}
                        classNames="bank-name"
                        isSearchable={false}
                        // isDisabled={currentStore === null}
                      />
                    </div>
                  </secondaryComponents.LoaderHelper>
                ) : null}
                <div className={styles.searchSection}>
                  <secondaryComponents.BulkRestockComboBox
                    handleAppendRestockData={handleAppendRestockData}
                    setStoreError={setStoreError}
                  />
                </div>
              </div>
            </div>

            {/* table section */}
            <secondaryComponents.LoaderHelper
              classNames={"mt-5"}
              isLoading={isLoading}
            >
              <secondaryComponents.BulkRestockTable
                restockData={restockData}
                setRestockData={setRestockData}
                suppliers={suppliers}
                currentStore={currentStore}
                control={control}
                trigger={trigger}
                errors={errors}
                register={register}
              />
            </secondaryComponents.LoaderHelper>

            {/* price summary */}
            <div
              className={`${styles.summarySection} ${styles.individualBody}`}
            >
              <h4 className="mb-3">Price Summary</h4>
              <div className={styles.summaryRow}>
                <p>Subtotal:</p>
                <p className="font-weight-bold">
                  {formatCurrency({
                    value: subtotal,
                    currencyCode: storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.currency_code,
                    decimal: 2,
                  })}
                </p>
              </div>
              <div className={styles.summaryRow}>
                <p>Discount:</p>
                <primaryComponents.InputFieldV2
                  name="discount"
                  control={control}
                  classNames="white"
                  placeholder={`Discount amount (${
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.currency_code
                  })`}
                  isRequired={false}
                  type="number"
                  min="0"
                  step={0.01}
                  floatError={false}
                  value={otherRestockInfo?.discount}
                  onChange={({ target: { value } }) => {
                    setOtherRestockInfo((prev) => ({
                      ...prev,
                      discount: value,
                    }));
                  }}
                />
              </div>
              <div className={styles.summaryRow}>
                <p>VAT (%):</p>
                <primaryComponents.InputFieldV2
                  control={control}
                  name="vat"
                  classNames="white"
                  placeholder="Vat (%)"
                  floatError={false}
                  type="number"
                  min="0"
                  step={0.01}
                  value={otherRestockInfo?.vat}
                  onChange={({ target: { value } }) => {
                    setOtherRestockInfo((prev) => ({
                      ...prev,
                      vat: value,
                    }));
                  }}
                />
              </div>
              <div className={styles.summaryRow}>
                <p>Total:</p>
                <p className="purple--text font-weight-bold">
                  {formatCurrency({
                    value: totalPrice,
                    currencyCode: storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.currency_code,
                  })}
                </p>
              </div>
            </div>

            {/* payment section */}
            <div
              className={`${styles.summarySection} ${styles.individualBody}`}
            >
              <h4 className="mb-3">Payment Summary</h4>

              <div className={styles.summaryRow}>
                <p>Amount paid to supplier:</p>
                <div>
                  <primaryComponents.InputFieldV2
                    control={control}
                    name="amountPaid"
                    classNames="white"
                    placeholder={`Amount paid (${
                      storeList?.find(
                        (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                      )?.currency_code
                    })`}
                    floatError={false}
                    type="number"
                    step={0.01}
                    value={otherRestockInfo?.amount_paid}
                    onChange={({ target: { value } }) => {
                      setOtherRestockInfo((prev) => ({
                        ...prev,
                        amount_paid: value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className={styles.summaryRow}>
                <p>Payment Method:</p>

                <primaryComponents.SelectFieldV2
                  control={control}
                  name="paymentMethod"
                  options={["cash", "card", "transfer", "cheque"]}
                  floatError={false}
                  classNames="white"
                  giveControl={false}
                  value={{
                    label:
                      otherRestockInfo?.payment_method !== ""
                        ? ["cash", "card", "transfer", "cheque"]?.find(
                            (item) => item === otherRestockInfo?.payment_method,
                          )
                        : "Select payment",
                    value: otherRestockInfo?.payment_method,
                  }}
                  onChange={(value) => {
                    setOtherRestockInfo((prev) => ({
                      ...prev,
                      payment_method: value,
                    }));
                  }}
                />
              </div>
              <div className={styles.summaryRow}>
                <p>Outstanding Balance:</p>
                <p className="purple--text font-weight-bold">
                  {formatCurrency({
                    value:
                      totalPrice -
                      (otherRestockInfo?.amount_paid === ""
                        ? 0
                        : otherRestockInfo?.amount_paid),
                    currencyCode: storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.currency_code,
                  })}
                </p>
              </div>
              <div className={styles.summaryRow}>
                <p>Repayment/Invoice Due Date:</p>
                <primaryComponents.DateInputV2
                  control={control}
                  name="invoiceDueDate"
                  // floatError={false}
                  classNames="white"
                  onChange={({ target: { value } }) => {
                    setOtherRestockInfo((prev) => ({
                      ...prev,
                      invoice_due_date: value,
                    }));
                  }}
                  value={otherRestockInfo?.invoice_due_date}
                />
              </div>
            </div>

            <div className={`${styles.individualBody}`}>
              <h5>Notes</h5>
              <div className={styles.notesSection}>
                <div className={styles.noteField}>
                  <primaryComponents.InputFieldV2
                    control={control}
                    name="notes"
                    classNames="white"
                    floatError={false}
                    value={otherRestockInfo?.notes}
                    onChange={({ target: { value } }) => {
                      setOtherRestockInfo((prev) => ({
                        ...prev,
                        notes: value,
                      }));
                    }}
                  />
                </div>
                <div className={styles.invoiceField}>
                  <primaryComponents.FileInput
                    name="Drag and drop document here or Browse"
                    onChange={(document) => {
                      if (document) handleInvoiceUpload(document);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={` ${styles.individualBody}`}>
              <primaryComponents.Button
                type="submit"
                form="supplierForm"
                classNames="btn btn--primary ml-auto"
                isLoading={isRestocking || isLoadingDocUpload}
                loadingText={
                  isLoadingDocUpload
                    ? "Uploading document..."
                    : "Restocking products..."
                }
                isDisabled={isRestocking || isLoadingDocUpload}
              >
                Submit
              </primaryComponents.Button>
            </div>
          </form>

          <secondaryComponents.Modal
            isActive={modalViews?.isCreateSupplier}
            closeModal={() => resetModalViews("isCreateSupplier", false)}
            width="600px"
          >
            <secondaryComponents.SupplierForm
              closeWidget={() => resetModalViews("isCreateSupplier", false)}
              handleFetchSuppliers={handleFetchSuppliers}
            />
          </secondaryComponents.Modal>

          <secondaryComponents.Modal
            isActive={modalViews?.isWarning}
            closeModal={() => resetModalViews("isWarning", false)}
            width={500}
          >
            <WarningDialog
              closeModal={() => resetModalViews("isWarning", false)}
              title={"Pricing Warning"}
              handler={restockProducts}
            >
              <PricingWarning detectedPricing={detectedPricing} />
            </WarningDialog>
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default BulkRestock;
