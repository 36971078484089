import React, { useEffect, useState } from "react";
import styles from "../../MenuPage.module.scss";
import { useRef } from "react";
import MainsCard from "../MainsCard";
import { useGetStorefrontProductList } from "../../../../../hooks/api/queries";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import { useSearchParams } from "react-router-dom";
import {
  ChevronLeftArrow,
  ChevronRightArrow,
} from "../../../../../assets/svgIcons";

const CategorySection = ({ index, categoryItem, menuObjectDetail }) => {
  const categoryRef = useRef(null);
  const [search] = useSearchParams();
  const primaryColor = menuObjectDetail?.primary_colour;
  const secondaryColor = menuObjectDetail?.secondary_colour
    ? menuObjectDetail?.secondary_colour
    : index % 2
      ? "#f3f1fc"
      : "#fef7eb";
  const [params, setParams] = useState({
    category_id: categoryItem?.category?.id,
    page: 1,
    user_query: search?.get("query") || "",
  });
  const { data, isLoading } = useGetStorefrontProductList({
    params,
    storefrontId: menuObjectDetail?.id,
  });
  const productsList = data?.data;

  const searchProduct = () => {
    setParams((prev) => ({
      ...prev,
      page: 1,
      user_query: search?.get("query") || "",
    }));
  };

  const gotoPage = (value) => {
    if (!value) return;
    setParams((prev) => ({ ...prev, page: value }));
  };

  useEffect(() => {
    searchProduct();
  }, [search.get("query")]);

  return (
    <>
      {productsList?.length === 0 && search.get("query") ? null : (
        <div
          ref={categoryRef}
          style={{
            marginTop: index > 0 ? 50 : 32,
          }}
          className={styles.mainsContainer}
        >
          <div className="d-flex justify-content-between align-items-center gap-3">
            <h4 className="mb-0" style={{ textTransform: "capitalize" }}>
              {categoryItem?.category?.name}
            </h4>

            <div className="d-flex" style={{ gap: "10px" }}>
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                isDisabled={!data?.meta?.previous_page}
                style={{
                  borderColor: primaryColor,
                }}
                onClick={() => {
                  gotoPage(data?.meta?.previous_page);
                }}
              >
                <ChevronLeftArrow color={primaryColor} />
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                isDisabled={!data?.meta?.next_page}
                style={{
                  borderColor: primaryColor,
                }}
                onClick={() => {
                  gotoPage(data?.meta?.next_page);
                }}
              >
                <ChevronRightArrow color={primaryColor} />
              </primaryComponents.Button>
            </div>
          </div>

          <div
            className={styles.mainsCard}
            style={{
              // background: index % 2 ? "#f3f1fc" : "#fef7eb",
              background: secondaryColor,
            }}
          >
            <secondaryComponents.LoaderHelper
              isLoading={isLoading}
              iconColor={primaryColor}
              classNames={"py-4"}
            >
              {productsList?.length > 0 ? (
                <>
                  <div className={styles.mainsCard__items}>
                    {productsList?.map((item, index) => (
                      <MainsCard
                        key={item.id}
                        productIndex={index}
                        productItem={item}
                        store={menuObjectDetail?.store}
                        menuObjectDetail={menuObjectDetail}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div>
                  <h5>No Data Avialable</h5>
                </div>
              )}
            </secondaryComponents.LoaderHelper>
          </div>
        </div>
      )}
    </>
  );
};

export default CategorySection;
