import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  createOrder,
  getCartItems,
  createBillOrder,
  createPendCheckoutSession,
  addToCartBulk,
} from "../../../services/sales-point";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  calculateCartItem,
  cleanObject,
  //getRandomIntInclusive,
} from "../../../helpers";
import { useEffect } from "react";
import { useOtpAuthTrigger } from "../../../hooks/useOtpAuthTrigger";

const reArrangeCartItems = (items, storeId) => {
  return items?.map((item) => {
    const isService = item?.service ? true : false;
    const productType = isService
      ? "store_service_properties"
      : "store_product_properties";
    const isCustomPrice = item?.is_custom_price;
    const customPrice = isCustomPrice ? item?.unit_price : undefined;
    const variant = item?.variant
      ? {
          ...item?.variant,
          store_variant_properties: [
            item?.variant?.store_variant_properties?.find(
              (variantItem) => `${variantItem?.store_id}` === `${storeId}`,
            ),
          ],
        }
      : null;
    const unitPrice = variant
      ? (
          Number(variant?.store_variant_properties?.[0]?.selling_price) -
          (Number(variant?.store_variant_properties?.[0]?.selling_price) *
            Number(variant?.store_variant_properties?.[0]?.discount_rate)) /
            100
        ).toFixed(2)
      : (
          Number(item?.selling_price) -
          (Number(item?.selling_price) * Number(item?.discount_rate)) / 100
        ).toFixed(2);

    return {
      ...item,
      id: `${item?.product?.id || item?.service?.id}${item?.variant?.id || ""}`,
      is_service: isService,
      unit_price: isCustomPrice ? unitPrice : item?.unit_price,
      custom_price: customPrice,
      pricing_type_id: item?.pricing_type?.id,
      product: isService
        ? {
            ...item?.service,
            [productType]: [
              {
                pricings: item?.pricings,
              },
            ],
            is_service: isService,
          }
        : {
            ...item?.product,
            variant: item?.variant
              ? {
                  ...item?.variant,
                  store_variant_properties: [
                    item?.variant?.store_variant_properties?.find(
                      (variantItem) =>
                        `${variantItem?.store_id}` === `${storeId}`,
                    ),
                  ],
                }
              : null,
            [productType]: [
              {
                pricings: item?.pricings,
              },
            ],
            is_service: isService,
          },
    };
  });
};
const loyaltyObject = {
  loyaltyType: "GIFT_CARD",
  redemption_code: "",
  customer_id: "",
  giftcard_amount: "",
  loyalty_point_amount: "",
  customerDetails: {
    customerName: "",
    customerPhoneNumber: "",
    customerPhoneCode: "",
    customerEmail: "",
  },
};

const useStoreCart = ({
  setRefreshProducts,
  addCartItemToLocalStorage,
  isRefreshCart,
  setIsRefreshCart,
}) => {
  const {
    cartDetails: {
      data: cartItems,
      total_items,
      total_amount,
      total_items_amount,
      vat_amount,
      vat,
      service_charge,
      service_charge_amount,
      custom_pricing_auth_pin,
      loyalty_auth_pin,
      giftcard_auth_pin,
    },
    pendingCheckoutSessions: { meta: pageDetails },
  } = useSelector((state) => state.cartReducer);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const [discount, setDiscount] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [loyaltyDetails, setLoyaltyDetails] = useState(loyaltyObject);

  const {
    profileDetails: { role: business_role },
  } = useSelector((state) => state.profileDetailsReducer);
  const { tableDetails } = useSelector((state) => state.tableReducer);
  const { store_id } = useParams();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();
  const [discoutModals, setDiscoutModals] = useState({
    isDiscount: false,
    isDelivery: false,
    isLoyalty: false,
  });
  const { clearLatestOtpAuthCode, getAuthCodeBaseOnKey } = useOtpAuthTrigger(
    {},
  );

  useEffect(() => {
    if (isRefreshCart) refetchCartItems();
  }, [isRefreshCart]);

  const { isLoading: isFetchingCartItems, refetch: refetchCartItems } =
    useQuery(
      ["getCartItems", store_id],
      () =>
        getCartItems(store_id).then((res) => {
          let newData = {};
          if (localStorage.getItem(`cartItems${store_id}`)) {
            newData = JSON.parse(localStorage.getItem(`cartItems${store_id}`));
            if (newData?.length === 0)
              newData = reArrangeCartItems([...res?.data?.data], store_id);
          } else newData = reArrangeCartItems([...res?.data?.data], store_id);

          const data = calculateCartItem({
            cart_items: newData,
            vat: res?.data?.vat,
            service_charge: res?.data?.service_charge,
          });
          addCartItemToLocalStorage({
            ...data,
          });
          setIsRefreshCart(false);
          return { ...res.data };
        }),
      {
        staleTime: Infinity,
      },
    );

  const clearCartItems = () => {
    const data = {
      data: [],
      total_items: 0,
      total_amount: 0,
      total_items_amount: 0,
      vat_amount: 0,
      service_charge: 0,
      service_charge_amount: 0,
      custom_pricing_auth_pin: null,
      discount_auth_pin: null,
      delivery_fee_auth_pin: null,
      loyalty_auth_pin: null,
      giftcard_auth_pin: null,
    };
    addCartItemToLocalStorage({ ...data, vat, service_charge });
  };

  const handleCreateOrderMutation = useMutation((data) => createOrder(data));
  const handleCreateOrder = () => {
    const data = {
      store_id: store_id,
      discount_auth_pin: getAuthCodeBaseOnKey("add_discount_to_order"),
      delivery_fee_auth_pin: getAuthCodeBaseOnKey("add_delivery_fee_to_order"),
      loyalty_auth_pin,
      giftcard_auth_pin,
    };
    if (discount !== "")
      data.discount = {
        amount: Number(discount),
        type: "flat",
      };

    if (deliveryFee !== "")
      data.delivery_fee = {
        amount: Number(deliveryFee),
        type: "flat",
      };

    if (
      loyaltyDetails.redemption_code !== "" &&
      loyaltyDetails.giftcard_amount !== ""
    )
      data.giftcard = {
        redemption_code: loyaltyDetails.redemption_code,
        amount: Number(loyaltyDetails.giftcard_amount),
      };

    if (
      loyaltyDetails.customer_id !== "" &&
      loyaltyDetails.loyalty_point_amount !== ""
    )
      data.loyalty_point = {
        customer_id: loyaltyDetails.customer_id,
        amount: Number(loyaltyDetails.loyalty_point_amount),
      };

    handleCreateOrderMutation.mutate(cleanObject(data), {
      onSuccess: (res) => {
        const orderId = res?.data?.data?.id;
        navigate(`/sell-mode/${store_id}?order_id=${orderId}`);
        triggerToast("Order Created", "success");
        onSuccess();
      },
    });
  };

  const handleGenerateBillMutation = useMutation((data) =>
    createBillOrder(data),
  );
  const handleGenerateBill = () => {
    const data = {
      store_id: store_id,
      discount_auth_pin: getAuthCodeBaseOnKey("add_discount_to_order"),
      delivery_fee_auth_pin: getAuthCodeBaseOnKey("add_delivery_fee_to_order"),
      loyalty_auth_pin,
      giftcard_auth_pin,
    };

    if (discount !== "")
      data.discount = {
        amount: Number(discount),
        type: "flat",
      };

    if (deliveryFee !== "")
      data.delivery_fee = {
        amount: Number(deliveryFee),
        type: "flat",
      };

    handleGenerateBillMutation.mutate(cleanObject(data), {
      onSuccess: (res) => {
        setRefreshProducts();
        const orderId = res.data?.data?.id;
        navigate(`/sell-mode/${store_id}?bill_id=${orderId}`);
        triggerToast("Bill Generated", "success");
        onSuccess();
      },
    });
  };

  const onSuccess = () => {
    setDeliveryFee("");
    setDiscount("");
    setLoyaltyDetails(loyaltyObject);
    queryClient.invalidateQueries(["getOrders"]);
    queryClient.invalidateQueries(["getProductList"]);
    clearLatestOtpAuthCode([
      "add_discount_to_order",
      "add_delivery_fee_to_order",
    ]);
    clearCartItems();
  };

  const handlePendCheckoutMutation = useMutation((data) =>
    createPendCheckoutSession(data),
  );
  const handlePendCheckout = () => {
    handlePendCheckoutMutation.mutate(
      {
        store_id: store_id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getPendingSessions"]);
          clearCartItems();
          triggerToast("Session Pended!", "success");
        },
      },
    );
  };

  const bulkAddToCartMutation = useMutation((data) => addToCartBulk(data));
  const bulkAddToCart = (
    { isCreatOrder, isPendCheckout, isGenerateBill } = {
      isCreatOrder: false,
      isPendCheckout: false,
      isGenerateBill: false,
    },
  ) => {
    //setRequestLoaderProgress(getRandomIntInclusive(10, 30));
    const newData = cartItems?.map((item) => {
      return {
        [item?.is_service ? "service_id" : "product_id"]: item?.product?.id,
        quantity: item?.quantity,
        variant_id: item?.product?.variant
          ? item?.product?.variant?.id
          : undefined,
        is_product: !item?.is_service,
        pricing_type_id: item?.pricing_type_id,
        custom_price: item?.custom_price,
        items_number: item?.item_numbers,
      };
    });

    bulkAddToCartMutation.mutate(
      {
        store_id: store_id,
        items: cleanObject(newData),
        custom_pricing_auth_pin,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getTrackItems"]);
          if (isCreatOrder) handleCreateOrder();
          if (isPendCheckout) handlePendCheckout();
          if (isGenerateBill) handleGenerateBill();
        },
        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  const resetDiscoutModals = (key, status = true) => {
    setDiscoutModals({
      isDiscount: false,
      isDelivery: false,
      isLoyalty: false,
      [key]: status,
    });
  };

  return {
    tableDetails,
    isFetchingCartItems,
    cartItems,
    total_items,
    totalPrice:
      total_amount -
      ((discount === "" ? 0 : Number(discount)) +
        (loyaltyDetails.giftcard_amount
          ? Number(loyaltyDetails.giftcard_amount)
          : 0) +
        (loyaltyDetails.loyalty_point_amount
          ? Number(loyaltyDetails.loyalty_point_amount)
          : 0)) +
      (deliveryFee === "" ? 0 : Number(deliveryFee)),
    total_item_price: total_items_amount,
    vat,
    vat_amount,
    isCreatingOrder: handleCreateOrderMutation?.isLoading,
    isGeneratingBill: handleGenerateBillMutation?.isLoading,
    isPendingSessions: handlePendCheckout?.isLoading,
    isBulkAdding: bulkAddToCartMutation.isLoading,
    discount,
    deliveryFee,
    business_role,
    pageDetails,
    discoutModals,
    loyaltyDetails,
    totalAmount: total_amount,
    store_id,
    service_charge,
    service_charge_amount,
    setLoyaltyDetails,
    setDiscount,
    setDeliveryFee,
    bulkAddToCart,
    clearCartItems,
    resetDiscoutModals,
  };
};
export default useStoreCart;
