import { useQuery } from "react-query";
import { getEODPayment, getTransactions } from "../../../services/transaction";

export const useGetTransactions = ({ queries = "" }) => {
  return useQuery(
    ["getTransactions", queries],
    () => getTransactions(queries).then((res) => res?.data),
    {
      // staleTime: Infinity,
      enabled: queries === "" ? false : true,
    },
  );
};

export const useGetPaymentEODTransactions = (data) => {
  return useQuery(
    ["getTransactions", data],
    () => getEODPayment(data).then((res) => res?.data),
    {
      staleTime: Infinity,
      enabled: false,
    },
  );
};
