import { useMutation } from "react-query";
import {
  removeCustomer,
  createCustomer,
  updateCustomer,
  toggleCustomerLoyaltyEligibity,
} from "../../../services/customer";

export const useCreateCustomerMutation = () => {
  return useMutation((data) => createCustomer(data));
};

export const useEditCustomerMutation = () => {
  return useMutation((data) => updateCustomer(data));
};

export const useDeleteCustomerMutation = () => {
  return useMutation((data) => removeCustomer(data));
};

export const useCustomerLoyaltyEligibilityToggleMutation = () => {
  return useMutation((data) => toggleCustomerLoyaltyEligibity(data));
};
