/* eslint-disable react/jsx-key */
import "./UsersTable.scss";
import useUsersTableLogic from "./UsersTableLogic";
import secondaryComponents from "..";
import primaryComponents from "../../../components/primaryComponents";
import Icon from "../../../assets/icons";

const UsersTable = () => {
  const {
    instance,
    isFetchingUsers,
    showInviteModal,
    setShowInviteModal,
    userRole,
    userEmail,
    storeAccessList,
    setUserRole,
    setUserEmail,
    subAccountList,
    setsubAccountList,
    usersList,
    fetchUsersList,
    showDeleteUserModal,
    setShowDeleteUserModal,
    setStoreAccessList,
  } = useUsersTableLogic();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isFetchingUsers}
      classNames="mt-5"
    >
      <div className="users-table">
        {usersList.length > 0 ? (
          <>
            {/* shows on desktop */}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th className="font-weight-semibold">S/N</th>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                    <th className="font-weight-semibold">Actions</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps({ key: i })}>
                      <td>{i + 1}</td>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td>
                        {/* edit icon */}
                        <primaryComponents.Button
                          onClick={() => {
                            setUserEmail(usersList[i].email);
                            setUserRole(usersList[i].role.charAt(0));
                            setsubAccountList(usersList[i].businesses_id);
                            setStoreAccessList(usersList[i].store_id);
                            setShowInviteModal(true);
                          }}
                          classNames={"btn btn--primary smallBtn"}
                        >
                          Update
                        </primaryComponents.Button>

                        {/* delete icon */}
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Settings"}
                          permissionChildKey="can_delete_users"
                        >
                          <primaryComponents.Button
                            onClick={() => {
                              setUserEmail(usersList[i].email);
                              setShowDeleteUserModal(true);
                            }}
                            classNames={"ml-2 btn btn--outline-red smallBtn"}
                          >
                            Remove
                          </primaryComponents.Button>
                        </secondaryComponents.NavigateWithPermission>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* display on mobile */}
            <div className="mobile-table">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div
                    className="mobile-table__row"
                    {...row.getRowProps({ key: i })}
                  >
                    {row.cells.map((cell, j) => (
                      <div
                        className="row-item"
                        {...cell.getCellProps({ key: j })}
                      >
                        <h6 className="row-item__title">
                          {cell.column.Header}
                        </h6>
                        <p className="row-item__value">{cell.render("Cell")}</p>
                      </div>
                    ))}
                    {/*  action */}
                    <div className="row-item">
                      <primaryComponents.Button
                        onClick={() => {
                          setUserEmail(usersList[i].email);
                          setUserRole(
                            usersList[i].role.charAt(0).toUpperCase() +
                              usersList[i].role.slice(1).toLowerCase(),
                          );
                          setsubAccountList(usersList[i].businesses_id);
                          setStoreAccessList(usersList[i].store_id);
                          setShowInviteModal(true);
                        }}
                        classNames={"btn btn--primary smallBtn"}
                      >
                        Update
                      </primaryComponents.Button>
                      <p className="row-item__value">
                        {/* edit icon */}

                        {/* delete icon */}
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Settings"}
                          permissionChildKey="can_delete_users"
                        >
                          <primaryComponents.Button
                            classNames={"btn btn--outline-red smallBtn"}
                            onClick={() => {
                              setUserEmail(usersList[i].email);
                              setShowDeleteUserModal(true);
                            }}
                          >
                            <img src={Icon.deleteIcon} alt="" />
                          </primaryComponents.Button>
                        </secondaryComponents.NavigateWithPermission>
                        {/* <span
                          style={{ textDecoration: "underline" }}
                          className="ml-3 error--text"
                          onClick={() => {
                            setUserEmail(usersList[i].email);
                            setShowDeleteUserModal(true);
                          }}
                        >
                          remove
                        </span> */}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          // shows if user is empty
          <div className="text-center mt-5">
            <h4>You have not invited anyone yet</h4>
            <p>When you do, the users would appear here.</p>
          </div>
        )}
      </div>

      {/* edit user role modal */}
      {showInviteModal && (
        <secondaryComponents.EditInviteUser
          setShowInviteModal={setShowInviteModal}
          showInviteModal={showInviteModal}
          userEmail={userEmail}
          userRole={userRole}
          storeAccessList={storeAccessList}
          fetchUsersList={fetchUsersList}
          subAccountList={subAccountList}
        />
      )}

      {/* delete user modal */}
      {showDeleteUserModal && (
        <secondaryComponents.DeleteInvitedUser
          setShowDeleteUserModal={setShowDeleteUserModal}
          showDeleteUserModal={showDeleteUserModal}
          userEmail={userEmail}
          fetchUsersList={fetchUsersList}
        />
      )}
    </secondaryComponents.LoaderHelper>
  );
};

export default UsersTable;
