import primaryComponents from "../../../primaryComponents";
import AddCustomerSection from "../AddCustomerSection";
import "./WarehousePayment.scss";
import WarehousePaymentLogic from "./WarehousePaymentLogic";

const WarehousePayment = ({ closePaymentSession, orderDetails }) => {
  const {
    isConfirmingPayment,
    payViaWarehouseCheckout,
    customer,
    customerDetails,
    setCustomerDetails,
  } = WarehousePaymentLogic({ closePaymentSession, orderDetails });

  return (
    <>
      <div className="warehouse-payment">
        <p className="dark--text mx-auto black--text warehouse-payment__text_title">
          Attention: (PR) Checkout
        </p>
        <p
          className="dark--text mx-auto small-text-size mb-5"
          style={{ textAlign: "justify" }}
        >
          Please be aware that selecting this payment method will classify the
          items as unpaid, resulting in them not being recorded as sales for
          your business. To accurately track direct sales from the public
          relation, we recommend using an alternative payment method during
          checkout.
        </p>

        <AddCustomerSection
          setCustomerDetails={setCustomerDetails}
          customerDetails={customerDetails}
          customer={customer}
          convertPr={true}
        />
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={payViaWarehouseCheckout}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default WarehousePayment;
