import primaryComponents from "../../../../primaryComponents";
import { Validation } from "./Validation";
import { CautionIcon } from "../../../../../assets/svgIcons";
import useSubWallet from "./useSubWallet";

const AddSubWallet = ({ storeWalletDetails, closeModal, isAddSubWallet }) => {
  const {
    isSubName,
    setIsSubName,
    handleAddSubWallet,
    createSubWallet,
    editSubWallet,
    handleEditSubWallet,
  } = useSubWallet({
    storeWalletDetails,
    closeModal,
    isAddSubWallet,
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
  } = Validation();
  return (
    <div>
      <h4>
        {isAddSubWallet === "edit" ? "Edit Sub-wallet" : "Add Sub-wallet"}
      </h4>
      <hr style={{ marginLeft: "-15px", marginRight: "-15px" }} />
      {isAddSubWallet === "edit" ? null : (
        <div className="pending--squared m-4">
          <div className="d-flex align-items-center" style={{ gap: "3px" }}>
            <CautionIcon />
            <h2 className="smallest-text-size mt-2">Sub-wallets</h2>
          </div>
          <p className="smallest-text-size pl-4">
            Organize your money, <br /> your way. Create sub-wallets within a
            main wallet to manage different budgets, savings or expenses—all in
            one place.
          </p>
        </div>
      )}
      <div className="py-4 px-3">
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginBottom: "5px",
          }}
        >
          <h6 className="small-text-size">Sub-wallet Name</h6>
          <span className="small-text-size">
            Examples of a sub-wallet name could be: Name of another user,
            upkeep, savings
          </span>
        </p>
        <primaryComponents.InputFieldV2
          name="subName"
          control={control}
          isRequired={true}
          placeholder="Enter name"
          value={isSubName}
          onChange={({ target: { value } }) => setIsSubName(value)}
          classNames="white"
          errorMessage={
            errors.subName && {
              message: errors.subName.message,
            }
          }
          onKeyUp={() => errors.subName !== undefined && trigger("subName")}
          register={register}
        />
        <div
          className="d-flex justify-content-between mt-4"
          style={{ gap: "20px" }}
        >
          <primaryComponents.Button
            classNames={"btn btn--outline cover"}
            onClick={() => closeModal()}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            onClick={
              isAddSubWallet === "edit"
                ? handleSubmit(handleEditSubWallet)
                : handleSubmit(handleAddSubWallet)
            }
            isDisabled={createSubWallet.isLoading || editSubWallet.isLoading}
            isLoading={createSubWallet.isLoading || editSubWallet.isLoading}
            loadingText={
              isAddSubWallet === "edit" ? "Updating..." : "Creating..."
            }
          >
            {isAddSubWallet === "edit" ? "Update" : "Add"}
          </primaryComponents.Button>
        </div>
      </div>
    </div>
  );
};
export default AddSubWallet;
