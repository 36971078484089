import { useState } from "react";
import { AngleDown } from "../../../../assets/svgIcons";
import primaryComponents from "../../../primaryComponents";
// import { useClickOutSide } from "../../../../hooks/useClickOutside";
import styles from "./BillUpdate.module.scss";
import {
  useGetProductDetails,
  useGetServiceDetails,
} from "../../../../hooks/api/queries";
import secondaryComponents from "../..";
import { formatCurrency } from "../../../../helpers";
import { useOtpAuthTrigger } from "../../../../hooks/useOtpAuthTrigger";

const PricingTypeSelector = ({ item, ...rest }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <span>
      <span
        className="d-flex cursor-pointer"
        style={{
          background: "hsla(0, 0%, 94%, 1)",
          padding: "5px 10px",
          borderRadius: "10px",
          width: "fit-content",
        }}
        onClick={() => setIsActive((prev) => !prev)}
      >
        {item?.is_custom_price ? "Custom" : item?.pricing_type_label}
        <primaryComponents.Button
          classNames={"btn btn--outline ml-2"}
          style={{ width: "15px", height: "15px", padding: "0" }}
        >
          <AngleDown style={{ fontSize: "10px" }} />
        </primaryComponents.Button>
      </span>

      <secondaryComponents.Modal
        isActive={isActive}
        width={450}
        closeModal={() => setIsActive(false)}
      >
        <DropdownSection
          {...rest}
          item={item}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      </secondaryComponents.Modal>
    </span>
  );
};
export default PricingTypeSelector;

const DropdownSection = ({
  currentPricingId,
  itemId,
  storeInfo,
  isService = false,
  onSetPricingType,
  onSetPricingCustomPrice,
  setIsActive,
  isActive,
  item,
  params,
}) => {
  console.log(item);
  const { handleOrderModuleAuth } = useOtpAuthTrigger({
    storeId: storeInfo?.id,
    params,
  });
  const [selectedPricingId, setSelectedPricingId] = useState(
    item?.is_custom_price ? "custom" : currentPricingId,
  );
  const { isLoading, data: productDetails } = useGetProductDetails(
    item?.variant_id ? item?.product_id : itemId,
    !isService && isActive,
  );
  const { isLoadingService, data: serviceDetails } = useGetServiceDetails(
    itemId,
    isService && isActive,
  );
  const productName = isService
    ? "store_service_properties"
    : "store_product_properties";
  const productProperties =
    productDetails?.variants
      ?.find((variant) => `${variant?.id}` === `${item?.variant_id}`)
      ?.store_variant_properties?.find(
        (property) => `${property?.store_id}` === `${storeInfo?.id}`,
      ) ||
    productDetails?.[productName]?.find(
      (property) => `${property?.store_id}` === `${storeInfo?.id}`,
    ) ||
    serviceDetails?.[productName]?.find(
      (property) => `${property?.store_id}` === `${storeInfo?.id}`,
    );
  const pricingTypes = productProperties?.pricings || [];
  const [customPrice, setCustomPrice] = useState(
    item?.is_custom_price ? item?.unit_price : 0,
  );

  return (
    <span className={styles.dropdown}>
      <h5>Select Pricing Type</h5>

      <span className="d-flex flex-column">
        <span className={styles["row-item"]}>
          <span className="smallest-text-size">Pricing Type</span>
          <span className="smallest-text-size">Pricing</span>
        </span>

        <secondaryComponents.LoaderHelper
          isLoading={isLoading || isLoadingService}
          classNames={"mt-4"}
        >
          {storeInfo?.allow_custom_price && (
            <span className={styles["row-item"]}>
              <label
                htmlFor="custom"
                className="dark--text cursor-pointer d-flex align-items-center"
              >
                <primaryComponents.RadioButton
                  name="billPricingType"
                  id={"custom"}
                  checked={selectedPricingId === "custom"}
                  setRadioValue={() => {
                    handleOrderModuleAuth({
                      actionKey: "use_custom_pricing_on_order",
                      onSuccessCallBack: () => {
                        setSelectedPricingId("custom");
                        onSetPricingCustomPrice(customPrice);
                      },
                    });
                  }}
                />
                <span className="ml-2">Custom</span>
              </label>
              <div style={{ width: "170px" }}>
                <primaryComponents.AmountField
                  classNames={`white ${styles["input-height"]}`}
                  floatError={false}
                  value={customPrice}
                  isDisabled={selectedPricingId !== "custom"}
                  currency_code={storeInfo?.currency_code}
                  onChange={({ target: { value } }) => {
                    setCustomPrice(value);
                    onSetPricingCustomPrice(value);
                  }}
                />
              </div>
            </span>
          )}

          {pricingTypes?.map((item) => (
            <span className={styles["row-item"]} key={item?.pricing_type?.id}>
              <label className="dark--text cursor-pointer d-flex align-items-center">
                <primaryComponents.RadioButton
                  name="billPricingType"
                  id={item?.pricing_type?.id}
                  checked={selectedPricingId === item?.pricing_type?.id}
                  setRadioValue={() => {
                    const unitPrice = (
                      item?.price -
                      (item?.price * item?.discount_rate) / 100
                    ).toFixed(2);
                    setSelectedPricingId(item?.pricing_type?.id);
                    onSetPricingType(
                      item?.pricing_type?.id,
                      unitPrice,
                      item?.pricing_type?.label,
                    );
                  }}
                />
                <span htmlFor={item?.pricing_type?.id} className="ml-2">
                  {item?.pricing_type?.label}
                </span>
              </label>

              <span className="dark--text">
                {formatCurrency({
                  value: (
                    item?.price -
                    (item?.price * item?.discount_rate) / 100
                  ).toFixed(2),
                  currencyCode: storeInfo?.currency_code,
                })}
              </span>
            </span>
          ))}

          <primaryComponents.Button
            classNames={"mt-3 btn btn--primary smallBtn ml-auto"}
            onClick={() => setIsActive(false)}
          >
            Continue
          </primaryComponents.Button>
        </secondaryComponents.LoaderHelper>
      </span>
    </span>
  );
};
