import { useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  downloadSalesEndOfDay,
  endOfDayDetails,
} from "../../../../services/sales-point";

const useEndOfDayDetails = ({ endOfDayFormDetails }) => {
  const [isDownloadReport, setIsDownloadReport] = useState(false);
  const [endOfDayId, setEndOfDayId] = useState(null);
  const [loadingPercent, setLoadingPercent] = useState("50%");
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadModal, setDownloadModal] = useState(false);
  const productSummaryLoadingRef = useRef(false);
  const salesSummaryLoadingRef = useRef(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { mutate: exportEndOfDay, isLoading } = useMutation((data) =>
    downloadSalesEndOfDay(data),
  );

  const { data: endOfDayDetailsData } = useQuery(
    ["endOfDayDetails", endOfDayId],
    () => endOfDayDetails({ id: endOfDayId }).then((res) => res?.data?.data),
    {
      refetchInterval: endOfDayId ? 2000 : false,
      enabled: endOfDayId ? true : false,
      onSuccess: (data) => {
        if (data.status === "pending") {
          setLoadingPercent("80%");
        }
        if (data.status === "completed") {
          setDownloadUrl(data?.url);
          setEndOfDayId(null);
          setLoadingPercent("100%");
        }
      },
    },
  );

  const downloadEndOfDay = ({ download_format, delivery_method, type }) => {
    if (type === "product_summary") {
      productSummaryLoadingRef.current = true;
    } else if (type === "sales_summary") {
      salesSummaryLoadingRef.current = true;
    }
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    exportEndOfDay(
      cleanObject({
        store_id: endOfDayFormDetails?.stores.map((item) => item?.value),
        initiator_id: endOfDayFormDetails?.initiators?.some(
          (item) => item?.value === "select all",
        )
          ? null
          : endOfDayFormDetails?.initiators?.length > 0
            ? endOfDayFormDetails?.initiators.map((item) => item?.value)
            : null,
        download_format: download_format,
        start_date: endOfDayFormDetails?.startDate,
        end_date: endOfDayFormDetails?.endDate,
        start_time: endOfDayFormDetails?.startTime,
        end_time: endOfDayFormDetails?.endTime,
        order_source: endOfDayFormDetails?.order_source.value
          ? [endOfDayFormDetails?.order_source.value]
          : [],
        delivery_method,
        type,
      }),
      {
        onSuccess: (res) => {
          if (delivery_method === "download") {
            const receiptLink = res?.data?.data?.url;
            if (receiptLink) return window.open(receiptLink, "_blank");
            if (res?.data?.data?.end_of_day_log_id)
              setEndOfDayId(res?.data?.data?.end_of_day_log_id);
          } else {
            triggerToast(res?.data?.detail, "success");
          }
        },
        onSettled: () => {
          if (type === "product_summary") {
            productSummaryLoadingRef.current = false;
          } else if (type === "sales_summary") {
            salesSummaryLoadingRef.current = false;
          }
          setRequestLoaderProgress(100);
        },
      },
    );
  };

  return {
    downloadUrl,
    loadingPercent,
    isDownloadReport,
    endOfDayDetailsData,
    sucessEndOfDay: loadingPercent === "100%" ? true : false,
    setIsDownloadReport,
    downloadEndOfDay,
    setDownloadModal,
    downloadModal,
    isLoading,
    productSummaryLoadingRef,
    salesSummaryLoadingRef,
  };
};
export default useEndOfDayDetails;
