import primaryComponents from "../../primaryComponents";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

export const WarningDialog = ({
  title = null,
  children,
  isLoading,
  closeModal = () => {},
  handler = () => {},
}) => {
  return (
    <div className="d-flex flex-column" style={{ gap: "10px" }}>
      <h4 className="text-center font-weight-semibold">{title}</h4>
      <div className={globalStyles.cardWithBackground}>{children}</div>

      <div
        className="d-flex justify-content-between mt-3"
        style={{ gap: "10px" }}
      >
        <primaryComponents.Button
          onClick={closeModal}
          classNames={"btn btn--outline"}
        >
          Cancel
        </primaryComponents.Button>

        <primaryComponents.Button
          onClick={() => handler()}
          classNames={"btn btn--danger"}
          isLoading={isLoading}
          isDisabled={isLoading}
          loadingText="Loading..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
