import { useContext, useState } from "react";
import {
  useCreateSubWalletMutation,
  useEditSubWalletMutation,
} from "../../../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";
import { ToastContext } from "../../../../../hooks/context";
import { cleanObject } from "../../../../../helpers";

const useSubWallet = ({ storeWalletDetails, closeModal, isAddSubWallet }) => {
  const [isSubName, setIsSubName] = useState(
    isAddSubWallet === "edit" ? storeWalletDetails?.tag : "",
  );
  const createSubWallet = useCreateSubWalletMutation();
  const editSubWallet = useEditSubWalletMutation();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const handleAddSubWallet = () => {
    createSubWallet.mutate(
      cleanObject({
        tag: isSubName,
        customer_id: storeWalletDetails?.customer?.id,
      }),
      {
        onSuccess: () => {
          triggerToast(
            `subwallet has been created to customer: (${storeWalletDetails?.customer?.full_name}) wallet`,
            "success",
          );
          queryClient.invalidateQueries(["getStoreWalletDetails"]);
          queryClient.invalidateQueries(["getStoreWallets"]);
          queryClient.invalidateQueries(["getCustomerStoreWallets"]);
          queryClient.invalidateQueries(["getStoreWalletTransaction"]);
          closeModal();
        },
      },
    );
  };

  const handleEditSubWallet = () => {
    editSubWallet.mutate(
      cleanObject({
        id: storeWalletDetails?.id,
        tag: isSubName,
      }),
      {
        onSuccess: () => {
          triggerToast(
            `subwallet updated: (${storeWalletDetails?.customer?.full_name}) wallet`,
            "success",
          );
          queryClient.invalidateQueries(["getStoreWalletDetails"]);
          queryClient.invalidateQueries(["getStoreWallets"]);
          queryClient.invalidateQueries(["getCustomerStoreWallets"]);
          queryClient.invalidateQueries(["getStoreWalletTransaction"]);
          closeModal();
        },
      },
    );
  };

  return {
    isSubName,
    setIsSubName,
    handleAddSubWallet,
    createSubWallet,
    editSubWallet,
    handleEditSubWallet,
  };
};

export default useSubWallet;
