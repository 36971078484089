import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import AddCustomerSection from "../AddCustomerSection";
import { Validation } from "./Validation";
import useStoreWalletPayment from "./useStoreWalletPayment";
import { useEffect } from "react";
import secondaryComponents from "../..";

const StoreWalletPayment = ({ orderDetails, closePaymentSession }) => {
  const {
    isConfirmingPayment,
    customerDetails,
    isOtpWidget,
    store_currency,
    setIsOtpWidget,
    handlePayViaStoreWallet,
    setCustomerDetails,
    walletDetails,
    setWalletDetails,
  } = useStoreWalletPayment({
    closePaymentSession,
    orderDetails,
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = Validation({ customerDetails });

  useEffect(() => {
    if (customerDetails) setError("customer", "");
    setValue("customer", customerDetails?.customerId);
  }, [customerDetails]);

  return (
    <>
      <div className="cash-payment mt-5">
        <AddCustomerSection
          setCustomerDetails={(info) => setCustomerDetails(info)}
          customerDetails={customerDetails}
          showAddCustomer={false}
          showLoyalty={false}
          showWalletBalance={true}
          showStoreWallet={true}
          walletDetails={walletDetails}
          setWalletDetails={setWalletDetails}
        />
        <p
          className={`smallest-text-size error-message text-danger ${errors.customer?.message ? "show" : "invisible"}`}
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          <span>{errors.customer?.message}</span>
        </p>
      </div>

      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={
            isConfirmingPayment ||
            walletDetails === null ||
            customerDetails === null
          }
          onClick={handleSubmit(handlePayViaStoreWallet)}
        >
          Confirm
        </primaryComponents.Button>
      </div>

      <secondaryComponents.Modal
        isActive={isOtpWidget !== null}
        closeModal={() => setIsOtpWidget(null)}
      >
        <secondaryComponents.StoreWalletOtpWidget
          debitSessionDetails={isOtpWidget}
          closeModal={() => setIsOtpWidget(null)}
          debitDetails={{
            amount: orderDetails?.total_amount,
            customer: customerDetails,
          }}
          currencyCode={store_currency}
          callBackFunc={() => {
            closePaymentSession();
          }}
          is_order={true}
          isViaSellMode={true}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default StoreWalletPayment;
