import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    customerName: yup.string().required("customer Name is required"),
    customerPhone: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),
    customerEmail: yup
      .string()
      .email("Invalid email address")
      .max(50, "Maximum of 50 characters is allowed"),
    customerCountry: yup.string(),
    customerState: yup.string(),
    //customerStoreId: yup.string().required("customer store id is required"),
  })
  .required("All fields are required");

const Validation = ({ customerDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customerName: customerDetails?.full_name,
      customerPhone: customerDetails?.phone,
      customerEmail: customerDetails?.email,
      customerCountry: customerDetails?.country,
      customerState: customerDetails?.state,
      customerCity: customerDetails?.city,
      //customerStoreId: customerDetails?.store_id,
    },
  });
};

export default Validation;
