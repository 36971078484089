import BankboxManager from "@ravenpay/bankbox-me-sdk/bundles/index.esm.js";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeRavenPayment,
  resetHasCompletedPayment,
  resetPopUpRaven,
  setRavenCredential,
} from "../store/modules/payments";

export const useRavenPayment = ({ allowMount = false }) => {
  const bankboxRef = useRef(null);
  const { raven } = useSelector((state) => state.paymentsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (raven?.popUpRaven) {
      handlePayment(raven?.amount);
      dispatch(resetPopUpRaven());
    }
  }, [raven?.popUpRaven]);

  useEffect(() => {
    if (raven?.hasCompletedPayment) dispatch(resetHasCompletedPayment());
  }, [raven?.hasCompletedPayment]);

  useEffect(() => {
    if (!allowMount) return;
    bankboxRef.current = new BankboxManager({
      appName: "lumi",
      environment: process.env.REACT_APP_RAVEN_ENV,
      widgetOptions: {
        isPersistent: true,
      },
      onSuccess: (data) => {
        dispatch(setRavenCredential({ rrn: data?.rrn, paymentType: "raven" }));
        dispatch(closeRavenPayment());
        if (!raven?.allowFeedBack) bankboxRef?.current?.close();
      },
      onLoad: () => {
        console.log("Bankbox is ready");
      },
      onError: (error) => {
        console.error("An error occurred:", error);
      },
    });

    return () => {
      if (bankboxRef?.current) bankboxRef.current = null;
    };
  }, [allowMount]);

  const handlePayment = (amount = null) => {
    if (!amount) return bankboxRef?.current?.open();

    bankboxRef?.current?.$event.emit(
      bankboxRef?.current?.constants.sdkPaymentData,
      { amount: Math.ceil(amount) },
    );
    bankboxRef?.current?.open({ amount });
  };

  return {
    bankbox: bankboxRef?.current,
    handlePayment,
  };
};
