import { useState, useContext } from "react";
import { reverseOrder } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import {
  calculateCartItem,
  cleanObject,
  deepCopyFunction,
  findPricingViaIdHelper,
  getRandomIntInclusive,
} from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  useOutletContext,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { setCartDetails } from "../../../store/modules/storeCart";
import {
  useGetAllProductsList,
  useGetPricingTypes,
} from "../../../hooks/api/queries/useInventory";
import { DEFAULT_PRICING_ID } from "../../../helpers/constants";
import { useCloseOtpAuthorizationPrompt } from "../../../hooks/useCloseOtpAuthorizationPrompt";

const useProductsPage = () => {
  const { store_id } = useParams();
  const [search, setSearch] = useSearchParams();
  const queries = (() => {
    let params = `?store_id=${store_id}&exclude_manage_mode_only_products=${true}`;
    for (const entry of search.entries()) {
      if (entry[0] !== "bill_id" && entry[0] !== "order_id")
        params = `${params}&${entry[0]}=${entry[1]}`;
    }
    return params;
  })();
  const { productContainer } = useOutletContext();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [refetchCart, setRefetchCart] = useState(false);
  const { data: cartItems, ...otherCartInfo } = useSelector(
    (state) => state.cartReducer.cartDetails,
  );
  const { tableDetails } = useSelector((state) => state.tableReducer);
  const navigate = useNavigate();
  const [isActiveCart, setIsActiveCart] = useState(false);
  const [viewType, setViewType] = useState("gridView");
  const dispatch = useDispatch();
  const [isRefreshCart, setIsRefreshCart] = useState(false);
  const [orderId, setOrderId] = useState("");
  const { closeOtpAuthorizationPrompt } = useCloseOtpAuthorizationPrompt();
  const queryClient = useQueryClient();
  const { data: pricingTypes, isLoading: isLoadingPricingTypes } =
    useGetPricingTypes();
  const [pricingType, setPricingType] = useState(DEFAULT_PRICING_ID);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const showGeneratBills = (() => {
    return storeList?.find((item) => `${item?.id}` === store_id)
      ?.allow_generate_bill;
  })();

  const {
    isLoading: isfetchingProducts,
    data: products,
    refetch: handleFetchProducts,
  } = useGetAllProductsList({
    queries,
    onSuccess: (res) => {
      if (search.get("is_scan") === "true")
        if (res?.data?.length > 0) addToCartForScanedproduct(res?.data?.[0]);
    },
  });

  const addToCartForScanedproduct = (product) => {
    const data = { quantity: 1, product };
    if (product?.variants?.length > 0) data.variant = product?.variants?.[0];
    handleAddToCart(data);
    search.delete("query");
    search.delete("is_scan");
    setSearch(search, { replace: true });
  };

  const handleAddToCart = ({
    product,
    quantity,
    variant,
    item_numbers = null,
  }) => {
    updateCart({ product, quantity, variant, item_numbers });
  };

  const handleReduceCartItem = ({
    product,
    quantity,
    variant,
    item_numbers = null,
  }) => {
    updateCart({ product, quantity, variant, item_numbers });
  };

  const updateCart = ({
    product,
    quantity,
    variant = null,
    pricingTypeId = pricingType,
    item_numbers = null,
  }) => {
    const isService = product?.is_service ? true : false;
    const productType = isService
      ? "store_service_properties"
      : "store_product_properties";

    const stockQuantity = variant
      ? variant?.store_variant_properties?.[0]?.stock_quantity
      : product?.[productType]?.[0]?.stock_quantity;
    quantity = quantity.toFixed(2);
    const cartItemName = variant
      ? `${product?.name} -- ${variant?.option}: ${variant?.value}`
      : product?.name;

    if (
      !isService &&
      product?.composite_product_type !== "ON_DEMAND" &&
      quantity > stockQuantity
    )
      return triggerToast(
        `Quantity can not be more than available quantity of ${stockQuantity} for item ( ${cartItemName} )`,
        "warning",
      );

    const newCartItems = deepCopyFunction(cartItems);

    const currentCartItemIndex = newCartItems?.findIndex(
      (item) =>
        `${item?.id}` === `${product?.id}${variant?.id || ""}` &&
        item?.is_service === isService,
    );
    const currentCartItem = newCartItems?.[currentCartItemIndex];
    const pricingList = variant
      ? variant?.store_variant_properties?.[0]?.pricings
      : product?.[productType]?.[0]?.pricings;
    const pricingDetails = findPricingViaIdHelper(
      currentCartItem?.pricing_type_id || pricingTypeId,
      pricingList,
    );
    const unitPrice = (
      pricingDetails?.price -
      (pricingDetails?.price * pricingDetails?.discount_rate) / 100
    ).toFixed(2);

    if (currentCartItem) {
      if (quantity > 0) {
        newCartItems[currentCartItemIndex] = {
          ...currentCartItem,
          quantity: Number(quantity),
          total_price: newCartItems?.[currentCartItemIndex]?.custom_price
            ? newCartItems?.[currentCartItemIndex]?.custom_price *
              Number(quantity)
            : unitPrice * Number(quantity),
          total_price_without_discount:
            pricingDetails?.price * Number(quantity),
          item_numbers,
        };
      } else {
        newCartItems.splice(currentCartItemIndex, 1);
      }
    } else {
      newCartItems.unshift({
        id: `${product?.id}${variant?.id || ""}`,
        quantity: Number(quantity),
        pricing_type_id: pricingTypeId,
        is_service: product?.is_service,
        cart_item_name: cartItemName,
        total_price: (unitPrice * Number(quantity)).toFixed(2),
        discount_rate: pricingDetails?.discount_rate,
        unit_price: Number(unitPrice),
        total_price_without_discount: pricingDetails?.price * Number(quantity),
        pricings: pricingList,
        item_numbers,
        product: {
          image_url: product?.media_files?.[0]?.url || product?.image_url,
          category: product?.category,
          id: product?.id,
          name: product?.name,
          [productType]: product?.[productType],
          is_service: isService,
          variant: variant,
          composite_product_type: product?.composite_product_type,
        },
        product_unit: product?.unit,
      });
    }

    const data = calculateCartItem({
      cart_items: newCartItems,
      ...otherCartInfo,
    });
    addCartItemToLocalStorage({
      ...data,
    });
  };

  const addCartItemToLocalStorage = (cartData) => {
    dispatch(setCartDetails(cartData));
    localStorage.setItem(
      `cartItems${store_id}`,
      JSON.stringify(cartData?.data),
    );
  };

  const getCartItems = (productId, typeKey = "product") => {
    const fountItem = cartItems?.find(
      (item) => item?.[typeKey]?.id === productId,
    );
    return fountItem;
  };

  const closeStoreCheckout = () => {
    navigate(`/sell-mode/${store_id}`);
  };

  const gotoPage = (pageNumber) => {
    productContainer.current.scrollTo(0, 0);
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const { mutate: cancelOrder } = useMutation((data) => reverseOrder(data));
  const cancelCurrentOrder = (
    orderNo = null,
    callBackFunc = () => {
      closeStoreCheckout();
      handleFetchProducts();
    },
  ) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    const data = { orderId: search.get("order_id") || orderId };

    if (orderNo) data.orderId = orderNo;
    cancelOrder(cleanObject(data), {
      onSuccess: () => {
        triggerToast("Order Cancelled Successfully", "warning");
        callBackFunc();
        queryClient.invalidateQueries(["getOrders"]);
        queryClient.invalidateQueries(["getProductList"]);
        closeOtpAuthorizationPrompt();
      },
      onError: (error) => {
        const status = error?.response?.status;
        const detail = error?.response?.data?.detail;

        if (status === 400) {
          if (detail === "Order with status: COMPLETED can not be cancel") {
            closeStoreCheckout();
          }
        }
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    tableDetails,
    products: products?.data || [],
    isfetchingProducts,
    refetchCart,
    cartItems,
    search,
    productPageDetails: products?.meta || {},
    isActiveCart,
    store_id,
    viewType,
    isRefreshCart,
    isLoadingPricingTypes,
    pricingTypes,
    pricingType,
    showGeneratBills,
    setPricingType,
    setIsRefreshCart,
    setViewType,
    handleReduceCartItem,
    getCartItems,
    setRefetchCart,
    handleAddToCart,
    closeStoreCheckout,
    setIsActiveCart,
    gotoPage,
    cancelCurrentOrder,
    handleFetchProducts,
    addCartItemToLocalStorage,
    orderId,
    setOrderId,
  };
};

export default useProductsPage;
