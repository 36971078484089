import { useState, useContext } from "react";
import {
  securityQuestionAnswer,
  sendSecurityPin,
} from "../../../services/settings";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useMutation } from "react-query";
import { useGetSecurityQuestions } from "../../../hooks/api/queries/useSettings";

const SecurityPinLogic = () => {
  const [verificationModal, setVerificationModal] = useState(null);
  const [pin, setPin] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [confirmedAnswer, setIsConfirmedAnswer] = useState(false);
  const [confirmedPin, setIsConfirmedPin] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const { mutate: sendPin, isLoading: isSendingPin } = useMutation((data) =>
    sendSecurityPin(data),
  );

  const { mutate: sendSecurityQuestion, isLoading: isSendingSecurityQuestion } =
    useMutation((data) => securityQuestionAnswer(data));

  const handleSendPin = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({
      security_pin: pin,
    });

    sendPin(data, {
      onSuccess: () => {
        triggerToast("Pin Saved", "success");
        setIsConfirmedAnswer(false);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const handleSendSecurityQuestion = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({
      security_question_id: questionId,
      security_answer: answer,
    });

    sendSecurityQuestion(data, {
      onSuccess: () => {
        triggerToast("Security Question Saved", "success");
        setIsConfirmedPin(false);
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const { data: securityQuestions, isLoadingSecurityQuestions } =
    useGetSecurityQuestions();

  return {
    pin,
    setPin,
    verificationModal,
    setVerificationModal,
    setIsConfirmedAnswer,
    confirmedAnswer,
    confirmedPin,
    setIsConfirmedPin,
    isSendingPin,
    handleSendPin,
    securityQuestions,
    isLoadingSecurityQuestions,
    isSendingSecurityQuestion,
    handleSendSecurityQuestion,
    questionId,
    answer,
    setQuestionId,
    setAnswer,
  };
};
export default SecurityPinLogic;
